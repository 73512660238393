import moment from 'moment'
import React, { FunctionComponent } from 'react'

import { IPersonCVInfo } from '../../../../interfaces/persons/IPerson'
import { ICVProjectRole, ICVProjectSkill } from '../../../../interfaces/projects/IProjects'
import { ICVSkills } from '../../../../interfaces/skills/ISkills'
import NetumJob from './NetumJob'

interface OwnProps {
  detailsData: IPersonCVInfo
  netumAloitus: string
  workExperienceVisibility: boolean
  educationVisibility: boolean
  certificatesVisibility: boolean
  skillsVisibility: boolean
  languageVisibility: boolean
  manDaysVisibility: string
  currentEmployerName: string
}

const SkillDate = ({ skill, pSkill: pSkill }: { skill: ICVSkills | undefined; pSkill: ICVProjectSkill[] | undefined }) => {
  if (pSkill === undefined || skill === undefined) {
    return <p></p>
  }
  const projectSkill = pSkill.filter((pSkill) => {
    return pSkill.skillName === skill.skillName
  })
  if (projectSkill.length === 0) {
    return <p></p>
  }

  if ('endDate' in projectSkill[0]) {
    return <p className='plainText'>{moment(projectSkill[0].endDate?.toString()).format('M/YYYY')}</p>
  } else {
    return <p></p>
  }
}
const RoleName = ({ roleName, projectId }: { roleName: ICVProjectRole[] | undefined; projectId?: number }) => {
  if (roleName === undefined) {
    return <p></p>
  }
  const projectSkill = roleName.filter((roleName) => {
    return roleName.projectId === projectId
  })
  if (projectSkill.length === 0) {
    return <p></p>
  }

  if ('roleName' in projectSkill[0]) {
    return <p className='plainText'>{projectSkill[0].roleName}</p>
  } else {
    return <p></p>
  }
}
const ManDays = ({ manDaysVisibility, expDays }: { manDaysVisibility: string; expDays: number }) => {
  if (expDays <= 0) {
    return <p className='plainText'>-</p>
  }
  if (manDaysVisibility === '1') {
    return <p className='plainText'>{expDays + ' htp'}</p>
  } else {
    return <p className='plainText'>{Math.ceil(expDays / 30.42).toLocaleString() + ' kk'}</p>
  }
}

const DetailsSection: FunctionComponent<OwnProps> = ({
  detailsData: { projects, projectSkills, languages, skills, certificates, educations, projectRoles },
  netumAloitus,
  workExperienceVisibility,
  educationVisibility,
  certificatesVisibility,
  skillsVisibility,
  languageVisibility,
  manDaysVisibility,
  currentEmployerName,
}) => (
  <div>
    {workExperienceVisibility && (
      <div>
        <p className='heading'>{'Työkokemus'}</p>
        <p className='workplace'>{netumAloitus}</p>
        {[...projects]
          ?.sort(
            (a, b) => new Date(b.startDate)?.getTime() - new Date(a.startDate)?.getTime() || a.projectName.localeCompare(b.projectName),
          )
          ?.map((exp) => (
            <div key={exp.projectId}>
              {exp.projectOwner === 0 && exp.canUseAsReference !== false && (
                <>
                  <p className='projectName'>
                    {exp.projectName}, {exp.customer}
                  </p>
                  <ManDays manDaysVisibility={manDaysVisibility} expDays={exp.expDays} />
                  <NetumJob key={exp.projectId} {...exp} />
                  <RoleName roleName={projectRoles} projectId={exp.projectId} />
                  <p className='plainText'>{exp.description ? exp.description : '-'}</p>
                </>
              )}
            </div>
          ))}

        {[...projects]
          ?.sort(
            (a, b) =>
              new Date(b?.employerStartDate || 0)?.getTime() - new Date(a?.employerStartDate || 0)?.getTime() ||
              a.employerName.localeCompare(b.employerName) ||
              new Date(b.startDate)?.getTime() - new Date(a.startDate)?.getTime(),
          )
          ?.map((other) => (
            <div key={other.projectId}>
              {other.canUseAsReference === true && other.projectOwner !== 0 && (
                <>
                  {currentEmployerName !== other.employerName && (
                    <>
                      <p className='workplace'>
                        {(currentEmployerName = other.employerName)}, {moment(other.employerStartDate).format('M/YYYY')} {' - '}
                        {other.employerEndDate ? moment(other.employerEndDate).format('M/YYYY') : ''}
                      </p>
                    </>
                  )}
                  <p className='projectName'>
                    {other.projectName}, {other.customer}
                  </p>
                  <ManDays manDaysVisibility={manDaysVisibility} expDays={other.expDays} />
                  <p className='plainText'>
                    {moment(other.startDate).format('D.M.YYYY')} {' - '}
                    {other.endDate ? moment(other.endDate).format('D.M.YYYY') : 'jatkuu'}
                  </p>
                  <RoleName roleName={projectRoles} projectId={other.projectId} />
                  <p className='plainText'>{other.description ? other.description : '-'}</p>
                </>
              )}
            </div>
          ))}
      </div>
    )}
    {educationVisibility && (
      <>
        <p className='heading'>{'Koulutus'}</p>
        {educations
          ?.sort((a, b) => {
            if (typeof a.degree === 'string' && typeof b.degree === 'string') {
              return new Date(b.startDate || 0)?.getTime() - new Date(a.startDate || 0)?.getTime() || a.degree.localeCompare(b.degree)
            }
            return 0
          })
          .map((edu) => (
            <>
              <p className='projectName'>
                {edu.degree}, {edu.school}
              </p>
              <p className='plainText'>
                {`${moment(edu.startDate).format('D.M.yyyy')} - ${edu.endDate ? moment(edu.endDate).format('D.M.yyyy') : ''}`}
              </p>
              <p className='plainText'>{edu.comment ? edu.comment : '-'}</p>
            </>
          ))}
      </>
    )}
    {certificatesVisibility && (
      <>
        <p className='heading'>{'Sertifikaatit'}</p>
        {certificates
          ?.sort((a, b) => {
            if (typeof a.name === 'string' && typeof b.name === 'string') {
              return a.name.localeCompare(b.name)
            }
            return 0
          })
          .map((cert) => (
            <>
              <p className='projectName'>
                {cert.name}, {cert.school}
              </p>

              <p className='plainText'>
                {`${moment(cert.startDate).format('D.M.yyyy')} - ${
                  cert.expirationDate ? moment(cert.expirationDate).format('D.M.yyyy') : ''
                }`}
              </p>
            </>
          ))}
      </>
    )}
    {skillsVisibility && (
      <>
        <p className='heading'>{'Oma osaaminen'}</p>
        {skills?.map((skills) => (
          <>
            <p key={skills.skillId} className='projectName'>
              {skills.skillName}
            </p>

            <p className='plainText'>{skills.personSkillProjects}</p>
            <ManDays manDaysVisibility={manDaysVisibility} expDays={skills.experienceDays} />
            <SkillDate skill={skills} pSkill={projectSkills} />
            <p className='plainText'>{`Kuvaus: ${skills.comment}`}</p>
          </>
        ))}
      </>
    )}
    {languageVisibility && (
      <div>
        <p className='heading'>{'Kielitaito'}</p>
        {languages?.map((lang) => (
          <>
            <p key={lang.languageId} className='projectName'>
              {lang.languageName}
            </p>
            <p className='plainText'>{lang.languageLevel}</p>
          </>
        ))}
      </div>
    )}
  </div>
)

export default DetailsSection
