import { AlertTitle } from '@mui/material'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { InfoIcon } from '../styles/Icons'
import '../styles/snackbar.scss'
interface SnackbarProps {
  isPartnerPage?: boolean
  text1: string
  text2?: string
  type?: 'discussionInfo'
  completed?: boolean
  icon?: JSX.Element
  ariaLabel?: string
  dataTestId?: string
}

export const OPSnackbar = (props: SnackbarProps): JSX.Element => {
  const { text1, text2, type, completed, icon = <InfoIcon />, ariaLabel, dataTestId, isPartnerPage } = props
  const { t } = useTranslation()

  return (
    <Alert
      aria-label={ariaLabel}
      datatest-id={dataTestId}
      icon={
        type !== 'discussionInfo' ? (
          <Stack direction='row' alignItems={'center'}>
            {icon}
          </Stack>
        ) : (
          false
        )
      }
      variant='standard'
      className={isPartnerPage ? 'infoSnackbar--blue' : 'infoSnackbar'}
    >
      {type !== 'discussionInfo' ? (
        <>
          <div className='headertext-bold'>{text1}</div>
          <div className='text2'>{text2}</div>
        </>
      ) : (
        <div>
          <AlertTitle>{completed ? t('Discussions.GoalCompleted') : t('Navbar.Info')}</AlertTitle>
          {text1}
        </div>
      )}
    </Alert>
  )
}
