import { useFormikContext } from 'formik'
import { t } from 'i18next'
import _ from 'lodash'
import React, { useCallback, useEffect } from 'react'
import { useStore } from '../../Provider'

interface IAutoSaveProps {
  debounceMs?: number
  fetchFunction?: (userId: number) => void
}

const AutoSave = (props: IAutoSaveProps) => {
  const { debounceMs = 1000, fetchFunction } = props
  const formik = useFormikContext()
  const rootStore = useStore()
  const { setSavingText, savingText, setIsError, isError } = rootStore.sidebarStore
  const { validatedUser } = rootStore.loginStore
  const debouncedSubmit = useCallback(
    _.debounce(async () => {
      const validation = await formik.validateForm()
      if (Object.keys(validation).length > 0) {
        if (!isError) setIsError(true)
        setSavingText(t('Buttons.InvalidForm'))
        return
      }
      return formik.submitForm().then(() => {
        setSavingText(t('Buttons.ChangesSaved'))
      })
    }, debounceMs),
    [formik.submitForm, debounceMs, formik.isValid],
  )
  useEffect(() => {
    if (formik.dirty && !formik.isValidating) {
      debouncedSubmit()
    }
  }, [debouncedSubmit, formik.dirty, formik.isValidating])

  useEffect(() => {
    if (formik.isSubmitting) {
      if (isError) setIsError(false)
      setSavingText(t('Buttons.Saving'))
    }
  }, [formik.isSubmitting])

  useEffect(() => {
    if (savingText === t('Buttons.ChangesSaved')) {
      if (fetchFunction) fetchFunction(validatedUser)
      const values = formik.values
      formik.resetForm({ values })
      setTimeout(() => {
        setSavingText('')
      }, 4000)
    }
  }, [savingText])

  useEffect(() => {
    return () => {
      if (isError) setIsError(false)
      setSavingText('')
    }
  }, [])

  return <></>
}
export default AutoSave
