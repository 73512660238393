import { Grid } from '@mui/material'
import { t } from 'i18next'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { IProfileSkill } from '../../../../interfaces/skills/ISkills'

const ProfileSkillOverview = (props: { skill: IProfileSkill }) => {
  const { skill } = props

  return (
    <Grid container className='gridcontainer--formcontainer__itempaddings' rowSpacing={2}>
      <Grid xs={12} item>
        <span className='myskill--font__titlefont sini'>{t('Skills.SkillInfo')}</span>
      </Grid>
      <Grid xs={12} item>
        <span className='myskill--font__titlefont subTitle'>{`${t('Skills.SkillName')}: `}</span>
        <span className={'myskill--font__regtext' + (skill?.skillName ? '' : ' placeholder')}>
          {skill?.skillName ?? t('Projects.NotDefined')}
        </span>
      </Grid>
      <Grid xs={12} item>
        <span className='myskill--font__titlefont subTitle'>{`${t('Skills.SkillOverview')}: `}</span>
        <span className={'myskill--font__regtext' + (skill?.skillOverview ? '' : ' placeholder')}>
          {skill?.skillOverview ?? t('Projects.NotDefined')}
        </span>
      </Grid>
      <Grid xs={12} item>
        <span className='myskill--font__titlefont subTitle'>{`${t('Skills.Category')}: `}</span>
        <span className={'myskill--font__regtext' + (skill?.skillCategory ? '' : ' placeholder')}>
          {skill?.skillCategory ?? t('Projects.NotDefined')}
        </span>
      </Grid>
      <Grid container xs={12} wrap='nowrap' item>
        <Grid item>
          <span className='myskill--font__titlefont subTitle'>{`${t('Skills.Tags')}: `}</span>
          <span className={'myskill--font__regtext' + (skill?.skillTags ? '' : ' placeholder')}>
            {skill?.skillTags ?? t('Projects.NotDefined')}
          </span>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default observer(ProfileSkillOverview)
