import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import React from 'react'
import { NavLink } from 'react-router-dom'
import '../styles/SideBar.scss'

/**
 * Generate a SideBareMenuItems with this.
 * @param props SidebarMenuItem, links yms.
 * @returns Ready to add sidebarmenuitem
 */

export interface SideBarItem {
  link: string
  icon?: JSX.Element
  text: string
  isExact?: boolean
  dataTestId?: string
  ariaLabel?: string
  disabled?: boolean
  isNested?: boolean
  nestedItems?: SideBarItem[]
  styleName?: string
  activeStyle?: string
  menuID?: string
  nestFont?: boolean
}

export const SideBarItem = (props: SideBarItem): JSX.Element => {
  const { link, icon, text, dataTestId, ariaLabel, disabled, styleName, activeStyle = '', isExact = false } = props
  return (
    <ListItem key={text} className='listItemStyling' disablePadding aria-label={ariaLabel} data-testid={dataTestId}>
      <ListItemButton
        component={NavLink}
        exact={isExact}
        to={link}
        activeClassName={styleName + activeStyle}
        className={styleName}
        disabled={disabled}
        disableRipple
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={text} />
      </ListItemButton>
    </ListItem>
  )
}
