import { Grid, List } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import '../Homepage/homepage.css'

import { useStore } from '../../Provider'
import { OPSnackbar } from '../OPcomponents/OPSnackbar'
import { Toast } from '../OPcomponents/Toast'

import '../../../src/components/Partners/partners2.scss'
import { OPLoadingCircle } from '../OPcomponents/OPLoadingcircle'
import { OPLoadMore } from '../OPcomponents/OPLoadMore'
import OPSearch from '../OPcomponents/OPSearch'
import { InfoIcon2 } from '../styles/Icons'
const Partners = (): JSX.Element => {
  const { t } = useTranslation()
  const rootStore = useStore()
  const { getPartnerList, filteredPartnerList, loadingPartnerList } = rootStore.partnerStore
  const [tableLength, setTableLength] = useState(10)
  const { searchValue, setIsSearching, setSearchValue, isSearching } = rootStore.partnerStore
  useEffect(() => {
    getPartnerList()
  }, [])

  return (
    <Grid container spacing={1}>
      <Toast />
      <Grid item xs={12}>
        <p className='profile--titlebigfont'>{t('Partners.PartnersHeader')}</p>
      </Grid>
      <>
        <Grid item xs={12} className='partner-infocontainer'>
          <OPSnackbar
            icon={<InfoIcon2 />}
            isPartnerPage={true}
            text1={t('Partners.PartnersHeader')}
            text2={t('Partners.PartnersListInfo')}
          />
        </Grid>
      </>
      <div className='partner-search'>
        <Grid item xs={12}>
          <OPSearch
            placeholder={t('Partners.PartnerSearch')}
            onSearchChange={(val) => {
              setSearchValue(val)
              if (!isSearching) setIsSearching(true)
            }}
            resetValue={() => {
              if (isSearching) setIsSearching(false)
              setSearchValue('')
            }}
          />
        </Grid>
      </div>
      <Grid item xs={12}>
        <List className='partner-list'>
          {loadingPartnerList ? (
            <OPLoadingCircle />
          ) : (
            filteredPartnerList.slice(0, tableLength).map((partner) => (
              <>
                <Link className='partner-list-link' to={`/partners/${partner.partnerId}`}>
                  <div key={partner.partnerId} className='partner-list-name'>
                    {partner.partnerName}
                    <div className='partner-last-updated-text'> {t('Partners.LastUpdated')}</div>
                  </div>
                </Link>
              </>
            ))
          )}
        </List>
        <div className='partner-list-loadmore'>
          <OPLoadMore
            stepper={10}
            curLength={tableLength}
            maxLength={filteredPartnerList.length}
            onLoadMore={(data) => setTableLength(data)}
            filteredArray={filteredPartnerList}
          />
        </div>
      </Grid>
    </Grid>
  )
}
export default observer(Partners)
