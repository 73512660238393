import { Avatar, Button, CircularProgress, Drawer, Grid, List } from '@mui/material'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import history from '../../history'
import { useStore } from '../../Provider'
import { SideBarCollapse, SideBarLogo } from '../styles/Icons'
import '../styles/SideBar.scss'

import { CollapseableSideBarItem } from './CollapseableSideBarItem'
import { SideBarItem } from './SideBarItem'

export interface sideBar {
  data: SideBarItem[]
}

const SideBar = (props: sideBar): JSX.Element => {
  const rootStore = useStore()
  const { userProfileImage } = rootStore.loginStore
  const { isLoading, sidebarUser, setIsOpen } = rootStore.sidebarStore
  const { data } = props

  return (
    <Drawer variant='permanent' anchor='left' className='sidebarresp' PaperProps={{ className: 'drawer' }}>
      <Grid container className='gridjustify'>
        <Grid xs={12} item className='logo'>
          <SideBarLogo className='logolink' onClick={() => history.push('')} />
        </Grid>
        <Grid xs={12} item>
          <Avatar className='avatar' src={`data:image/jpeg;base64,${userProfileImage}`} />
        </Grid>
        <Grid xs={12} className='fonts name' item>
          <p>{sidebarUser.name}</p>
        </Grid>
        <Grid xs={12} className='fonts email' item>
          {isLoading ? <CircularProgress /> : <p>{sidebarUser.department}</p>}
        </Grid>
        <Grid xs={12} item>
          <List>
            {data.map((sideBarItem: SideBarItem) => (
              <div key={sideBarItem.text}>
                {sideBarItem.isNested ? (
                  <CollapseableSideBarItem
                    key={sideBarItem.text}
                    link={sideBarItem.link}
                    icon={sideBarItem.icon}
                    text={sideBarItem.text}
                    isExact={sideBarItem.isExact}
                    dataTestId={sideBarItem.dataTestId}
                    ariaLabel={sideBarItem.ariaLabel}
                    disabled={sideBarItem.disabled}
                    nestedItems={sideBarItem.nestedItems}
                    menuID={sideBarItem.menuID}
                  />
                ) : (
                  <SideBarItem
                    styleName='buttonStyling'
                    activeStyle=' defActive'
                    key={sideBarItem.text}
                    isExact={sideBarItem.isExact}
                    link={sideBarItem.link}
                    icon={sideBarItem.icon}
                    text={sideBarItem.text}
                    dataTestId={sideBarItem.dataTestId}
                    ariaLabel={sideBarItem.ariaLabel}
                    disabled={sideBarItem.disabled}
                  />
                )}
              </div>
            ))}
          </List>
        </Grid>
      </Grid>
      <Grid container className='restBox'>
        <Button className='sideBarCollapse' onClick={() => setIsOpen(false)}>
          <SideBarCollapse />
        </Button>
      </Grid>
    </Drawer>
  )
}

export default observer(SideBar)
