import { Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IProfilePersonSkill } from '../../../../interfaces/skills/ISkills'
import { OPSkillbar } from '../../../OPcomponents/OPSkillbar'

const ProfileProjectPerson = (props: { data: IProfilePersonSkill[] | undefined }) => {
  const { t } = useTranslation()
  const { data } = props

  return (
    <Grid container rowGap={2} className='gridcontainer--formcontainer__lastitempaddings'>
      <Grid item xs={12}>
        <span className='myskill--font__titlefont sini'>{t('Projects.UserSkillsUsedInProject')}</span>
      </Grid>
      <Grid item xs={12} container rowGap={4}>
        {data && data?.length > 0 ? (
          data
            ?.sort((a, b) => b?.skillLevel - a?.skillLevel)
            .map((skill: IProfilePersonSkill) => (
              <Grid key={skill.skillName} item xs={12} container rowGap={1}>
                <Grid item xs={12}>
                  <span className='myskill--font__multilinetext title'>{`${t('Skills.SkillName')}: `}</span>
                  <span className='myskill--font__multilinetext'>
                    {skill?.skillName !== '' ? skill?.skillName : t('Projects.NotDefined')}
                  </span>
                </Grid>
                <Grid item xs={12}>
                  <span className='myskill--font__multilinetext title'>{`${t('Skills.SkillOverview')}: `}</span>
                  <span className='myskill--font__multilinetext'>
                    {skill?.skillComment !== '' ? skill?.skillComment : t('Projects.NotDefined')}
                  </span>
                </Grid>
                <Grid xs={12} item>
                  <OPSkillbar darklabel readonly value={skill?.skillLevel} label={t('Skills.Level')} hasLabel name={'skilllvl'} />
                </Grid>
              </Grid>
            ))
        ) : (
          <Grid item xs={12}>
            <span className='myskill--font__multilinetext'>{t('Projects.NoSkillsUsedInProject')}</span>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default observer(ProfileProjectPerson)
