import { CircularProgress } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import history from '../../history'
import { IListDevelopmentGoal, IListDiscussion } from '../../interfaces/discussions/IDiscussion'
import { useStore } from '../../Provider'
import { OPAccordionItem } from '../Accordion/OPAccordionItem'
import { scrollHelper } from '../common/scrollHelper'
import { MuiButtonVariants, OPButton } from '../OPcomponents/OPButton'
import { OPDivider } from '../OPcomponents/OPDivider'
import { OPSnackbar } from '../OPcomponents/OPSnackbar'
import { Toast } from '../OPcomponents/Toast'
import '../styles/developmentPage.scss'
import { ColleagueFeedback } from './ColleagueFeedback'
import { ColleagueFeedbackForm } from './ColleagueFeedbackForm'
import { DevelopmentDiscussion } from './DevelopmentDiscussion'
import { DevelopmentGoal } from './DevelopmentGoal'
import { DevelopmentGoalForm } from './DevelopmentGoalForm'
import { FeedbackGoal } from './FeedbackGoal'
import { FeedbackGoalForm } from './FeedbackGoalForm'
import { MiniDiscussion } from './MiniDiscussion'
import { NewDiscussion } from './NewDiscussion'

interface clickedGoal {
  goalId?: number
}

export const DevelopmentPage = observer((): JSX.Element => {
  const { t } = useTranslation()
  const rootStore = useStore()
  const {
    isConfirmReadySectionVisible,
    isConfirmDeleteGoalVisible,
    isAddCommentVisible,
    isNewDevGoalVisible,
    setIsNewDevGoalVisible,
    isNewFBGoalVisible,
    setIsNewFBGoalVisible,
    developmentGoals,
    feedbackGoals,
    colleagueFeedbacks,
    getEmployeeDiscussions,
    loadingDiscussions,
    userDiscussion,
    getGoals,
    loadingUserGoals,
    isEditDiscussionGoal,
    isEditFeedbackGoal,
    setIsNewDevDiscussionVisible,
    isNewDevDiscussionVisible,
    setIsNewColleagueFeedbackVisible,
    isNewColleagueFeedbackVisible,
    isDeleteColleagueFeedbackVisible,
    isEditColleagueFeedback,
    setIsNewMiniDiscussionVisible,
    isNewMiniDiscussionVisible,
    getFormTemplates,
    formTemplates,
    loadingFormTemplates,
    loadingDiscussionQuestions,
    selectTemplate,
    isConfirmSendDiscussionVisible,
    selectedDiscussion,
    selectedFeedbackGoal,
    selectedDevelopmentGoal,
    setIsAddCommentVisible,
    setIsConfirmReadySectionVisible,
    setIsConfirmDeleteGoalVisible,
    setIsConfirmSendDiscussionVisible,
    selectDiscussion,
    selectFeedbackGoal,
    selectDevelopmentGoal,
    setIsEditFeedbackGoal,
    setIsEditColleagueFeedback,
    setIsDeleteColleagueFeedbackVisible,
    setIsEditDevelopmentGoal,
    resetUser,
  } = rootStore.kekeStore
  const { setSearchValue } = rootStore.managerStore
  const { validatedUser } = rootStore.loginStore

  const { userId } = useParams() as {
    userId?: string
  }

  const isManager = history.location.state === 'managerPage'
  const isHR = history.location.state === 'hrPage'
  const { goalId } = (history.location.state as clickedGoal) || {}

  const selectedUser = userId !== undefined ? Number(userId) : validatedUser

  const [readyDevGoals, setReadyDevGoals] = useState<IListDevelopmentGoal[]>([])
  const [notReadyDevGoals, setNotReadyDevGoals] = useState<IListDevelopmentGoal[]>([])
  const [readyFbGoals, setReadyFbGoals] = useState<IListDevelopmentGoal[]>([])
  const [notReadyFbGoals, setNotReadyFbGoals] = useState<IListDevelopmentGoal[]>([])
  const [readyDiscussions, setReadyDiscussions] = useState<IListDiscussion[]>([])
  const [notReadyDiscussions, setNotReadyDiscussions] = useState<IListDiscussion[]>([])
  let kekesRendered = 0
  let miniKekesRendered = 0

  const disabledButtons =
    isConfirmReadySectionVisible ||
    isNewDevGoalVisible ||
    isNewFBGoalVisible ||
    isEditFeedbackGoal ||
    isEditDiscussionGoal ||
    isAddCommentVisible ||
    isNewDevDiscussionVisible ||
    isConfirmDeleteGoalVisible ||
    isNewMiniDiscussionVisible ||
    isNewColleagueFeedbackVisible ||
    isDeleteColleagueFeedbackVisible ||
    isEditColleagueFeedback ||
    isConfirmSendDiscussionVisible

  useEffect(() => {
    return () => {
      resetUser()
      selectDiscussion(undefined)
      setIsConfirmSendDiscussionVisible(false)
      setIsConfirmReadySectionVisible(false)
      setIsConfirmDeleteGoalVisible(false)
      setIsNewDevGoalVisible(false)
      setIsNewFBGoalVisible(false)
      setIsNewDevDiscussionVisible(false)
      setIsNewMiniDiscussionVisible(false)
      setIsNewColleagueFeedbackVisible(false)
      setIsDeleteColleagueFeedbackVisible(false)
      setIsEditColleagueFeedback(false)
      setIsAddCommentVisible(false)
      selectFeedbackGoal(undefined)
      selectDevelopmentGoal(undefined)
      selectTemplate(undefined)
      setIsEditFeedbackGoal(false)
      setIsEditDevelopmentGoal(false)
      selectTemplate(undefined)
      setIsNewDevDiscussionVisible(false)
      setIsNewMiniDiscussionVisible(false)
    }
  }, [])

  useEffect(() => {
    getFormTemplates()
  }, [getFormTemplates])

  useEffect(() => {
    if (selectedUser === validatedUser && history.location.pathname !== '/development') history.replace('/development')
    getGoals(selectedUser)
    getEmployeeDiscussions(selectedUser)
  }, [selectedUser, getGoals, getEmployeeDiscussions, validatedUser])

  useEffect(() => {
    const filterReadyDevGoals = () => {
      const readyGoals = developmentGoals.filter((goal) => goal.completed === true)
      setReadyDevGoals(readyGoals)
      const notCompleted = developmentGoals.filter((goal) => goal.completed === false)
      setNotReadyDevGoals(notCompleted)
    }
    filterReadyDevGoals()
  }, [developmentGoals])

  useEffect(() => {
    const filterReadyFbGoals = () => {
      const readyGoals = feedbackGoals.filter((goal) => goal.completed === true)
      setReadyFbGoals(readyGoals)
      const notCompleted = feedbackGoals.filter((goal) => goal.completed === false)
      setNotReadyFbGoals(notCompleted)
    }
    filterReadyFbGoals()
  }, [feedbackGoals])

  useEffect(() => {
    if (userDiscussion) {
      setIsEmployee(validatedUser === userDiscussion?.employeeId)
      setIsEmployer(validatedUser === userDiscussion?.employerId)
      if (userDiscussion.discussions) {
        const allDiscussions = userDiscussion.discussions.filter((d) => d.formTemplateType === 'Kehityskeskustelu')
        const incomplete = allDiscussions?.filter((d) => d.state !== 'Completed')
        if (incomplete) setNotReadyDiscussions(incomplete)
        const complete = allDiscussions?.filter((d) => d.state === 'Completed')
        if (complete) setReadyDiscussions(complete)
      }
    }
  }, [userDiscussion, validatedUser])

  const showName = (): string => (userDiscussion && userDiscussion.employeeId !== validatedUser ? ` - ${userDiscussion.employeeName}` : '')

  const [isEmployee, setIsEmployee] = useState<boolean | undefined>(undefined)
  const [isEmployer, setIsEmployer] = useState<boolean | undefined>(undefined)
  const getDiscussions = (formTemplateType: string, completed: boolean): IListDiscussion[] => {
    const templateDiscussions = userDiscussion?.discussions.filter((d) => d.formTemplateType === formTemplateType)
    const discussions = templateDiscussions?.filter((d) => (completed ? d.state === 'Completed' : d.state !== 'Completed'))
    return discussions ?? []
  }

  useEffect(() => {
    return () => {
      if (isManager || isHR || selectedUser === validatedUser) {
        scrollHelper(isManager ? '/manager' : isHR ? '/hr' : '', setSearchValue, true, rootStore)
      }
    }
  }, [])

  return (
    <div data-testid='development.page' className='developmentPage'>
      <Toast />
      <p className='profile--titlebigfont'>{t('Navbar.Development')}</p>
      <div data-testid='development.container' className='contentContainer'>
        {loadingDiscussions ? (
          <div className='loaderDiv'>
            <CircularProgress />
          </div>
        ) : (
          isEmployee && (
            <OPSnackbar
              text1={`${t('Discussions.InfoSection1') + ' ' + userDiscussion?.employerName}.`}
              text2={t('Discussions.InfoSection2')}
            />
          )
        )}
        <div data-testid='page.title.development' className='sectionTitle'>
          <p>{`${t('DiscussionsCategories.Goals')}${showName()}`}</p>
        </div>
        <>
          <OPAccordionItem
            isOpenOnInit={true}
            lockedOpen={
              isEditDiscussionGoal ||
              isNewDevGoalVisible ||
              (selectedDevelopmentGoal !== undefined && (isAddCommentVisible || isConfirmDeleteGoalVisible || isConfirmReadySectionVisible))
            }
            dataTestId='developmentgoals'
            title={`${t('Discussions.DevelopmentAccordionGoals')} (${notReadyDevGoals.length})`}
            content={
              loadingUserGoals && loadingDiscussions ? (
                <div data-testid='goals.loader' className='loaderDiv'>
                  <CircularProgress />
                </div>
              ) : (
                <div data-testid='devgoals.list' className='accordionContent'>
                  {notReadyDevGoals.length > 0 ? (
                    notReadyDevGoals.map((goal) => {
                      return (
                        <DevelopmentGoal
                          isOpen={goalId === goal.goalId}
                          key={goal.goalId}
                          setGoalReady={(g) => {
                            const filtered = notReadyDevGoals.filter((goal) => goal.goalId !== g.goalId)
                            setNotReadyDevGoals(filtered)
                            setReadyDevGoals([...readyDevGoals, g])
                          }}
                          onDelete={(g) => {
                            const filtered = notReadyDevGoals.filter((goal) => goal.goalId !== g.goalId)
                            setNotReadyDevGoals(filtered)
                          }}
                          goal={goal}
                        />
                      )
                    })
                  ) : (
                    <div data-testid='no-dev-goals' className='noContent'>
                      {t('Discussions.NoGoalsShort')}
                    </div>
                  )}
                  {(userDiscussion?.employeeId === validatedUser || userDiscussion?.employerId === validatedUser) && (
                    <div className='addGoalDiv'>
                      <OPButton
                        dataTestId='addDevGoal.button'
                        label={t('Buttons.AddDevGoal')}
                        disabled={disabledButtons}
                        onClick={() => setIsNewDevGoalVisible(true)}
                        buttonVariant={MuiButtonVariants.primary}
                      />
                    </div>
                  )}
                  {isNewDevGoalVisible && !isEditDiscussionGoal && (
                    <DevelopmentGoalForm onCancel={() => setIsNewDevGoalVisible(false)} onSave={() => setIsNewDevGoalVisible(false)} />
                  )}
                  {readyDevGoals.length > 0 && (
                    <OPAccordionItem
                      lockedOpen={
                        selectedDevelopmentGoal !== undefined && selectedDevelopmentGoal.completed === true && isConfirmReadySectionVisible
                      }
                      insideAccordion
                      finished
                      title={`${t('Discussions.ReadyDevGoals')} (${readyDevGoals.length})`}
                      content={
                        <div className='accordionContent'>
                          {readyDevGoals.map((goal) => {
                            return (
                              <DevelopmentGoal
                                key={goal.goalId}
                                reOpenGoal={(g) => {
                                  const filtered = readyDevGoals.filter((goal) => goal.goalId !== g.goalId)
                                  setReadyDevGoals(filtered)
                                  setNotReadyDevGoals([...notReadyDevGoals, g])
                                }}
                                goal={goal}
                              />
                            )
                          })}
                        </div>
                      }
                    />
                  )}
                  <OPDivider />
                </div>
              )
            }
          />
          <OPAccordionItem
            isOpenOnInit={true}
            lockedOpen={
              isEditFeedbackGoal ||
              isNewFBGoalVisible ||
              (selectedFeedbackGoal !== undefined && (isAddCommentVisible || isConfirmDeleteGoalVisible || isConfirmReadySectionVisible))
            }
            dataTestId='feedbackgoals'
            title={`${t('Discussions.DevelopmentAccordionFeedbackGoals')} (${notReadyFbGoals.length})`}
            content={
              loadingUserGoals && loadingDiscussions ? (
                <div data-testid='goals.loader' className='loaderDiv'>
                  <CircularProgress />
                </div>
              ) : (
                <div data-testid='fbgoals.list' className='accordionContent'>
                  {notReadyFbGoals.length > 0 ? (
                    notReadyFbGoals.map((goal) => {
                      return (
                        <FeedbackGoal
                          key={goal.goalId}
                          setGoalReady={(g) => {
                            const filtered = notReadyFbGoals.filter((goal) => goal.goalId !== g.goalId)
                            setNotReadyFbGoals(filtered)
                            setReadyFbGoals([...readyFbGoals, g])
                          }}
                          goal={goal}
                          onDelete={(g) => {
                            const filtered = notReadyFbGoals.filter((goal) => goal.goalId !== g.goalId)
                            setNotReadyFbGoals(filtered)
                          }}
                        />
                      )
                    })
                  ) : (
                    <div className='noContent'>{t('Discussions.NoGoalsShort')}</div>
                  )}
                  {(userDiscussion?.employeeId === validatedUser || userDiscussion?.employerId === validatedUser) && (
                    <div className='addGoalDiv'>
                      <OPButton
                        dataTestId='feedbackgoal.addbutton'
                        label={t('Buttons.AddFeedbackGoal')}
                        disabled={disabledButtons}
                        buttonVariant={MuiButtonVariants.primary}
                        onClick={() => setIsNewFBGoalVisible(true)}
                      />
                    </div>
                  )}
                  {isNewFBGoalVisible && !isEditFeedbackGoal && (
                    <FeedbackGoalForm onCancel={() => setIsNewFBGoalVisible(false)} onSave={() => setIsNewFBGoalVisible(false)} />
                  )}
                  {readyFbGoals.length > 0 && (
                    <OPAccordionItem
                      lockedOpen={
                        selectedFeedbackGoal !== undefined && selectedFeedbackGoal.completed === true && isConfirmReadySectionVisible
                      }
                      insideAccordion
                      finished
                      title={`${t('Discussions.ReadyFeedbackGoals')} (${readyFbGoals.length})`}
                      content={
                        <div className='accordionContent'>
                          {readyFbGoals.map((goal) => {
                            return (
                              <FeedbackGoal
                                key={goal.goalId}
                                reOpenGoal={(g) => {
                                  const filtered = readyFbGoals.filter((goal) => goal.goalId !== g.goalId)
                                  setReadyFbGoals(filtered)
                                  setNotReadyFbGoals([...notReadyFbGoals, g])
                                }}
                                goal={goal}
                              />
                            )
                          })}
                        </div>
                      }
                    />
                  )}
                </div>
              )
            }
          />
        </>
        <div data-testid='page.title.discussions' className='sectionTitle'>
          <p>{`${t('Discussions.Header')}${showName()}`}</p>
        </div>
        {loadingDiscussions && loadingFormTemplates && loadingDiscussionQuestions ? (
          <div data-testid='discussion.loader' className='loaderDiv'>
            <CircularProgress />
          </div>
        ) : (
          <div data-testid='discussion.container'>
            {formTemplates.map((template) => {
              if (template.type === 'Kehityskeskustelu') kekesRendered++
              if (template.type === 'Mini kehityskeskustelu') miniKekesRendered++
              return (
                ((template.type === 'Kehityskeskustelu' && kekesRendered === 1) ||
                  (template.type === 'Mini kehityskeskustelu' && miniKekesRendered === 1)) && (
                  <div data-testid={`discussion.template.${template.type}`} key={template.name}>
                    <OPAccordionItem
                      lockedOpen={
                        (template.type === 'Kehityskeskustelu' && (isConfirmSendDiscussionVisible || isNewDevDiscussionVisible)) ||
                        (template.type === 'Mini kehityskeskustelu' &&
                          (isNewMiniDiscussionVisible || (isAddCommentVisible && selectedDiscussion !== undefined)))
                      }
                      title={
                        template.type === 'Kehityskeskustelu'
                          ? `${t(`DiscussionHeaders.${template.name}`, {
                              defaultValue: template.name,
                            })}`
                          : template.type === 'Mini kehityskeskustelu'
                          ? t('Discussions.MiniKeket')
                          : template.name
                      }
                      content={
                        <div className='accordionContent'>
                          {template.type === 'Kehityskeskustelu' ? (
                            <div data-testid='keke.content'>
                              {getDiscussions(template.type, false).length > 0 ? (
                                getDiscussions(template.type, false).map((d) => {
                                  return (
                                    <DevelopmentDiscussion
                                      key={d.developmentDiscussionId}
                                      discussion={d}
                                      employerName={userDiscussion?.employerName ?? ''}
                                      employerId={userDiscussion?.employerId}
                                      employeeId={userDiscussion?.employeeId}
                                      onApprove={(id) => {
                                        const filtered = notReadyDiscussions.filter((disc) => id !== disc.developmentDiscussionId)
                                        setNotReadyDiscussions(filtered)
                                        setReadyDiscussions([...readyDiscussions, { ...d, state: 'Completed' }])
                                      }}
                                    />
                                  )
                                })
                              ) : (
                                <div className='noContent'>{t('Discussions.NoDiscussions')}</div>
                              )}
                              {(isEmployee || isEmployer) && (
                                <div className='addGoalDiv'>
                                  <OPButton
                                    label={t('Buttons.AddDiscussion')}
                                    disabled={disabledButtons}
                                    buttonVariant={MuiButtonVariants.primary}
                                    onClick={() => {
                                      setIsNewDevDiscussionVisible(true)
                                      selectTemplate(template)
                                    }}
                                  />
                                </div>
                              )}
                              {isNewDevDiscussionVisible && <NewDiscussion />}
                              {getDiscussions(template.type, true).length > 0 && (
                                <div>
                                  <OPAccordionItem
                                    insideAccordion
                                    finished
                                    title={t('Discussions.ReadyDiscussions')}
                                    content={
                                      <div className='accordionContent'>
                                        {getDiscussions(template.type, true).map((d) => {
                                          return (
                                            <DevelopmentDiscussion
                                              key={d.developmentDiscussionId}
                                              discussion={d}
                                              employeeId={userDiscussion?.employeeId}
                                              employerName={userDiscussion?.employerName ?? ''}
                                              employerId={userDiscussion?.employerId}
                                            />
                                          )
                                        })}
                                      </div>
                                    }
                                  />
                                  <OPDivider />
                                </div>
                              )}
                            </div>
                          ) : template.type === 'Mini kehityskeskustelu' ? (
                            <div>
                              {getDiscussions(template.type, true).length > 0 ? (
                                <div>
                                  {getDiscussions(template.type, true).map((d) => {
                                    return <MiniDiscussion key={d.developmentDiscussionId} discussion={d} />
                                  })}
                                </div>
                              ) : (
                                <div className='noContent'>{t('Discussions.NoMiniDiscussions')}</div>
                              )}
                              {(isEmployee || isEmployer) && (
                                <div className='addGoalDiv'>
                                  <OPButton
                                    label={t('Buttons.AddMiniDiscussion')}
                                    disabled={disabledButtons}
                                    buttonVariant={MuiButtonVariants.primary}
                                    onClick={() => {
                                      setIsNewMiniDiscussionVisible(true)
                                      selectTemplate(template)
                                    }}
                                  />
                                </div>
                              )}
                              {isNewMiniDiscussionVisible && <NewDiscussion />}
                            </div>
                          ) : (
                            <div className='noContent'>No content yet</div>
                          )}
                        </div>
                      }
                    />
                  </div>
                )
              )
            })}
          </div>
        )}
        <div data-testid='page.title.feedback' className='sectionTitle'>
          <p>{`${t('Discussions.FeedbackHeader')}${showName()}`}</p>
        </div>
        <div>
          <OPAccordionItem
            lockedOpen={isNewColleagueFeedbackVisible || isEditColleagueFeedback || isDeleteColleagueFeedbackVisible}
            dataTestId='colleaguefeedbacks'
            title={t('Discussions.ColleagueFeedbackListHeader')}
            content={
              <div className='accordionContent'>
                {loadingUserGoals ? (
                  <div className='loaderDiv'>
                    <CircularProgress />
                  </div>
                ) : colleagueFeedbacks.length === 0 ? (
                  <div className='noContent'>{t('Discussions.NoColleagueFeedback')}</div>
                ) : (
                  <div data-testid='feedbacks.list'>
                    {colleagueFeedbacks.map((fb) => {
                      return (
                        <ColleagueFeedback
                          isCurrentUser={!isManager && !isHR}
                          key={fb.id}
                          data-testid='feedback.data'
                          colleagueFeedback={fb}
                        />
                      )
                    })}
                  </div>
                )}
                {!isManager && !isHR && (
                  <div className='addGoalDiv'>
                    <OPButton
                      dataTestId='feedback.button.add'
                      label={t('Buttons.ColleagueFeedbackAdd')}
                      disabled={disabledButtons}
                      buttonVariant={MuiButtonVariants.primary}
                      onClick={() => setIsNewColleagueFeedbackVisible(true)}
                    />
                  </div>
                )}
                {isNewColleagueFeedbackVisible && <ColleagueFeedbackForm />}
              </div>
            }
          />
        </div>
      </div>
    </div>
  )
})
