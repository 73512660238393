import { Grid } from '@mui/material'
import { Formik } from 'formik'
import { difference } from 'lodash'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { toastNeg, toastPos } from '../../common/toastHelper'
import { IDropdownSkill, IEditedSkill, IEditedSkillInfo, ISkillFormPackage } from '../../interfaces/skills/ISkills'
import { useStore } from '../../Provider'
import { ExitConfirmation } from '../OPcomponents/ExitConfirmation'
import { OPMultiline } from '../OPcomponents/OPMultiline'
import { OPSkillsCombobox } from '../OPcomponents/OPSkillsCombobox'
import { editSkillSchema } from '../OPcomponents/ValidationSchemas'
import { OPSkillsButtons } from '../Profile/OPSkillsButtons'

const EditSkill = (props: { data: ISkillFormPackage; setSkillData: (val: ISkillFormPackage) => void }): JSX.Element => {
  const { t } = useTranslation()
  const { data, setSkillData } = props
  const rootStore = useStore()
  const { validatedUser } = rootStore.loginStore

  const {
    currentSkill,
    dropdownCategories,
    dropdownTags,
    setShowEditInfo,
    showEditInfo,
    editSkillInfo,
    addSkillTag,
    removeSkillTag,
    setCurrentskill,
  } = rootStore.skillStore

  const initialTags: IDropdownSkill[] = data.skill.skill.skillTags
    ? (data.skill.skill.skillTags
        .split(',')
        .map((tag: string) => tag.trim())
        .map((skillTag: string) => dropdownTags.find((tag) => tag.text === skillTag)) as IDropdownSkill[])
    : []

  const initialValues: IEditedSkill = {
    skillId: data.skill.skill.skillId,
    skillName: data.skill.skill.skillName,
    skillDescription: data.skill.skill.skillDescription ?? '',
    categoryId: data.skill.skill.skillCategoryId,
    skillTags: initialTags as IDropdownSkill[],
  }

  const submitForm = async (values: IEditedSkill) => {
    const editedSkill: IEditedSkillInfo = {
      name: values.skillName,
      description: values.skillDescription,
      categoryId: values.categoryId,
      creatorId: validatedUser,
    }
    const removedTags = difference(initialTags, values.skillTags)
    const addedTags = difference(values.skillTags, initialTags)

    try {
      await editSkillInfo(validatedUser, values.skillId, editedSkill, false)
      await Promise.all(
        addedTags
          .map((tag) => {
            addSkillTag(tag.key, values.skillId)
          })
          .concat(
            removedTags.map((tag) => {
              removeSkillTag(tag.key, values.skillId)
            }),
          ),
      )
      toastPos(t('Toasts.SkillInfoEdit'))
      setSkillData({
        ...data,
        skill: {
          ...data.skill,
          skill: {
            ...data.skill.skill,
            categoryId: values.categoryId,
            categoryName: dropdownCategories.find((category) => category.key === values.categoryId)?.text as string,
            skillCategoryId: values.categoryId,
            skillDescription: values.skillDescription,
            skillName: values.skillName,
            skillTags: values.skillTags.map((tag) => tag.text).join(', '),
          },
        },
      })
      setCurrentskill({
        ...currentSkill,
      })
    } catch (error) {
      toastNeg(t('Toasts.SkillInfoError'))
    } finally {
      setShowEditInfo(!showEditInfo)
    }
  }

  return (
    <Formik initialValues={initialValues} onSubmit={submitForm} validationSchema={editSkillSchema}>
      {({ handleSubmit, handleChange, isValid, values, setFieldValue, dirty }) => (
        <Grid container rowSpacing={2} columnSpacing={4} className='gridcontainer--formcontainer__itempaddings'>
          <ExitConfirmation when={dirty} />
          <Grid xs={12} lg={6} item>
            <Grid container rowSpacing={2}>
              <Grid xs={12} item>
                <OPMultiline
                  maxChars={100}
                  hasCounter
                  required
                  name='skillName'
                  label={t('Skills.SkillName')}
                  value={values.skillName}
                  onChange={handleChange}
                />
              </Grid>
              <Grid xs={12} item>
                <OPSkillsCombobox
                  name='categoryId'
                  optionList={dropdownCategories}
                  curState={dropdownCategories.find((x) => x.key === values.categoryId)}
                  label={t('Skills.Category')}
                  onChange={(_e, val) => {
                    setFieldValue('categoryId', val.key)
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12} lg={6} item>
            <Grid xs={12} item>
              <OPMultiline
                hasCounter
                maxChars={250}
                required
                name='skillDescription'
                label={t('Skills.SkillOverview')}
                value={values.skillDescription}
                onChange={handleChange}
                minRows={5}
              />
            </Grid>
          </Grid>
          <Grid xs={12} lg={6} item>
            <OPSkillsCombobox
              multiple
              name='skillTags'
              optionList={dropdownTags}
              curState={values.skillTags}
              label={t('Skills.Tags')}
              onDelete={(val) => {
                setFieldValue(
                  'skillTags',
                  values.skillTags.filter((x) => x.key !== val.option.key),
                )
              }}
              onChange={(_e, val) => {
                setFieldValue('skillTags', val)
              }}
            />
          </Grid>
          <Grid xs={12} item>
            <OPSkillsButtons onClose={() => setShowEditInfo(!showEditInfo)} onSubmit={handleSubmit} disabled={!(isValid && dirty)} />
          </Grid>
        </Grid>
      )}
    </Formik>
  )
}

export default observer(EditSkill)
