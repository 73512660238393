import { observer } from 'mobx-react-lite'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ICertificate } from '../../../interfaces/certAndEducation/certAndEducation'
import { useStore } from '../../../Provider'
import { OPAccordionItem } from '../../Accordion/OPAccordionItem'
import { OPLoadMore } from '../../OPcomponents/OPLoadMore'
import { Data, OPNewTable } from '../../OPcomponents/OPNewTable'
import CertEduLangBase from './CertEduLangBase'

interface CertSearchAccordionProps {
  search: string
}

const CertSearchAccordion = (props: CertSearchAccordionProps) => {
  const { search } = props
  const { t } = useTranslation()
  const rootStore = useStore()
  const { certificates, getFilteredCertificatesByText, loadingCertificates } = rootStore.certAndEducationStore
  const { setCertEduLangBase } = rootStore.seachStore
  const [tableLength, setTableLength] = useState(10)

  const [tableRows, setTableRows] = useState<Data[]>([])

  const onRowClick = (id: number) => {
    setCertEduLangBase({
      id: id,
      type: 'certificate',
    })
  }

  const filteredSearchCertificates = useMemo(() => {
    if (loadingCertificates) return []
    return certificates ? getFilteredCertificatesByText(search) : []
  }, [certificates, loadingCertificates, getFilteredCertificatesByText, search])

  useEffect(() => {
    const newRows: Data[] = []
    filteredSearchCertificates.forEach((certificate: ICertificate) => {
      newRows.push({
        ['id']: certificate.key ?? -1,
        rows: {
          [t('CertAndEducation.CertificateName')]: { text: certificate.text ?? '' },
        },
      })
      setTableRows(newRows)
    })
  }, [t, filteredSearchCertificates])

  return (
    <OPAccordionItem
      title={`${t('CertAndEducation.CertificateHeader')} (${loadingCertificates ? '\u2026' : filteredSearchCertificates.length})`}
      content={
        <>
          {filteredSearchCertificates.length > 0 ? (
            <>
              <OPNewTable
                rows={tableRows}
                curLength={tableLength}
                onRowClick={(id) => onRowClick(Number(id))}
                FormBase={CertEduLangBase}
                isCloseable
              />
              <OPLoadMore
                stepper={10}
                filteredArray={filteredSearchCertificates}
                onLoadMore={(data) => setTableLength(data)}
                curLength={tableLength}
                maxLength={filteredSearchCertificates.length}
              />
            </>
          ) : (
            <span className='profile--notfound'>{t('NotFound.NoResults')}</span>
          )}
        </>
      }
    />
  )
}

export default observer(CertSearchAccordion)
