import { Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useTranslation } from 'react-i18next'
import '../styles/ophelp.scss'
import DevHelp from './DevHelp'
import { HelpItem } from './HelpItem'
import SkillsHelp from './SkillsHelp'

const Help = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Grid container rowGap={4}>
      <Grid item xs={12}>
        <p className='profile--titlebigfont'>{t('Help.Header')}</p>
      </Grid>
      <Grid item xs={12}>
        <HelpItem title={t('Help.Profile')} meta={t('Help.MySkills')} />
      </Grid>
      <Grid item xs={12}>
        <HelpItem title={t('Help.SkillsTitle')} meta={t('Help.Skills')} />
      </Grid>
      <Grid item xs={12}>
        <HelpItem title={t('Help.ProjectsTitle')} meta={t('Help.Projects')} />
      </Grid>
      <Grid item xs={12}>
        <HelpItem title={t('Help.PersonsTitle')} meta={t('Help.Persons')} />
      </Grid>
      <Grid item xs={12}>
        <SkillsHelp />
      </Grid>
      <Grid item xs={12}>
        <DevHelp />
      </Grid>
      <Grid item xs={12}>
        <HelpItem
          title={t('Help.FeedbackTitle')}
          meta={t('Help.FeedbackText')}
          link={t('Help.FeedbackLink')}
          linkUrl='https://netum-dev.slack.com/archives/CMHPSNRDX'
        />
      </Grid>
    </Grid>
  )
}

export default observer(Help)
