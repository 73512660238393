import { Icon, InputLabel, Rating } from '@mui/material'
import React from 'react'
import '../styles/skillbar.scss'

interface OPSkillbar {
  name: string
  value?: number
  readonly?: boolean
  disabled?: boolean
  onChange?: (e: React.SyntheticEvent<Element, Event>, newVal: number | null) => void
  maxRating?: number
  hasLabel?: boolean
  label?: string
  darklabel?: boolean
}

export const OPSkillbar = (props: OPSkillbar): JSX.Element => {
  const { name, value, readonly, disabled, onChange, maxRating, hasLabel = false, darklabel = false, label } = props

  return (
    <>
      {hasLabel && (
        <InputLabel className={'skillLabel' + (darklabel ? ' darklabel' : '')}>
          <span className={'typoLabelSkills'}>{label}</span>
        </InputLabel>
      )}
      <Rating
        data-testid={name}
        className='skillStyling'
        emptyIcon={<Icon className='skillButton' fontSize='inherit' />}
        icon={<Icon className='skillButton' fontSize='inherit' />}
        name={name}
        value={value}
        onChange={(e, newVal) => {
          if (newVal !== null && newVal > 0) onChange?.(e, newVal)
        }}
        readOnly={readonly}
        disabled={disabled}
        max={maxRating}
      />
    </>
  )
}
