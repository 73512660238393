import { Grid } from '@mui/material'
import React from 'react'

interface HelpItemProps {
  title: string
  meta: string
  link?: string
  linkUrl?: string
}

export const HelpItem = (props: HelpItemProps) => {
  const { title, meta, link, linkUrl } = props

  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={12}>
          <p className='help--titlefont'>{title}</p>
        </Grid>
        <Grid item xs={12}>
          <p className='help--metafont'>
            {meta}
            {link && linkUrl && (
              <a className='help--linkunderline' href={linkUrl}>
                {link}
              </a>
            )}
          </p>
        </Grid>
      </Grid>
    </Grid>
  )
}
