import { Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ICertEduProps } from '../../../interfaces/certAndEducation/certAndEducation'
import { useStore } from '../../../Provider'
import { OPAccordionItem } from '../../Accordion/OPAccordionItem'
import { MuiButtonVariants, OPButton } from '../../OPcomponents/OPButton'
import '../../styles/grid.scss'
import '../ceretedu.scss'
import CertTable from './CertTable'
import NewCert from './NewCert'

const Certificates = (props: ICertEduProps): JSX.Element => {
  const { t } = useTranslation()
  const rootStore = useStore()
  const { isOpen, rowId } = props

  const { validatedUser } = rootStore.loginStore
  const {
    getCertificates,
    loadingCertificates,
    getUserCertificates,
    filteredCertificates,
    showNewCertificateModal,
    showEditCertificateModalHandler,
    newCertificateModal,
    setCurrentCertificate,
  } = rootStore.certAndEducationStore

  const onAddClickHandler = () => {
    getCertificates(validatedUser)
    showNewCertificateModal()
  }

  useEffect(() => {
    return () => {
      showNewCertificateModal(false)
      showEditCertificateModalHandler(false)
      setCurrentCertificate({})
    }
  }, [showNewCertificateModal, showEditCertificateModalHandler, setCurrentCertificate])

  useEffect(() => {
    if (filteredCertificates.length === 0) getUserCertificates(validatedUser)
  }, [])

  return (
    <Grid container item display='flex'>
      <Grid container item className='gridcontainer--certedu__addbuttonmargin'>
        <Grid item className='griditem--addbutton'>
          <OPButton label={t('Buttons.AddNew')} buttonVariant={MuiButtonVariants.primary_outlined} onClick={onAddClickHandler} />
        </Grid>
      </Grid>
      <OPAccordionItem
        setOutsideExpanded={isOpen}
        title={`${t('CertAndEducation.CertificateHeader')} (${loadingCertificates ? '\u2026' : filteredCertificates.length})`}
        content={
          <Grid container>
            <Grid container className='gridcontainer--certedu__tablepadding'>
              {newCertificateModal && (
                <Grid container className='gridcontainer--certedu__containerpadding'>
                  <Grid item xs={12} className='griditem--formitem__zindex'>
                    <NewCert />
                  </Grid>
                </Grid>
              )}
              {filteredCertificates.length > 0 ? (
                <>
                  <Grid item xs={12}>
                    <CertTable rowId={rowId} />
                  </Grid>
                </>
              ) : (
                <>{!newCertificateModal && <div className='ceretedu--fonttext'>{t('CertAndEducation.NoCertFound')}</div>}</>
              )}
            </Grid>
          </Grid>
        }
      />
    </Grid>
  )
}

export default observer(Certificates)
