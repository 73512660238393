import { CircularProgress } from '@mui/material'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import agent from '../../api/agent'
import { getErrMsg, toastNeg, toastPos } from '../../common/toastHelper'
import { IComment, IListDevelopmentGoal } from '../../interfaces/discussions/IDiscussion'
import { useStore } from '../../Provider'
import { OPAccordionContent } from '../Accordion/OPAccordionContent'
import { OPAccordionItem } from '../Accordion/OPAccordionItem'
import { MuiButtonVariants, OPButton } from '../OPcomponents/OPButton'
import { OPDivider } from '../OPcomponents/OPDivider'
import { OPTextField } from '../OPcomponents/OPTextField'
import '../styles/developmentPage.scss'
import { DeleteIcon } from '../styles/Icons'
import { DevelopmentGoalForm } from './DevelopmentGoalForm'

interface DevGoalProps {
  goal: IListDevelopmentGoal
  reOpenGoal?: (g: IListDevelopmentGoal) => void
  setGoalReady?: (g: IListDevelopmentGoal) => void
  onDelete?: (g: IListDevelopmentGoal) => void
  isOpen?: boolean
}

export const DevelopmentGoal = observer((props: DevGoalProps): JSX.Element => {
  const { goal, reOpenGoal, setGoalReady, onDelete, isOpen } = props
  const {
    i18n: { language },
    t,
  } = useTranslation()
  const rootStore = useStore()
  const {
    userDiscussion,
    isConfirmReadySectionVisible,
    setIsConfirmReadySectionVisible,
    isAddCommentVisible,
    setIsAddCommentVisible,
    isConfirmDeleteGoalVisible,
    setIsConfirmDeleteGoalVisible,
    isNewDevGoalVisible,
    isNewFBGoalVisible,
    isNewDiscussionFBGoalVisible,
    isNewDiscussionDevGoalVisible,
    completeDevelopmentGoal,
    postGoalComment,
    getGoals,
    isEditFeedbackGoal,
    reopenDevelopmentGoal,
    selectDevelopmentGoal,
    selectedDevelopmentGoal,
    deleteDevelopmentGoal,
    setIsEditDevelopmentGoal,
    isEditDiscussionGoal,
    isNewMiniDiscussionVisible,
    isNewDevDiscussionVisible,
    isConfirmSendDiscussionVisible,
    isEditColleagueFeedback,
    isConfirmDeleteDiscussionVisible,
  } = rootStore.kekeStore
  const { validatedUser } = rootStore.loginStore

  const isEmployee = validatedUser === userDiscussion?.employeeId
  const isEmployer = validatedUser === userDiscussion?.employerId

  const [goalValues, setGoalValues] = useState<IListDevelopmentGoal>(goal)
  const [commentsLoading, setCommentsLoading] = useState<boolean>(true)
  const [comments, setComments] = useState<IComment[]>([])
  const [newCommentValue, setNewCommentValue] = useState('')
  const [accordionOpen, setAccordionOpen] = useState<boolean>(false)

  const divRef = useRef<HTMLDivElement>(null)

  const getComments = async () => {
    setCommentsLoading(true)
    try {
      const response = await agent.Discussions.getDevelopmentGoal(goal.goalId)
      setComments(response.comments)
      setCommentsLoading(false)
    } catch (error) {
      toastNeg('Tosts.FetchCommentsError')
      setCommentsLoading(false)
    }
  }

  const handleMarkReadySectionOpen = (goal: IListDevelopmentGoal) => {
    setIsConfirmReadySectionVisible(true)
    selectDevelopmentGoal(goal)
  }

  const handleMarkReadySectionClose = () => {
    setIsConfirmReadySectionVisible(false)
    selectDevelopmentGoal(undefined)
  }

  const handleAddComment = async () => {
    try {
      await postGoalComment(goalValues.goalId, { personId: validatedUser, comment: newCommentValue })
      setIsAddCommentVisible(false)
      setNewCommentValue('')
      getComments()
      toastPos(t('Toasts.DiscussionCommentSuccess'))
    } catch (error) {
      toastNeg(getErrMsg(error, t) || t('Toasts.DiscussionCommentError'))
    }
  }

  const confirmGoalReady = async () => {
    try {
      await completeDevelopmentGoal(goalValues.goalId)
      if (setGoalReady) setGoalReady({ ...goalValues, completed: true })
      handleMarkReadySectionClose()
      toastPos(t('Toasts.DiscussionGoalCompleteSuccess'))
    } catch (error) {
      toastNeg(getErrMsg(error, t) || t('Toasts.DiscussionGoalCompleteError'))
    }
  }

  const confirmReOpen = async () => {
    try {
      await reopenDevelopmentGoal(goalValues.goalId)
      if (reOpenGoal) reOpenGoal({ ...goalValues, completed: false })
      setIsConfirmReadySectionVisible(false)
      selectDevelopmentGoal(undefined)
      await getGoals(validatedUser)
      toastPos(t('Toasts.DiscussionGoalReopenSuccess'))
    } catch (error) {
      toastNeg(getErrMsg(error, t) || t('Toasts.DiscussionGoalReopenError'))
    }
  }

  const handleDelete = async () => {
    try {
      setIsConfirmDeleteGoalVisible(false)
      setIsEditDevelopmentGoal(false)
      if (selectedDevelopmentGoal) {
        if (onDelete) onDelete(goal)
        await deleteDevelopmentGoal(selectedDevelopmentGoal.goalId)
        toastPos(t('Toasts.DiscussionGoalDeleteSuccess'))
      }
    } catch (error) {
      toastNeg(getErrMsg(error, t) || t('Toasts.DiscussionGoalDeleteError'))
    }
  }

  useEffect(() => {
    if (accordionOpen) {
      divRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [accordionOpen])

  const disabledButtons =
    isEditFeedbackGoal ||
    isConfirmDeleteGoalVisible ||
    isAddCommentVisible ||
    isConfirmReadySectionVisible ||
    isNewDevGoalVisible ||
    isNewFBGoalVisible ||
    isNewDiscussionFBGoalVisible ||
    isNewDiscussionDevGoalVisible ||
    isNewMiniDiscussionVisible ||
    isNewDevDiscussionVisible ||
    isConfirmSendDiscussionVisible ||
    isEditColleagueFeedback ||
    isConfirmDeleteDiscussionVisible ||
    (isEditDiscussionGoal && goal.goalId !== selectedDevelopmentGoal?.goalId)

  return (
    <OPAccordionItem
      setOutsideExpanded={isOpen}
      insideAccordion
      lockedOpen={
        selectedDevelopmentGoal?.goalId === goal.goalId &&
        (isEditDiscussionGoal || isConfirmDeleteGoalVisible || isConfirmReadySectionVisible || isAddCommentVisible)
      }
      key={goalValues.goalId}
      title={goalValues.name ?? ''}
      created={`${t('Discussions.GoalCreated')} ${moment(goalValues.created).locale(language).format('L')} (${
        goalValues.completed ? t('Discussions.GoalCompleted') : t('Discussions.GoalNotCompleted')
      })`}
      onFirstOpen={() => getComments()}
      setIsOpen={setAccordionOpen}
      content={
        <div ref={divRef} className='scrollPadding'>
          {selectedDevelopmentGoal?.goalId === goalValues.goalId && isEditDiscussionGoal ? (
            <DevelopmentGoalForm
              onCancel={() => {
                setIsEditDevelopmentGoal(false)
                selectDevelopmentGoal(undefined)
              }}
              goal={goalValues}
              onSave={(g, name) => {
                setIsEditDevelopmentGoal(false)
                selectDevelopmentGoal(undefined)
                setGoalValues({
                  ...goalValues,
                  description: g?.description,
                  name: g?.name,
                  schedule: g?.schedule,
                  skillId: g?.skillId,
                  skillName: name,
                })
              }}
            />
          ) : (
            <div>
              <OPAccordionContent listContent={{ label: `${t('Discussions.GoalDesc')}:`, specs: goalValues.description ?? '' }} />
              <OPAccordionContent listContent={{ label: `${t('Discussions.GoalSchedule')}:`, specs: goalValues.schedule ?? '' }} />
              <OPAccordionContent listContent={{ label: `${t('Discussions.GoalSkill')}:`, specs: goalValues.skillName ?? '' }} />
              <div className='addCommentsDiv'>
                <div>
                  <p>{t('Discussions.GoalComments')}:</p>
                </div>
                {!goalValues.completed && (
                  <div>
                    <OPButton
                      label={t('Buttons.AddComment')}
                      disabled={disabledButtons}
                      buttonVariant={MuiButtonVariants.secondary_outlined}
                      onClick={() => {
                        selectDevelopmentGoal(goalValues)
                        setIsAddCommentVisible(true)
                      }}
                    />
                  </div>
                )}
              </div>
              {isAddCommentVisible && selectedDevelopmentGoal?.goalId === goalValues.goalId && (
                <div className='newCommentDiv'>
                  <OPTextField
                    value={newCommentValue}
                    fullWidth
                    label={t('Discussions.NewComment')}
                    onChange={(e) => setNewCommentValue(e.target.value)}
                    maxLength={2000}
                  />
                  <div className='newCommentButtons'>
                    <div className='hideCommentButton'>
                      <OPButton
                        buttonVariant={MuiButtonVariants.error_outlined}
                        label={t('Buttons.Cancel')}
                        onClick={() => {
                          setNewCommentValue('')
                          selectDevelopmentGoal(undefined)
                          setIsAddCommentVisible(false)
                        }}
                      />
                    </div>
                    <div>
                      <OPButton
                        disabled={newCommentValue === ''}
                        buttonVariant={MuiButtonVariants.primary}
                        onClick={handleAddComment}
                        label={t('Buttons.AddComment')}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className='commentSection'>
                {comments.length === 0 ? (
                  <div>{t('Discussions.NoComments')}</div>
                ) : commentsLoading ? (
                  <div className='loaderDiv'>
                    <CircularProgress />
                  </div>
                ) : (
                  comments.map((c) => {
                    return (
                      <div className='singleComment' key={c.commentId}>
                        {c.personName} {moment(c.date).locale(language).format('L')} {t('Discussions.CommentMidString')} {c.message}
                      </div>
                    )
                  })
                )}
              </div>
              <div className='spacediv'></div>
              {(isEmployee || isEmployer) &&
                (!goalValues.completed ? (
                  <div className='modifyButtonDiv'>
                    <div className='deleteButton'>
                      <OPButton
                        label={t('Buttons.Delete')}
                        disabled={disabledButtons}
                        buttonVariant={MuiButtonVariants.error}
                        onClick={() => {
                          selectDevelopmentGoal(goalValues)
                          setIsConfirmDeleteGoalVisible(true)
                        }}
                        endIcon={<DeleteIcon fill={disabledButtons ? '#757575' : 'white'} />}
                      />
                    </div>
                    <div className='modifyButton'>
                      <OPButton
                        label={t('Buttons.EditGoal')}
                        disabled={disabledButtons}
                        buttonVariant={MuiButtonVariants.secondary_outlined}
                        onClick={() => {
                          selectDevelopmentGoal(goalValues)
                          setIsEditDevelopmentGoal(true)
                        }}
                      />
                    </div>
                    <div>
                      <OPButton
                        label={t('Buttons.Complete')}
                        disabled={disabledButtons}
                        buttonVariant={MuiButtonVariants.primary_outlined}
                        onClick={() => handleMarkReadySectionOpen(goalValues)}
                      />
                    </div>
                  </div>
                ) : (
                  <div className='modifyButtonDiv'>
                    <div>
                      <OPButton
                        label={t('Buttons.Reopen')}
                        disabled={disabledButtons}
                        buttonVariant={MuiButtonVariants.secondary_outlined}
                        onClick={() => handleMarkReadySectionOpen(goalValues)}
                      />
                    </div>
                  </div>
                ))}
              {isConfirmReadySectionVisible && selectedDevelopmentGoal?.goalId === goalValues.goalId && (
                <div className='markReadyDiv'>
                  <div className='markReadyHeader'>
                    <p>{`${
                      goalValues.completed
                        ? t('Discussions.ConfirmDiscussionReopenComplete')
                        : t('Discussions.ConfirmDiscussionGoalComplete')
                    }?`}</p>
                  </div>
                  <div className='markReadyButtons'>
                    <div className='cancelMarkReadyButton'>
                      <OPButton
                        label={t('Buttons.Cancel')}
                        buttonVariant={MuiButtonVariants.error_outlined}
                        onClick={handleMarkReadySectionClose}
                      />
                    </div>
                    <div>
                      {goalValues.completed ? (
                        <OPButton
                          label={t('Buttons.Reopen')}
                          buttonVariant={MuiButtonVariants.primary_outlined}
                          onClick={() => confirmReOpen()}
                        />
                      ) : (
                        <OPButton
                          label={t('Buttons.Complete')}
                          buttonVariant={MuiButtonVariants.primary_outlined}
                          onClick={() => confirmGoalReady()}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
              {goalValues.completed && <div className='commentPadding' />}
              <OPDivider />
            </div>
          )}
          {isConfirmDeleteGoalVisible && selectedDevelopmentGoal?.goalId === goal?.goalId && (
            <div>
              <div className='confirmDiv'>
                <div className='confirmHeader'>
                  <p>{`${t('Discussions.ConfirmDiscussionGoalDelete')}?`}</p>
                </div>
                <div className='confirmButtons'>
                  <div className='cancelConfirmButton'>
                    <OPButton
                      label={t('Buttons.Cancel')}
                      buttonVariant={MuiButtonVariants.error_outlined}
                      onClick={() => {
                        setIsConfirmDeleteGoalVisible(false)
                      }}
                    />
                  </div>
                  <div>
                    <OPButton
                      label={t('Buttons.Delete')}
                      buttonVariant={MuiButtonVariants.error}
                      endIcon={<DeleteIcon />}
                      onClick={handleDelete}
                    />
                  </div>
                </div>
              </div>
              <OPDivider />
            </div>
          )}
        </div>
      }
    />
  )
})
