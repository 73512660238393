import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-chained-backend'
import HttpApi from 'i18next-http-backend'
import I18NextLocalStorageBackend from 'i18next-localstorage-backend'
import { initReactI18next } from 'react-i18next'

i18next
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: 'en-US',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {
      backends: [I18NextLocalStorageBackend, HttpApi],
      backendOptions: [
        {
          /* options for primary backend */
          expirationTime: 7 * 24 * 60 * 60 * 1000, // 7 days
          versions: { fi: '1.0.0.1.16', en: '1.0.0.1.14' },
          loadPath: '../public/locales/{{lng}}/{{ns}}.json',
        },
        {
          /* options for secondary backend */
        },
      ],
    },
  })

export default i18next
