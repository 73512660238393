import { useIsAuthenticated, useMsal, useMsalAuthentication } from '@azure/msal-react'

import { InteractionRequiredAuthError, InteractionType } from '@azure/msal-browser'

import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import App from './App'
import { toastNeg } from './common/toastHelper'
import { getUser, loginRequest, msalInstance } from './msal/msalConfig'
import { useStore } from './Provider'

const AppAuth = () => {
  const isAuthenticated = useIsAuthenticated()

  const { loginStore } = useStore()

  const { accounts, instance, inProgress } = useMsal()
  const { validateUser, gdprConsent, setShowTermsModal, updateUser, getUserPhoto, getUserLargePhoto } = loginStore

  const { login, error } = useMsalAuthentication(InteractionType.Silent, loginRequest)

  useEffect(() => {
    if (error instanceof InteractionRequiredAuthError) {
      login(InteractionType.Redirect, loginRequest)
    }
  }, [error, login])

  useEffect(() => {
    ; (async () => {
      if (isAuthenticated) {
        if (msalInstance.getActiveAccount() === null) msalInstance.setActiveAccount(accounts[0])
        const userr = await getUser()
        if (userr) {
          validateUser(userr)
            .catch((error) => {
              console.log(error)
              toastNeg(error?.toString() ?? 'Authorization failed')
            })
            .then(() => {
              updateUser().catch((error) => console.log(error))
              getUserLargePhoto().catch((error) => console.log(error))
              getUserPhoto().catch((error) => console.log(error))
            })
        }
      }

      if (!gdprConsent) {
        setShowTermsModal()
      }
    })()
  }, [gdprConsent, setShowTermsModal, updateUser, validateUser, getUserPhoto, accounts, isAuthenticated, getUserLargePhoto])

  return <App />
}

export default observer(AppAuth)
