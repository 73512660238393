import { Grid } from '@mui/material'
import DOMPurify from 'dompurify'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { ICertEduProps } from '../../../interfaces/certAndEducation/certAndEducation'
import { useStore } from '../../../Provider'
import { OPAccordionItem } from '../../Accordion/OPAccordionItem'
import DeleteConfirmation from '../../OPcomponents/DeleteConfirmation'
import { MuiButtonVariants, OPButton } from '../../OPcomponents/OPButton'
import { OPLoadingCircle } from '../../OPcomponents/OPLoadingcircle'
import '../../styles/grid.scss'
import { DeleteIcon } from '../../styles/Icons'

import { getErrMsg, toastNeg, toastPos } from '../../../common/toastHelper'
import EditPublication from './EditPublication'
import NewPublication from './NewPublication'

interface IParams {
  userId: string
}

const Publications = (props: ICertEduProps): JSX.Element => {
  const { t } = useTranslation()
  const rootStore = useStore()
  const { isOpen } = props

  const { userId } = useParams<IParams>()

  const [user, setUser] = useState(0)

  const [deletePubConfirmation, setDeletePubConfirmation] = useState(false)
  const [deletePubId, setDeletePubId] = useState(-1)
  const { validatedUser } = rootStore.loginStore
  const {
    removeUserPublication,
    isNewPublication,
    newPublications,
    loadingPublications,
    editPublications,
    publication,
    isPublicationsEditing,
    getPersonPublications,
  } = rootStore.certAndEducationStore

  const deleteHandler = async (pubId: number) => {
    setDeletePubId(pubId ?? -1), setDeletePubConfirmation(true)
  }

  const deletePublication = async () => {
    try {
      if (deletePubId) {
        await removeUserPublication(user, deletePubId)
        toastPos(t('Toasts.DeleteSuccess'))
      }
    } catch (error) {
      toastNeg(getErrMsg(error, t) || t('Toasts.DeleteError'))
    } finally {
      getPersonPublications(user)
      isPublicationsEditing(false)
    }
  }

  useEffect(() => {
    if (userId !== undefined) {
      getPersonPublications(Number(userId))
      setUser(Number(userId))
    } else {
      getPersonPublications(validatedUser)
      setUser(validatedUser)
    }
  }, [getPersonPublications, validatedUser, userId])

  return (
    <>
      {loadingPublications ? (
        <OPLoadingCircle />
      ) : (
        <Grid container item display='inline-flex'>
          <Grid container item className='gridcontainer--certedu__addbuttonmargin'>
            <Grid item className='griditem--addbutton'>
              {validatedUser === user ? (
                <OPButton
                  label={t('CertAndEducation.AddPublications')}
                  buttonVariant={MuiButtonVariants.primary_outlined}
                  onClick={() => isNewPublication(true)}
                  disabled={newPublications || editPublications}
                />
              ) : (
                ''
              )}
            </Grid>
          </Grid>

          <OPAccordionItem
            title={`${t('CertAndEducation.PublicationsHeader')} (${loadingPublications ? '\u2026' : publication.length})`}
            setOutsideExpanded={newPublications || isOpen}
            content={
              <Grid container className='griditem--certedu__accordionpadding'>
                <Grid item xs={12}>
                  {!newPublications ? (
                    <>
                      {publication.length ? (
                        <>
                          {publication.map((pub) => (
                            <OPAccordionItem
                              publicationPaddings
                              key={pub.publicationId}
                              title={
                                pub.publicationTitle ??
                                (t('CertAndEducation.PublicationTitleNotFound') && pub.publicationTitle !== undefined)
                                  ? pub.publicationTitle
                                  : t('CertAndEducation.PublicationTitleNotFound')
                              }
                              content={
                                <>
                                  {!editPublications ? (
                                    <>
                                      <Grid container>
                                        <Grid
                                          item
                                          xs={10}
                                          className='ceretedu--fonttext griditem--certedu__pubtextpadding'
                                          dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(pub.publicationDescription ?? '', {
                                              // eslint-disable-next-line prettier/prettier
                                              ALLOWED_TAGS: ['b', 'i', 'u', 'ul', 'li', 'p', 'strong', 'br', 'em', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
                                            }),
                                          }}
                                        />
                                        {validatedUser === user ? (
                                          <Grid container justifyContent={'flex-end'}>
                                            <Grid item className='griditem--addbutton__removebutton'>
                                              <OPButton
                                                label={t('Buttons.Delete')}
                                                buttonVariant={MuiButtonVariants.error}
                                                onClick={() => deleteHandler(pub.publicationId ?? -1)}
                                                endIcon={<DeleteIcon />}
                                              />
                                            </Grid>

                                            <Grid item className='griditem--addbutton__editbutton'>
                                              <OPButton
                                                label={t('CertAndEducation.EditPublications')}
                                                buttonVariant={MuiButtonVariants.secondary_outlined}
                                                onClick={() => isPublicationsEditing(true)}
                                              />
                                            </Grid>
                                            <DeleteConfirmation
                                              whatToDelete='Publication'
                                              open={deletePubConfirmation}
                                              deleteHandler={deletePublication}
                                              handleClose={() => setDeletePubConfirmation(false)}
                                            />
                                          </Grid>
                                        ) : (
                                          ''
                                        )}
                                      </Grid>
                                    </>
                                  ) : (
                                    <EditPublication
                                      title={pub.publicationTitle ?? ''}
                                      desc={pub.publicationDescription ?? ''}
                                      publicationId={pub.publicationId ?? -1}
                                    />
                                  )}
                                </>
                              }
                            />
                          ))}
                        </>
                      ) : (
                        <div className='ceretedu--fonttext'>{t('CertAndEducation.NoPublicationsFound')}</div>
                      )}
                    </>
                  ) : (
                    <>
                      <NewPublication />
                    </>
                  )}
                </Grid>
              </Grid>
            }
          />
        </Grid>
      )}
    </>
  )
}

export default observer(Publications)
