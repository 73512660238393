import { Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import history from '../../history'
import { IOtherEmployerDetails } from '../../interfaces/projects/IProjects'
import { useStore } from '../../Provider'
import { Toast } from '../OPcomponents/Toast'
import PrivateWorkHistory from './workhistory/PrivateWorkHistory'
import PublicWorkHistory from './workhistory/PublicWorkHistory'

const WorkHistory = (): JSX.Element => {
  const { t } = useTranslation()
  const rootStore = useStore()

  const {
    showAddPrivateProjectModalHandler,
    showAddProjectModalHandler,
    setCurrentProject,
    setCurrentEmployerDetails,
    allOtherEmployerDetails,
    getAllEmployers,
    getAllEmployerDetails,
    allEmployers,
  } = rootStore.projectStore

  const { validatedUser } = rootStore.loginStore
  const { getUserPrivateWorkHistory, getUserPublicWorkHistory, publicWorkHistory, privateWorkHistory } = rootStore.personStore

  const profileClickedId = history.location.state as number

  useEffect(() => {
    if (publicWorkHistory.length === 0) getUserPublicWorkHistory(validatedUser)
    if (privateWorkHistory.length === 0) getUserPrivateWorkHistory(validatedUser)
    if (allOtherEmployerDetails.length === 0) getAllEmployerDetails(validatedUser)
    if (allEmployers.length === 0) getAllEmployers(validatedUser)
  }, [])

  useEffect(() => {
    return () => {
      showAddPrivateProjectModalHandler(false)
      showAddProjectModalHandler(false)
      setCurrentEmployerDetails({} as IOtherEmployerDetails)
      setCurrentProject({})
    }
  }, [setCurrentEmployerDetails, setCurrentProject, showAddPrivateProjectModalHandler, showAddProjectModalHandler])

  const findProject = (isPublic: boolean) => {
    if (isPublic) {
      return publicWorkHistory.find((project) => project.projectId === profileClickedId)?.projectId
    }
    return privateWorkHistory.find((project) => project.projectId === profileClickedId)?.projectId
  }

  return (
    <Grid>
      <Toast />

      <Grid container rowGap={4}>
        <Grid item xs={12}>
          <span className='profile--titlebigfont'>{t('Buttons.WorkHistory')}</span>
        </Grid>
        <Grid item xs={12}>
          <PublicWorkHistory rowId={findProject(true)} />
        </Grid>
        <Grid item xs={12}>
          <PrivateWorkHistory rowId={findProject(false)} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default observer(WorkHistory)
