import { Divider, Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IDropdownProjects, IProjects } from '../../../../interfaces/projects/IProjects'
import { useStore } from '../../../../Provider'
import { MuiButtonVariants, OPButton } from '../../../OPcomponents/OPButton'
import { OPSkillsCombobox } from '../../../OPcomponents/OPSkillsCombobox'
import ProjectHeader from '../ProjectHeader'
import NewProject from './NewProject'
import UserSkillPublic from './UserSkillPublic'

const AddNewProject = () => {
  const { t } = useTranslation()
  const rootStore = useStore()
  const { validatedUser } = rootStore.loginStore
  const { filteredPublicWorkHistories, getAllUserSkills } = rootStore.personStore
  const { getAllProjects, projects, publicDropdownProjects, setCurrentProject, getAllRoles, setNewProjectName } = rootStore.projectStore
  const { showAddProjectModalHandler } = rootStore.projectStore
  const [creatingNewProject, setCreatingNewProject] = useState(false)
  const [addingProject, setAddingProject] = useState(false)
  const [newProject, setNewProject] = useState<IProjects['project'] | undefined>(undefined)

  useEffect(() => {
    getAllProjects(validatedUser)
    getAllRoles()
    getAllUserSkills(validatedUser)
  }, [getAllProjects, getAllUserSkills, getAllRoles, validatedUser])

  const filteredProjects = publicDropdownProjects.filter((project) =>
    filteredPublicWorkHistories.every((userProject) => project.key !== userProject.projectId),
  )

  const handleAdd = (v: string | IDropdownProjects | null) => {
    if (v !== null) {
      if (typeof v !== 'string' && v.value) {
        const foundProject = projects.find((project) => project.project.projectId === Number(v.value))
        setNewProject(foundProject?.project)
      } else if (typeof v === 'string') {
        setNewProjectName(v)
        setCreatingNewProject(true)
      }
      setAddingProject(true)
    }
  }

  return (
    <Grid container rowSpacing={4}>
      <Grid item xs={12}>
        <span className='profile--newprojectheader'>{t('Projects.AddNewProject')}</span>
      </Grid>
      {!addingProject ? (
        <>
          <Grid item xs={6}>
            <OPSkillsCombobox
              isAddable
              label={`${t('WorkHistory.ProjectName')}: *`}
              optionList={filteredProjects}
              onChange={(_e, v) => {
                handleAdd(v)
              }}
              bold
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent={'flex-end'}>
              <OPButton
                label={t('Buttons.Cancel')}
                buttonVariant={MuiButtonVariants.error_outlined}
                onClick={() => showAddProjectModalHandler(false)}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          {creatingNewProject ? (
            <Grid item xs={12}>
              <NewProject setNewProject={setNewProject} setCreatingNewProject={setCreatingNewProject} />
            </Grid>
          ) : (
            <>
              <Grid item xs={12}>
                <ProjectHeader data={newProject} isNewProject={true} />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <UserSkillPublic projectId={newProject?.projectId} />
              </Grid>
            </>
          )}
        </>
      )}
    </Grid>
  )
}

export default observer(AddNewProject)
