import { CircularProgress } from '@mui/material'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import agent from '../../api/agent'
import { getErrMsg, toastNeg, toastPos } from '../../common/toastHelper'
import { IComment, IListFeedbackGoal } from '../../interfaces/discussions/IDiscussion'
import { useStore } from '../../Provider'
import '../styles/developmentPage.scss'
import { DeleteIcon } from '../styles/Icons'
import { OPAccordionContent } from '../Accordion/OPAccordionContent'
import { OPAccordionItem } from '../Accordion/OPAccordionItem'
import { MuiButtonVariants, OPButton } from '../OPcomponents/OPButton'
import { OPDivider } from '../OPcomponents/OPDivider'
import { OPTextField } from '../OPcomponents/OPTextField'
import { FeedbackGoalForm } from './FeedbackGoalForm'

interface FeedbackGoalProps {
  goal: IListFeedbackGoal
  reOpenGoal?: (g: IListFeedbackGoal) => void
  setGoalReady?: (g: IListFeedbackGoal) => void
  onDelete?: (g: IListFeedbackGoal) => void
}

export const FeedbackGoal = observer((props: FeedbackGoalProps): JSX.Element => {
  const { goal, setGoalReady, reOpenGoal, onDelete } = props
  const {
    i18n: { language },
    t,
  } = useTranslation()
  const rootStore = useStore()
  const { validatedUser } = rootStore.loginStore
  const {
    userDiscussion,
    completeFeedbackGoal,
    postFeedbackGoalComment,
    reopenFeedbackGoal,
    deleteFeedbackGoal,
    isNewDevGoalVisible,
    isNewFBGoalVisible,
    setIsConfirmReadySectionVisible,
    isConfirmReadySectionVisible,
    setIsAddCommentVisible,
    isAddCommentVisible,
    isConfirmDeleteGoalVisible,
    setIsConfirmDeleteGoalVisible,
    selectedFeedbackGoal,
    isEditFeedbackGoal,
    isEditDiscussionGoal,
    selectFeedbackGoal,
    setIsEditFeedbackGoal,
    isNewMiniDiscussionVisible,
    isNewDevDiscussionVisible,
    isConfirmSendDiscussionVisible,
    isEditColleagueFeedback,
  } = rootStore.kekeStore
  const [goalValues, setGoalValues] = useState<IListFeedbackGoal>(goal)
  const [commentsLoading, setCommentsLoading] = useState<boolean>(true)
  const [comments, setComments] = useState<IComment[]>([])
  const [newCommentValue, setNewCommentValue] = useState('')
  const [accordionOpen, setAccordionOpen] = useState<boolean>(false)

  const isEmployee = validatedUser === userDiscussion?.employeeId
  const isEmployer = validatedUser === userDiscussion?.employerId

  const divRef = React.useRef<HTMLDivElement>(null)

  const getComments = async () => {
    setCommentsLoading(true)
    try {
      const response = await agent.Discussions.getFeedbackGoal(goalValues.goalId)
      setComments(response.comments)
      setCommentsLoading(false)
    } catch (error) {
      toastNeg('Tosts.FetchCommentsError')
      setCommentsLoading(false)
    }
  }

  const handleMarkReadySectionOpen = (goal: IListFeedbackGoal) => {
    setIsConfirmReadySectionVisible(true)
    selectFeedbackGoal(goal)
  }

  const handleMarkReadySectionClose = () => {
    setIsConfirmReadySectionVisible(false)
    selectFeedbackGoal(undefined)
  }

  const handleAddComment = async () => {
    try {
      await postFeedbackGoalComment(goalValues.goalId, { personId: validatedUser, comment: newCommentValue })
      setIsAddCommentVisible(false)
      setNewCommentValue('')
      selectFeedbackGoal(undefined)
      getComments()
      toastPos(t('Toasts.DiscussionCommentSuccess'))
    } catch (error) {
      toastNeg(getErrMsg(error, t) || t('Toasts.DiscussionCommentError'))
    }
  }

  const confirmGoalReady = async () => {
    try {
      await completeFeedbackGoal(goalValues.goalId)
      if (setGoalReady) setGoalReady({ ...goalValues, completed: true })
      handleMarkReadySectionClose()
      toastPos(t('Toasts.DiscussionGoalCompleteSuccess'))
    } catch (error) {
      toastNeg(getErrMsg(error, t) || t('Toasts.DiscussionGoalCompleteError'))
    }
  }

  const confirmReOpen = async () => {
    try {
      await reopenFeedbackGoal(goalValues.goalId)
      setIsConfirmReadySectionVisible(false)
      selectFeedbackGoal(undefined)
      if (reOpenGoal) reOpenGoal({ ...goalValues, completed: false })
      toastPos(t('Toasts.DiscussionGoalReopenSuccess'))
    } catch (error) {
      toastNeg(getErrMsg(error, t) || t('Toasts.DiscussionGoalReopenError'))
    }
  }

  const handleDelete = async () => {
    setIsConfirmDeleteGoalVisible(false)
    setIsEditFeedbackGoal(false)
    if (selectedFeedbackGoal) {
      if (onDelete) onDelete(goal)
      try {
        await deleteFeedbackGoal(selectedFeedbackGoal.goalId)
        toastPos(t('Toasts.DiscussionGoalDeleteSuccess'))
      } catch (error) {
        toastNeg(getErrMsg(error, t) || t('Toasts.DiscussionGoalDeleteError'))
      }
    }
  }

  useEffect(() => {
    if (accordionOpen) {
      divRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [accordionOpen])

  const disabledButtons =
    isEditDiscussionGoal ||
    isConfirmDeleteGoalVisible ||
    isAddCommentVisible ||
    isConfirmReadySectionVisible ||
    isNewDevGoalVisible ||
    isNewFBGoalVisible ||
    isNewMiniDiscussionVisible ||
    isNewDevDiscussionVisible ||
    isConfirmSendDiscussionVisible ||
    isEditColleagueFeedback ||
    (isEditFeedbackGoal && goal.goalId !== selectedFeedbackGoal?.goalId)

  return (
    <OPAccordionItem
      insideAccordion
      lockedOpen={
        selectedFeedbackGoal?.goalId === goal.goalId &&
        (isEditFeedbackGoal || isConfirmDeleteGoalVisible || isConfirmReadySectionVisible || isAddCommentVisible)
      }
      key={goalValues.goalId}
      setIsOpen={setAccordionOpen}
      title={`${t('Discussions.FeedbackGoal')}`}
      created={`${t('Discussions.GoalCreated')} ${moment(goalValues.created).locale(language).format('L')} (${
        goalValues.completed ? t('Discussions.GoalCompleted') : t('Discussions.GoalNotCompleted')
      })`}
      onFirstOpen={() => getComments()}
      content={
        <div ref={divRef} className='scrollPadding'>
          {selectedFeedbackGoal?.goalId === goalValues.goalId && isEditFeedbackGoal ? (
            <FeedbackGoalForm
              goal={goalValues}
              onCancel={() => {
                setIsEditFeedbackGoal(false)
                selectFeedbackGoal(undefined)
              }}
              onSave={(g) => {
                setIsEditFeedbackGoal(false)
                selectFeedbackGoal(undefined)
                setGoalValues({
                  ...goalValues,
                  teamFeedback: g?.teamFeedback,
                  employerFeedback: g?.employerFeedback,
                  customerFeedback: g?.customerFeedback,
                })
              }}
            />
          ) : (
            <div>
              {goalValues.teamFeedback && (
                <OPAccordionContent
                  listContent={{
                    label: `${t('Discussions.FeedbackGoalTeam')}:`,
                    specs: goalValues.teamFeedback,
                  }}
                />
              )}
              {goalValues.employerFeedback && (
                <OPAccordionContent
                  listContent={{
                    label: `${t('Discussions.FeedbackGoalEmployer')}:`,
                    specs: goalValues.employerFeedback,
                  }}
                />
              )}
              {goalValues.customerFeedback && (
                <OPAccordionContent
                  listContent={{
                    label: `${t('Discussions.FeedbackGoalCustomer')}:`,
                    specs: goalValues.customerFeedback,
                  }}
                />
              )}
              <div className='addCommentsDiv'>
                <div>
                  <p>{t('Discussions.GoalComments')}:</p>
                </div>
                {!goalValues.completed && (
                  <div>
                    <OPButton
                      label={t('Buttons.AddComment')}
                      disabled={disabledButtons}
                      buttonVariant={MuiButtonVariants.secondary_outlined}
                      onClick={() => {
                        selectFeedbackGoal(goalValues)
                        setIsAddCommentVisible(true)
                      }}
                    />
                  </div>
                )}
              </div>
              {isAddCommentVisible && selectedFeedbackGoal?.goalId === goalValues.goalId && (
                <div className='newCommentDiv'>
                  <OPTextField
                    value={newCommentValue}
                    fullWidth
                    label={t('Discussions.NewComment')}
                    onChange={(e) => setNewCommentValue(e.target.value)}
                    maxLength={2000}
                  />
                  <div className='newCommentButtons'>
                    <div className='hideCommentButton'>
                      <OPButton
                        buttonVariant={MuiButtonVariants.error_outlined}
                        label={t('Buttons.Cancel')}
                        onClick={() => {
                          setNewCommentValue('')
                          selectFeedbackGoal(undefined)
                          setIsAddCommentVisible(false)
                        }}
                      />
                    </div>
                    <div>
                      <OPButton
                        disabled={newCommentValue === ''}
                        buttonVariant={MuiButtonVariants.primary}
                        onClick={handleAddComment}
                        label={t('Buttons.AddComment')}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className='commentSection'>
                {comments.length === 0 ? (
                  <div>{t('Discussions.NoComments')}</div>
                ) : commentsLoading ? (
                  <div className='loaderDiv'>
                    <CircularProgress />
                  </div>
                ) : (
                  comments.map((c) => {
                    return (
                      <div className='singleComment' key={c.commentId}>
                        {c.personName} {moment(c.date).locale(language).format('L')} {t('Discussions.CommentMidString')} {c.message}
                      </div>
                    )
                  })
                )}
              </div>
              <div className='spacediv'></div>
              {(isEmployee || isEmployer) &&
                (!goalValues.completed ? (
                  <div className='modifyButtonDiv'>
                    <div className='deleteButton'>
                      <OPButton
                        label={t('Buttons.Delete')}
                        disabled={disabledButtons}
                        buttonVariant={MuiButtonVariants.error}
                        onClick={() => {
                          selectFeedbackGoal(goalValues)
                          setIsConfirmDeleteGoalVisible(true)
                        }}
                        endIcon={<DeleteIcon fill={disabledButtons ? '#757575' : 'white'} />}
                      />
                    </div>
                    <div className='modifyButton'>
                      <OPButton
                        label={t('Buttons.EditGoal')}
                        disabled={disabledButtons}
                        buttonVariant={MuiButtonVariants.secondary_outlined}
                        onClick={() => {
                          selectFeedbackGoal(goalValues)
                          setIsEditFeedbackGoal(true)
                        }}
                      />
                    </div>
                    <div>
                      <OPButton
                        label={t('Buttons.Complete')}
                        disabled={disabledButtons}
                        buttonVariant={MuiButtonVariants.primary_outlined}
                        onClick={() => handleMarkReadySectionOpen(goalValues)}
                      />
                    </div>
                  </div>
                ) : (
                  <div className='modifyButtonDiv'>
                    <div>
                      <OPButton
                        label={t('Buttons.Reopen')}
                        disabled={disabledButtons}
                        buttonVariant={MuiButtonVariants.secondary_outlined}
                        onClick={() => handleMarkReadySectionOpen(goalValues)}
                      />
                    </div>
                  </div>
                ))}
              {isConfirmReadySectionVisible && selectedFeedbackGoal?.goalId === goalValues.goalId && (
                <div className='markReadyDiv'>
                  <div className='markReadyHeader'>
                    <p>{`${
                      goalValues.completed ? t('Discussions.ConfirmFeedbackReopenComplete') : t('Discussions.ConfirmDiscussionGoalComplete')
                    }?`}</p>
                  </div>
                  <div className='markReadyButtons'>
                    <div className='cancelMarkReadyButton'>
                      <OPButton
                        label={t('Buttons.Cancel')}
                        buttonVariant={MuiButtonVariants.error_outlined}
                        onClick={handleMarkReadySectionClose}
                      />
                    </div>
                    <div>
                      {goalValues.completed ? (
                        <OPButton
                          label={t('Buttons.Reopen')}
                          buttonVariant={MuiButtonVariants.primary_outlined}
                          onClick={() => confirmReOpen()}
                        />
                      ) : (
                        <OPButton
                          label={t('Buttons.Complete')}
                          buttonVariant={MuiButtonVariants.primary_outlined}
                          onClick={() => confirmGoalReady()}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
              {goalValues.completed && <div className='commentPadding' />}
              <OPDivider />
            </div>
          )}
          {isConfirmDeleteGoalVisible && selectedFeedbackGoal?.goalId === goal?.goalId && (
            <div>
              <div className='confirmDiv'>
                <div className='confirmHeader'>
                  <p>{`${t('Discussions.ConfirmDiscussionGoalDelete')}?`}</p>
                </div>
                <div className='confirmButtons'>
                  <div className='cancelConfirmButton'>
                    <OPButton
                      label={t('Buttons.Cancel')}
                      buttonVariant={MuiButtonVariants.error_outlined}
                      onClick={() => setIsConfirmDeleteGoalVisible(false)}
                    />
                  </div>
                  <div>
                    <OPButton
                      label={t('Buttons.Delete')}
                      endIcon={<DeleteIcon />}
                      buttonVariant={MuiButtonVariants.error}
                      onClick={handleDelete}
                    />
                  </div>
                </div>
              </div>
              <div className='grayDivider' />
            </div>
          )}
        </div>
      }
    />
  )
})
