import React from 'react'
import '../styles/accordionStyles.scss'

interface AccordionContentProps {
  contentText?: string
  listContent?: ContentListItem
  ariaLabel?: string
  dataTestId?: string
}

interface ContentListItem {
  label: string
  specs: string | JSX.Element
}

export const OPAccordionContent = (props: AccordionContentProps): JSX.Element => {
  const { contentText, listContent, ariaLabel, dataTestId } = props

  return contentText ? (
    <div aria-label={ariaLabel} data-testid={dataTestId} className='contentBase contentText'>
      <p>{contentText}</p>
    </div>
  ) : listContent ? (
    <div aria-label={ariaLabel} data-testid={dataTestId} className='contentBase contentFlex'>
      <div className='typoLabel'>{listContent.label}</div>
      <div className='typoSpecs'>{listContent.specs}</div>
    </div>
  ) : (
    <div></div>
  )
}
