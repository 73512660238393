import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import 'mobx-react-lite/batchingForReactDom'
import React, { Suspense } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { createRoot } from 'react-dom/client'
import { Router } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.min.css'
import AppAuth from './AppAuth'
import ScrollToTop from './containers/ScrollToTop/ScrollToTop'
import history from './history'
import './i18next'
import './index.scss'
import { Provider } from './Provider'
import { RootStore } from './stores/storesContext'

import { msalInstance } from './msal/msalConfig'
import { OPLoadingCircle } from './components/OPcomponents/OPLoadingcircle'
import CustomLoader from './common/CustomLoader'

const container = document.getElementById('root')
const root = createRoot(container!)

if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
}

msalInstance.enableAccountStorageEvents()

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult
    const account = payload.account
    msalInstance.setActiveAccount(account)
  }
})

root.render(
  <Provider value={new RootStore()}>
    <MsalProvider instance={msalInstance}>
      <Suspense
        fallback={
          <CustomLoader/>
          
        }
      >
        <Router history={history}>
          <ScrollToTop>
            <AppAuth />
          </ScrollToTop>
        </Router>
      </Suspense>
    </MsalProvider>
  </Provider>,
)
