import { Avatar, Grid } from '@mui/material'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { localizedLanguageSkills } from '../../../common/languageHelper'
import history from '../../../history'
import { useStore } from '../../../Provider'
import '../../styles/certedulang.scss'

interface CertEduLangBaseProps {
  id: number
  name?: string
  substring?: string
  photo?: string
}

const CertEduLangBase = () => {
  const { t } = useTranslation()
  const rootStore = useStore()
  const { CertEduLangBase, getLanguagePersons, getEducationPersons, getCertificatePersons } = rootStore.seachStore

  const [data, setData] = useState<CertEduLangBaseProps[]>([])
  const [title, setTitle] = useState('')
  const thisCertEduLangBase = CertEduLangBase

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    if (thisCertEduLangBase) {
      if (thisCertEduLangBase.type === 'language') {
        setTitle(t('Language.Expert'))
        const languageSkillOptions = localizedLanguageSkills(t)

        const langPersons = await getLanguagePersons(thisCertEduLangBase.id)
        const langPersonsData = langPersons?.map((person) => {
          return {
            photo: person.photo ?? '',
            id: person.personId ?? -1,
            name: person.personName ?? '',
            substring: languageSkillOptions.find((lang) => lang.value === person.languageLevel)?.text ?? '',
          }
        })
        setData(langPersonsData ?? [])
      } else if (thisCertEduLangBase.type === 'education') {
        setTitle(t('CertAndEducation.Educated'))

        const eduPersons = await getEducationPersons(thisCertEduLangBase.id)
        const eduPersonsData = eduPersons?.map((person) => {
          return {
            photo: person.photo ?? '',
            id: person.personId ?? -1,
            substring: person.personName ?? '',
          }
        })
        setData(eduPersonsData ?? [])
      } else if (thisCertEduLangBase.type === 'certificate') {
        setTitle(t('CertAndEducation.Certified'))

        const certPersons = await getCertificatePersons(thisCertEduLangBase.id)
        const certPersonsData = certPersons?.map((person) => {
          return {
            photo: person.photo ?? '',
            id: person.personId ?? -1,
            name: person.personName ?? '',
            substring:
              `${t('CertAndEducation.CertActive')} ${
                person.startDate ? `${moment(person.startDate).format('L')}` : t('CertAndEducation.NotDefined')
              } - ${person.expirationDate ? `${moment(person.expirationDate).format('L')}` : t('CertAndEducation.NotDefined')}` ?? '',
          }
        })
        setData(certPersonsData ?? [])
      }
    }
  }

  return (
    <Grid container spacing={2} className='gridcontainer--formcontainer__innerform '>
      <>
        <Grid item xs={12} className='certedu__headertext'>
          <span>{title}</span>
        </Grid>
        {data.length === 0 ? (
          <Grid item xs={12}>
            <span className='certedu__mainfont'>{t('Placeholders.NoResults')}</span>
          </Grid>
        ) : (
          <>
            {data.map((item) => (
              <Grid
                item
                xs={12}
                key={_.uniqueId('certedu-')}
                className='certedu__personstyle'
                onClick={() => history.push('/persons/' + item.id)}
              >
                <Grid container columnSpacing={2} alignItems='center'>
                  <Grid item>
                    <Avatar src={`data:image/jpeg;base64,${item.photo}`} />
                  </Grid>
                  <Grid item>
                    <Grid item xs={12}>
                      <span className='certedu__mainfont'>{item.name}</span>
                    </Grid>
                    <Grid item xs={12}>
                      <span className='certedu__subfont'>{item.substring}</span>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </>
        )}
      </>
    </Grid>
  )
}

export default observer(CertEduLangBase)
