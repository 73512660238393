export const languageSkillOptions = [
  {
    key: 'A1: perustason kielenkäyttäjä',
    text: 'A1: perustason kielenkäyttäjä',
    value: 'A1: perustason kielenkäyttäjä',
  },
  {
    key: 'A2: perustason kielenkäyttäjä',
    text: 'A2: perustason kielenkäyttäjä',
    value: 'A2: perustason kielenkäyttäjä',
  },
  {
    key: 'B1: itsenäinen kielenkäyttäjä',
    text: 'B1: itsenäinen kielenkäyttäjä',
    value: 'B1: itsenäinen kielenkäyttäjä',
  },
  {
    key: 'B2: itsenäinen kielenkäyttäjä',
    text: 'B2: itsenäinen kielenkäyttäjä',
    value: 'B2: itsenäinen kielenkäyttäjä',
  },
  {
    key: 'C1: taitava kielenkäyttäjä',
    text: 'C1: taitava kielenkäyttäjä',
    value: 'C1: taitava kielenkäyttäjä',
  },
  {
    key: 'C2: taitava kielenkäyttäjä',
    text: 'C2: taitava kielenkäyttäjä',
    value: 'C2: taitava kielenkäyttäjä',
  },
]
