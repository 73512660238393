import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteInputChangeReason,
  createFilterOptions,
  InputLabel,
  Paper,
  TextField,
} from '@mui/material'
import { t } from 'i18next'
import React, { useState } from 'react'
import '../styles/combobox.scss'
import { Cross, DropdownArrow, DropDownArrowFocused, DropdownArrow_blue } from '../styles/Icons'

interface ComboboxProps {
  curState?: any[] | any
  optionList: any[]
  label: string
  name?: string
  multiple?: boolean
  ariaLabel?: string
  dataTestId?: string
  disabled?: boolean
  disableCloseOnSelect?: boolean
  placeholder?: string
  onChange: (e: React.SyntheticEvent, v: string[] | string | null | any) => void
  onDelete?: (option: any) => void
  freeSolo?: boolean
  error?: boolean
  helperText?: string
  manInput?: boolean
  disableClearable?: boolean
  isAddable?: boolean
  bold?: boolean
}

interface filterItem {
  inputValue: string
  text: string
}

export const OPSkillsCombobox = (props: ComboboxProps): JSX.Element => {
  const {
    curState,
    multiple,
    disableCloseOnSelect = multiple,
    optionList,
    label,
    ariaLabel,
    dataTestId,
    disabled,
    placeholder,
    onChange,
    onDelete,
    freeSolo,
    name,
    error,
    helperText,
    manInput,
    disableClearable = true,
    isAddable = false,
    bold,
  } = props

  const filter = createFilterOptions<filterItem>()

  const [isOpen, setIsOpen] = useState(false)
  const [hover, setHover] = useState(false)
  const [itemValue, setItemValue] = useState<string[] | string | null>(multiple ? (curState ? curState : []) : curState ? curState : null)

  const handleChange = (e: React.SyntheticEvent, v: any, situation: string, option: AutocompleteChangeDetails<any> | undefined) => {
    if (situation === 'removeOption' || situation === 'clear') {
      onDelete?.(option)
    } else {
      setItemValue(v)
      onChange(e, v.inputValue ?? v)
    }
  }
  const handleManualInput = (e: React.SyntheticEvent, v: string, reason?: AutocompleteInputChangeReason) => {
    setItemValue(v)

    if (manInput && reason !== 'clear') {
      onChange(e, v)
    }
  }

  const partiallyBold = (text: string) => {
    const translation = t('Buttons.AddNew')
    if (text.startsWith(translation)) {
      const plainText = text.substring(translation.length)
      return (
        <>
          <b>{translation}</b>
          {plainText}
        </>
      )
    } else {
      return <>{text}</>
    }
  }

  return (
    <>
      <InputLabel className='comboBoxLabel' error={error} disabled={disabled} id={label}>
        {label}
      </InputLabel>
      <Autocomplete
        filterOptions={(options, params) => {
          const filtered = filter(options, params)
          if (params.inputValue !== '' && isAddable && !optionList?.find((option) => option.text === params.inputValue)) {
            filtered.push({
              inputValue: params.inputValue,
              text: `${t('Buttons.AddNew')}: "${params.inputValue}"`,
            })
          }

          return filtered
        }}
        className={`combobox ${isOpen ? 'open' : ''} ${multiple && itemValue && itemValue.length > 0 ? 'multiOptions' : ''}`}
        multiple={multiple}
        freeSolo={freeSolo}
        forcePopupIcon
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        disableCloseOnSelect={disableCloseOnSelect}
        disabled={disabled}
        onChange={handleChange}
        value={curState}
        selectOnFocus={false}
        options={optionList}
        disableClearable={disableClearable}
        aria-label={ariaLabel}
        data-testid={dataTestId}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        clearIcon={<Cross onMouseDown={(e) => e.stopPropagation()} />}
        popupIcon={isOpen ? <DropDownArrowFocused /> : hover ? <DropdownArrow_blue /> : <DropdownArrow />}
        ChipProps={{
          className: 'chipStyling',
          deleteIcon: <Cross />,
        }}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.key || option.text}>
              {bold ? partiallyBold(option.text) : option.text}
            </li>
          )
        }}
        isOptionEqualToValue={(option, value) => option.key === value.key}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option
          }
          if (option.inputValue) {
            return option.inputValue
          }
          return option.text
        }}
        PaperComponent={({ children }) => <Paper className='paperStyle'>{children}</Paper>}
        renderInput={(params) => (
          <TextField
            error={error}
            name={name}
            {...params}
            InputProps={{ ...params.InputProps, disableUnderline: true }}
            variant='standard'
            placeholder={placeholder}
          />
        )}
        onInputChange={handleManualInput}
      />
      {error && !disabled && <div className='comboboxerrortext'>{helperText}</div>}
    </>
  )
}
