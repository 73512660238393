import moment from 'moment'
import React, { FunctionComponent } from 'react'

interface OwnProps {
  startDate?: Date
  endDate?: Date | null
  roleName?: string
  employerId?: number
  children?: React.ReactNode
  canUseAsReference?: boolean | null | undefined
  projectOwner?: number
}

function showDate(startDateformat: string, endDateformat?: string, projectOwner?: number | undefined): string {
  if (projectOwner === 0) {
    if (startDateformat && endDateformat) {
      return `${startDateformat} - ${endDateformat}`
    } else if (startDateformat && !endDateformat) {
      return `${startDateformat} - jatkuu`
    } else {
      return ''
    }
  } else {
    return ''
  }
}

const NetumJob: FunctionComponent<OwnProps> = (props) => {
  let startDateformat = ''
  let endDateformat = ''

  if (props.startDate !== null && props.startDate !== undefined) {
    startDateformat = moment(props.startDate).format('D.M.yyyy')
  }
  if (props.endDate !== null && props.endDate !== undefined) {
    endDateformat = moment(props.endDate).format('D.M.yyyy')
  }

  return (
    <>
      <p className='plainText'>{showDate(startDateformat, endDateformat, props.projectOwner)}</p>
      <p className='plainText'>{props.children}</p>
    </>
  )
}

export default NetumJob
