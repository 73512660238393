import { Grid } from '@mui/material'
import { Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getErrMsg, toastNeg, toastPos } from '../../../common/toastHelper'
import { ICertificate, IExistingCertificate, INewCertificate } from '../../../interfaces/certAndEducation/certAndEducation'
import { useStore } from '../../../Provider'
import DeleteConfirmation from '../../OPcomponents/DeleteConfirmation'
import { ExitConfirmation } from '../../OPcomponents/ExitConfirmation'
import AutoSave from '../../OPcomponents/FormikAutosave'
import { OPDateField } from '../../OPcomponents/OPDateField'
import { OPMultiline } from '../../OPcomponents/OPMultiline'
import { ITableForm } from '../../OPcomponents/OPNewTable'
import { OPSkillsCombobox } from '../../OPcomponents/OPSkillsCombobox'
import { certSchema } from '../../OPcomponents/ValidationSchemas'
import '../../styles/grid.scss'
import '../ceretedu.scss'
import { OPSkillsButtons } from '../OPSkillsButtons'

const NewCert = (props: ITableForm): JSX.Element => {
  const { collapseHandler, isEdit, data } = props

  const { t } = useTranslation()

  const [deleteCertConfirmation, setDeleteCertConfirmation] = useState(false)

  const rootStore = useStore()
  const {
    showEditCertificateModalHandler,
    showEditCertificateModal,
    removeUserCertificate,
    editUserCertificate,
    certificates,
    postNewExistingCertificate,
    showNewCertificateModal,
    newCertificateModal,
    postNewCertificate,
    setCurrentCertificate,
    userCertificates,
    getUserCertificates,
    filteredCertificates,
  } = rootStore.certAndEducationStore
  const { validatedUser } = rootStore.loginStore

  const curCert = filteredCertificates.find((cert) => cert.sertificateId === data)

  const initVal: ICertificate = {
    name: curCert?.name ?? '',
    school: curCert?.school ?? '',
    startDate: curCert?.startDate ?? undefined,
    expirationDate: curCert?.expirationDate ?? undefined,
    comment: curCert?.comment ?? '',
    sertificateId: curCert?.sertificateId ?? -1,
  }

  const isNewCert = (cert: ICertificate): cert is INewCertificate => {
    return 'name' in cert && 'school' in cert && 'startDate' in cert && 'comment' in cert
  }
  const isNewExistingCert = (cert: ICertificate): cert is IExistingCertificate => {
    return (
      'sertificateId' in cert &&
      cert.sertificateId !== -1 &&
      'school' in cert &&
      'startDate' in cert &&
      'expirationDate' in cert &&
      'comment' in cert
    )
  }

  const filteredCerts = certificates.filter((allCert) => userCertificates.every((cert) => cert.sertificateId !== allCert.key))

  const onSubmit = async (values: ICertificate) => {
    const certId = curCert?.sertificateId ?? values.sertificateId
    try {
      if (isEdit) {
        if (certId && isNewCert(values)) {
          const editedCertificate: INewCertificate = {
            personId: validatedUser,
            name: values.name,
            school: values.school,
            startDate: values.startDate,
            expirationDate: values.expirationDate,
            comment: values.comment,
          }

          if (isEdit) {
            await editUserCertificate(certId, editedCertificate)
          }
        }
      } else if (newCertificateModal && certId) {
        if (certId === -1 && isNewCert(values)) {
          const formValues: INewCertificate = {
            personId: validatedUser,
            name: values.name,
            school: values.school,
            startDate: values.startDate,
            expirationDate: values.expirationDate,
            comment: values.comment,
          }
          await postNewCertificate(formValues)
        } else if (isNewExistingCert(values)) {
          const formValues: IExistingCertificate = {
            personId: validatedUser,
            certificateId: certId,
            school: values.school,
            startDate: values.startDate,
            expirationDate: values.expirationDate,
            comment: values.comment,
          }
          await postNewExistingCertificate(formValues)
        }
        toastPos(t('Toasts.CreateSuccess'))
      }
    } catch (err) {
      toastNeg(getErrMsg(err, t) || (isEdit ? t('Toasts.EditError') : t('Toasts.CreateError')))
    } finally {
      if (showEditCertificateModal) showEditCertificateModalHandler(false)
      if (!isEdit) closeHandler()
    }
  }

  const closeHandler = () => {
    if (newCertificateModal) showNewCertificateModal()
    if (showEditCertificateModal) showEditCertificateModalHandler(false)
    if (isEdit && collapseHandler) collapseHandler()
    setCurrentCertificate({})
    setTimeout(() => getUserCertificates(validatedUser), 550)
  }

  const deleteHandler = async () => {
    try {
      if (curCert?.sertificateId) {
        await removeUserCertificate(validatedUser, curCert?.sertificateId)
        toastPos(t('Toasts.DeleteSuccess'))
      }
    } catch (err) {
      getErrMsg(err, t) || t('Toasts.DeleteError')
    } finally {
      closeHandler()
    }
  }

  return (
    <Formik enableReinitialize initialValues={initVal} validationSchema={certSchema} onSubmit={(values) => onSubmit(values)}>
      {({ setFieldValue, values, handleChange, isSubmitting, isValid, dirty, handleSubmit, errors }) => (
        <Grid
          container
          rowSpacing={2}
          columnSpacing={3}
          className={isEdit ? 'gridcontainer--formcontainer__itempaddings' : 'gridcontainer--certedu__containerpadding'}
        >
          {!isEdit && (
            <Grid item xs={12}>
              <span className='profile--addnewfont nopadding'>{t('CertAndEducation.NewCertificate')}</span>
            </Grid>
          )}
          <ExitConfirmation when={dirty} />
          <Grid container rowGap={4} item xs={6}>
            {!isEdit ? (
              <Grid item xs={6}>
                <OPSkillsCombobox
                  freeSolo
                  disabled={isEdit}
                  curState={values.name}
                  name='name'
                  optionList={filteredCerts}
                  label={`${t('CertAndEducation.CertificateName')}*`}
                  onChange={(_e, val) => {
                    if (val && _e) {
                      setFieldValue('name', val.text ?? val)
                      setFieldValue('sertificateId', certificates.find((cert) => cert.text === val.text)?.key ?? -1)
                    }
                  }}
                  multiple={false}
                  manInput
                />
              </Grid>
            ) : (
              <Grid container item xs={12} rowGap={0.5}>
                <Grid item xs={12}>
                  <label className='profile--titlecaption'>{`${t('CertAndEducation.CertificateName')}*`}</label>
                </Grid>
                <Grid item xs={12}>
                  <label className='certedu--fonttext'>{values.name}</label>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12} className='griditem--formitem__itemtitlemargin'>
              <OPMultiline
                name='school'
                value={values.school}
                label={`${t('CertAndEducation.CertificateGranterName')}*`}
                onChange={handleChange}
                maxChars={50}
                hasCounter
                error={Boolean(errors.school)}
                errorText={t(errors.school || '')}
                disabled={deleteCertConfirmation}
              />
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <OPMultiline
              value={values.comment}
              name='comment'
              label={t('CertAndEducation.FurtherInfo')}
              onChange={handleChange}
              minRows={6}
              maxChars={250}
              hasCounter
              disabled={deleteCertConfirmation}
            />
          </Grid>
          <Grid item xs={3}>
            <OPDateField
              name='startDate'
              label={`${t('CertAndEducation.CertificateGrantDate')}*`}
              value={values.startDate ?? null}
              onChange={(v) => setFieldValue('startDate', v)}
              error={Boolean(errors.startDate)}
              errMessage={t(errors.startDate || '')}
              disabled={deleteCertConfirmation}
            />
          </Grid>
          <Grid item xs={3}>
            <OPDateField
              name='expirationDate'
              label={t('CertAndEducation.CertificateExpirationDate')}
              value={values.expirationDate ?? null}
              minDate={values.startDate}
              onChange={(v) => setFieldValue('expirationDate', v)}
              error={Boolean(errors.expirationDate)}
              errMessage={t(errors.expirationDate || '')}
              disabled={deleteCertConfirmation}
            />
          </Grid>
          <Grid container item xs={12} className='gridcontainer--certedu__flexend'>
            <Grid item>
              <OPSkillsButtons
                noPadding
                isDelete={isEdit}
                onDelete={() => setDeleteCertConfirmation(true)}
                onClose={closeHandler}
                disabled={!(isValid && dirty) || isSubmitting}
                onSubmit={handleSubmit}
                onAutosave={isEdit}
                bothDisabled={deleteCertConfirmation || isSubmitting}
              />
              {isEdit && <AutoSave fetchFunction={getUserCertificates} />}
            </Grid>
            {isEdit && (
              <DeleteConfirmation
                whatToDelete='Certificate'
                open={deleteCertConfirmation}
                handleClose={() => setDeleteCertConfirmation(false)}
                deleteHandler={deleteHandler}
              />
            )}
          </Grid>
        </Grid>
      )}
    </Formik>
  )
}

export default observer(NewCert)
