import React from 'react'
import { RootStore } from './stores/storesContext'

const StoreContext = React.createContext(new RootStore())

function useStore() {
  const store = React.useContext(StoreContext)
  if (!store) {
    throw new Error('Cannot use `useStore` outside of a StoreProvider')
  }
  return store
}

function Provider(props: any) {
  const store = React.useMemo(() => new RootStore(), [])
  return <StoreContext.Provider value={store} {...props} />
}

export { Provider, useStore }
