import { Grid, ListItem, ListItemText } from '@mui/material'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import history from '../../../history'
import { IListDevelopmentGoal } from '../../../interfaces/discussions/IDiscussion'
import { useStore } from '../../../Provider'
import { OPLoadingCircle } from '../../OPcomponents/OPLoadingcircle'

const Goals = (): JSX.Element => {
  const { t } = useTranslation()
  const rootStore = useStore()
  const { validatedUser } = rootStore.loginStore
  const { getGoals, loadingUserGoals, developmentGoals } = rootStore.kekeStore

  useEffect(() => {
    getGoals(validatedUser)
  }, [getGoals, validatedUser])

  const filteredGoals: IListDevelopmentGoal[] = []

  developmentGoals.forEach((dev) => {
    if (!dev.completed) filteredGoals.push(dev)
  })

  return (
    <>
      {!loadingUserGoals ? (
        <>
          <Grid item onClick={() => history.push('/development')} className='profile--pointer'>
            <div data-testid='profiletitletext' className='profile--dev__titlefont'>
              {t('Profile.Goals')}
            </div>
          </Grid>
          <>
            {filteredGoals.length > 0 ? (
              filteredGoals.slice(0, 5).map((goal) => (
                <Grid container rowGap={2} paddingLeft={'0.5rem'} key={_.uniqueId('goal-')}>
                  <ListItem
                    disablePadding
                    className='profile--pointer'
                    onClick={() => history.push('/development', { goalId: goal.goalId })}
                  >
                    <ListItemText
                      secondaryTypographyProps={{ noWrap: true, className: 'profile--dev__text' }}
                      primaryTypographyProps={{ className: 'profile--dev__headertext' }}
                      primary={goal.name}
                      secondary={goal.description}
                    />
                  </ListItem>
                </Grid>
              ))
            ) : (
              <Grid item>
                <div className='profile--neededskills__text'>{t('Skills.NoSkillsWithDepTag')}</div>
              </Grid>
            )}
          </>
        </>
      ) : (
        <OPLoadingCircle />
      )}
    </>
  )
}

export default observer(Goals)
