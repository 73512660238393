import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import React, { useEffect, useState } from 'react'
import OPTooltip from '../OPcomponents/OPTooltip'
import '../styles/accordionStyles.scss'
import { DropdownArrow } from '../styles/Icons'

interface AccordionItemProps {
  title: string
  content: JSX.Element
  insideAccordion?: boolean
  finished?: boolean
  isOpenOnInit?: boolean
  titleNoPadding?: boolean
  publicationPaddings?: boolean
  created?: string
  lockedOpen?: boolean
  ariaLabel?: string
  dataTestId?: string
  disabled?: boolean
  onFirstOpen?: () => void
  setOutsideExpanded?: boolean
  setIsOpen?: (isExpanded: boolean) => void
  isLocked?: boolean
}

export const OPAccordionItem = (props: AccordionItemProps): JSX.Element => {
  const {
    title,
    content,
    lockedOpen,
    insideAccordion,
    finished,
    created,
    titleNoPadding,
    publicationPaddings,
    ariaLabel,
    isOpenOnInit,
    dataTestId,
    disabled,
    onFirstOpen,
    setOutsideExpanded,
    setIsOpen,
    isLocked = false,
  } = props

  const [expanded, setExpanded] = useState<boolean>(isOpenOnInit ? isOpenOnInit : false)
  const [hasOpened, setHasOpened] = useState<boolean>(false)

  useEffect(() => {
    if (setOutsideExpanded) setExpanded(setOutsideExpanded)
  }, [setOutsideExpanded])

  const handleChange = (e: React.SyntheticEvent, exp: boolean) => {
    if (!isLocked) {
      if (!lockedOpen) {
        if (!hasOpened && exp) {
          if (onFirstOpen) onFirstOpen()
          setHasOpened(true)
        }
        setExpanded(!expanded)
      } else {
        if (!expanded) setExpanded(true)
      }
    }
  }

  return (
    <Accordion
      disabled={disabled}
      className='accordionContainer'
      aria-label={ariaLabel}
      data-testid={dataTestId}
      expanded={expanded}
      onChange={handleChange}
      square
      disableGutters
      TransitionProps={{
        unmountOnExit: true,
        onEntered: () => {
          if (setIsOpen) setIsOpen(true)
        },
        onExited: () => {
          if (setIsOpen) setIsOpen(false)
        },
      }}
    >
      <AccordionSummary
        className={`accordionTitle ${insideAccordion ? 'miniTitle' : ''} ${finished ? 'greenTitle' : ''} ${
          location.pathname === '/neededcompetence' ? 'miniBlueTitle' : ''
        } ${titleNoPadding ? 'nopadding' : ''} ${publicationPaddings ? 'publicationPaddings' : ''}`}
        expandIcon={!isLocked ? <DropdownArrow fill='#03499E' width='0.75rem' height='0.4631rem' /> : undefined}
      >
        {insideAccordion ? (
          <>
            <OPTooltip title={title}>
              <div className={`typoTitle ${created ? '' : 'fullWidth'}`}>{title}</div>
            </OPTooltip>
            <OPTooltip title={created ?? ''}>
              <div className='typoCreated'>{created}</div>
            </OPTooltip>
          </>
        ) : (
          title
        )}
      </AccordionSummary>
      <AccordionDetails className='accordionDetails'>{content}</AccordionDetails>
    </Accordion>
  )
}
