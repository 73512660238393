import { Grid, Paper } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../Provider'
import { MuiButtonVariants, OPButton } from '../OPcomponents/OPButton'
import { Toast } from '../OPcomponents/Toast'
import './myskills.scss'

import AddNewSkill from './myskills/AddNewSkill'
import SkillTable from './myskills/SkillTable'
import './profile.scss'

const MySkills = (): JSX.Element => {
  const { t } = useTranslation()
  const rootStore = useStore()

  const { validatedUser } = rootStore.loginStore
  const { getAllUserSkills, userSkills } = rootStore.personStore
  const { showEditSkillModal, showNewSkillModalHandler, showNewSkillModal, allSkills, getAllSkills } = rootStore.skillStore

  useEffect(() => {
    if (userSkills.length === 0) getAllUserSkills(validatedUser)
    if (allSkills.length === 0) getAllSkills(validatedUser, true)
  }, [])

  useEffect(() => {
    return () => {
      getAllUserSkills(validatedUser)
      showNewSkillModalHandler(false)
    }
  }, [getAllUserSkills, showNewSkillModalHandler, validatedUser])

  return (
    <Grid container rowGap={4} data-testid='myskills'>
      <Toast />
      <Grid container item direction='row' justifyContent='space-between' alignItems='center'>
        <Grid item>
          <p className='profile--titlebigfont'>{t('Buttons.MySkills')}</p>
        </Grid>
        <Grid item>
          <OPButton
            buttonVariant={MuiButtonVariants.primary_outlined}
            label={t('Buttons.AddNew')}
            disabled={showNewSkillModal || showEditSkillModal}
            onClick={() => showNewSkillModalHandler(true)}
          />
        </Grid>
      </Grid>
      {showNewSkillModal && (
        <Grid item xs={12}>
          <Paper className='profile--paper__addformpadding'>
            <Grid container rowGap={2}>
              <Grid item xs={12}>
                <span className='profile--addnewfont nopadding'>{t('Skills.AddNewSkill')}</span>
              </Grid>
              <Grid item xs={12}>
                <AddNewSkill />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      )}
      <Grid item xs={12}>
        <SkillTable />
      </Grid>
    </Grid>
  )
}

export default observer(MySkills)
