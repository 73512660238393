import { Breadcrumbs, Link, LinkProps, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router'
import { Link as RouterLink } from 'react-router-dom'
import history from '../../history'
import { useStore } from '../../Provider'
import '../styles/navbar.scss'

const OPBreadcrumbs = () => {
  const { t } = useTranslation()

  const rootStore = useStore()

  const { personProfileOverview } = rootStore.profileStore
  const { userDiscussion } = rootStore.kekeStore

  const breadcrumbNames: { [key: string]: string } = {
    '/profile/overview/cv': 'CV',
    '/profile/overview': t('Buttons.Overview'),
    '/profile/myskill': t('Buttons.MySkills'),
    '/profile/certedu': t('Buttons.CertEduPublic'),
    '/profile/workhistory': t('Buttons.WorkHistory'),
    '/profile/interests': t('Buttons.Interests'),
    '/neededcompetence': t('AllDeparmentsCompetenceNeeds.Box1'),
    '/search': t('Search.Search'),
    '/skills': t('Navbar.Skills'),
    '/projects': t('Navbar.Projects'),
    '/development': t('Navbar.Development'),
    '/persons': t('Navbar.Persons'),
    '/partners': t('Navbar.Partners'),
    '/instructions': t('Navbar.Info'),
    '/manager': t('Discussions.DiscussionEmployer'),
    '/hrpage': t('Discussions.NavHR'),
    '/hr': t('Discussions.NavHR'),
    '/privacypolicy': t('HomePage.PrivacyPolicy'),
    '/404': '404',
    '/profile/': t('Navbar.Profile'),
    '/': t('Navbar.Frontpage'),
  }

  const pathnames = history.location.pathname.split('/').filter((x) => x)

  const fromSearch = history.location.state === 'search'
  const fromProfileProject = history.location.state === 'profileProject'
  const fromProject = history.location.state === 'project'
  const fromProfileSkill = history.location.state === 'profileSkill'
  const fromSkill = history.location.state === 'skill'
  const isProfile = useRouteMatch('/profile/:subpage')
  const isPersonProfile = useRouteMatch('/persons/:userId')
  const isHRDevelopment = useRouteMatch('/hr/development/:userId')
  const isManagerDevelopment = useRouteMatch('/manager/development/:userId')
  const isPersonCV = useRouteMatch('/persons/:userId/cv')

  const getTitleName = () => {
    if (isPersonProfile) return personProfileOverview.name
    if (isHRDevelopment || isManagerDevelopment)
      return `${t('Discussions.Development')} - ${userDiscussion?.employeeName !== undefined ? userDiscussion?.employeeName : ''}`
    return ''
  }

  if (fromProject || fromProfileProject)
    return (
      <Breadcrumbs className='lineheight'>
        <LinkRouter className='activecrumb' to='/'>
          {breadcrumbNames['/']}
        </LinkRouter>
        {fromProfileProject && <Typography className='basecrumb'>{breadcrumbNames['/profile/']}</Typography>}
        {fromProfileProject ? (
          <LinkRouter className='activecrumb' to='/profile/workhistory'>
            {breadcrumbNames['/profile/workhistory']}
          </LinkRouter>
        ) : (
          <LinkRouter className='activecrumb' to='/projects'>
            {breadcrumbNames['/projects']}
          </LinkRouter>
        )}
        <Typography className='basecrumb'>{getTitleName()}</Typography>
      </Breadcrumbs>
    )

  if (fromSkill || fromProfileSkill)
    return (
      <Breadcrumbs className='lineheight'>
        <LinkRouter className='activecrumb' to='/'>
          {breadcrumbNames['/']}
        </LinkRouter>
        {fromProfileSkill && <Typography className='basecrumb'>{breadcrumbNames['/profile/']}</Typography>}
        {fromProfileSkill ? (
          <LinkRouter className='activecrumb' to='/profile/myskill'>
            {breadcrumbNames['/profile/myskill']}
          </LinkRouter>
        ) : (
          <LinkRouter className='activecrumb' to='/skills'>
            {breadcrumbNames['/skills']}
          </LinkRouter>
        )}
        <Typography className='basecrumb'>{getTitleName()}</Typography>
      </Breadcrumbs>
    )

  if (fromSearch)
    return (
      <Breadcrumbs className='lineheight'>
        <LinkRouter className='activecrumb' to='/'>
          {breadcrumbNames['/']}
        </LinkRouter>
        <LinkRouter className='activecrumb' to='/search'>
          {breadcrumbNames['/search']}
        </LinkRouter>
        <Typography className='basecrumb'>{getTitleName()}</Typography>
      </Breadcrumbs>
    )

  if (isPersonCV) {
    return (
      <Breadcrumbs className='lineheight'>
        <LinkRouter className='activecrumb' to='/'>
          {breadcrumbNames['/']}
        </LinkRouter>
        <LinkRouter className='activecrumb' to='/persons'>
          {breadcrumbNames['/persons']}
        </LinkRouter>
        <LinkRouter className='activecrumb' to={`/persons/${personProfileOverview.id}`}>
          {getTitleName()}
        </LinkRouter>
        <Typography className='basecrumb'>{breadcrumbNames['/profile/overview/cv']}</Typography>
      </Breadcrumbs>
    )
  }

  return (
    <Breadcrumbs className='lineheight'>
      <LinkRouter className={pathnames.length > 0 ? 'activecrumb' : 'basecrumb'} to='/'>
        {breadcrumbNames['/']}
      </LinkRouter>
      {isProfile && <Typography className='basecrumb'>{breadcrumbNames['/profile/']}</Typography>}
      {pathnames.map((value, index) => {
        const last = index === pathnames.length - 1
        const to = `/${pathnames.slice(0, index + 1).join('/')}`
        if (breadcrumbNames[to] === undefined && !last) return null
        return last ? (
          <Typography className='basecrumb' key={to}>
            {isPersonProfile || isHRDevelopment || isManagerDevelopment ? getTitleName() : breadcrumbNames[to]}
          </Typography>
        ) : (
          <LinkRouter className='activecrumb' to={to} key={to}>
            {breadcrumbNames[to]}
          </LinkRouter>
        )
      })}
    </Breadcrumbs>
  )
}
export default observer(OPBreadcrumbs)

interface LinkRouterProps extends LinkProps {
  to: string
  replace?: boolean
}

const LinkRouter = (props: LinkRouterProps) => <Link {...props} component={RouterLink as any} />
