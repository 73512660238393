import { t } from 'i18next'
import React, { useState } from 'react'
import { MuiButtonVariants, OPButton } from '../../OPcomponents/OPButton'
import { HeartFilled, HeartNotFilled } from '../../styles/Icons'

interface InterestedButton {
  isActive: boolean
  likeHandler: () => void
}

export const InterestedButton = (props: InterestedButton): JSX.Element => {
  const { isActive, likeHandler } = props
  const [isHover, setIsHover] = useState(false)

  const darkerGreen = '#297E26'
  const lighterGreen = '#3AA935'

  const onHoverEvent = (val: boolean) => setIsHover(val)

  return (
    <OPButton
      onClick={likeHandler}
      label={t('Buttons.Interested')}
      hoverEvent={onHoverEvent}
      buttonVariant={MuiButtonVariants.interested}
      className={isActive ? 'active' : ''}
      endIcon={
        isHover || isActive ? (
          <HeartFilled fill={isActive && isHover ? lighterGreen : darkerGreen} />
        ) : (
          <HeartNotFilled fill={darkerGreen} />
        )
      }
    />
  )
}
