import { Collapse, Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { ISkillFormPackage } from '../../interfaces/skills/ISkills'
import { useStore } from '../../Provider'
import { ITableForm } from '../OPcomponents/OPNewTable'
import { Toast } from '../OPcomponents/Toast'
import ProjectSkillLink from '../Profile/myskills/ProjectSkillLink'
import SkillExperts from '../Profile/myskills/SkillExperts'
import SkillOverview from '../Profile/myskills/SkillOverview'
import UserSkill from '../Profile/myskills/UserSkill'
import EditSkill from './EditSkill'

const OpenSkill = (props: ITableForm) => {
  const { collapseHandler, scrollRef } = props

  const rootStore = useStore()
  const { getTags, getCategories, isNewPersonSkill, showEditInfo } = rootStore.skillStore
  const { getAllProjects } = rootStore.projectStore
  const { validatedUser } = rootStore.loginStore
  if (!isNewPersonSkill) getAllProjects(validatedUser)
  const [linkingProject, setLinkingProject] = useState<boolean>(false)
  const [skillData, setSkillData] = useState<ISkillFormPackage>(props.data as ISkillFormPackage)
  const [projectLinks, setProjectLinks] = useState(skillData?.userSkill?.linkedProjects || [])

  useEffect(() => {
    getTags()
    getCategories()
  }, [getTags, getCategories])

  return (
    <>
      <Grid container>
        <Toast />
        {!showEditInfo ? (
          <SkillOverview data={skillData} setSkillData={setSkillData} />
        ) : (
          <EditSkill data={skillData} setSkillData={setSkillData} />
        )}
        <hr className='profile--form__editdivider' />
        <SkillExperts data={skillData?.skillPersons} />
        <hr className='profile--form__editdivider' />
        <UserSkill
          collapseHandler={collapseHandler}
          setLinkingProject={setLinkingProject}
          linkingProject={linkingProject}
          data={skillData?.userSkill}
          setLinkedProjects={setProjectLinks}
          linkedProjects={projectLinks}
          isNew={skillData?.isNew}
        />
        <Collapse
          in={linkingProject && !isNewPersonSkill}
          timeout={500}
          onExited={() => {
            setLinkingProject(false)
            scrollRef?.()
          }}
          className='profile--form__formwidth'
          unmountOnExit
        >
          <ProjectSkillLink setLinkingProject={setLinkingProject} setLinkedProjects={setProjectLinks} />
        </Collapse>
      </Grid>
    </>
  )
}

export default observer(OpenSkill)
