import FormData from 'form-data'
import { isEmpty } from 'lodash'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'

import agent from '../../api/agent'
import {
  IFetchedPartnerExpert,
  IPartnerBasicInformation,
  IPartnerCertificates,
  IPartnerExpert,
  IPartnerExpertToUpload,
  IPartnerIndustry,
  IPartnerIndustryPerson,
  IPartnerInternalComment,
  IPartnerList,
  IPartnerSector,
} from '../../interfaces/partner/IPartner'
import { INewSkill, IPartnerSkill, ISkillExpert, ISkillToSubmit } from '../../interfaces/partner/IPartnerSkills'

export default class PartnerStore {
  loadingPartnerList = true
  partnerList: IPartnerList[] = []
  planmillPartnerInfo: any = {}

  searchValue = ''
  isSearching = false
  currentBasicInformation: IPartnerBasicInformation | undefined = undefined
  loadingBasicInformation = true

  currentPartnerExperts: IFetchedPartnerExpert[] = []
  currentExpert?: IPartnerExpert = undefined
  currentCv: File = new File([], '')
  currentSector?: IPartnerSector = undefined
  currentIndustries: IPartnerIndustry[] = []
  currentIndustry?: IPartnerIndustry = undefined
  currentIndustryPersons: IPartnerIndustryPerson[] = []
  loadingExperts = true
  loadingSector = true
  loadingIndustries = true
  loadingIndustryPersons = true

  showEditExpertModal = false
  showAddIndustryModal = false
  showAddSkillModal = false
  uploadingExpert = false

  partnerSkills: IPartnerSkill[] = []
  currentPartnerSkills: IPartnerSkill[] = []
  currentSkill?: IPartnerSkill = undefined
  currentSkillExperts?: ISkillExpert[] = undefined
  loadingPartnerSkills = true
  loadingSkillExperts = true
  addingNewSkill = true

  //search observables

  constructor() {
    makeObservable(this, {
      searchValue: observable,
      isSearching: observable,
      loadingPartnerList: observable,
      partnerList: observable,
      planmillPartnerInfo: observable,
      currentBasicInformation: observable,
      loadingBasicInformation: observable,
      currentPartnerExperts: observable,
      currentExpert: observable,
      currentCv: observable,
      currentSector: observable,
      currentIndustries: observable,
      currentIndustry: observable,
      currentIndustryPersons: observable,
      loadingExperts: observable,
      loadingSector: observable,
      loadingIndustries: observable,
      loadingIndustryPersons: observable,
      showEditExpertModal: observable,
      showAddIndustryModal: observable,
      showAddSkillModal: observable,
      uploadingExpert: observable,
      partnerSkills: observable,
      currentPartnerSkills: observable,
      currentSkill: observable,
      currentSkillExperts: observable,
      loadingPartnerSkills: observable,
      loadingSkillExperts: observable,
      addingNewSkill: observable,
      filteredPartnerList: computed,
      dropdownSkills: computed,
      dropdownExperts: computed,
      currentCertificates: observable,
      loadingCurrentCertificates: observable,
      loadingPartnerDescription: observable,
      editPartnerDesc: observable,
      editedPartnerDesc: observable,
      partnerDesc: observable,
      currentInternalRemarks: observable,
      loadingCurrentInternalRemarks: observable,
      setIsSearching: action,
      setSearchValue: action,
      getPartnerList: action,
      getPartnerBasicInformation: action,
      getCurrentExperts: action,
      setCurrentExpert: action,
      postExpert: action,
      putExpert: action,
      deleteExpert: action,
      getExpertCv: action,
      deleteExpertCv: action,
      getCurrentSector: action,
      postSector: action,
      getCurrentIndustries: action,
      setCurrentIndustry: action,
      getPartnerIndustryPersons: action,
      postPartnerIndustry: action,
      putPartnerIndustry: action,
      deletePartnerIndustry: action,
      setShowEditExpertModal: action,
      setShowAddIndustryModal: action,
      setShowAddSkillModal: action,
      setAddingNewSkill: action,
      setCurrentSkill: action,
      getPartnerSkills: action,
      getCurrentSkillExperts: action,
      postNewSkill: action,
      postNewSkillExperts: action,
      deleteSkillExpert: action,
      deletePartnerSkill: action,
      putSkillExperts: action,
      getCurrentCertificates: action,
      isPartnerDescEditing: action,
      editPartnerDescHandler: action,
      postNewPartnerDesc: action,
      getPartnerDesc: action,
      putCertificates: action,
      getCurrentInternalRemarks: action,
      setCurrentInternalRemarks: action,
    })
  }

  setIsSearching = (isItSearching: boolean) => {
    this.isSearching = isItSearching
  }

  setSearchValue = (value: string) => {
    this.searchValue = value
  }
  get dropdownSkills() {
    return this.partnerSkills
      .filter((x) => x.partnerHas === false)
      .map((skill) => ({
        key: skill.skillId,
        value: skill.skillId,
        text: skill.name,
      }))
      .sort((a, b) => a.text.localeCompare(b.text))
  }

  get dropdownExperts() {
    return this.currentIndustryPersons
      .map((person) => ({
        key: person.personId,
        value: person.personId,
        text: person.firstName + ' ' + person.lastName,
      }))
      .filter((x) => this.currentSkillExperts?.find((y) => y.personId === x.key) === undefined)
      .sort((a, b) => a.text.localeCompare(b.text))
  }

  currentCertificates = ''
  loadingCurrentCertificates = true

  /**
   * Description observables
   */
  loadingPartnerDescription = false
  editPartnerDesc = false
  editedPartnerDesc = ''
  partnerDesc = ''
  currentInternalRemarks?: IPartnerInternalComment = undefined
  loadingCurrentInternalRemarks = true

  get filteredPartnerList() {
    const allPartners = this.partnerList
    const params = this.searchValue
    if (isEmpty(params)) {
      return allPartners
    }
    return allPartners.filter((partner: IPartnerList) => partner.partnerName?.toLowerCase().includes(params.toLowerCase()))
  }

  getPartnerList = async () => {
    this.loadingPartnerList = true
    //if (userPermission) {}
    try {
      const fetchedPartnerList: IPartnerList[] = await agent.Partners.getPartnerList()
      runInAction(() => {
        this.partnerList = fetchedPartnerList
        this.loadingPartnerList = false
      })
    } catch (error) {
      runInAction(() => (this.loadingPartnerList = false))
    }
  }

  getPartnerBasicInformation = async (partnerId: number) => {
    this.loadingBasicInformation = true
    try {
      const fetchedInfo: IPartnerBasicInformation = await agent.Partners.getBasicInformation(partnerId)
      runInAction(() => {
        this.currentBasicInformation = fetchedInfo
        this.loadingBasicInformation = false
      })
    } catch (error) {
      runInAction(() => {
        this.loadingBasicInformation = false
      })
    }
  }

  getCurrentExperts = async (partnerId: number) => {
    this.loadingExperts = true
    try {
      const fetchedInfo: IFetchedPartnerExpert[] = await agent.Partners.getExperts(partnerId)

      runInAction(() => {
        this.currentPartnerExperts = fetchedInfo
        this.loadingExperts = false
      })
    } catch (error) {
      runInAction(() => {
        this.loadingExperts = false
      })
    }
  }

  setCurrentExpert = (value: IPartnerExpert | undefined) => {
    this.currentExpert = value
  }

  postExpert = async (expert: IPartnerExpertToUpload) => {
    this.uploadingExpert = true
    this.loadingExperts = true
    try {
      const formData = new FormData()

      formData.append('PlanmillPartnerId', expert.planmillPartnerId.toString())
      formData.append('FirstName', expert.firstName)
      formData.append('LastName', expert.lastName)
      formData.append('Title', expert.title)
      formData.append('CvType', expert.cvType)
      formData.append('Cv', expert.cv)

      await agent.Partners.postExpert(formData)
      runInAction(() => {
        this.uploadingExpert = false
        this.getCurrentExperts(expert.planmillPartnerId)
      })
    } catch (error) {
      Promise.reject(error)
      runInAction(() => {
        this.uploadingExpert = false
        this.getCurrentExperts(expert.planmillPartnerId)
      })
    }
    Promise.resolve()
  }

  putExpert = async (expert: IPartnerExpertToUpload) => {
    this.uploadingExpert = true
    this.loadingExperts = true
    try {
      const formData = new FormData()

      formData.append('PersonId', expert.personId.toString())
      formData.append('FirstName', expert.firstName)
      formData.append('LastName', expert.lastName)
      formData.append('Title', expert.title)
      formData.append('CvType', expert.cvType)
      formData.append('Cv', expert.cv)
      await agent.Partners.putExpert(formData)
      runInAction(() => {
        this.uploadingExpert = false
        this.getCurrentExperts(expert.planmillPartnerId)
      })
    } catch (error) {
      Promise.reject(error)
      runInAction(() => {
        this.uploadingExpert = false
        this.getCurrentExperts(expert.planmillPartnerId)
      })
    }
    Promise.resolve()
  }

  deleteExpert = async (partnerId: number, expertId: number) => {
    try {
      this.loadingExperts = true
      await agent.Partners.deleteExpert(partnerId, expertId)
      runInAction(() => this.getCurrentExperts(partnerId))
    } catch (error) {
      Promise.reject(error)
    }
    Promise.resolve()
  }

  getExpertCv = async (expertId: number, fileName: string) => {
    try {
      await agent.Partners.getExpertCv(expertId, fileName)
      //const file = await this.base64ToFile(fetchedCv.cv, fetchedCv.name, fetchedCv.type)
      //runInAction(() => this.currentCv = file);
    } catch (error) {
      Promise.reject(error)
    }
    Promise.resolve()
  }

  deleteExpertCv = async (partnerId: number, expertId: number) => {
    try {
      this.loadingExperts = true
      await agent.Partners.deleteExpertCv(expertId)
      runInAction(() => this.getCurrentExperts(partnerId))
    } catch (error) {
      Promise.reject(error)
    }
    Promise.resolve()
  }

  getCurrentSector = async (partnerId: number) => {
    this.loadingSector = true
    try {
      const fetchedSector: IPartnerSector = await agent.Partners.getSector(partnerId)
      runInAction(() => {
        this.currentSector = fetchedSector
        this.loadingSector = false
      })
    } catch (error) {
      runInAction(() => {
        this.loadingSector = false
      })
    }
  }

  postSector = async (sector: IPartnerSector) => {
    try {
      await agent.Partners.postSector(sector)
      runInAction(async () => {
        await this.getCurrentSector(sector.partnerId)
      })
    } catch (error) {
      Promise.reject(error)
    }
    Promise.resolve()
  }

  getCurrentIndustries = async (partnerId: number) => {
    this.loadingIndustries = true
    try {
      const fetchedIndustries: IPartnerIndustry[] = await agent.Partners.getIndustry(partnerId)
      runInAction(() => {
        this.currentIndustries = fetchedIndustries
        this.loadingIndustries = false
      })
    } catch (error) {
      runInAction(() => {
        this.loadingIndustries = false
      })
    }
  }

  setCurrentIndustry = (value: IPartnerIndustry | undefined) => {
    this.currentIndustry = value
  }

  getPartnerIndustryPersons = async (partnerId: number) => {
    this.loadingIndustryPersons = true
    try {
      const fetchedIndustryPersons: IPartnerIndustryPerson[] = await agent.Partners.getIndustryPersons(partnerId)
      runInAction(() => {
        this.currentIndustryPersons = fetchedIndustryPersons
        this.loadingIndustryPersons = false
      })
    } catch (error) {
      runInAction(() => (this.loadingIndustryPersons = false))
    }
  }

  postPartnerIndustry = async (partnerIndustry: IPartnerIndustry) => {
    this.loadingIndustries = true
    try {
      await agent.Partners.postPartnerIndustry(partnerIndustry)
      runInAction(async () => {
        await this.getCurrentIndustries(partnerIndustry.planMillPartnerId)
      })
    } catch (error) {
      Promise.reject(error)
    }
    Promise.resolve()
  }

  putPartnerIndustry = async (partnerIndustry: IPartnerIndustry) => {
    this.loadingIndustries = true
    try {
      await agent.Partners.putPartnerIndustry(partnerIndustry)
      runInAction(async () => this.getCurrentIndustries(partnerIndustry.planMillPartnerId))
    } catch (error) {
      Promise.reject(error)
    }
    Promise.resolve()
  }

  deletePartnerIndustry = async (partnerId: number, industryId: number) => {
    try {
      this.loadingIndustries = true
      await agent.Partners.deletePartnerIndustry(partnerId, industryId)
      runInAction(async () => this.getCurrentIndustries(partnerId))
    } catch (error) {
      Promise.reject(error)
    }
    Promise.resolve()
  }

  setShowEditExpertModal = (value: boolean) => {
    this.showEditExpertModal = value
  }

  setShowAddIndustryModal = (value: boolean) => {
    this.showAddIndustryModal = value
  }

  setShowAddSkillModal = (value: boolean) => {
    this.showAddSkillModal = value
  }

  setAddingNewSkill = (value: boolean) => {
    this.addingNewSkill = value
  }

  setCurrentSkill = (value: IPartnerSkill | undefined) => {
    this.currentSkill = value
  }

  getPartnerSkills = async (partnerId: number) => {
    this.loadingPartnerSkills = true
    try {
      const fetchedSkills: IPartnerSkill[] = await agent.Partners.getSkills(partnerId)
      runInAction(() => {
        this.partnerSkills = fetchedSkills
        this.currentPartnerSkills = fetchedSkills.filter((skill) => skill.partnerHas)
        this.loadingPartnerSkills = false
      })
    } catch (error) {
      runInAction(() => {
        this.loadingPartnerSkills = false
      })
    }
  }

  getCurrentSkillExperts = async (skillId: number) => {
    this.loadingSkillExperts = true
    try {
      const fetchedExperts: ISkillExpert[] = await agent.Partners.getSkillExperts(skillId)
      runInAction(() => {
        this.currentSkillExperts = fetchedExperts
        this.loadingSkillExperts = false
      })
    } catch (error) {
      runInAction(() => {
        this.loadingSkillExperts = false
      })
    }
  }

  postNewSkill = async (partnerId: number, newSkill: INewSkill): Promise<number> => {
    this.loadingPartnerSkills = true
    try {
      var skillId = await agent.Skills.createNewSkill(newSkill)
      runInAction(() => this.getPartnerSkills(partnerId))
    } catch (error) {
      runInAction(() => this.getPartnerSkills(partnerId))
      Promise.reject(error)
      return -1
    }
    Promise.resolve()
    return skillId
  }

  postNewSkillExperts = async (data: ISkillToSubmit) => {
    this.loadingPartnerSkills = true
    try {
      await agent.Partners.postNewSkillExperts(data)
      runInAction(() => this.getPartnerSkills(data.partnerSkill.planmillPartnerId))
    } catch (error) {
      runInAction(() => this.getPartnerSkills(data.partnerSkill.planmillPartnerId))
      Promise.reject(error)
    }
    Promise.resolve()
  }

  deleteSkillExpert = async (skillId: number, personId: number) => {
    this.loadingSkillExperts = true
    try {
      await agent.Partners.deleteSkillExpert(skillId, personId)
      runInAction(() => this.getCurrentSkillExperts(skillId))
    } catch (error) {
      runInAction(() => this.getCurrentSkillExperts(skillId))
      Promise.reject(error)
    }
    Promise.resolve()
  }

  deletePartnerSkill = async (skillId: number, partnerId: number) => {
    this.loadingPartnerSkills = true
    try {
      await agent.Partners.deletePartnerSkill(partnerId, skillId)
      runInAction(() => this.getPartnerSkills(partnerId))
    } catch (error) {
      runInAction(() => this.getPartnerSkills(partnerId))
      Promise.reject(error)
    }
    Promise.resolve()
  }

  putSkillExperts = async (data: ISkillToSubmit) => {
    this.loadingPartnerSkills = true
    try {
      await agent.Partners.putSkillExperts(data)
      runInAction(() => this.getPartnerSkills(data.partnerSkill.planmillPartnerId))
    } catch (error) {
      runInAction(() => this.getPartnerSkills(data.partnerSkill.planmillPartnerId))
      Promise.reject(error)
    }
    Promise.resolve()
  }

  getCurrentCertificates = async (partnerId: number) => {
    this.loadingCurrentCertificates = true
    try {
      const fetchedCertificates: string = await agent.Partners.getCertificates(partnerId)
      runInAction(() => {
        this.currentCertificates = fetchedCertificates
        this.loadingCurrentCertificates = false
      })
    } catch (error) {
      runInAction(() => {
        this.loadingCurrentCertificates = false
      })
    }
  }

  isPartnerDescEditing = (value: boolean) => {
    this.editPartnerDesc = value
  }

  editPartnerDescHandler = (desc: any) => {
    this.editedPartnerDesc = desc
  }

  postNewPartnerDesc = async (partnerId: number, desc: string) => {
    this.loadingPartnerDescription = true
    this.partnerDesc = desc

    try {
      await agent.Partners.editPartnerDescription({
        partnerId: partnerId,
        description: desc,
      })
    } catch (error) {
      return Promise.reject(error)
    } finally {
      runInAction(() => {
        this.loadingPartnerDescription = false
      })
    }
    return Promise.resolve()
  }

  getPartnerDesc = async (partnerId: number) => {
    this.loadingPartnerDescription = true
    try {
      const fetchedDesc: {
        description: string
      } = await agent.Partners.getPartnerDescription(partnerId)
      runInAction(() => {
        this.partnerDesc = fetchedDesc.description
        console.log(fetchedDesc)
        this.editedPartnerDesc = fetchedDesc.description
      })
    } catch (error) {
      runInAction(() => {
        return Promise.reject(error)
      })
    } finally {
      runInAction(() => {
        this.loadingPartnerDescription = false
      })
    }
    return Promise.resolve()
  }

  putCertificates = async (certificates: IPartnerCertificates) => {
    this.loadingCurrentCertificates = true
    try {
      await agent.Partners.putCertificates(certificates)
      runInAction(async () => this.getCurrentCertificates(certificates.partnerId))
    } catch (error) {
      Promise.reject(error)
    }
    Promise.resolve()
  }

  getCurrentInternalRemarks = async (partnerId: number) => {
    this.loadingCurrentInternalRemarks = true
    try {
      const fetchedInternalRemarks: IPartnerInternalComment = await agent.Partners.getInternalRemarks(partnerId)
      runInAction(() => {
        this.currentInternalRemarks = fetchedInternalRemarks
        this.loadingCurrentInternalRemarks = false
      })
    } catch (error) {
      runInAction(() => {
        this.loadingCurrentInternalRemarks = false
      })
    }
  }

  setCurrentInternalRemarks = async (partnerInternalComment: IPartnerInternalComment) => {
    this.loadingCurrentInternalRemarks = true
    try {
      await agent.Partners.editInternalRemarks(partnerInternalComment)
      runInAction(async () => {
        await this.getCurrentInternalRemarks(partnerInternalComment.partnerId)
      })
    } catch (error) {
      Promise.reject(error)
    }
    Promise.resolve()
  }
}
