import { Grid, Paper } from '@mui/material'
import { Formik } from 'formik'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { getErrMsg, toastNeg, toastPos } from '../../common/toastHelper'
import { useStore } from '../../Provider'
import { ExitConfirmation } from '../OPcomponents/ExitConfirmation'
import { MuiButtonVariants, OPButton } from '../OPcomponents/OPButton'
import { OPMultiline } from '../OPcomponents/OPMultiline'
import { Toast } from '../OPcomponents/Toast'
import { overviewSchema } from '../OPcomponents/ValidationSchemas'
import '../styles/grid.scss'

import history from '../../history'
import { OPSkillsButtons } from './OPSkillsButtons'
import AddNewLanguage from './overview/AddNewLanguage'
import LanguageTable from './overview/LanguageTable'

interface EditedOverview {
  title: string
  summary: string
}

const Overview = (): JSX.Element => {
  const { t } = useTranslation()

  const rootStore = useStore()
  const { sidebarUser, fetchSidebarData } = rootStore.sidebarStore
  const { validatedUser, user } = rootStore.loginStore
  const {
    userObject,
    getUserOverview,
    showUserModal,
    setShowUserModal,
    postNewTitle,
    postNewSummary,
    showLanguageModal,
    setShowLanguageModal,
    getCurrentLanguageToEdit,
    openLanguageEditModal,
    filteredLanguages,
  } = rootStore.personStore

  useEffect(() => {
    if (isEmpty(userObject)) getUserOverview(validatedUser)
  }, [])

  useEffect(() => {
    return () => {
      setShowUserModal(false)
      setShowLanguageModal(false)
      getCurrentLanguageToEdit('')
    }
  }, [getCurrentLanguageToEdit, setShowLanguageModal, setShowUserModal])

  const onSubmit = async (values: EditedOverview) => {
    try {
      await postNewTitle(validatedUser, values.title)
      await postNewSummary(validatedUser, values.summary)
      toastPos(t('Toasts.EditSuccess'))
    } catch (error) {
      toastNeg(getErrMsg(error, t) || t('Toasts.EditError'))
    } finally {
      await getUserOverview(validatedUser)
      await fetchSidebarData(validatedUser, user.name)
      setShowUserModal(false)
    }
  }

  return (
    <Grid>
      <Toast />
      <Grid container rowSpacing={4}>
        <Grid item xs={12}>
          <Grid container justifyContent={'space-between'}>
            <Grid item>
              <span className='profile--titlebigfont'>{t('Buttons.Overview')}</span>
            </Grid>
            <Grid item>
              <OPButton
                buttonVariant={MuiButtonVariants.primary_outlined}
                onClick={() => {
                  history.push('/profile/overview/cv/')
                }}
                label={t('Buttons.DownloadCV')}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {!showUserModal ? (
            <Grid container spacing={4}>
              <Grid item>
                <span className='profile--titlecaption nopadding'>{`${t('Profile.ProfileTitle')}:`}</span>
                <span className='profile--deptext nopadding'>
                  {` ${userObject?.title !== '' ? userObject.title : t('Profile.NoAddedTitle')}`}
                </span>
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <span className='profile--titlecaption nopadding'>{t('Profile.ProfileSummary')}:</span>
                </Grid>
                <Grid item xs={12}>
                  <span className='profile--deptext nopadding'>
                    {userObject?.summary !== '' ? userObject.summary : t('Profile.NoAddedSummary')}
                  </span>
                </Grid>
              </Grid>
              <Grid item xs={12} textAlign='right'>
                <OPButton
                  label={t('Buttons.Edit')}
                  buttonVariant={MuiButtonVariants.primary_outlined}
                  onClick={() => setShowUserModal(true)}
                />
              </Grid>
            </Grid>
          ) : (
            <Formik
              validationSchema={overviewSchema}
              initialValues={{ title: sidebarUser.department, summary: userObject.summary } as EditedOverview}
              onSubmit={onSubmit}
            >
              {({ handleChange, handleSubmit, values, isValid, dirty, isSubmitting }) => (
                <Paper className='profile--paper__addformpadding'>
                  <ExitConfirmation when={dirty} />
                  <Grid container spacing={4}>
                    <Grid item xs={7}>
                      <OPMultiline
                        name='title'
                        value={values.title}
                        label={t('Profile.ProfileTitle')}
                        maxChars={100}
                        hasCounter
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <OPMultiline
                        name='summary'
                        value={values.summary}
                        maxChars={4000}
                        hasCounter
                        minRows={5}
                        label={t('Profile.ProfileSummary')}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <OPSkillsButtons
                        noPadding
                        onSubmit={handleSubmit}
                        disabled={!(isValid && dirty) || isSubmitting}
                        bothDisabled={isSubmitting}
                        onClose={() => setShowUserModal(false)}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              )}
            </Formik>
          )}
        </Grid>
        <hr className='profile--form__editdivider' />
        <Grid container justifyContent='space-between'>
          <Grid item>
            <span className='profile--subtitlefont'>{t('Language.LanguageSkills')}</span>
          </Grid>
          <Grid item className='griditem--addbutton'>
            <OPButton
              label={t('Buttons.AddNew')}
              buttonVariant={MuiButtonVariants.primary_outlined}
              onClick={() => {
                setShowLanguageModal()
                getCurrentLanguageToEdit('')
              }}
              disabled={showLanguageModal || showUserModal || openLanguageEditModal}
            />
          </Grid>
        </Grid>
        {showLanguageModal && (
          <Grid item xs={12}>
            <Paper className='profile--paper__addformpadding'>
              <Grid item xs={12} className='griditem--formitem__titlemargin'>
                <span className='profile--addnewfont nopadding'>{t('Language.AddNewLanguageSkill')}</span>
              </Grid>
              <AddNewLanguage />
            </Paper>
          </Grid>
        )}
        {filteredLanguages.length > 0 ? (
          <Grid item xs={12}>
            <LanguageTable />
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Paper>
              <Grid item xs={12} className='griditem--formitem__titlemargin'>
                <span className='profile--notfound'>{t('Profile.NoAddedLanguages')}</span>
              </Grid>
            </Paper>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default observer(Overview)
