import moment from 'moment'

export const utcHelper = (date: Date | undefined | string | null) => {
  if (date) {
    return moment(date).add(moment().utcOffset(), 'minutes').toDate()
  }
  return undefined
}

export const periodsToCommas = (months: string): string => months.toString().replace('.', ',')

//export const convertMonthsToWorkingDays = (months: number): number => Math.round(months * 20)
