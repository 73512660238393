import { Divider, Grid, useMediaQuery } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useTranslation } from 'react-i18next'
import history from '../../../history'
import { IPersonSkillResponse } from '../../../interfaces/certAndEducation/certAndEducation'
import { useStore } from '../../../Provider'
import { theme } from '../../../theme/CustomTheme'
import { OPAccordionItem } from '../../Accordion/OPAccordionItem'
import { OPSkillbar } from '../../OPcomponents/OPSkillbar'
import '../../styles/grid.scss'
import '../myskills.scss'

enum locations {
  'profile' = 'profileSkill',
  'skill' = 'skill',
  'search' = 'search',
}

const SkillExperts = (props: { data: IPersonSkillResponse | null }): JSX.Element => {
  const { data } = props
  const { t } = useTranslation()
  const rootStore = useStore()
  const { resetPersonProfileOverview } = rootStore.profileStore

  const placeholderName = `${t('Persons.FirstName')}  ${t('Persons.LastName')}`

  const shouldWrap = useMediaQuery(theme.breakpoints.down('lg'))

  const fromWhere = () => Object.entries(locations).find((entry) => history.location.pathname.includes(entry[0]))?.[1]

  const profileLink = (id: number) => {
    resetPersonProfileOverview()
    history.push(`/persons/${id}`, fromWhere())
  }

  return (
    <Grid container className='gridcontainer--formcontainer__itempaddings'>
      <Grid item xs={12} className='griditem--skillexperts__titlecontainer '>
        <span className='myskill--font__titlefont sini'>{t('Skills.Experts')}</span>
      </Grid>
      <Grid item xs={12}>
        <OPAccordionItem
          title={`${data?.skilled.length || 0} ${t('Skills.SkillExperts')}, ${data?.interested.length || 0}
            ${t('Persons.AreInterested')}`}
          titleNoPadding={true}
          content={
            <Grid container wrap={shouldWrap ? 'wrap' : 'nowrap'}>
              <Grid container className='gridcontainer--skillexperts__margintable' rowGap={3}>
                <Grid item xs={12} className='griditem--skillexperts__title' container>
                  <Grid item xs={5}>
                    <span className='myskill--font__titlefont osaajat'>{t('Skills.Experts')}</span>
                  </Grid>
                  <Grid item xs={5}>
                    <span className='myskill--font__titlefont osaajat'>{t('Skills.Level')}</span>
                  </Grid>
                  <Grid item xs={2}>
                    <span className='myskill--font__titlefont osaajat'>{t('Skills.Expirience')}</span>
                  </Grid>
                </Grid>
                {data?.skilled.length === 0 ? (
                  <Grid container justifyContent='center'>
                    <Grid item>
                      <span className='myskill--font__personText'>{t('Skills.NoAddedSkilled')}</span>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    {data?.skilled.map((skill) => (
                      <Grid
                        container
                        key={_.uniqueId('skillperson-')}
                        className='gridcontainer--skillexperts__personcontainer'
                        alignItems='center'
                        wrap='nowrap'
                        onClick={() => profileLink(skill.personSkill.personId)}
                      >
                        <Grid item xs={5} marginLeft={'1rem'}>
                          <Grid container alignItems='center' columnSpacing={2} wrap='nowrap'>
                            <Grid item>
                              <Avatar src={`data:image/jpeg;base64,${skill.photo}`} />
                            </Grid>
                            <Grid item>
                              <span className='myskill--font__personText'>{skill?.personSkill?.personName ?? placeholderName}</span>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={5}>
                          <OPSkillbar
                            name={skill?.personSkill?.personName ?? placeholderName}
                            value={skill?.personSkill?.skillLevel}
                            readonly
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <span className='myskill--font__personText'>{`${skill?.personSkill?.experienceDays} ${t(
                            'CVTemplates.Mandays',
                          )}`}</span>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                )}
              </Grid>
              <Divider orientation='vertical' flexItem variant='middle' />
              <Grid container className='gridcontainer--skillexperts__margintable' overflow='hidden' rowGap={3}>
                <Grid item xs={12} className='griditem--skillexperts__title'>
                  <span className='myskill--font__titlefont osaajat'>{t('Skills.Interested')}</span>
                </Grid>
                {data?.interested.length === 0 ? (
                  <Grid container justifyContent='center'>
                    <Grid item>
                      <span className='myskill--font__personText'>{t('Skills.NoAddedInterested')}</span>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    {data?.interested.map((skill) => (
                      <Grid
                        container
                        alignItems='center'
                        key={skill.personSkill.personName}
                        className='gridcontainer--skillexperts__personcontainer'
                        onClick={() => profileLink(skill.personSkill.personId)}
                      >
                        <Grid item marginLeft={'1rem'}>
                          <Grid container alignItems='center' columnSpacing={2}>
                            <Grid item>
                              <Avatar src={`data:image/jpeg;base64,${skill.photo}`} />
                            </Grid>
                            <Grid item>
                              <span className='myskill--font__personText'>{skill?.personSkill?.personName ?? placeholderName}</span>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                )}
              </Grid>
            </Grid>
          }
        />
      </Grid>
    </Grid>
  )
}

export default observer(SkillExperts)
