import { Collapse, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { useStore } from '../../Provider'
import { SideBarDropDown, SideBarDropDownUp } from '../styles/Icons'
import { SideBarItem } from './SideBarItem'

export const CollapseableSideBarItem = (props: SideBarItem) => {
  const { icon, text, menuID, dataTestId, ariaLabel, disabled, nestedItems, link } = props

  const rootStore = useStore()

  const history = useHistory()

  const [isClosed, setIsClosed] = useState(true)
  const [closedStyle, setClosedStyle] = useState(true)

  const mouseDown = (e: React.MouseEvent<HTMLElement>) => {
    setIsClosed(!isClosed)
    e.preventDefault()
  }

  useEffect(() => {
    const paths = history.location.pathname.split('/')
    const linkText = link.substring(1)
    if (paths.includes(linkText)) {
      setIsClosed(false)
    }
  }, [history.location.pathname, link])

  return (
    <ListItem key={text} className='listItemStyling' disablePadding aria-label={ariaLabel} data-testid={dataTestId}>
      <ListItemButton
        component={NavLink}
        onClick={mouseDown}
        to={link}
        activeClassName={'buttonStyling dropdownButton'}
        activeStyle={{ ...(closedStyle && { borderRadius: '0.5rem' }) }}
        className={'buttonStyling' + (!closedStyle ? ' dropdownButton' : '')}
        disabled={disabled}
        disableRipple
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={text} />
        <IconButton onClick={mouseDown} className='listJustify'>
          {isClosed ? <SideBarDropDownUp /> : <SideBarDropDown />}
        </IconButton>
      </ListItemButton>
      <Collapse in={!isClosed} timeout='auto' unmountOnExit onEnter={() => setClosedStyle(false)} onExited={() => setClosedStyle(true)}>
        <List disablePadding className='sidebarDropper'>
          {nestedItems?.map((sideBarItem) => (
            <SideBarItem
              styleName='nestedButtonStyling'
              activeStyle=' active'
              key={sideBarItem.text}
              link={sideBarItem.link}
              text={sideBarItem.text}
              dataTestId={sideBarItem.dataTestId}
              ariaLabel={sideBarItem.ariaLabel}
              disabled={sideBarItem.disabled}
            />
          ))}
        </List>
      </Collapse>
    </ListItem>
  )
}
