import { CircularProgress } from '@mui/material'
import { Change, diffWordsWithSpace } from 'diff'
import { Form, Formik } from 'formik'
import { flatMap, head, sortBy } from 'lodash'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import agent from '../../api/agent'
import { utcHelper } from '../../common/dateHelper'
import { getErrMsg, toastNeg, toastPos } from '../../common/toastHelper'
import {
  IDiscussion,
  IDiscussionAnswer,
  IDiscussionVersion,
  IFormQuestion,
  IFormQuestionCategory,
  IListDevelopmentGoal,
  IListDiscussion,
  IListFeedbackGoal,
  INewAnswer,
  INewDiscussionVersion,
} from '../../interfaces/discussions/IDiscussion'
import { useStore } from '../../Provider'
import { OPAccordionContent } from '../Accordion/OPAccordionContent'
import { OPAccordionItem } from '../Accordion/OPAccordionItem'
import { ExitConfirmation } from '../OPcomponents/ExitConfirmation'
import { MuiButtonVariants, OPButton } from '../OPcomponents/OPButton'
import { OPDivider } from '../OPcomponents/OPDivider'
import { DropdownItem, OPDropdown } from '../OPcomponents/OPDropdown'
import { OPMultiline } from '../OPcomponents/OPMultiline'
import { OPSnackbar } from '../OPcomponents/OPSnackbar'
import { OPSwitch } from '../OPcomponents/OPSwitch'
import '../styles/developmentdiscussion.scss'
import { DeleteIcon } from '../styles/Icons'
import { DiscussionGoals } from './DiscussionGoals'

interface DiscussionProps {
  discussion: IListDiscussion
  employerId: number | undefined
  employerName: string
  employeeId?: number | undefined
  onApprove?: (id: number) => void
}

interface FormValues {
  [key: string]: string
}

export const DevelopmentDiscussion = observer((props: DiscussionProps): JSX.Element => {
  const { discussion, employerName, employerId, employeeId, onApprove } = props
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const rootStore = useStore()
  const { validatedUser } = rootStore.loginStore
  const {
    postDiscussionVersion,
    setIsConfirmSendDiscussionVisible,
    isConfirmSendDiscussionVisible,
    approveDiscussionAsEmployee,
    approveDiscussionAsEmployer,
    isConfirmReadySectionVisible,
    isNewDevGoalVisible,
    isNewFBGoalVisible,
    isEditFeedbackGoal,
    isEditDiscussionGoal,
    isAddCommentVisible,
    isNewDevDiscussionVisible,
    isConfirmDeleteGoalVisible,
    isNewMiniDiscussionVisible,
    isDeleteColleagueFeedbackVisible,
    isEditColleagueFeedback,
    isNewColleagueFeedbackVisible,
    isConfirmDeleteDiscussionVisible,
    setIsConfirmDeleteDiscussionVisible,
    selectDiscussion,
    selectedDiscussion,
    deleteDevelopmentDiscussion,
  } = rootStore.kekeStore

  const isInputQuestionType = (type: string) => {
    const questionType = type.toLocaleLowerCase()
    switch (questionType) {
      case 'textarea':
      case 'input':
        return true
      default:
        return false
    }
  }

  const getInitialValues = () => {
    return flatMap(templateCategories, (cat) => cat.questions)
      .filter((q) => isInputQuestionType(q.type))
      .reduce((obj: FormValues, v: IFormQuestion) => {
        obj[`q${v.formQuestionId}`] = v.description ?? ''
        return obj
      }, {})
  }

  const [templateCategories, setTemplateCategories] = useState<IFormQuestionCategory[]>([])
  const [loadingTemplateCategories, setLoadingTemplateCategories] = useState<boolean>(false)

  const findQuestionAnswer = (version: number, questionId: number, defaultValue?: string) => {
    const versionIndex = discussionData?.versions.find((v) => v.version === version)
    return versionIndex?.answers.find((a) => a.formQuestionId === questionId)?.answer ?? defaultValue ?? ''
  }

  const [selectedVersion, setSelectedVersion] = useState(0)

  const [activeSection, setActiveSection] = useState(templateCategories.length > 0 ? templateCategories[0].category : '')
  const [devGoals, setDevGoals] = useState<IListDevelopmentGoal[]>([])
  const [fbGoals, setFbGoals] = useState<IListFeedbackGoal[]>([])
  const [draft, setDraft] = useState<boolean>(false)
  const [discussionData, setDiscussionData] = useState<IDiscussion>()
  const [loadingData, setLoadingData] = useState<boolean>(false)
  const [versionList, setVersionList] = useState<DropdownItem[]>([])
  const [showDiff, setShowDiff] = useState(false)
  const [approving, setApproving] = useState(false)

  const [isEmployee, setIsEmployee] = useState<boolean>(employeeId === validatedUser)
  const [isEmployer, setIsEmployer] = useState<boolean>(employerId === validatedUser)

  const [answers, setAnswers] = useState<FormValues>(getInitialValues())

  const [accordionOpen, setAccordionOpen] = useState<boolean>(false)

  const divRef = useRef<HTMLDivElement>(null)

  const getAnswers = (d: IDiscussionAnswer[]) => {
    const answerObj = d.reduce<FormValues>((obj, d) => {
      const field = `q${d.formQuestionId}`
      return { ...obj, [field]: d.answer }
    }, {})
    setAnswers(answerObj)
  }

  useEffect(() => {
    const answers = discussionData?.versions.find((v) => v.version === selectedVersion)
    if (answers) {
      getAnswers(answers.answers)
    } else {
      setAnswers(
        flatMap(templateCategories, (cat) => cat.questions)
          .filter((q) => isInputQuestionType(q.type))
          .reduce((obj: FormValues, v: IFormQuestion) => {
            obj[`q${v.formQuestionId}`] = v.description ?? ''
            return obj
          }, {}),
      )
    }
    if (selectedVersion < 2) setShowDiff(false)
    setLoadingData(false)
  }, [discussionData, selectedVersion, templateCategories])

  const getGoals = async () => {
    try {
      const response = await agent.Discussions.getDiscussionGoals(discussion.developmentDiscussionId)
      setDevGoals(response.developmentGoals)
      setFbGoals(response.feedbackGoals)
    } catch (err) {
      toastNeg('Error getting goals. ' + err)
    }
  }

  const versionTitle = (version: IDiscussionVersion) => {
    const mostRecent = head(sortBy(version?.answers, ['created']).reverse())
    return `${t('Discussions.Version')} ${version?.version}, ${moment(utcHelper(mostRecent?.created))?.locale(language).format('L LT')}, ${
      mostRecent?.creatorName ?? ''
    }`
  }

  const getVersionsList = (list: IDiscussionVersion[]): void => {
    setSelectedVersion(list[0].version)
    const values: DropdownItem[] = []
    list.forEach((val) => {
      values.push({ label: versionTitle(val), value: val.version.toString() })
    })
    setVersionList(values)
  }

  const getDiscussionData = async () => {
    setLoadingData(true)
    try {
      const response = await agent.Discussions.getDiscussion(discussion.developmentDiscussionId)
      setDiscussionData(response)
      setIsEmployee(response.employeeId === validatedUser)
      setIsEmployer(response.employerId === validatedUser)
      if (response.versions.length > 0) {
        if (response.employerId === validatedUser && response.state === 'EmployeeDraft') {
          if (response.versions.length < 2) {
            setSelectedVersion(0)
          } else {
            const draftVersion = response.versions.length
            setSelectedVersion(draftVersion - 1)
            getVersionsList(response.versions.filter((v: IDiscussionVersion) => v.version !== draftVersion))
          }
        } else if (response.employeeId === validatedUser && response.state === 'EmployerDraft') {
          setSelectedVersion(response.versions[1].version)
          const notDraftVersion = response.versions.length
          getVersionsList(response.versions.filter((v: IDiscussionVersion) => v.version !== notDraftVersion))
        } else {
          setSelectedVersion(response.versions[0].version)
          getVersionsList(response.versions)
        }
      }
    } catch (err) {
      toastNeg('Error getting discussion data. ' + err)
    }
  }

  const disabledInput = (): boolean => {
    if (
      (discussionData && discussionData?.versions.length > 0 && selectedVersion !== discussionData?.versions[0]?.version) ||
      discussionData?.state === 'Completed'
    ) {
      return true
    }
    if (isEmployee) {
      return (
        discussionData?.state !== 'EmployeeAnswering' &&
        discussionData?.state !== 'EmployeeDraft' &&
        discussionData?.state !== 'EmployeeReading'
      )
    }
    if (isEmployer) {
      return (
        discussionData?.state !== 'DevelopmentDiscussion' &&
        discussionData?.state !== 'EmployerDraft' &&
        discussionData?.state !== 'EmployerReading'
      )
    }
    return true
  }

  const handlePostingValues = async (values: FormValues, draft = true) => {
    try {
      if (approving) {
        approveVersion()
      } else {
        const answers: INewAnswer[] = []

        for (const [q, a] of Object.entries(values)) {
          const ans: INewAnswer = {
            answer: a,
            formTemplateId: Number(q.slice(1)),
          }
          answers.push(ans)
        }
        const newVersion: INewDiscussionVersion = {
          creatorId: validatedUser,
          answers,
        }
        await postDiscussionVersion(discussion.developmentDiscussionId, draft, newVersion)
        if (draft) {
          toastPos(t('Toasts.DiscussionVersionDraft'))
        } else {
          toastPos(t('Toasts.DiscussionVersionSave'))
        }
      }
    } catch (error) {
      toastNeg('Error posting values' + error)
    } finally {
      await getDiscussionData()
      setIsConfirmSendDiscussionVisible(false)
      setApproving(false)
    }
  }

  const approveState = (isEmployer && discussion.state === 'EmployerReading') || (isEmployee && discussion.state === 'EmployeeReading')

  const approveVersion = async () => {
    setApproving(true)
    try {
      if (isEmployee) {
        await approveDiscussionAsEmployee(validatedUser, discussion.developmentDiscussionId)
      } else if (isEmployer) {
        await approveDiscussionAsEmployer(validatedUser, discussion.developmentDiscussionId)
      }
      if (onApprove) onApprove(discussion.developmentDiscussionId)
      toastPos(t('Toasts.DiscussionApprove'))
    } catch (error) {
      toastNeg('Approving development discussion failed: ' + error)
    } finally {
      setApproving(false)
    }
  }

  const getDifferences = (questionId: number) => {
    if (selectedVersion > 1) {
      const currentAnswer = findQuestionAnswer(selectedVersion, questionId)
      const previousAnswer = findQuestionAnswer(selectedVersion - 1, questionId)

      const diff = diffWordsWithSpace(previousAnswer, currentAnswer)

      return diff.map((part: Change) => {
        return (
          <span
            key={`${part.value}-${Math.random()}-${questionId}`}
            className={part.added ? 'greenText' : part.removed ? 'redText' : 'defaultText'}
          >
            {part.value}
          </span>
        )
      })
    }
    return <span />
  }

  const renderQuestionType = (question: IFormQuestion, handleChange: (e: React.ChangeEvent) => void, value?: string) => {
    const questionType = question.type.toLocaleLowerCase()
    switch (questionType) {
      case 'textarea':
        return (
          <div className='fieldMargin'>
            <OPMultiline
              resizeable
              name={`q${question.formQuestionId}`}
              readOnly={disabledInput()}
              value={value}
              label={question.question}
              rows={5}
              onChange={handleChange}
            />
            {showDiff && (
              <div className='fieldMargin diffDiv'>
                <p>{getDifferences(question.formQuestionId)}</p>
              </div>
            )}
          </div>
        )
      case 'input':
        return (
          <div className='fieldMargin'>
            <OPMultiline
              resizeable
              name={`q${question.formQuestionId}`}
              readOnly={disabledInput()}
              value={value}
              label={question.question}
              rows={5}
              onChange={handleChange}
            />
            {showDiff && (
              <div className='fieldMargin diffDiv'>
                <p>{getDifferences(question.formQuestionId)}</p>
              </div>
            )}
          </div>
        )
      case 'h1':
      case 'h2':
      case 'h3':
      case 'h4':
      case 'h5':
      case 'h6':
        return (
          <div className='discFormTitle'>
            <p>{question.question}</p>
          </div>
        )
      case 'p':
        return <p>{question.question}</p>
      default:
        return <></>
    }
  }

  const getGuideText = (): string => {
    if (isEmployee) {
      switch (discussion.state) {
        case 'Completed':
          return `${t('Discussions.VersionApproved')} ${moment(utcHelper(discussionData?.employeeApproveTime))
            ?.locale(language)
            .format('L LT')}`
        case 'EmployerReading':
          return `${t('Discussions.VersionApproved')} ${moment(utcHelper(discussionData?.employeeApproveTime))
            ?.locale(language)
            .format('L LT')}`
        case 'EmployeeReading':
          return t('Discussions.InfoSnack8')
        case 'DevelopmentDiscussion':
          return `${t('Discussions.VersionApproved')} ${moment(utcHelper(discussionData?.employeeApproveTime))
            ?.locale(language)
            .format('L LT')}`
        case 'EmployerDraft':
          return `${t('Discussions.VersionApproved')} ${moment(utcHelper(discussionData?.employeeApproveTime))
            ?.locale(language)
            .format('L LT')}`
        case 'EmployeeDraft':
          return `${discussion.creatorName === employerName ? t('Discussions.InfoSnack4') : t('Discussions.InfoSnack2')}`
        case 'EmployeeAnswering':
          return `${discussion.creatorName === employerName ? t('Discussions.InfoSnack4') : t('Discussions.InfoSnack2')}`
      }
    }
    if (isEmployer) {
      switch (discussion.state) {
        case 'Completed':
          return `${t('Discussions.VersionApproved')} ${moment(utcHelper(discussionData?.employerApproveTime))
            ?.locale(language)
            .format('L LT')}`
        case 'EmployerReading':
          return t('Discussions.InfoSnack7')
        case 'EmployeeReading':
          return `${t('Discussions.VersionApproved')} ${moment(utcHelper(discussionData?.employerApproveTime))
            ?.locale(language)
            .format('L LT')}`
        case 'DevelopmentDiscussion':
          return t('Discussions.InfoSnack5')
        case 'EmployerDraft':
          return t('Discussions.InfoSnack5')
        case 'EmployeeDraft':
          return ''
        case 'EmployeeAnswering':
          return ''
      }
    }
    return ''
  }

  const showVersionDiv = (): boolean => {
    if (discussionData?.employerId === validatedUser && discussionData.state === 'EmployeeDraft' && discussionData.versions.length < 2) {
      return false
    }
    if (discussionData && discussionData.versions.length > 0) {
      return true
    }
    return false
  }

  useEffect(() => {
    if (accordionOpen) {
      divRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [accordionOpen])

  const disabledButtons =
    isConfirmReadySectionVisible ||
    isNewDevGoalVisible ||
    isNewFBGoalVisible ||
    isEditFeedbackGoal ||
    isEditDiscussionGoal ||
    isAddCommentVisible ||
    isNewDevDiscussionVisible ||
    isConfirmDeleteGoalVisible ||
    isNewMiniDiscussionVisible ||
    isNewColleagueFeedbackVisible ||
    isDeleteColleagueFeedbackVisible ||
    isEditColleagueFeedback ||
    isConfirmSendDiscussionVisible ||
    isConfirmDeleteDiscussionVisible

  const handleDelete = async () => {
    try {
      if (selectedDiscussion) {
        await deleteDevelopmentDiscussion(Number(employeeId), selectedDiscussion.developmentDiscussionId)
        toastPos(t('Toasts.DiscussionDeleteSuccess'))
      }
      setIsConfirmDeleteDiscussionVisible(false)
    } catch (error) {
      toastNeg(getErrMsg(error, t) || t('Toasts.DiscussionDeleteError'))
    }
  }

  return (
    <OPAccordionItem
      insideAccordion
      lockedOpen={isConfirmSendDiscussionVisible && selectedDiscussion?.developmentDiscussionId === discussion.developmentDiscussionId}
      title={`${t(`Discussions.${discussion.formTemplateName}`, { defaultValue: discussion.formTemplateName })} ${moment(discussion.date)
        .locale(language)
        .format('L')}`}
      created={t(`DiscussionsStates.${discussion.state}`)}
      onFirstOpen={() => {
        const addCatQuestions = async () => {
          setLoadingTemplateCategories(true)
          const response = await agent.Discussions.getFormQuestions(discussion.formTemplateId)
          setActiveSection(response[0].category)
          setTemplateCategories(response)
          await getDiscussionData()
          await getGoals()
          setLoadingTemplateCategories(false)
        }
        addCatQuestions()
      }}
      setIsOpen={setAccordionOpen}
      content={
        loadingData && loadingTemplateCategories ? (
          <div className='loaderDiv'>
            <CircularProgress />
          </div>
        ) : (
          <div ref={divRef} className='scrollPadding'>
            <OPAccordionContent listContent={{ label: t('Discussions.DiscussionEmployer'), specs: discussionData?.employerName ?? '' }} />
            <OPAccordionContent listContent={{ label: t('Discussions.DiscussionEmployee'), specs: discussionData?.employeeName ?? '' }} />
            <OPAccordionContent
              listContent={{ label: t('Discussions.DiscussionState'), specs: `${t(`DiscussionsStates.${discussion.state}`)}` }}
            />
            <OPAccordionContent listContent={{ label: t('Discussions.DiscussionNote'), specs: discussion.note ?? '' }} />
            {showVersionDiv() && (
              <OPAccordionContent
                listContent={{
                  label: t('Discussions.Version'),
                  specs: (
                    <OPDropdown
                      curState={selectedVersion === 0 || versionList.length === 0 ? '' : selectedVersion.toString()}
                      saveState={(e) => setSelectedVersion(Number(e.target.value))}
                      label={''}
                      dropdownItemList={versionList}
                    />
                  ),
                }}
              />
            )}
            {!(isEmployer && (discussion.state === 'EmployeeAnswering' || discussion.state === 'EmployeeDraft')) && (
              <OPSnackbar text1={getGuideText()} type='discussionInfo' completed={discussion.state === 'Completed'} />
            )}
            <div className='navDiv'>
              <div className='discussionNav'>
                {templateCategories.map((category) => {
                  return (
                    <div
                      key={category.formQuestionCategoryId}
                      className={activeSection === category.category ? 'selectedNav' : ''}
                      onClick={() => setActiveSection(category.category)}
                    >
                      {t(`DiscussionsCategories.${category.category}`)}
                    </div>
                  )
                })}
                <div className={activeSection === 'goals' ? 'selectedNav' : ''} onClick={() => setActiveSection('goals')}>
                  {t('DiscussionsCategories.Goals')}
                </div>
              </div>
              {selectedVersion > 1 && (
                <div className='switchDiv'>
                  <OPSwitch
                    isChecked={showDiff}
                    label={t('Discussions.VersionChanges')}
                    value={''}
                    onChange={(e) => setShowDiff(e.target.checked)}
                  />
                </div>
              )}
            </div>
            <div className='formContainer'>
              {activeSection === 'goals' && (
                <div className='goalsDiv'>
                  <DiscussionGoals discussion={discussion} devGoals={devGoals} fbGoals={fbGoals} />
                  <div className='spacediv'></div>
                </div>
              )}
              <Formik
                key={selectedVersion}
                enableReinitialize={true}
                initialValues={answers}
                onSubmit={(values) => handlePostingValues(values, draft)}
              >
                {({ handleChange, values, dirty }) => (
                  <Form>
                    <ExitConfirmation when={dirty} />
                    {templateCategories.map((category) => {
                      return (
                        activeSection === category.category && (
                          <div key={category.formQuestionCategoryId}>
                            {category.questions.map((question) => {
                              return (
                                <div key={question.formQuestionId}>
                                  {renderQuestionType(question, handleChange, values[`q${question.formQuestionId}`])}
                                </div>
                              )
                            })}
                          </div>
                        )
                      )
                    })}
                    {disabledInput() === false && (
                      <div className='saveDeleteButtonsDiv'>
                        <div className='saveButton'>
                          <OPButton
                            label={t('Buttons.SaveDraft')}
                            buttonVariant={MuiButtonVariants.secondary_outlined}
                            disabled={disabledButtons}
                            onClick={() => {
                              setIsConfirmSendDiscussionVisible(true)
                              selectDiscussion(discussion)
                              setDraft(true)
                            }}
                          />
                        </div>
                        <div>
                          <OPButton
                            label={t('Buttons.CreateNewVersion')}
                            disabled={disabledButtons}
                            buttonVariant={approveState ? MuiButtonVariants.primary_outlined : MuiButtonVariants.primary}
                            onClick={() => {
                              setIsConfirmSendDiscussionVisible(true)
                              selectDiscussion(discussion)
                              setDraft(false)
                            }}
                          />
                        </div>
                        {approveState && (
                          <div className='approveButton'>
                            <OPButton
                              label={t('Buttons.ApproveVersion')}
                              disabled={isConfirmSendDiscussionVisible}
                              buttonVariant={MuiButtonVariants.primary}
                              onClick={() => {
                                setIsConfirmSendDiscussionVisible(true)
                                selectDiscussion(discussion)
                                setApproving(true)
                              }}
                            />
                          </div>
                        )}
                      </div>
                    )}
                    {isConfirmSendDiscussionVisible && selectedDiscussion?.developmentDiscussionId === discussion.developmentDiscussionId && (
                      <div>
                        <div className='confirmSendDiv'>
                          <div className='confirmSendHeader'>
                            <p>{`${
                              approving
                                ? t('Discussions.ConfirmApproveNew')
                                : draft
                                ? isEmployer
                                  ? t('Discussions.ConfirmSaveDraftAsEmployer')
                                  : t('Discussions.ConfirmSaveDraftAsEmployee')
                                : isEmployer
                                ? t('Discussions.ConfirmSaveAnswersAsManager')
                                : t('Discussions.ConfirmSaveAnswersAsEmployee')
                            }`}</p>
                          </div>
                          <div className='confirmSendButtons'>
                            <div className='cancelConfirmSendButton'>
                              <OPButton
                                label={t('Buttons.Cancel')}
                                buttonVariant={MuiButtonVariants.error_outlined}
                                onClick={() => {
                                  setIsConfirmSendDiscussionVisible(false)
                                  setApproving(false)
                                }}
                              />
                            </div>
                            <div>
                              <OPButton
                                label={approving ? t('Buttons.Complete') : draft ? t('Buttons.SaveDraft') : t('Buttons.CreateNewVersion')}
                                buttonVariant={MuiButtonVariants.primary}
                                type='submit'
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {isEmployer && (
                      <div className='saveDeleteButtonsDiv'>
                        <div className='deleteButton'>
                          <OPButton
                            label={t('Buttons.Delete')}
                            disabled={disabledButtons}
                            buttonVariant={MuiButtonVariants.error}
                            onClick={() => {
                              setIsConfirmDeleteDiscussionVisible(true)
                              selectDiscussion(discussion)
                            }}
                            endIcon={<DeleteIcon fill={isConfirmDeleteDiscussionVisible ? '#757575' : 'white'} />}
                          />
                        </div>
                      </div>
                    )}
                    {isConfirmDeleteDiscussionVisible &&
                      selectedDiscussion?.developmentDiscussionId === discussion?.developmentDiscussionId && (
                        <div>
                          <div className='confirmDiv'>
                            <div className='confirmHeader'>
                              <p>{`${t('Discussions.ConfirmDiscussionDelete')}?`}</p>
                            </div>
                            <div className='confirmButtons'>
                              <div className='cancelConfirmButton'>
                                <OPButton
                                  label={t('Buttons.Cancel')}
                                  buttonVariant={MuiButtonVariants.error_outlined}
                                  onClick={() => {
                                    setIsConfirmDeleteDiscussionVisible(false)
                                  }}
                                />
                              </div>
                              <div>
                                <OPButton
                                  label={t('Buttons.Delete')}
                                  buttonVariant={MuiButtonVariants.error}
                                  endIcon={<DeleteIcon />}
                                  onClick={handleDelete}
                                />
                              </div>
                            </div>
                          </div>
                          <OPDivider />
                        </div>
                      )}
                  </Form>
                )}
              </Formik>
            </div>
            <OPDivider />
          </div>
        )
      }
    />
  )
})
