import { Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ISkillFormPackage } from '../../../interfaces/skills/ISkills'
import { useStore } from '../../../Provider'
import { MuiButtonVariants, OPButton } from '../../OPcomponents/OPButton'
import { InterestedButton } from './InterestedButton'

const SkillOverview = (props: { data: ISkillFormPackage; setSkillData: (data: ISkillFormPackage) => void }): JSX.Element => {
  const { data, setSkillData } = props
  const { t } = useTranslation()
  const rootStore = useStore()
  const { validatedUser } = rootStore.loginStore
  const { getPersonInterests } = rootStore.personStore
  const { getSkillPersons } = rootStore.seachStore
  const { addInterest, removeInterest, setShowEditInfo, showEditInfo, showUserSkillEdit } = rootStore.skillStore
  const { isAdmin, isHr, isSales, isPowerUser } = rootStore.loginStore
  const onLikeHandler = async () => {
    if (data?.skill.userInterested) {
      await removeInterest(validatedUser, data?.skill?.skill.skillId)
    } else if (!data?.skill?.userInterested) {
      await addInterest(validatedUser, data?.skill?.skill.skillId)
    }
    const skillPersons = await getSkillPersons(data?.skill?.skill.skillId)
    setSkillData({
      ...data,
      skill: {
        ...data.skill,
        userInterested: !data?.skill?.userInterested,
      },
      skillPersons,
    })
    getPersonInterests(validatedUser)
  }

  return (
    <Grid container className='gridcontainer--formcontainer__itempaddings' rowSpacing={2}>
      <Grid xs={12} item>
        <span className='myskill--font__titlefont sini'>{t('Skills.SkillInfo')}</span>
      </Grid>
      <Grid xs={12} item>
        <span className='myskill--font__titlefont subTitle'>{`${t('Skills.SkillName')}: `}</span>
        <span className={'myskill--font__regtext' + (data?.skill?.skill.skillName ? '' : ' placeholder')}>
          {data?.skill?.skill.skillName ?? t('Projects.NotDefined')}
        </span>
      </Grid>
      <Grid xs={12} item>
        <span className='myskill--font__titlefont subTitle'>{`${t('Skills.SkillOverview')}: `}</span>
        <span className={'myskill--font__regtext' + (data?.skill?.skill.skillDescription ? '' : ' placeholder')}>
          {data?.skill?.skill.skillDescription ?? t('Projects.NotDefined')}
        </span>
      </Grid>
      <Grid xs={12} item>
        <span className='myskill--font__titlefont subTitle'>{`${t('Skills.Category')}: `}</span>
        <span className={'myskill--font__regtext' + (data?.skill?.skill.categoryName ? '' : ' placeholder')}>
          {data?.skill?.skill.categoryName ?? t('Projects.NotDefined')}
        </span>
      </Grid>
      <Grid container xs={12} wrap='nowrap' item>
        <Grid item>
          <span className='myskill--font__titlefont subTitle'>{`${t('Skills.Tags')}: `}</span>
          <span className={'myskill--font__regtext' + (data?.skill?.skill.skillTags ? '' : ' placeholder')}>
            {data?.skill?.skill.skillTags ?? t('Projects.NotDefined')}
          </span>
        </Grid>
        <Grid item container justifyContent={'flex-end'}>
          <InterestedButton isActive={data?.skill?.userInterested} likeHandler={onLikeHandler} />
          {(isAdmin || isHr || isSales || isPowerUser) && showUserSkillEdit && (
            <Grid paddingLeft={'1rem'}>
              <OPButton
                onClick={() => setShowEditInfo(!showEditInfo)}
                label={t('Buttons.Edit')}
                buttonVariant={MuiButtonVariants.secondary_outlined}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default observer(SkillOverview)
