import { Box, Collapse, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import _ from 'lodash'
import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router'
import i18n from '../../i18next'
import { ICertificate, IEducation } from '../../interfaces/certAndEducation/certAndEducation'
import { IProjectFormPackage } from '../../interfaces/projects/IProjects'
import { ISkillFormPackage } from '../../interfaces/skills/ISkills'
import '../styles/datatable.scss'
import '../styles/grid.scss'
import { DatefieldDown, DatefieldUp } from '../styles/Icons'
import { OPLoadingCircle } from './OPLoadingcircle'
import OPTooltip from './OPTooltip'

export interface ITableForm {
  collapseHandler?: () => void
  isEdit?: boolean
  scrollRef?: () => void
  data?: ISkillFormPackage | IProjectFormPackage | ICertificate | IEducation
  projectId?: number
  isTable?: boolean
}
interface OPNewTable {
  ellipsis?: boolean
  rows: Data[]
  curLength: number
  FormBase?: React.ElementType
  onRowClick?: (rowId: number | string) => Promise<ISkillFormPackage> | any
  isCloseable?: boolean
  hideHeader?: boolean
  noBorders?: boolean
  underLine?: boolean
  noPadding?: boolean
  isClickable?: MutableRefObject<HTMLDivElement | null>
  onRowClose?: () => void
  profileclickedId?: string | number | null
}
interface CollapseTableRow {
  row: Data['rows']
  headers: { text: string; width: string | undefined }[]
  isClickable?: MutableRefObject<HTMLDivElement | null>
  FormBase?: React.ElementType
  onRowClick?: (rowId: number | string) => ISkillFormPackage
  isCloseable?: boolean
  onRowClose?: () => void
  profileclickedId?: string | number | null
  id: string | number
}
export interface Data {
  id: string | number
  rows: {
    [key: string]: { text: string | number | JSX.Element; width?: string; bolded?: boolean }
  }
}

interface openData {
  id: string | number
  open?: boolean
  isLoading?: boolean
  data?: ISkillFormPackage | undefined
}

interface Tableheader {
  ellipsis: boolean
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data['rows']) => void
  order: Order
  orderBy: string
  headers: { text: string; width: string | undefined }[]
}

export const OPNewTable = (props: OPNewTable): JSX.Element => {
  const {
    ellipsis,
    rows,
    FormBase,
    onRowClick,
    isCloseable,
    hideHeader = false,
    noBorders = false,
    underLine = false,
    noPadding = false,
    isClickable,
    onRowClose,
    profileclickedId,
    curLength,
  } = props

  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState('')

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data['rows']) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property.toString())
  }

  const headers =
    rows.length > 0
      ? Object.entries(rows[0].rows).map((header) => {
          return { text: header[0], width: header[1].width }
        })
      : []

  return (
    <TableContainer>
      <Table
        className={
          'newbase' +
          `${noBorders ? ' noborder' : ''}` +
          ' newbase' +
          `${underLine ? ' underline' : ''}` +
          'newbase' +
          `${noPadding ? ' nopadding' : ''}`
        }
      >
        {!hideHeader && (
          <SortableHeader
            ellipsis={ellipsis ?? false}
            onRequestSort={handleRequestSort}
            order={order}
            orderBy={orderBy}
            headers={headers}
          />
        )}
        <TableBody>
          {rows
            .sort((a, b) => getComparator(order, orderBy)(a.rows, b.rows))
            .slice(0, curLength)
            .map((row: Data) => {
              return (
                <CollapseTableRow
                  key={row.id}
                  id={row.id}
                  row={row.rows}
                  headers={headers}
                  FormBase={FormBase}
                  onRowClick={onRowClick}
                  isCloseable={isCloseable}
                  isClickable={isClickable}
                  onRowClose={onRowClose}
                  profileclickedId={profileclickedId}
                />
              )
            })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const SortableHeader = (props: Tableheader) => {
  const { order, orderBy, onRequestSort, headers, ellipsis } = props

  const createSortHandler = (property: keyof Data['rows']) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headers.map((header) => (
          <TableCell
            width={header.width}
            key={_.uniqueId('header-')}
            sortDirection={orderBy === header.text ? order : false}
            onClick={createSortHandler(header.text)}
          >
            {orderBy === header.text ? (
              <Box component='span' sx={visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </Box>
            ) : null}
            <TableSortLabel
              active={orderBy === header.text}
              direction={orderBy === header.text ? order : 'asc'}
              IconComponent={order === 'asc' ? DatefieldDown : DatefieldUp}
            >
              <OPTooltip title={header.text}>
                <div className={ellipsis ? 'ellipsis' : 'overflow'}>{header.text}</div>
              </OPTooltip>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const CollapseTableRow = (props: CollapseTableRow) => {
  const { row, headers, isClickable, FormBase, onRowClick, onRowClose, profileclickedId, id } = props
  const initVals = { id, open: false, isLoading: false, data: undefined }
  const [rowData, setRowData] = useState<openData>(initVals)
  const refElement = useRef<HTMLDivElement>(null)

  const handleOpen = async (e: React.MouseEvent<HTMLTableRowElement, MouseEvent> | null) => {
    if (rowData?.open) {
      handleClose()
      return
    }
    setRowData((oldRowData) => ({ ...oldRowData, open: true, isLoading: true }))
    const newData = await onRowClick?.(rowData.id)
    setRowData((oldRowData) => ({ ...oldRowData, isLoading: false, data: newData }))
    setTimeout(() => {
      scrollRef()
    }, 600)
  }

  useEffect(() => {
    if (profileclickedId && profileclickedId === rowData.id && !rowData.open) {
      handleOpen(null)
    }
  }, [])

  const handleClose = () => {
    setRowData(initVals)
  }
  const scrollRef = () => {
    if (typeof refElement !== 'function' && refElement?.current) {
      if (isClickable) isClickable.current = refElement.current
      if (refElement.current.scrollIntoView) refElement.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }
  const location = useLocation()

  return (
    <>
      <TableRow
        className={`${
          rowData?.open ? (location.pathname === '/neededcompetence' ? ' newbase MuiTableCell-head' : 'activebackground') : ''
        }`}
        key={_.uniqueId('tablerow-')}
        style={{ cursor: 'pointer' }}
        onClick={(e) => {
          handleOpen(e)
        }}
      >
        {headers.map((header) => (
          <TableCell width={row[header.text].width} key={_.uniqueId('cell-')} align={'inherit'}>
            <OPTooltip title={row[header.text].text}>
              <div className={`ellipsis${row[header.text].bolded ? '_bolded' : ''}`}>{row[header.text].text}</div>
            </OPTooltip>
          </TableCell>
        ))}
      </TableRow>
      {FormBase && (
        <TableRow className='collapsenohover'>
          <TableCell padding='none' colSpan={headers.length}>
            <Collapse in={rowData?.open} timeout={400} unmountOnExit onExited={() => onRowClose?.()}>
              <Grid ref={refElement} item xs={12} className='griditem--formitem__papermargin scrollmargin'>
                {rowData?.isLoading ? (
                  <OPLoadingCircle />
                ) : (
                  <FormBase scrollRef={scrollRef} collapseHandler={handleClose} isEdit={true} data={rowData?.data} />
                )}
              </Grid>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

function descendingComparator(a: Data['rows'], b: Data['rows'], orderBy: keyof Data['rows'], order: string) {
  const lang = i18n.language
  const locLang = lang === 'fi' ? 'DD.MM.YYYY' : 'MM/DD/YYYY'
  dayjs.extend(customParseFormat)
  dayjs.locale(lang)

  const aObject = String(a[orderBy]?.text)
  const bObject = String(b[orderBy]?.text)

  if (aObject.length === 0) {
    return 1
  }
  if (bObject.length === 0) {
    return -1
  }

  const jsxObjectA = a[orderBy]?.text as JSX.Element
  const jsxObjectB = b[orderBy]?.text as JSX.Element

  if ((jsxObjectA?.key !== null && jsxObjectA?.key !== undefined) && (jsxObjectB?.key !== null && jsxObjectB?.key !== undefined)) {
    const jsxOrder = jsxObjectA.key.toString().localeCompare(jsxObjectB.key.toString())
    return order === 'desc' ? jsxOrder : -jsxOrder
  }

  const aDate = dayjs(aObject, locLang)
  const bDate = dayjs(bObject, locLang)

  const skillA = a[orderBy]?.text as SkillBar
  const skillB = b[orderBy]?.text as SkillBar

  if (skillA?.props !== undefined && skillB?.props !== undefined) {
    const skillOrder = skillA.props.value - skillB.props.value
    return order === 'desc' ? skillOrder : -skillOrder
  }

  if (aDate.isValid() && bDate.isValid()) {
    const sortNumber = dayjs(aDate).isBefore(dayjs(bDate)) ? -1 : 1
    return order === 'desc' ? sortNumber : -sortNumber
  }

  const sortNumber = aObject.localeCompare(bObject, undefined, { ignorePunctuation: true, numeric: true })
  return order === 'desc' ? sortNumber : -sortNumber
}

type Order = 'asc' | 'desc'

function getComparator<Key extends keyof Data['rows']>(order: Order, orderBy: Key): (a: Data['rows'], b: Data['rows']) => number {
  return (a, b) => descendingComparator(a, b, orderBy, order)
}

type SkillBar = {
  props: {
    name: string
    value: number
  }
}
