import { useMediaQuery } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useStore } from '../../Provider'
import { theme } from '../../theme/CustomTheme'
import {
  SideBarCollab,
  SideBarDev,
  SideBarHome,
  SideBarHR,
  SideBarInstr,
  SideBarPeople,
  SideBarProfile,
  SideBarProjects,
  SideBarSkills,
  SideBarSuperior,
} from '../styles/Icons'
import { MiniSideBar } from './MiniSideBar'
import SideBar from './SideBar'
import { SideBarItem } from './SideBarItem'

const SideBarController = (): JSX.Element => {
  const location = useLocation()
  const { t } = useTranslation()

  const rootStore = useStore()
  const { validatedUser, user, isPartner, isSales, isHr, isManager } = rootStore.loginStore
  const { fetchSidebarData, setOpenState, isOpen, setIsOpen, openState } = rootStore.sidebarStore
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

  const sidebarLinks: SideBarItem[] = [
    {
      link: '/',
      icon: <SideBarHome />,
      menuID: '0',
      isExact: true,
      text: t('Navbar.Frontpage'),
    },
    {
      link: '/profile',
      menuID: '1',
      icon: <SideBarProfile />,
      text: t('Navbar.Profile'),
      isNested: true,
      nestedItems: [
        {
          link: '/profile/overview',
          text: t('Buttons.Overview'),
        },
        {
          link: '/profile/myskill',
          text: t('Buttons.MySkills'),
        },
        {
          link: '/profile/certedu',
          text: t('Buttons.CertEduPublic'),
        },
        {
          link: '/profile/workhistory',
          text: t('Buttons.WorkHistory'),
        },
        {
          link: '/profile/interests',
          text: t('Buttons.Interests'),
        },
      ],
    },
    ...(!isPartner
      ? [
          {
            link: '/skills',
            menuID: '3',
            icon: <SideBarSkills />,
            text: t('Navbar.Skills'),
          },
          {
            link: '/projects',
            menuID: '4',
            icon: <SideBarProjects />,
            text: t('Navbar.Projects'),
          },
          {
            link: '/development',
            menuID: '10',
            icon: <SideBarDev />,
            text: t('Navbar.Development'),
          },
          {
            link: '/persons',
            menuID: '5',
            icon: <SideBarPeople />,
            text: t('Navbar.Persons'),
          },
        ]
      : []),
    ...(isSales || isHr
      ? [
          {
            link: '/partners',
            menuID: '6',
            icon: <SideBarCollab />,
            text: t('Navbar.Partners'),
          },
        ]
      : []),
    ...(isManager
      ? [
          {
            link: '/manager',
            menuID: '9',
            icon: <SideBarSuperior />,
            text: t('Discussions.DiscussionEmployer'),
          },
        ]
      : []),
    ...(isHr
      ? [
          {
            link: '/hr',
            menuID: '11',
            icon: <SideBarHR />,
            text: `${t('Discussions.NavHR')}`,
          },
        ]
      : []),
    {
      link: '/instructions',
      menuID: '7',
      icon: <SideBarInstr />,
      text: t('Navbar.Info'),
    },
  ]

  useEffect(() => {
    fetchSidebarData(validatedUser, user.name)
  }, [fetchSidebarData, user.name, validatedUser])

  useEffect(() => {
    const curLoc = `/${location.pathname.split('/')[1]}`
    setOpenState(curLoc === '/profile' ? '1' : 'none')
  }, [location.pathname, setOpenState])

  useEffect(() => setIsOpen(isMdUp), [isMdUp, setIsOpen])

  return isOpen ? <SideBar data={sidebarLinks} /> : <MiniSideBar data={sidebarLinks} />
}
export default observer(SideBarController)
