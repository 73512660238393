import { InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import React, { ReactNode, useState } from 'react'
import '../styles/dropdown.scss'
import { DropdownArrow, DropDownArrowFocused } from '../styles/Icons'

export interface DropdownItem {
  label: string
  ariaLabel?: string
  dataTestId?: string
  disabled?: boolean
  value: string
}
interface Dropdown {
  curState: string
  saveState: (value: SelectChangeEvent<string>) => void
  label: string
  ariaLabel?: string
  dataTestId?: string
  disabled?: boolean
  dropdownItemList: DropdownItem[]
  placeholder?: string
  required?: boolean
}

export const OPDropdown = (props: Dropdown): JSX.Element => {
  const { curState, saveState, label, ariaLabel, dataTestId, disabled, dropdownItemList, placeholder, required } = props

  const [open, setOpen] = useState(false)
  const [focus, setFocus] = useState(false)

  const handleChange = (e: SelectChangeEvent<string>) => {
    saveState(e)
  }

  const isPlaceholder = (value: string): ReactNode => {
    return value.length > 0 ? (
      Array.isArray(value) ? (
        value.join(', ')
      ) : (
        <div className='typoNormal'>{dropdownItemList.find(({ value: v }) => v === value)?.label}</div>
      )
    ) : (
      <div className='typoPlace'>{placeholder}</div>
    )
  }

  return (
    <>
      <InputLabel id={label}>
        <div className='typoLabel'>{label}</div>
      </InputLabel>

      <Select
        required={required}
        className={'dropdownstyling' + (open ? ' open' : '')}
        aria-label={ariaLabel}
        data-testid={dataTestId}
        disabled={disabled}
        onChange={handleChange}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        onMouseEnter={() => setFocus(true)}
        onMouseLeave={() => setFocus(false)}
        size='small'
        IconComponent={focus ? DropDownArrowFocused : DropdownArrow}
        displayEmpty={true}
        value={curState}
        renderValue={(value: string) => isPlaceholder(value)}
        MenuProps={{ className: 'dropdownmenuStyling', MenuListProps: { disablePadding: true } }}
      >
        {dropdownItemList.map((DpItem: DropdownItem) => (
          <MenuItem
            key={DpItem.value}
            value={DpItem.value}
            data-testid={DpItem.dataTestId}
            disabled={DpItem.disabled}
            aria-label={DpItem.ariaLabel}
            disableRipple
          >
            <div className='typoNormal'>{DpItem.label}</div>
          </MenuItem>
        ))}
      </Select>
    </>
  )
}
