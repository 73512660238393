import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import React, { useState } from 'react'
import '../styles/textfield.scss'

interface OPTextFieldProps {
  name?: string
  label: string
  width?: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
  value?: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  fullWidth?: boolean
  error?: boolean
  type?: 'text' | 'password'
  dataTestId?: string
  tabIndex?: number
  maxLength?: number
  errorText?: string
}

export const OPTextField = (props: OPTextFieldProps): JSX.Element => {
  const {
    name,
    label,
    value,
    placeholder,
    width,
    maxLength,
    required,
    disabled,
    errorText,
    fullWidth,
    onChange,
    error,
    type,
    dataTestId,
    tabIndex,
  } = props

  const [focused, setFocused] = useState<boolean>(false)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e)
  }

  return (
    <>
      <InputLabel className='textfieldLabel' required={required} disabled={disabled} error={error}>
        {label}
      </InputLabel>
      <Input
        name={name}
        className={`inputStyle ${error && !disabled ? 'error' : ''}`}
        sx={{
          width: width,
        }}
        autoComplete='off'
        type={type ? type : 'text'}
        required={required}
        disabled={disabled}
        placeholder={focused && value === '' ? '' : placeholder}
        fullWidth={fullWidth}
        error={error}
        disableUnderline
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        multiline={false}
        value={value}
        onChange={handleChange}
        tabIndex={tabIndex ?? 0}
        inputProps={{ 'data-testid': dataTestId, maxLength: maxLength }}
      />
      {error && !disabled && <div className='errorHelperText'>{errorText}</div>}
    </>
  )
}
