import { action, makeObservable, observable } from 'mobx'

export default class UtilStore {
  showModal = false
  minDate: Date | null = null
  maxDate: Date | null = null

  showModalHandler = () => {
    this.showModal = !this.showModal
  }

  setMinDate = (date: Date | null) => {
    this.minDate = date
  }

  setMaxDate = (date: Date | null) => {
    this.maxDate = date
  }

  constructor() {
    makeObservable(this, {
      showModal: observable,
      minDate: observable,
      maxDate: observable,
      showModalHandler: action,
      setMinDate: action,
      setMaxDate: action,
    })
  }
}
