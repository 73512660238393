import { Grid } from '@mui/material'
import { t } from 'i18next'
import React from 'react'
import { MuiButtonVariants, OPButton } from '../OPcomponents/OPButton'
import '../styles/grid.scss'
import { DeleteIcon } from '../styles/Icons'

interface SkillsButtons {
  onClose?: () => void
  onSubmit?: (values?: any) => void
  onDelete?: () => void
  isDelete?: boolean
  disabled?: boolean
  bothDisabled?: boolean
  type?: 'button' | 'submit' | 'reset'
  noPadding?: boolean
  isAddingSkillToSelf?: boolean
  bottomPadding?: boolean
  paddingRight?: boolean
  onAutosave?: boolean
}

export const OPSkillsButtons = (props: SkillsButtons): JSX.Element => {
  const {
    onClose,
    onSubmit,
    disabled,
    bothDisabled,
    type,
    isDelete,
    onDelete,
    noPadding,
    bottomPadding,
    isAddingSkillToSelf,
    onAutosave,
    paddingRight,
  } = props

  return (
    <>
      <Grid
        container
        className={
          'gridcontainer--skillsbuttons__skillsButtonsContainer' + (noPadding ? ' nopadding' : '') + (bottomPadding ? ' bottompadding' : '')
        }
        columnSpacing={2}
      >
        {isDelete && (
          <Grid item className={'griditem--skillsbuttons' + `${paddingRight ? '__paddingRight' : ''}`}>
            <OPButton
              onClick={() => onDelete?.()}
              buttonVariant={MuiButtonVariants.error}
              disabled={bothDisabled ? disabled : false}
              label={t('Buttons.Delete')}
              endIcon={<DeleteIcon />}
            />
          </Grid>
        )}
        {!onAutosave && (
          <>
            <Grid item>
              <OPButton
                disabled={bothDisabled ? disabled : false}
                buttonVariant={MuiButtonVariants.error_outlined}
                label={t('Buttons.Cancel')}
                onClick={() => onClose?.()}
              />
            </Grid>
            <Grid item>
              <OPButton
                label={isAddingSkillToSelf ? t('Buttons.AddSkill') : t('Buttons.Save')}
                onClick={() => onSubmit?.()}
                disabled={disabled}
                type={type}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  )
}
