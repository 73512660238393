import React from 'react'
import { useTranslation } from 'react-i18next'
import { OPLoadingCircle } from '../components/OPcomponents/OPLoadingcircle'

const CustomLoader = () => {
  const { t } = useTranslation()
  return (
    <div style={{ marginTop: '30em', display:'flex', justifyContent: 'center', width:'100%', flexWrap: 'wrap'}}>
      <div style={{flexBasis: '100%'}}>
      <OPLoadingCircle/>
      </div>
      <div>
        {t('Loading.Loading')}
      </div>
    </div>
  )
}

export default CustomLoader
