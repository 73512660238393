import { isEmpty, sortBy } from 'lodash'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import agent from '../../api/agent'
import { sortByType } from '../../common/sortHelper'
import { IDiscussionState } from '../../interfaces/discussions/IDiscussion'
import { ISortDirection } from '../../interfaces/Sort'

export interface IEmployee {
  personId: number
  personName: string
  personPhoto: string
  managerId: number
  managerName: string
  latestCompleteDiscussionTime: Date
  latestMiniDiscussionTime: Date
  latestOpenDiscussionTime: Date
  latestOpenDiscussionState?: null | IDiscussionState
  goalsToComplete: { discussionId: number; goalId: number; goalName: string; created?: Date }[]
}

export interface IEmployeeV2 {
  personId: number
  personName: string
  personPhoto: string
  managerId: number
  managerName: string
  latestCompleteDiscussionTime: Date
  latestMiniDiscussionTime: Date
  latestOpenDiscussionTime: Date
  latestOpenDiscussionState?: null | IDiscussionState
  goalsToComplete: { discussionId: number; goalId: number; goalName: string; created?: Date }[]
}

export default class ManagerStore {
  managerEmployees: Array<IEmployee> = []
  hrEmployees: Array<IEmployee> = []
  searchValue = ''
  managerSearchValue = ''
  loadingInitial = true

  employeeSortColumn = 'personName'
  employeeSortDirection: ISortDirection = 'ascending'

  setSearchValue = (value: string) => {
    this.searchValue = value
  }

  setManagerSearchValue = (value: string) => {
    this.managerSearchValue = value
  }

  setEmployeeSortColumn = (value: string) => {
    this.employeeSortColumn = value
  }

  toggleEmployeeSortDirection = () => {
    this.employeeSortDirection = this.employeeSortDirection === 'ascending' ? 'descending' : 'ascending'
  }

  constructor() {
    makeObservable(this, {
      managerEmployees: observable,
      hrEmployees: observable,
      searchValue: observable,
      managerSearchValue: observable,
      loadingInitial: observable,
      employeeSortColumn: observable,
      employeeSortDirection: observable,
      setSearchValue: action,
      setManagerSearchValue: action,
      setEmployeeSortColumn: action,
      toggleEmployeeSortDirection: action,
      filteredEmployees: computed,
      filteredManagerEmployees: computed,
      getManagerDiscussions: action,
      getHrDiscussions: action,
    })
  }

  get filteredEmployees() {
    let employees = sortBy(this.hrEmployees, (emp) => {
      return sortByType(emp, this.employeeSortColumn)
    })
    if (this.employeeSortDirection === 'descending') {
      employees = employees.reverse()
    }
    const params = this.searchValue
    if (isEmpty(params)) {
      return employees
    }
    return employees.filter(
      (employee: IEmployee) =>
        employee.personName?.toLowerCase().includes(params.toLowerCase()) ||
        employee.managerName?.toLowerCase().includes(params.toLowerCase()),
    )
  }

  get filteredManagerEmployees() {
    let employees = sortBy(this.managerEmployees, (emp) => {
      return sortByType(emp, this.employeeSortColumn)
    })
    if (this.employeeSortDirection === 'descending') {
      employees = employees.reverse()
    }
    const params = this.managerSearchValue
    if (isEmpty(params)) {
      return employees
    }
    return employees.filter(
      (employee: IEmployee) =>
        employee.personName?.toLowerCase().includes(params.toLowerCase()) ||
        employee.managerName?.toLowerCase().includes(params.toLowerCase()),
    )
  }

  getManagerDiscussions = async () => {
    this.loadingInitial = true
    try {
      const resp = await agent.Discussions.getManagerDiscussions()
      runInAction(() => {
        this.managerEmployees = resp
        this.loadingInitial = false
      })
    } catch (error) {
      runInAction(() => {
        this.loadingInitial = false
      })
      return Promise.reject(error)
    }
    return Promise.resolve()
  }

  getHrDiscussions = async () => {
    this.loadingInitial = true
    try {
      const resp = await agent.Discussions.getHRDiscussions()
      runInAction(() => {
        this.hrEmployees = resp
        this.loadingInitial = false
      })
    } catch (error) {
      runInAction(() => {
        this.loadingInitial = false
      })
      return Promise.reject(error)
    }
    return Promise.resolve()
  }

  getHrDiscussionsV2 = async () => {
    this.loadingInitial = true
    try {
      const resp = await agent.Discussions.getHRDiscussionsV2()
      runInAction(() => {
        this.hrEmployees = resp
        this.loadingInitial = false
      })
    } catch (error) {
      runInAction(() => {
        this.loadingInitial = false
      })
      return Promise.reject(error)
    }
    return Promise.resolve()
  }
}
