import { IconButton, InputBase } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { ChangeEvent, KeyboardEvent, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SearchGlass } from '../styles/Icons'
import '../styles/search.scss'

interface SearchProps {
  placeholder?: string
  searchValue?: string
  onSearchChange: (value: string) => void
  resetValue: () => void
}

const OPSearch = (props: SearchProps): JSX.Element => {
  const { placeholder, searchValue, onSearchChange, resetValue } = props
  const { t } = useTranslation()

  const [isSearching, setIsSearching] = useState<boolean>(false)

  const [fillState, setFillState] = useState('#757575')
  const ref = useRef(null)

  const setActive = () => setFillState('#3C3C3B')
  const setDefault = () => {
    if (document.activeElement !== ref.current && !isSearching) setFillState('#757575')
  }

  return (
    <InputBase
      inputRef={ref}
      onMouseEnter={setActive}
      onMouseLeave={setDefault}
      onFocus={setActive}
      onBlur={setDefault}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        e.target.value.length > 0 ? setIsSearching(true) : setIsSearching(false)
        onSearchChange(e.target.value)
      }}
      endAdornment={
        <IconButton disableRipple sx={{ padding: '0' }}>
          <SearchGlass fill={fillState} />
        </IconButton>
      }
      className='searchfield filtering'
      placeholder={placeholder ? placeholder : t('Search.Find')}
      type='search'
      onKeyDown={(e: KeyboardEvent) => {
        if (e.key === 'Escape') {
          setIsSearching(false)
          resetValue()
        }
      }}
      value={searchValue}
    />
  )
}
export default observer(OPSearch)
