import { Grid, List, ListItem } from '@mui/material'
import React from 'react'
import { SideBarItem } from './SideBarItem'

interface CollapsedList {
  title: string
  sideBarNestedItem?: SideBarItem[]
  isClick?: boolean
  isNested?: boolean
}

export const CollapsedList = (props: CollapsedList): JSX.Element => {
  const { title, sideBarNestedItem, isClick } = props
  return (
    <Grid container>
      <List className={'collapselistStyling' + (isClick ? ' active' : '')}>
        <ListItem className={'title'} disablePadding>
          <div className='fonts nestedTitle'>{title}</div>
        </ListItem>
        {sideBarNestedItem?.map((sideBarItem: SideBarItem) => (
          <SideBarItem
            styleName={'nestedButtonStyling' + ' collapseHover' + (isClick ? 'collapseActive' : '')}
            activeStyle={' active'}
            key={sideBarItem.text}
            link={sideBarItem.link}
            icon={sideBarItem.icon}
            text={sideBarItem.text}
            dataTestId={sideBarItem.dataTestId}
            ariaLabel={sideBarItem.ariaLabel}
            disabled={sideBarItem.disabled}
          />
        ))}
      </List>
    </Grid>
  )
}
