import { Input } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NumberFieldDownArrow, NumberFieldUpArrow, StepperDisabled } from '../styles/Icons'
import '../styles/numberfield.scss'

interface NumberFieldProps {
  label: string
  value?: number
  ariaLabel?: string
  dataTestId?: string
  disabled?: boolean
  tabIndex?: number
  minValue?: number
  maxValue?: number
  name?: string
  error?: boolean
  errorText?: string
  onChange: (e: number) => void
}

export const OPNumberField = (props: NumberFieldProps): JSX.Element => {
  const { t } = useTranslation()
  const { value, label, ariaLabel, dataTestId, disabled, minValue, maxValue, error, errorText, onChange, tabIndex, name } = props

  const [currentValue, setCurrentValue] = useState<number>(value ? value : 0)
  const [focused, setFocused] = useState<boolean>(false)
  const [hover, setHover] = useState<boolean>(false)
  const [upArrowActive, setUpArrowActive] = useState<boolean>(false)
  const [downArrowActive, setDownArrowActive] = useState<boolean>(false)
  const minimumValue = minValue ? minValue : 0
  const maximumValue = maxValue ? maxValue : 99999999

  useEffect(() => {
    setCurrentValue(value ?? 0)
  }, [value])

  useEffect(() => {
    const checkInputErrors = (v: number) => {
      if (v >= minimumValue && v <= maximumValue) {
        onChange(v)
      } else {
        onChange(v)
      }
    }
    checkInputErrors(currentValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isNaN(parseInt(e.target.value)) && parseInt(e.target.value) >= 0) {
      setCurrentValue(parseInt(e.target.value))
    } else if (e.target.value === '') {
      setCurrentValue(0)
    }
  }

  const handleIncrement = () => {
    setUpArrowActive(true)
    setCurrentValue(currentValue + 1)
  }

  const handleDecrement = () => {
    setDownArrowActive(true)
    setCurrentValue(currentValue - 1)
  }

  const onBlurEvent = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
    setFocused(false)
    if (typeof e.target.value === 'number') onChange(e.target.value)
  }

  return (
    <>
      <InputLabel className='numberfieldLabel' disabled={disabled} error={error}>
        {label}
      </InputLabel>
      <Input
        name={name}
        className={`numberInputStyle ${currentValue === 0 ? 'emptyInput' : 'filledInput'}`}
        type='number'
        disabled={disabled}
        endAdornment={
          <InputAdornment position='end' className='endArrows'>
            {disabled ? (
              <StepperDisabled />
            ) : currentValue === 0 && !hover && !focused ? (
              <div />
            ) : (
              <span className='iconSpan'>
                <IconButton
                  className={`upArrow ${error ? 'errorArrow' : ''}`}
                  disableRipple
                  onMouseDown={handleIncrement}
                  onMouseUp={() => setUpArrowActive(false)}
                >
                  <NumberFieldUpArrow fill={`${upArrowActive ? '#B8B8B8' : error ? '#9E3644' : '#03499E'}`} />
                </IconButton>
                <IconButton
                  className={`downArrow ${error ? 'errorArrow' : ''}`}
                  disableRipple
                  onMouseDown={handleDecrement}
                  onMouseUp={() => setDownArrowActive(false)}
                >
                  <NumberFieldDownArrow fill={`${downArrowActive ? '#B8B8B8' : error ? '#9E3644' : '#03499E'}`} />
                </IconButton>
              </span>
            )}
          </InputAdornment>
        }
        fullWidth
        disableUnderline
        multiline={false}
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onFocus={() => setFocused(true)}
        onChange={handleChange}
        onBlur={(e) => onBlurEvent(e)}
        value={focused && currentValue === 0 ? '' : currentValue}
        tabIndex={tabIndex ?? 0}
        aria-label={ariaLabel}
        data-testid={dataTestId}
        error={error}
      />
      <div className='helperText'>{error ? errorText : ''}</div>
    </>
  )
}
