import { Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../Provider'
import { OPCheckBox } from '../OPcomponents/OPCheckBox'
import OPNavbarSearch from '../OPcomponents/OPNavbarSearch'
import CertSearchAccordion from './SearchAccordions/CertSearchAccordion'
import EduSearchAccordion from './SearchAccordions/EduSearchAccordion'
import LangSearchAccordion from './SearchAccordions/LangSearchAccordion'
import PersonSearchAccordion from './SearchAccordions/PersonSearchAccordion'
import ProjectSearchAccordion from './SearchAccordions/ProjectSearchAccordion'
import SkillSearchAccordion from './SearchAccordions/SkillSearchAccordion'

const Search = (): JSX.Element => {
  const { t } = useTranslation()
  const rootStore = useStore()

  const {
    scrollPosition,
    setStepperAmount,
    setScrollPosition,
    resetSearchFilters,
    searchFilters,
    setFromPersonSearch,
    toggleSearchFilter,
    searchParams,
    emptySearchFilters,
  } = rootStore.seachStore
  const { validatedUser } = rootStore.loginStore
  const { getAllPersons, getAllLanguages } = rootStore.personStore
  const { getAllProjects } = rootStore.projectStore
  const { getAllSkills } = rootStore.skillStore
  const { getEducations, getCertificates } = rootStore.certAndEducationStore

  useEffect(() => {
    async function fetchData() {
      await Promise.allSettled([
        getAllProjects(validatedUser),
        getAllSkills(validatedUser),
        getAllPersons(),
        getEducations(),
        getCertificates(validatedUser),
        getAllLanguages(),
      ])
    }
    fetchData()
  }, [getAllLanguages, getAllPersons, getAllProjects, getAllSkills, getCertificates, getEducations, validatedUser])

  useEffect(() => {
    if (scrollPosition) {
      setTimeout(() => window.scrollTo(0, scrollPosition), 100)
    }
  }, [])

  const resetOrEmptySearchFilters = () => (Object.values(searchFilters).every(Boolean) ? emptySearchFilters() : resetSearchFilters())

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <p className='profile--titlebigfont'>{t('Search.Results')}</p>
          </Grid>
          <Grid item xs={12}>
            <OPNavbarSearch />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item className='griditem--search__checksmall'>
            <OPCheckBox
              label={Object.values(searchFilters).every(Boolean) ? t('Search.RemoveAll') : t('Search.ChooseAll')}
              value={Object.values(searchFilters).every(Boolean)}
              onChangeState={resetOrEmptySearchFilters}
            />
          </Grid>
          <Grid item>
            <OPCheckBox label={t('Skills.Header')} value={searchFilters.skills} onChangeState={() => toggleSearchFilter('skills')} />
          </Grid>
          <Grid item>
            <OPCheckBox label={t('Projects.Header')} value={searchFilters.projects} onChangeState={() => toggleSearchFilter('projects')} />
          </Grid>
          <Grid item>
            <OPCheckBox label={t('Persons.Header')} value={searchFilters.persons} onChangeState={() => toggleSearchFilter('persons')} />
          </Grid>
          <Grid item>
            <OPCheckBox
              label={t('CertAndEducation.CertificateHeader')}
              value={searchFilters.certifications}
              onChangeState={() => toggleSearchFilter('certifications')}
            />
          </Grid>
          <Grid item>
            <OPCheckBox
              label={t('CertAndEducation.EducationHeader')}
              value={searchFilters.educations}
              onChangeState={() => toggleSearchFilter('educations')}
            />
          </Grid>
          <Grid item>
            <OPCheckBox
              label={t('Language.Header')}
              value={searchFilters.languages}
              onChangeState={() => toggleSearchFilter('languages')}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          {searchFilters.skills && (
            <Grid item xs={12}>
              <SkillSearchAccordion search={searchParams} />
            </Grid>
          )}
          {searchFilters.projects && (
            <Grid item xs={12}>
              <ProjectSearchAccordion search={searchParams} />
            </Grid>
          )}
          {searchFilters.persons && (
            <Grid item xs={12}>
              <PersonSearchAccordion search={searchParams} />
            </Grid>
          )}
          {searchFilters.certifications && (
            <Grid item xs={12}>
              <CertSearchAccordion search={searchParams} />
            </Grid>
          )}
          {searchFilters.educations && (
            <Grid item xs={12}>
              <EduSearchAccordion search={searchParams} />
            </Grid>
          )}
          {searchFilters.languages && (
            <Grid item xs={12}>
              <LangSearchAccordion search={searchParams} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default observer(Search)
