import { CircularProgress } from '@mui/material'
import React from 'react'
import '../styles/loadingcircle.scss'

export const OPLoadingCircle = (): JSX.Element => {
  return (
    <div className='loadingcircle'>
      <CircularProgress />
    </div>
  )
}
