import { Buffer } from 'buffer'

import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx'
import moment from 'moment'
import agent from '../../api/agent'
import { IValidatedUser } from '../../interfaces/user/IProfile'
export default class LoginStore {
  constructor() {
    makeObservable(this, {
      validatedUser: observable,
      appLoaded: observable,
      user: observable,
      gdprConsent: observable,
      userPermission: observable,
      isManager: observable,
      isHr: observable,
      isSales: observable,
      isAdmin: observable,
      isPowerUser: observable,
      isPartner: observable,
      partnerIds: observable,
      selectedPartner: observable,
      userIsValidated: observable,
      showTermsModal: observable,
      userImage: observable,
      userProfileImage: observable,
      isLoggedIn: computed,
      setValidatedUserForUnitTests: action,
      validateUser: action,
      setSelectedPartner: action,
      getUserPhoto: action,
      getUserLargePhoto: action,
      updateUser: action,
      setShowTermsModal: action,
      acceptTerms: action,
      setAppLoading: observable,
    })

    reaction(
      () => this.validatedUser,
      (validatedUser) => {
        if (validatedUser) {
          window.sessionStorage.setItem('validatedUser', validatedUser.toString())
        } else {
          // TODO: Should this be sessionStorage?
          window.localStorage.removeItem('validatedUser')
        }
      },
    )
  }
  validatedUser = Number(sessionStorage.getItem('validatedUser')!)

  appLoaded = false
  user: any = undefined
  gdprConsent = true
  userPermission = false
  isManager = false
  isHr = false
  isSales = false
  isAdmin = false
  isPowerUser = false
  isPartner = false
  partnerIds: number[] = []
  selectedPartner: number | undefined = undefined
  userIsValidated = false
  showTermsModal = false
  userImage: string | null = localStorage.getItem(`${this.validatedUser}_image`)
  userProfileImage: string | null = localStorage.getItem(`${this.validatedUser}_profileimage`)
  get isLoggedIn() {
    return !!this.validatedUser
  }

  setValidatedUserForUnitTests = (userId: number) => {
    this.validatedUser = userId
  }

  validateUser = async (user: Record<string, unknown>) => {
    try {
      this.user = user
      const resp: IValidatedUser = await agent.HomePage.validateUser(user)
      runInAction(() => {
        if (resp.isManager || resp.isHr || resp.isSales) this.userPermission = true
        if (resp.isManager) this.isManager = true
        if (resp.isHr) this.isHr = true
        if (resp.isSales) this.isSales = true
        if (resp.isAdmin) this.isAdmin = true
        if (resp.isPartner) this.isPartner = true
        if (resp.isPowerUser) this.isPowerUser = true
        this.validatedUser = resp.userId
        this.gdprConsent = resp.gdprConsent
        this.userIsValidated = true
        this.partnerIds = resp.partnerIds
        if (resp.partnerIds.length === 1) {
          this.selectedPartner = resp.partnerIds[0]
        }
      })
    } catch (error) {
      return Promise.reject(error)
    }
    return Promise.resolve()
  }

  setSelectedPartner = (value: number) => {
    this.selectedPartner = value
  }

  getUserPhoto = async () => {
    let cacheImage = localStorage.getItem(`${this.validatedUser}_image`)
    if (cacheImage != null) {
      const expires = localStorage.getItem(`${this.validatedUser}_image_expire_time`)
      if (expires != null) {
        const now = moment()
        if (now.isSameOrAfter(moment(expires, 'YYYY-MM-DD'), 'days')) {
          cacheImage = null
          localStorage.removeItem(`${this.validatedUser}_image`)
          localStorage.removeItem(`${this.validatedUser}_image_expire_time`)
        }
      } else {
        cacheImage = null
      }
    }
    if (cacheImage == null) {
      try {
        const photo = await agent.HomePage.getUserPhoto(this.validatedUser)
        if (photo) {
          runInAction(() => {
            const base64Image = Buffer.from(photo, 'binary').toString('base64')
            this.userImage = base64Image
            localStorage.setItem(`${this.validatedUser}_image`, base64Image)
            localStorage.setItem(`${this.validatedUser}_image_expire_time`, moment().add(3, 'days').format('YYYY-MM-DD'))
          })
        }
      } catch (error) {
        return Promise.reject(error)
      }
    } else {
      this.userImage = cacheImage
    }
    return Promise.resolve()
  }

  getUserLargePhoto = async () => {
    let cacheImage = localStorage.getItem(`${this.validatedUser}_profileimage`)
    if (cacheImage != null) {
      const expires = localStorage.getItem(`${this.validatedUser}_profileimage_expire_time`)
      if (expires != null) {
        const now = moment()
        if (now.isSameOrAfter(moment(expires, 'YYYY-MM-DD'), 'days')) {
          cacheImage = null
          localStorage.removeItem(`${this.validatedUser}_profileimage`)
          localStorage.removeItem(`${this.validatedUser}_profileimage_expire_time`)
        }
      } else {
        cacheImage = null
      }
    }
    if (cacheImage == null) {
      try {
        const photo = await agent.HomePage.getUserLargePhoto(this.validatedUser)
        if (photo) {
          runInAction(() => {
            const base64Image = Buffer.from(photo, 'binary').toString('base64')
            this.userProfileImage = base64Image
            localStorage.setItem(`${this.validatedUser}_profileimage`, base64Image)
            localStorage.setItem(`${this.validatedUser}_profileimage_expire_time`, moment().add(3, 'days').format('YYYY-MM-DD'))
          })
        }
      } catch (error) {
        return Promise.reject(error)
      }
    } else {
      this.userProfileImage = cacheImage
    }
    return Promise.resolve()
  }

  updateUser = async () => {
    console.log('Updating Azure profile')
    try {
      await agent.HomePage.updateUser(this.validatedUser, this.user.email)
      console.log('Azure data up to date')
    } catch (error) {
      console.log('Could not update Azure profile')
      return Promise.reject(error)
    }
    return Promise.resolve()
  }

  setShowTermsModal = (bool = true) => {
    this.showTermsModal = bool
  }

  acceptTerms = async (userId: number) => {
    try {
      await agent.HomePage.acceptTerms(userId)
    } catch (error) {
      return Promise.reject(error)
    }
    runInAction(() => {
      this.gdprConsent = true
    })
    return Promise.resolve()
  }

  setAppLoading = () => {
    runInAction(() => {
      this.appLoaded = !this.appLoaded
    })
  }
}
