import { Backdrop, Divider, Grid, Paper } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IProjects } from '../../interfaces/projects/IProjects'
import { useStore } from '../../Provider'
import { MuiButtonVariants, OPButton } from '../OPcomponents/OPButton'
import '../Profile/myskills.scss'
import '../Profile/profile.scss'
import AddNewPrivateProject from '../Profile/workhistory/newprivproject/AddNewPrivateProject'
import NewProject from '../Profile/workhistory/newpubproject/NewProject'
import UserSkillPublic from '../Profile/workhistory/newpubproject/UserSkillPublic'
import ProjectHeader from '../Profile/workhistory/ProjectHeader'
import './project.scss'

const AddProject = (): JSX.Element => {
  const { t } = useTranslation()
  const rootStore = useStore()
  const [creatingNewProject, setCreatingNewProject] = useState(true)
  const [newProject, setNewProject] = useState<IProjects['project'] | undefined>(undefined)

  const { validatedUser } = rootStore.loginStore
  const {
    showAddProjectModal,
    showAddPrivateProjectModal,
    showAddPrivateProjectModalHandler,
    showAddProjectModalHandler,
    getAllEmployers,
  } = rootStore.projectStore

  useEffect(() => {
    getAllEmployers(validatedUser)
  }, [getAllEmployers, validatedUser])

  useEffect(() => {
    if (!showAddProjectModal) setCreatingNewProject(true)
  }, [showAddProjectModal])

  useEffect(() => {
    return () => {
      showAddProjectModalHandler(false)
      showAddPrivateProjectModalHandler(false)
    }
  }, [showAddProjectModalHandler, showAddPrivateProjectModalHandler])

  return (
    <Paper className='profile--paper__addformpadding'>
      {!showAddProjectModal && !showAddPrivateProjectModal && (
        <>
          <Grid item xs={12} className='griditem--formitem__titlemargin' textAlign='center'>
            <span className='profile--projecttext'>{t('Projects.AddProjectInfoSection1')} </span>
            <br />
            <span className='profile--projecttext'>{t('Projects.AddProjectInfoSection2')}</span>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} justifyContent='center'>
              <Grid item textAlign='center'>
                <OPButton
                  label={t('Projects.AddOtherProject')}
                  buttonVariant={MuiButtonVariants.secondary_outlined}
                  onClick={() => showAddPrivateProjectModalHandler(true)}
                />
              </Grid>
              <Grid item>
                <OPButton label={t('Projects.AddNetumProject')} onClick={() => showAddProjectModalHandler(true)} />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      {showAddProjectModal && (
        <>
          <Backdrop open invisible />
          <Grid item xs={12} className='projects--projectheader'>
            <span>{t('Projects.AddNewNetumProject')}</span>
          </Grid>
          {creatingNewProject ? (
            <Grid item xs={12}>
              <NewProject setNewProject={setNewProject} setCreatingNewProject={setCreatingNewProject} />
            </Grid>
          ) : (
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <ProjectHeader data={newProject} />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <UserSkillPublic projectId={newProject?.projectId} />
              </Grid>
            </Grid>
          )}
        </>
      )}
      {showAddPrivateProjectModal && (
        <>
          <Backdrop open invisible />
          <Grid item xs={12} className='projects--projectheader'>
            <span>{t('Projects.AddNewOtherProject')}</span>
          </Grid>
          <Grid item xs={12}>
            <AddNewPrivateProject isProjectSite={true} />
          </Grid>
        </>
      )}
    </Paper>
  )
}

export default observer(AddProject)
