import { createTheme } from '@mui/material'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    captionLight: React.CSSProperties
    labelLight: React.CSSProperties
    paraRegular: React.CSSProperties
    paraCaption: React.CSSProperties
    paraLabel: React.CSSProperties
    medH1: React.CSSProperties
    medH2: React.CSSProperties
    medH3: React.CSSProperties
    medH4: React.CSSProperties
    medPara: React.CSSProperties
    medCaption: React.CSSProperties
    medLabel: React.CSSProperties
    boldH1: React.CSSProperties
    boldH2: React.CSSProperties
    boldH3: React.CSSProperties
    boldH4: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    captionLight?: React.CSSProperties
    labelLight?: React.CSSProperties
    paraRegular?: React.CSSProperties
    paraCaption?: React.CSSProperties
    paraLabel?: React.CSSProperties
    medH1?: React.CSSProperties
    medH2?: React.CSSProperties
    medH3?: React.CSSProperties
    medH4?: React.CSSProperties
    medPara?: React.CSSProperties
    medCaption?: React.CSSProperties
    medLabel?: React.CSSProperties
    boldH1?: React.CSSProperties
    boldH2?: React.CSSProperties
    boldH3?: React.CSSProperties
    boldH4?: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    captionLight: true
    labelLight: true
    paraRegular: true
    paraCaption: true
    paraLabel: true
    medH1: true
    medH2: true
    medH3: true
    medH4: true
    medPara: true
    medCaption: true
    medLabel: true
    boldH1: true
    boldH2: true
    boldH3: true
    boldH4: true
  }
}

const fontTheme = createTheme({
  typography: {
    fontFamily: 'Fira Sans',
    captionLight: {
      fontSize: '0.8313rem',
      fontWeight: 300,
    },
    labelLight: {
      fontSize: '0.6944rem',
      fontWeight: 300,
    },
    paraRegular: {
      fontSize: '1rem',
      fontWeight: 400,
    },
    paraCaption: {
      fontSize: '0.8313rem',
      fontWeight: 400,
    },
    paraLabel: {
      fontSize: '0.6944rem',
      fontWeight: 400,
    },
    medH1: {
      fontSize: '2.075rem',
      fontWeight: 500,
    },
    medH2: {
      fontSize: '1.725rem',
      fontWeight: 500,
    },
    medH3: {
      fontSize: '1.4375rem',
      fontWeight: 500,
    },
    medH4: {
      fontSize: '1.2rem',
      fontWeight: 500,
    },
    medPara: {
      fontSize: '1rem',
      fontWeight: 500,
    },
    medCaption: {
      fontSize: '0.8313rem',
      fontWeight: 500,
    },
    medLabel: {
      fontSize: '0.6937rem',
      fontWeight: 500,
    },
    boldH1: {
      fontSize: '2.075rem',
      fontWeight: 700,
    },
    boldH2: {
      fontSize: '1.725rem',
      fontWeight: 700,
    },
    boldH3: {
      fontSize: '1.4375rem',
      fontWeight: 700,
    },
    boldH4: {
      fontSize: '1.2rem',
      fontWeight: 700,
    },
  },
})

export { fontTheme }
