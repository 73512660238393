import { Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../Provider'
import { MuiButtonVariants, OPButton } from '../OPcomponents/OPButton'
import { OPLoadingCircle } from '../OPcomponents/OPLoadingcircle'
import OPSearch from '../OPcomponents/OPSearch'
import { Toast } from '../OPcomponents/Toast'
import '../Profile/myskills.scss'
import '../Profile/profile.scss'
import AddSkill from './AddSkill'
import SkillsTable from './SkillsTable'

const Skills = (): JSX.Element => {
  const { t } = useTranslation()
  const rootStore = useStore()
  const { validatedUser } = rootStore.loginStore
  const {
    getAllSkills,
    loadingSkills,
    showModal,
    showModalHandler,
    setSearchValue,
    setIsSearching,
    isSearching,
    showEditSkillModalHandler,
    clearEditCurrent,
  } = rootStore.skillStore

  useEffect(() => {
    return () => {
      setSearchValue('')
      setIsSearching(false)
      clearEditCurrent()
      showEditSkillModalHandler(false)
      showModalHandler(false)
    }
  }, [clearEditCurrent, setIsSearching, setSearchValue, showEditSkillModalHandler, showModalHandler])

  useEffect(() => {
    getAllSkills(validatedUser)
  }, [getAllSkills, validatedUser])

  return (
    <Grid container rowSpacing={4}>
      <Toast />
      <Grid item xs={12}>
        <p className='profile--titlebigfont'>{t('Navbar.Skills')}</p>
      </Grid>
      <Grid item xs={12} marginTop={'-1rem'}>
        <Grid container direction='row' justifyContent='space-between' alignItems='center'>
          <Grid item>
            <OPSearch
              placeholder={t('Skills.FindSkill')}
              onSearchChange={(val) => {
                setSearchValue(val)
                if (!isSearching) setIsSearching(true)
              }}
              resetValue={() => {
                if (isSearching) setIsSearching(false)
                setSearchValue('')
              }}
            />
          </Grid>
          <Grid item>
            <OPButton
              label={t('Skills.AddNewSkill')}
              disabled={showModal}
              onClick={showModalHandler}
              buttonVariant={MuiButtonVariants.primary_outlined}
            />
          </Grid>
        </Grid>
      </Grid>

      {showModal && (
        <Grid item xs={12}>
          <AddSkill />
        </Grid>
      )}
      {loadingSkills ? (
        <Grid item xs={12}>
          <OPLoadingCircle />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <SkillsTable />
        </Grid>
      )}
    </Grid>
  )
}

export default observer(Skills)
