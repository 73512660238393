import Button from '@mui/material/Button'
import React from 'react'

export enum MuiButtonVariants {
  primary = 'primary',
  primary_outlined = 'primary_outlined',
  secondary = 'secondary',
  secondary_outlined = 'secondary_outlined',
  error = 'error',
  error_outlined = 'error_outlined',
  contained = 'contained',
  outlined = 'outlined',
  interested = 'interested',
}

interface OPButtonProps {
  label: string
  className?: string
  onClick?: any
  ariaLabel?: string
  buttonVariant?: MuiButtonVariants
  size?: 'small' | 'large'
  type?: 'button' | 'submit' | 'reset'
  disabled?: boolean
  startIcon?: JSX.Element
  endIcon?: JSX.Element
  dataTestId?: string
  tabIndex?: number
  form?: string
  hoverEvent?: (val: boolean) => void
}

export const OPButton = (props: OPButtonProps): JSX.Element => {
  const {
    hoverEvent,
    label,
    onClick,
    ariaLabel,
    buttonVariant,
    size,
    type,
    disabled,
    startIcon,
    endIcon,
    dataTestId,
    tabIndex,
    form,
    className,
  } = props
  return (
    <Button
      disableElevation
      className={className}
      variant={buttonVariant ? buttonVariant : MuiButtonVariants.primary}
      disabled={disabled}
      aria-label={ariaLabel}
      type={type ? type : 'button'}
      onClick={onClick}
      size={size ? size : 'small'}
      onMouseEnter={() => hoverEvent?.(true)}
      onMouseLeave={() => hoverEvent?.(false)}
      startIcon={startIcon}
      endIcon={endIcon}
      data-testid={dataTestId}
      tabIndex={tabIndex ?? 0}
      form={form}
    >
      {label}
    </Button>
  )
}
