import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack5'
import gdprfile from '../../assets/documents/Netum_Osaamispankki_tietosuojaseloste_sis_v2.1_2022.pdf'
import { useStore } from '../../Provider'
import '../styles/privacypolicy.scss'

export const PrivacyPolicyPage = observer((): JSX.Element => {
  const url = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`
  pdfjs.GlobalWorkerOptions.workerSrc = url
  const rootStore = useStore()
  const [numPages, setNumPages] = useState<null | number>(null)
  return (
    <div className='privacypolicy__page'>
      <div className='privacypolicy__page__content'>
        <Document
          file={gdprfile}
          onLoadSuccess={({ numPages }) => {
            setNumPages(numPages)
          }}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              scale={96 / 72}
              width={750}
              renderTextLayer={false}
              renderAnnotationLayer={false}
            />
          ))}
        </Document>
      </div>
    </div>
  )
})
