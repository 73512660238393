import { Jodit } from 'jodit'
import JoditEditor from 'jodit-react'
import { observer } from 'mobx-react-lite'
import React, { useMemo } from 'react'

interface EditorProps {
  setValue: (value: string) => void
  value: string
  placeholderString: string
  charLimit: number
}

const TextEditor = (props: EditorProps): JSX.Element => {
  const { setValue, value, placeholderString, charLimit } = props

  const config = {
    ...Jodit.defaultOptions,
    buttons: 'bold,italic,underline,ul,ol,undo,redo',
    buttonsMD: 'bold,italic,underline,ul,ol,undo,redo',
    buttonsSM: 'bold,italic,underline,ul,ol,undo,redo',
    buttonsXS: 'bold,italic,underline,ul,ol,undo,redo',
    placeholder: placeholderString,
    limitChars: charLimit,
    limitHTML: true as false,
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    spellcheck: false,
    countHTMLChars: true,
    beautifyHTML: true,
    cleanHTML: {
      ...Jodit.defaultOptions.cleanHTML,
      replaceNBSP: true,
      removeEmptyElements: true,
    },
  }

  return useMemo(
    () => (
      <JoditEditor
        value={value}
        onChange={(editedValue) => {
          setValue(editedValue)
        }}
        config={config}
      />
    ),
    [],
  )
}

export default observer(TextEditor)
