import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ISkillFormPackage, ISkills } from '../../interfaces/skills/ISkills'
import { useStore } from '../../Provider'
import { OPLoadMore } from '../OPcomponents/OPLoadMore'
import { Data, OPNewTable } from '../OPcomponents/OPNewTable'
import OpenSkill from './OpenSkill'

const SkillsTable = (): JSX.Element => {
  const rootStore = useStore()
  const { t } = useTranslation()
  const { filteredSkills, allSkills, getUserDetailedSkillLinkedProjects, getAllSkills } = rootStore.skillStore
  const { getAllUserSkills } = rootStore.personStore
  const { getSkillPersons } = rootStore.seachStore
  const { validatedUser } = rootStore.loginStore
  const [tableLength, setTableLength] = useState(10)

  const [tableRows, setTableRows] = useState<Data[]>([])

  const openSkillRow = async (id: number | string) => {
    const curClicked = allSkills.find((skill) => skill.skill.skillId === Number(id))
    const userSkill = await getUserDetailedSkillLinkedProjects(validatedUser, Number(id))
    const skillPersons = await getSkillPersons(Number(id))

    if (curClicked?.skill) {
      const skillInfo: ISkillFormPackage = {
        skill: { skill: curClicked.skill, userInterested: curClicked.userInterested },
        userSkill: userSkill.skill,
        isNew: userSkill.isNew,
        skillPersons: skillPersons,
      }
      return skillInfo
    }
  }

  const onRowClose = () => {
    Promise.allSettled([getAllSkills(validatedUser, false), getAllUserSkills(validatedUser, false)])
  }

  useEffect(() => {
    const newRows: Data[] = []
    filteredSkills.forEach((skill: ISkills) => {
      newRows.push({
        ['id']: skill.skill.skillId,
        rows: {
          [t('Skills.SkillName')]: { text: skill.skill.skillName ?? '', width: '30%' },
          [t('Skills.Description')]: { text: skill.skill.skillDescription ?? '', width: '30%' },
          [t('Skills.Category')]: { text: skill.skill.categoryName ?? '', width: '30%' },
          [t('Skills.Experts')]: { text: skill.skill.osaajat ?? 0, width: '10%' },
        },
      })
      setTableRows(newRows)
    })
  }, [t, filteredSkills])
  return filteredSkills.length ? (
    <>
      <OPNewTable rows={tableRows} curLength={tableLength} onRowClose={onRowClose} FormBase={OpenSkill} onRowClick={openSkillRow} />
      <OPLoadMore
        stepper={10}
        filteredArray={filteredSkills}
        onLoadMore={(data) => setTableLength(data)}
        curLength={tableLength}
        maxLength={filteredSkills.length}
      />
    </>
  ) : (
    <span className='profile--notfound'>{t('NotFound.NoResults')}</span>
  )
}

export default observer(SkillsTable)
