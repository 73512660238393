import { observer } from 'mobx-react-lite'
import React from 'react'
import { useTranslation } from 'react-i18next'
import history from '../../history'
import { BackButtonArrow, Logo } from '../styles/Icons'
import '../styles/notfoundpage.scss'
import { MuiButtonVariants, OPButton } from './OPButton'

export const NotFoundPage = observer((): JSX.Element => {
  const { t } = useTranslation()

  return (
    <div>
      <div className='notfound__content'>
        <div className='notfound__content__background'>
          <Logo />
        </div>
        <div className='notfound__content--alignment'>
          <div className='notfound__content__title'>404</div>
          <div className='notfound__content__description'>{t('NotFound.NotFoundText')}</div>
          <div className='notfound__content__buttons'>
            <div>
              <OPButton
                label={t('Buttons.ToHomepage')}
                buttonVariant={MuiButtonVariants.secondary_outlined}
                onClick={() => history.push('/')}
              />
            </div>
            {history.length > 2 && (
              <div className='notfound__content__buttons--backbutton'>
                <OPButton label={t('Buttons.Back')} onClick={() => history.goBack()} startIcon={<BackButtonArrow />} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
})
