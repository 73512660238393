import { PublicClientApplication } from '@azure/msal-browser'
import jwtDecode from 'jwt-decode'

export const msalConfig = {
  auth: {
    clientId: (process.env.REACT_APP_AAD_CLIENTID ?? '8453641f-4a65-4bfd-98e8-022bdd295209') as string, // Random guid for tests
    authority: process.env.REACT_APP_AAD_TENANT,
    redirectUri: window.location.origin,
  },
  endpoints: {
    api: process.env.REACT_APP_AAD_API_ENDPOINT as string,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
}
export const msalInstance = new PublicClientApplication(msalConfig)

export const loginRequest = {
  scopes: [process.env.REACT_APP_AAD_CLIENTID + '/.default'],
}

export const getToken = async () => {
  const account = msalInstance.getActiveAccount()
  if (!account) {
    throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.')
  }

  const response = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account: account,
  })
  return response.accessToken
}

export const getUser = async () => {
  const token = await getToken()
  const decodedUser = jwtDecode<any>(token)
  if (decodedUser.appid === process.env.REACT_APP_AAD_CLIENTID) {
    return {
      lastName: decodedUser.family_name,
      firstName: decodedUser.given_name,
      email: decodedUser.unique_name,
      name: decodedUser.name,
    }
  }
  return {}
}
