import { Grid } from '@mui/material'
import DOMPurify from 'dompurify'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { IPersonCertificate, IPersonEducation } from '../../../interfaces/certAndEducation/certAndEducation'
import { useStore } from '../../../Provider'
import { OPAccordionItem } from '../../Accordion/OPAccordionItem'
import { OPLoadingCircle } from '../../OPcomponents/OPLoadingcircle'
import { OPNewTable } from '../../OPcomponents/OPNewTable'
import Publications from '../../Profile/certedu/Publications'
import { HighestDegree } from '../../styles/Icons'

import ProfileCert from './ProfileCertEduPub/ProfileCert'
import ProfileEdu from './ProfileCertEduPub/ProfileEdu'

interface IProps {
  userId: string
}

const ProfileCertEduPub = (props: IProps): JSX.Element => {
  const { userId } = props
  const { t, i18n } = useTranslation()
  const lang = i18n.language
  const rootStore = useStore()
  const {
    getPersonCertificates,
    getPersonEducations,
    getPersonPublications,
    loadingCertificates,
    loadingPublications,
    loadingEducations,
    personCertificates,
    personEducations,
    personPublications,
  } = rootStore.profileStore

  const certRows = personCertificates.map((cert: IPersonCertificate) => {
    return {
      ['id']: cert.sertificateId ?? -1,
      rows: {
        [t('CertAndEducation.CertificateName')]: { text: cert.name ?? '', width: '38%' },
        [t('CertAndEducation.Granter')]: { text: cert.school ?? '', width: '38%' },
        [t('CertAndEducation.CertificateGrantDate')]: {
          text: cert.startDate != null ? moment(cert.startDate).locale(lang).format('L') : '',
          width: '12%',
        },
        [t('CertAndEducation.CertificateExpirationDate')]: {
          text: cert.expirationDate != null ? moment(cert.expirationDate).locale(lang).format('L') : '',
          width: '12%',
        },
      },
    }
  })

  const eduRows = personEducations.map((edu: IPersonEducation) => {
    return {
      ['id']: edu.educationId ?? -1,
      rows: {
        [t('CertAndEducation.EducationName')]: {
          text: (
            <Grid container columnGap={1} wrap='nowrap'>
              {edu.isHighestDegree && (
                <Grid item marginLeft='-0.3125rem'>
                  <HighestDegree />
                </Grid>
              )}
              <Grid display='flex' alignItems='center' item>
                {edu?.degree ?? ''}
              </Grid>
            </Grid>
          ),
          width: '41%',
        },
        [t('CertAndEducation.EducationSchool')]: { text: edu.school ?? '', width: '41%' },
        [t('CertAndEducation.EducationStartDate')]: {
          text: edu.startDate != null ? moment(edu.startDate).locale(lang).format('L') : '',
          width: '8%',
        },
        [t('CertAndEducation.EducationEndDate')]: {
          text: edu.endDate != null ? moment(edu.endDate).locale(lang).format('L') : '',
          width: '10%',
        },
      },
    }
  })

  const onCertClick = (id: number | string) => personCertificates.find((cert: IPersonCertificate) => cert.sertificateId === id)
  const onEduClick = (id: number | string) => personEducations.find((edu: IPersonEducation) => edu.educationId === id)

  useEffect(() => {
    async function fetchData() {
      await Promise.allSettled([
        getPersonCertificates(Number(userId)),
        getPersonEducations(Number(userId)),
        getPersonPublications(Number(userId)),
      ])
    }
    fetchData()
  }, [getPersonCertificates, getPersonEducations, getPersonPublications, userId])

  const cleanedPublication = DOMPurify.sanitize(personPublications, {
    ALLOWED_TAGS: ['b', 'i', 'u', 'ul', 'li', 'p', 'strong', 'br', 'em', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
  })

  return (
    <Grid container spacing={5} className='gridcontainer--formcontainer__addnewpaddings'>
      {loadingCertificates || loadingEducations || loadingPublications ? (
        <Grid item xs={12}>
          <OPLoadingCircle />
        </Grid>
      ) : (
        <>
          <Grid item xs={12}>
            <OPAccordionItem
              title={`${t('CertAndEducation.CertificateHeader')} (${loadingCertificates ? '\u2026' : personCertificates.length})`}
              content={
                <Grid container className='gridcontainer--formcontainer__addnewpaddings'>
                  <Grid item xs={12}>
                    {personCertificates.length > 0 ? (
                      <OPNewTable rows={certRows} curLength={certRows.length} onRowClick={onCertClick} FormBase={ProfileCert} />
                    ) : (
                      <p className='profile--deptext profpadding'>{t('Profile.NoAddedCertificates')}</p>
                    )}
                  </Grid>
                </Grid>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <OPAccordionItem
              title={`${t('CertAndEducation.EducationHeader')} (${loadingEducations ? '\u2026' : personEducations.length})`}
              content={
                <Grid container className='gridcontainer--formcontainer__addnewpaddings'>
                  <Grid item xs={12}>
                    {personEducations.length > 0 ? (
                      <OPNewTable rows={eduRows} curLength={eduRows.length} onRowClick={onEduClick} FormBase={ProfileEdu} />
                    ) : (
                      <p className='profile--deptext profpadding'>{t('Profile.NoAddedEducations')}</p>
                    )}
                  </Grid>
                </Grid>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Publications isOpen={false} />
          </Grid>
        </>
      )}
    </Grid>
  )
}
export default observer(ProfileCertEduPub)
