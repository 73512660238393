import { Grid, Paper } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import history from '../../../history'
import { ISkillFormPackage } from '../../../interfaces/skills/ISkills'
import { useStore } from '../../../Provider'
import { OPLoadingCircle } from '../../OPcomponents/OPLoadingcircle'
import { Data, OPNewTable } from '../../OPcomponents/OPNewTable'
import { OPSkillbar } from '../../OPcomponents/OPSkillbar'
import OpenSkill from '../../Skills/OpenSkill'

const SkillTable = (): JSX.Element => {
  const { t } = useTranslation()
  const rootStore = useStore()

  const { validatedUser } = rootStore.loginStore
  const { filteredSkills, loadingSkills, getAllUserSkills } = rootStore.personStore
  const { setCurrentskill, allSkills, getUserDetailedSkillLinkedProjects, showEditSkillModalHandler, showNewSkillModal, getAllSkills } =
    rootStore.skillStore
  const { getSkillPersons } = rootStore.seachStore
  const loadingAllSkills = rootStore.skillStore.loadingSkills

  const profileClickedId = history.location.state as string

  const openSkillRow = async (id: number | string) => {
    const curClicked = allSkills.find((skill) => skill.skill.skillId === Number(id))
    const userSkill = await getUserDetailedSkillLinkedProjects(validatedUser, Number(id))
    const skillPersons = await getSkillPersons(Number(id))

    if (curClicked?.skill) {
      const skillInfo: ISkillFormPackage = {
        skill: { skill: curClicked.skill, userInterested: curClicked.userInterested },
        userSkill: userSkill.skill,
        isNew: userSkill.isNew,
        skillPersons: skillPersons,
      }
      return skillInfo
    }
  }

  const onRowClose = () => {
    Promise.allSettled([getAllSkills(validatedUser, false), getAllUserSkills(validatedUser, false)])
  }

  useEffect(() => {
    return () => {
      showEditSkillModalHandler(false)
      setCurrentskill({})
    }
  })

  const rows: Data[] = []

  filteredSkills.forEach((skill) =>
    rows.push({
      id: skill.skillId,
      rows: {
        [t('Skills.SkillName')]: { text: skill.skillName, width: '34%' },
        [t('Skills.Level')]: { text: <OPSkillbar name={skill.skillName} value={skill.skillLevel} readonly />, width: '21%' },
        [t('Skills.Expirience')]: { text: `${skill.experienceDays} ${t('Date.ExperienceDays')} `, width: '11%' },
        [t('Skills.Projects')]: { text: skill.linkedProjectNames?.join(', '), width: '34%' },
      },
    }),
  )

  return (
    <>
      {loadingSkills || loadingAllSkills ? (
        <Paper className='profile--paper__loadingskills'>
          <OPLoadingCircle />
        </Paper>
      ) : rows.length === 0 ? (
        <>
          {!showNewSkillModal && (
            <Paper className='profile--paper__addtitlepadding'>
              <Grid container textAlign='center'>
                <Grid item xs={12}>
                  <p className='profile--deptext'>{t('Skills.SkillsPlaceholder')}</p>
                </Grid>
              </Grid>
            </Paper>
          )}
        </>
      ) : (
        <OPNewTable
          profileclickedId={profileClickedId}
          rows={rows}
          curLength={rows.length}
          onRowClose={onRowClose}
          FormBase={OpenSkill}
          onRowClick={openSkillRow}
        />
      )}
    </>
  )
}

export default observer(SkillTable)
