import { FormGroup, Grid, useMediaQuery } from '@mui/material'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { toastNeg } from '../../../common/toastHelper'
import { IPersonCVInfo } from '../../../interfaces/persons/IPerson'
import { useStore } from '../../../Provider'
import { theme } from '../../../theme/CustomTheme'
import { MuiButtonVariants, OPButton } from '../../OPcomponents/OPButton'
import { OPCheckBox } from '../../OPcomponents/OPCheckBox'
import { OPLoadingCircle } from '../../OPcomponents/OPLoadingcircle'
import { OPRadioButton } from '../../OPcomponents/OPRadioButton'
import { OPSwitch } from '../../OPcomponents/OPSwitch'
import OPTooltip from '../../OPcomponents/OPTooltip'
import DetailsSection from './components/DetailsSection'
import PersonalSection from './components/PersonalSection'
import './CV.scss'

interface IParams {
  userId: string
}

const CVOverview = (): JSX.Element => {
  const { t } = useTranslation()

  const rootStore = useStore()
  const { validatedUser } = rootStore.loginStore
  const { getCVInfo, PersonCVInfo, loadingCVInfo, getCV, loadingCV, resetCVInfo } = rootStore.personStore
  const selectedCVElements: boolean[] = []

  const [manDays, setManDays] = useState('1')
  const [manDaysBool, setManDaysBool] = useState(true)

  const { userId } = useParams<IParams>()

  const [user, setUser] = useState(0)

  const shouldWrap = useMediaQuery(theme.breakpoints.down('cvwrap'))

  const [state, setState] = useState({
    workExperience: true,
    education: true,
    certificates: true,
    skills: true,
    languages: true,
    picture: true,
    personalData: true,
  })

  useEffect(() => {
    return () => {
      resetCVInfo()
    }
  }, [])

  const { workExperience, education, certificates, skills, languages, picture, personalData } = state

  useEffect(() => {
    if (userId !== undefined) {
      getCVInfo(Number(userId))
      setUser(Number(userId))
    } else {
      getCVInfo(validatedUser)
      setUser(validatedUser)
    }
  }, [getCVInfo, validatedUser, userId])

  useEffect(() => {
    if (!loadingCVInfo) setState((curState) => ({ ...curState, picture: PersonCVInfo.personDetails?.largePhoto !== null ? true : false }))
  }, [PersonCVInfo.personDetails?.largePhoto, loadingCVInfo])

  let netumAloitus = ''
  const currentEmployerName = ''
  if (!loadingCVInfo && PersonCVInfo.projects !== undefined) {
    netumAloitus =
      'Netum Oy, ' +
      moment(
        PersonCVInfo.projects
          .filter((x) => x.projectOwner === 0)
          .map((x) => x.startDate)
          .sort((a: Date, b: Date) => {
            if (a > b) {
              return 1
            }

            if (a < b) {
              return -1
            }

            return 0
          })
          .shift() || null,
      ).format('M/YYYY') +
      ' - jatkuu'
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
      [event.target.value]: event.target.checked,
    })
  }

  const radioOptions = [
    {
      value: '1',
      label: t('Date.ExperienceDays'),
    },
    {
      value: '0',
      label: t('Date.Month'),
    },
  ]

  return (
    <>
      {!loadingCVInfo && PersonCVInfo.personDetails !== undefined ? (
        <Grid container rowSpacing={4}>
          <Grid item xs={12}>
            <Grid container justifyContent={'space-between'}>
              <Grid>
                <span className='profile--titlebigfont'>{'CV'}</span>
              </Grid>
              <Grid>
                <OPButton
                  disabled={loadingCV}
                  buttonVariant={MuiButtonVariants.primary_outlined}
                  onClick={() => {
                    selectedCVElements.push(personalData, picture, workExperience, education, certificates, skills, languages, manDaysBool)
                    getCV(user, PersonCVInfo?.personDetails?.name ?? 'CV', selectedCVElements, 'fi').catch(() => {
                      toastNeg(t('Toasts.CVError'))
                    })
                  }}
                  label={t('CVTemplates.DownloadDocx')}
                />
              </Grid>
              <hr className='profile--form__editdivider' />
            </Grid>
            <Grid wrap={shouldWrap ? 'wrap' : 'nowrap'} container className='topPadding'>
              <Grid item width={shouldWrap ? '100%' : '13.5rem'} height={'fit-content'} container rowGap={shouldWrap ? 2 : 4}>
                <Grid item xs={12} width={shouldWrap ? '100%' : '13.5rem'} container>
                  <Grid item xs={shouldWrap ? 4 : 12} className='cvCheckbox cellRow'>
                    <OPCheckBox
                      textStyle='cvCheckbox cellLabel'
                      name='personalData'
                      label={t('CVTemplates.PersonalInformation')}
                      value={personalData}
                      onChangeState={(v, c) => handleChange(v)}
                    />
                  </Grid>
                  <Grid item xs={shouldWrap ? 4 : 12} className='cvCheckbox cellRow'>
                    <OPCheckBox
                      textStyle='cvCheckbox cellLabel'
                      name='workExperience'
                      label={t('CVTemplates.WorkExperience')}
                      value={workExperience}
                      onChangeState={(v, c) => handleChange(v)}
                    />
                  </Grid>
                  <Grid item xs={shouldWrap ? 4 : 12} className='cvCheckbox cellRow'>
                    <OPCheckBox
                      textStyle='cvCheckbox cellLabel'
                      name='education'
                      label={t('CertAndEducation.EducationHeader')}
                      value={education}
                      onChangeState={(v, c) => handleChange(v)}
                    />
                  </Grid>
                  <Grid item xs={shouldWrap ? 4 : 12} className='cvCheckbox cellRow'>
                    <OPCheckBox
                      textStyle='cvCheckbox cellLabel'
                      name='certificates'
                      label={t('CertAndEducation.CertificateHeader')}
                      value={certificates}
                      onChangeState={(v, c) => handleChange(v)}
                    />
                  </Grid>
                  <Grid item xs={shouldWrap ? 4 : 12} className='cvCheckbox cellRow'>
                    <OPCheckBox
                      textStyle='cvCheckbox cellLabel'
                      name='skills'
                      label={t('CVTemplates.Skills')}
                      value={skills}
                      onChangeState={(v, c) => handleChange(v)}
                    />
                  </Grid>
                  <Grid item xs={shouldWrap ? 4 : 12} className='cvCheckbox cellRow'>
                    <OPCheckBox
                      textStyle='cvCheckbox cellLabel'
                      name='languages'
                      label={t('CVTemplates.Language')}
                      value={languages}
                      onChangeState={(v, c) => handleChange(v)}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={shouldWrap ? 4 : 12} paddingBottom={shouldWrap ? '1rem' : 0}>
                  <OPRadioButton
                    fontStyle='cvRadio radioFont'
                    className='cvRadio radioPadding'
                    name='usingManDays'
                    radioOptions={radioOptions}
                    label={t('CVTemplates.DefiningWorkExperience')}
                    onChange={async (v: React.ChangeEvent<HTMLInputElement>) => {
                      setManDays(v.target.value)

                      if (v.target.value === '1') {
                        setManDaysBool(true)
                      } else {
                        setManDaysBool(false)
                      }
                    }}
                    defaultVal={'1'}
                  />
                </Grid>
                <Grid item>
                  <OPTooltip
                    alwaysVisible={PersonCVInfo.personDetails?.largePhoto === null ? true : false}
                    title={t('CVTemplates.NoProfilePicutre')}
                  >
                    <FormGroup className='cvRadio radioPadding notop'>
                      <OPSwitch
                        disabled={PersonCVInfo.personDetails?.largePhoto === null ? true : false}
                        value='picture'
                        label={t('CVTemplates.ShowPicture')}
                        isChecked={picture}
                        onChange={(e) => handleChange(e)}
                      />
                    </FormGroup>
                  </OPTooltip>
                </Grid>
              </Grid>
              <Grid item container marginLeft={shouldWrap ? 0 : '-13.5rem'} justifyContent={'center'}>
                {user === PersonCVInfo.personDetails.personId ? (
                  <>
                    <Grid item className='cvBase cvSidebar'>
                      <PersonalSection
                        personalSection={PersonCVInfo as IPersonCVInfo}
                        pictureVisibility={picture}
                        personalDataVisibility={personalData}
                      />
                      <DetailsSection
                        detailsData={PersonCVInfo as IPersonCVInfo}
                        netumAloitus={netumAloitus}
                        workExperienceVisibility={workExperience}
                        educationVisibility={education}
                        certificatesVisibility={certificates}
                        skillsVisibility={skills}
                        languageVisibility={languages}
                        manDaysVisibility={manDays}
                        currentEmployerName={currentEmployerName}
                      />
                    </Grid>
                    <Grid item className='cvfooter' />
                  </>
                ) : (
                  <OPLoadingCircle />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <OPLoadingCircle />
      )}
    </>
  )
}

export default observer(CVOverview)
