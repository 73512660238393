import { Divider, Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { IProjectFormPackage } from '../../../../interfaces/projects/IProjects'
import { ITableForm } from '../../../OPcomponents/OPNewTable'
import ProjectHeader from '../ProjectHeader'
import UserSkillEdit from './UserSkillEdit'

const EditPubProject = (props: ITableForm): JSX.Element => {
  const { collapseHandler } = props
  const data = props.data as IProjectFormPackage

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ProjectHeader data={data?.project} />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <UserSkillEdit data={data} collapseHandler={collapseHandler} />
      </Grid>
    </Grid>
  )
}

export default observer(EditPubProject)
