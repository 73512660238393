import { Grid } from '@mui/material'
import { Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import { default as React, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getErrMsg, toastNeg, toastPos } from '../../../../common/toastHelper'
import {
  IDropdownProjects,
  IEditUserProject,
  ILinkedSkill,
  IProjectFormPackage,
  IUserProject,
} from '../../../../interfaces/projects/IProjects'
import { ISkillProject } from '../../../../interfaces/skills/ISkills'
import { useStore } from '../../../../Provider'
import DeleteConfirmation from '../../../OPcomponents/DeleteConfirmation'
import { ExitConfirmation } from '../../../OPcomponents/ExitConfirmation'
import AutoSave from '../../../OPcomponents/FormikAutosave'
import { ITableForm } from '../../../OPcomponents/OPNewTable'
import { newPersonalProjectSchema } from '../../../OPcomponents/ValidationSchemas'
import { OPSkillsButtons } from '../../OPSkillsButtons'
import UserSkillForm from './UserSkillForm'

const UserSkillEdit = (props: ITableForm): JSX.Element => {
  const { collapseHandler } = props
  const data = props.data as IProjectFormPackage
  const rootStore = useStore()
  const { t } = useTranslation()
  const { validatedUser } = rootStore.loginStore
  const { setCurrentProject, getAllRoles, deleteUserProject, editUserProject, getAllProjects } = rootStore.projectStore

  const { getUserPublicWorkHistory, getUserPrivateWorkHistory } = rootStore.personStore

  const [linkedSkills, setLinkedSkills] = useState<ISkillProject[]>([])

  const [deleteProjectConfirmation, setDeleteProjectConfirmation] = useState(false)

  useEffect(() => {
    getAllRoles()
  }, [getAllRoles, validatedUser])

  const editOldInit: IUserProject = {
    userId: validatedUser,
    projectId: data?.userDetails.details.projectId,
    startDate: data?.userDetails.details.startDate,
    endDate: data?.userDetails.details.endDate,
    manDays: data?.userDetails.details.manDays ?? 0,
    quota: data?.userDetails.details.quota ?? 0,
    usingManDays: data?.userDetails.details.usingManDays ? true : false,
    description: data?.userDetails.details.description ?? '',
    roleIds:
      data?.userDetails.details.roles.map((role) => ({ value: role.roleId.toString(), key: role.roleId, text: role.roleName })) ?? [],
  }

  useEffect(() => {
    const links: ISkillProject[] = []

    if (data?.userDetails.details.linkedSkills) {
      data?.userDetails.details.linkedSkills.forEach((skill: ILinkedSkill) => {
        links.push({
          userId: validatedUser,
          projectId: data?.userDetails.details.projectId,
          skillId: skill.skillId,
          text: skill.skillName,
        })
      })
    }
    setLinkedSkills(links)
  }, [data?.userDetails.details.linkedSkills, data?.userDetails.details.projectId, validatedUser])

  const handleClose = async (isDelete = false) => {
    collapseHandler?.()
    setTimeout(async () => {
      await getUserPublicWorkHistory(validatedUser, false)
      await getUserPrivateWorkHistory(validatedUser, false)
      setCurrentProject({})
      if (isDelete) await getAllProjects(validatedUser, false)
    }, 500)
  }

  const deleteProject = async (id: number) => {
    try {
      await deleteUserProject(validatedUser, id, true)
      toastPos(t('Toasts.ProjectUserDelete'), true)
    } catch (error) {
      toastNeg(getErrMsg(error, t) || t('Toasts.ProjectUserDeleteError'), true)
    } finally {
      handleClose(true)
    }
  }
  const submitEdit = async (values: IUserProject) => {
    const roles = values.roleIds.map((role: IDropdownProjects) => role.key)
    const workLoad = values.usingManDays ? values.manDays : values.quota

    const editedProject: IEditUserProject = {
      userId: validatedUser,
      projectId: values.projectId,
      startDate: values.startDate,
      endDate: values.endDate,
      workAmount: workLoad,
      usingManDays: values.usingManDays,
      description: values.description,
      roleIds: roles || [],
    }

    try {
      await editUserProject(editedProject, true)
    } catch (error) {
      toastNeg(getErrMsg(error, t) || t('Toasts.ProjectUserEditError'), true)
    }
  }

  useEffect(() => {
    return () => {
      handleClose()
    }
  }, [])

  return (
    <Grid item xs={12}>
      <Formik
        initialValues={editOldInit}
        onSubmit={(values) => submitEdit(values)}
        validationSchema={newPersonalProjectSchema}
        enableReinitialize
      >
        {({ values, handleSubmit, isValid, dirty, isSubmitting }) => (
          <Grid container rowSpacing={4} columnSpacing={2} alignItems='center'>
            <ExitConfirmation when={dirty} />
            <Grid xs={12} item>
              <UserSkillForm
                isTable
                deleteProjectConfirmation={deleteProjectConfirmation}
                setProjectSkills={setLinkedSkills}
                projectSkills={linkedSkills}
              />
            </Grid>
            <Grid xs={12} item>
              <OPSkillsButtons
                isDelete
                onDelete={() => setDeleteProjectConfirmation(true)}
                onClose={handleClose}
                bothDisabled={isSubmitting || deleteProjectConfirmation}
                onSubmit={handleSubmit}
                disabled={!isValid || isSubmitting}
                onAutosave={data?.userHas}
              />
            </Grid>
            <Grid item />
            <DeleteConfirmation
              open={deleteProjectConfirmation}
              whatToDelete='Project'
              deleteHandler={() => deleteProject(values.projectId)}
              handleClose={() => setDeleteProjectConfirmation(false)}
            />
            <AutoSave />
          </Grid>
        )}
      </Formik>
    </Grid>
  )
}

export default observer(UserSkillEdit)
