import { Box, ThemeProvider } from '@mui/material'
import { observer } from 'mobx-react-lite'
import 'moment/locale/en-gb'
import 'moment/locale/fi'
import React from 'react'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'
import { CompatRouter } from 'react-router-dom-v5-compat'
import { ToastContainer } from 'react-toastify'
import { theme } from '../src/theme/CustomTheme'
import './App.scss'
import CustomLoader from './common/CustomLoader'
import { DevelopmentPage } from './components/Development/DevelopmentPage'
import Help from './components/Help/Help'
import CVOverview from './components/MySkills/CV/CVOverview'
import { GDPR } from './components/OPcomponents/GDPR'
import { HrPage } from './components/OPcomponents/HrPage'
import { ManagerPage } from './components/OPcomponents/ManagerPage'
import { NotFoundPage } from './components/OPcomponents/NotFoundPage'
import OPFooter from './components/OPcomponents/OPFooter'
import OPNavbar from './components/OPcomponents/OPNavbar'
import { PrivacyPolicyPage } from './components/OPcomponents/PrivacyPolicyPage'
import Partners2 from './components/Partners/Partners2'
import Persons from './components/Persons/Persons'
import PersonProfile from './components/Persons/Profile/PersonProfile'
import CertEdu from './components/Profile/CertEdu'
import Interests from './components/Profile/Interests'
import MySkill from './components/Profile/MySkill'
import AllNeededSkills from './components/Profile/neededskills/AllNeededSkills'
import Overview from './components/Profile/Overview'
import Profile from './components/Profile/Profile'
import WorkHistory from './components/Profile/WorkHistory'
import Projects from './components/Projects/Projects'
import Search from './components/Search/Search'
import SideBarController from './components/SideBar/SideBarController'
import Skills from './components/Skills/Skills'
import { useStore } from './Provider'

const App = () => {
  const rootStore = useStore()
  const { userPermission, userIsValidated, isHr, isManager } = rootStore.loginStore

  return (
    <Box className='max-width'>
      {userIsValidated ? (
        <ThemeProvider theme={theme}>
          <Box>
            <SideBarController />
          </Box>
          <Box component='main' className='main'>
            <Box className='navbar'>
              <OPNavbar />
            </Box>
            <Box className='content'>
              <Box className='maincontent'>
                <ToastContainer enableMultiContainer={true} containerId='main' position='bottom-right' />
                <CompatRouter>
                  <Switch>
                    <Route exact path='/' component={Profile} />
                    <Route exact path='/profile/overview' component={Overview} />
                    <Route exact path='/profile/overview/cv' component={CVOverview} />
                    <Route exact path='/profile/myskill' component={MySkill} />
                    <Route exact path='/profile/certedu' component={CertEdu} />
                    <Route exact path='/profile/workhistory' component={WorkHistory} />
                    <Route exact path='/profile/interests' component={Interests} />
                    <Route exact path='/neededcompetence' component={AllNeededSkills} />
                    <Route exact path='/search' component={Search} />
                    <Route exact path='/skills' component={Skills} />
                    <Route exact path='/projects' component={Projects} />
                    <Route exact path='/persons' component={Persons} />
                    <Route exact path='/persons/:userId/cv' component={CVOverview} />
                    <Route exact path='/persons/:userId' component={PersonProfile} />
                    {userPermission && <Route exact path='/partners' component={Partners2} />}
                    <Route exact path='/development' component={DevelopmentPage} />
                    <Route exact path='/instructions' component={Help} />
                    {isManager && <Route exact path='/manager' component={ManagerPage} />}
                    {isManager && <Route exact path='/manager/development/:userId' component={DevelopmentPage} />}
                    {isHr && <Route exact path='/hr' component={HrPage} />}
                    {isHr && <Route exact path='/hr/development/:userId' component={DevelopmentPage} />}
                    <Route exact path='/notfound' component={NotFoundPage} />
                    <Route exact path='/privacypolicy' component={PrivacyPolicyPage} />
                    <Route exact path='/404' component={NotFoundPage} />
                    <Route exact path='/null'>
                      <Redirect to='/' />
                    </Route>
                    <Route>
                      <Redirect to='/404' />
                    </Route>
                  </Switch>
                </CompatRouter>
              </Box>
            </Box>
            <OPFooter />
            <GDPR />
          </Box>
        </ThemeProvider>
      ) : (
        <CustomLoader />
      )}
    </Box>
  )
}

export default withRouter(observer(App))
