import { Grid } from '@mui/material'
import React, { FunctionComponent } from 'react'

import moment from 'moment'
import { IPersonCVInfo } from '../../../../interfaces/persons/IPerson'

interface OwnProps {
  personalSection: IPersonCVInfo
  pictureVisibility: boolean
  personalDataVisibility: boolean
}

const PersonalSection: FunctionComponent<OwnProps> = ({
  personalSection: { personDetails },
  pictureVisibility,
  personalDataVisibility,
}) => (
  <div>
    <Grid container justifyContent={'right'} className='header'>
      <Grid item xs={7}>
        ANSIOLUETTELO, LUOTTAMUKSELLINEN
      </Grid>
      <Grid item xs={3}>
        {moment(new Date()).format('D.M.YYYY')}
      </Grid>
    </Grid>
    <Grid container>
      <Grid item xs={8} whiteSpace={'pre-wrap'}>
        <p className='personName'>{personDetails.name}</p>
        <p className='summary'>{personDetails.title}</p>
        <p className='summary'>{personDetails.username}</p>
        {personalDataVisibility && <p className='desc'>{personDetails.summary}</p>}
      </Grid>
      <Grid item>{pictureVisibility && <img className='photo' src={`data:image/jpeg;base64,${personDetails.largePhoto}`} />}</Grid>
    </Grid>
  </div>
)

export default PersonalSection
