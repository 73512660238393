import { Grid, Paper } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../Provider'
import ConditionalWrapper from '../OPcomponents/ConditionalWrapper'
import Hobbies from './interests/Hobbies'
import InterestTable from './interests/InterestTable'

const Interests = (): JSX.Element => {
  const { t } = useTranslation()
  const rootStore = useStore()
  const { userInterests, editHobbies } = rootStore.personStore

  return (
    <Grid>
      <Grid container rowGap={4}>
        <ConditionalWrapper
          condition={userInterests.length === 0}
          wrapper={(children) => <Paper className='profile--paper__addtitlepadding'>{children}</Paper>}
        >
          <Grid container rowSpacing={4}>
            <Grid item xs={12}>
              <span className='profile--titlebigfont'>{t('Interests.InterestsHeader')}</span>
            </Grid>
            <Grid item xs={12}>
              <InterestTable />
            </Grid>
          </Grid>
        </ConditionalWrapper>
        <ConditionalWrapper
          condition={!editHobbies}
          wrapper={(children) => <Paper className='profile--paper__addtitlepadding'>{children}</Paper>}
        >
          <Grid container rowSpacing={4}>
            <Grid item xs={12}>
              <span className='profile--titlebigfont'>{t('Interests.HobbiesHeader')}</span>
            </Grid>
            <Grid item xs={12}>
              <Hobbies />
            </Grid>
          </Grid>
        </ConditionalWrapper>
      </Grid>
    </Grid>
  )
}

export default observer(Interests)
