import { Grid } from '@mui/material'
import DOMPurify from 'dompurify'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import history from '../../../history'
import { useStore } from '../../../Provider'
import { OPLoadingCircle } from '../../OPcomponents/OPLoadingcircle'

const CertEdPub = (): JSX.Element => {
  const { t } = useTranslation()
  const rootStore = useStore()

  const {
    loadingEducations,
    loadingCertificates,
    loadingPublications,
    getUserCertificates,
    getUserEducations,
    getPersonPublications,
    filteredEducations,
    filteredCertificates,
    userPublications,
  } = rootStore.certAndEducationStore
  const { validatedUser } = rootStore.loginStore

  const userpubString = (DOMPurify.sanitize(userPublications, {
    ALLOWED_TAGS: ['b', 'i', 'u', 'ul', 'li', 'p', 'strong', 'br', 'em', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
  }).split('</p>')[0] += '</p>')

  useEffect(() => {
    if (filteredEducations.length === 0) getUserEducations(validatedUser)
    if (filteredCertificates.length === 0) getUserCertificates(validatedUser)
    if (userPublications.length === 0) getPersonPublications(validatedUser)
  }, [])

  const certRows = filteredCertificates.slice(0, 2).map((cert) => {
    return {
      ['id']: cert.sertificateId,
      [t('CertAndEducation.Certificate')]: cert.name,
    }
  })

  const eduRows = filteredEducations.slice(0, 2).map((edu) => {
    return {
      ['id']: edu.educationId,
      [t('CertAndEducation.Education')]: `${edu.degree},\n ${edu.school}`,
    }
  })

  return (
    <>
      {!loadingCertificates && !loadingEducations && !loadingPublications ? (
        <Grid container rowGap={2.25}>
          <Grid item xs={12} onClick={() => history.push('/profile/certedu')} className='profile--pointer'>
            <div className='profile--titlefont'>{t('Buttons.CertEduPublic')}</div>
          </Grid>
          <Grid container rowGap={1} item xs={12}>
            <Grid container rowGap={2} item xs={12}>
              <Grid
                item
                xs={12}
                className='profile--cert__headertext profile--pointer'
                onClick={() => history.push('/profile/certedu', { whatClicked: 'cert' })}
              >
                {t('CertAndEducation.CertificateHeader')}
              </Grid>
              {certRows.length ? (
                certRows.map((cert) => (
                  <Grid
                    item
                    paddingLeft={'0.5rem'}
                    xs={12}
                    key={cert.id}
                    className='profile--cert__regtext profile--pointer'
                    onClick={() => history.push('/profile/certedu', { whatClicked: 'cert', rowId: cert.id })}
                  >
                    {cert[t('CertAndEducation.Certificate')]}
                  </Grid>
                ))
              ) : (
                <Grid item className='profile--cert__regtext profile--pointer'>
                  {t('CertAndEducation.NoCertFound')}
                </Grid>
              )}
            </Grid>
            <Grid container rowGap={2} item xs={12}>
              <Grid
                item
                xs={12}
                className='profile--cert__headertext profile--pointer'
                onClick={() => history.push('/profile/certedu', { whatClicked: 'education' })}
              >
                {t('CertAndEducation.EducationHeader')}
              </Grid>
              {eduRows.length ? (
                eduRows.map((edu) => (
                  <Grid
                    item
                    paddingLeft={'0.5rem'}
                    onClick={() => history.push('/profile/certedu', { whatClicked: 'education', rowId: edu.id })}
                    xs={12}
                    key={edu.id}
                    className='profile--cert__regtext profile--pointer'
                  >
                    {edu[t('CertAndEducation.Education')]}
                  </Grid>
                ))
              ) : (
                <Grid item className='profile--cert__regtext profile--pointer'>
                  {t('CertAndEducation.NoEduFound')}
                </Grid>
              )}
            </Grid>
            <Grid container rowGap={2} item xs={12}>
              <Grid
                item
                xs={12}
                className='profile--cert__headertext profile--pointer'
                onClick={() => history.push('/profile/certedu', { whatClicked: 'publication' })}
              >
                {t('CertAndEducation.PublicationsHeader')}
              </Grid>
              <Grid item paddingLeft={'0.5rem'} xs={12}>
                <div className='profile--cert__regtext profile--pointer' dangerouslySetInnerHTML={{ __html: userpubString }} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <OPLoadingCircle />
      )}
    </>
  )
}
export default observer(CertEdPub)
