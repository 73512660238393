import { isEmpty, sortBy } from 'lodash'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import agent from '../../api/agent'
import { sortByType } from '../../common/sortHelper'
import { IProjects } from '../../interfaces/projects/IProjects'
import {
  ICategoryTag,
  IEditedSkillInfo,
  ILinkedProject,
  INewSkill,
  ISkill,
  ISkillDepObject,
  ISkillForm,
  ISkillProject,
  ISkills,
} from '../../interfaces/skills/ISkills'
import { ISortDirection } from '../../interfaces/Sort'

export default class SkillStore {
  sortColumn = 'skillName'
  sortDirection: ISortDirection = 'ascending'

  /**
   * * Loader observables
   */

  loadingRecentSkills = false
  loadingInitial = false
  loadingSkills = false
  loadingLikeButton = false

  /**
   * * Skill observables
   */

  isNewPersonSkill = false
  allSkills: ISkills[] = []
  recentSkills: ISkills[] = []
  currentSkill: any = {}
  currentProject: any = {}
  selectedSkillLevel: number | null = null
  allUserProjectIds: IProjects[] = []
  editCurrentSkill: ISkillForm = {
    approved: false,
    comment: '',
    experience: 0,
    interest: 0,
    linkedProjects: [],
    skillId: 0,
    skillLevel: 0,
    skillName: '',
  }
  editCurrentProject: Array<ILinkedProject> = []
  skills: ISkill[] = []
  loadingNeededSkills = false
  userNeededSkills: ISkillDepObject[] = []
  loadingAllNeededSkills = false
  allUserNeededSkills: ISkills[] = []
  allDepartments: string[] = []
  sortedNeededSkills: ISkillDepObject[] = []
  userDepartment = ''

  /**
   * * Tag and category observables
   */
  tags: Array<ICategoryTag> = []
  selectedEditSkill?: ISkill
  loadingTags = false
  categories: Array<ICategoryTag> = []
  loadingCategories = false

  /**
   * * Modal and show/hide observables
   */

  saving = false
  deleting = false
  showModal = false
  showSkill = false
  showNewSkillModal = false
  showEditSkillModal = false
  showProjectTable = false
  showCreateNewSkillModal = false
  showAddProjectToSkillModal = false
  showEditInfo = false
  showUserSkillEdit = true

  /**
   * * Search observables
   */
  isSearching = false
  searchValue = ''

  constructor() {
    makeObservable(this, {
      sortColumn: observable,
      sortDirection: observable,
      loadingRecentSkills: observable,
      loadingInitial: observable,
      loadingSkills: observable,
      loadingLikeButton: observable,
      isNewPersonSkill: observable,
      allSkills: observable,
      recentSkills: observable,
      currentSkill: observable,
      currentProject: observable,
      selectedSkillLevel: observable,
      allUserProjectIds: observable,
      editCurrentSkill: observable,
      editCurrentProject: observable,
      skills: observable,
      loadingNeededSkills: observable,
      userNeededSkills: observable,
      loadingAllNeededSkills: observable,
      allUserNeededSkills: observable,
      allDepartments: observable,
      sortedNeededSkills: observable,
      userDepartment: observable,
      tags: observable,
      selectedEditSkill: observable,
      loadingTags: observable,
      categories: observable,
      loadingCategories: observable,
      saving: observable,
      deleting: observable,
      showModal: observable,
      showSkill: observable,
      showNewSkillModal: observable,
      showEditSkillModal: observable,
      showProjectTable: observable,
      showCreateNewSkillModal: observable,
      showAddProjectToSkillModal: observable,
      showEditInfo: observable,
      showUserSkillEdit: observable,
      isSearching: observable,
      searchValue: observable,
      dropdownTags: computed,
      filteredSkills: computed,
      dropdownCategories: computed,
      dropdownSkills: computed,
      allDropdownSkills: computed,
      getFilteredSkillsByText: action,
      setShowAddProjectToSkillModal: action,
      setCurrentskill: action,
      setSaving: action,
      setDeleting: action,
      setSearchValue: action,
      setSortColumn: action,
      toggleSortDirection: action,
      setAllSkills: action,
      clearEditCurrent: action,
      setEditCurrentProject: action,
      setShowCreateNewSkillModalHandler: action,
      setCurrentProject: action,
      toggleSkillHandler: action,
      showModalHandler: action,
      setShowProjectTable: action,
      showEditSkillModalHandler: action,
      showNewSkillModalHandler: action,
      setSelectedSkillLevel: action,
      setIsSearching: action,
      removeLinkedSkillProject: action,
      addLinkedProjectToSkill: action,
      getRecentSkills: action,
      getAllSkills: action,
      postNewSkillToUser: action,
      postNewSkillProject: action,
      deleteNewSkillProject: action,
      deleteNewSkillProjectLinks: action,
      getUserDetailedProjects: action,
      getUserDetailedSkillLinkedProjects: action,
      editUserSkill: action,
      createNewSkill: action,
      deleteUserSkill: action,
      addInterest: action,
      removeInterest: action,
      setShowEditInfo: action,
      editSkillInfo: action,
      addSkillTag: action,
      removeSkillTag: action,
      selectEditSkill: action,
      getTags: action,
      getCategories: action,
      getDepSkills: action,
      getAllDepSkills: action,
      getUserDep: action,
    })
  }

  /**
   * * Actions
   */

  get dropdownTags() {
    return this.tags.map((x) => ({
      key: x.id,
      value: x.name,
      text: x.name,
    }))
  }

  get filteredSkills() {
    let allSkills = sortBy(this.allSkills, (skill) => {
      return sortByType(skill.skill, this.sortColumn)
    })
    if (this.sortDirection === 'descending') {
      allSkills = allSkills.reverse()
    }
    const params = this.searchValue
    if (isEmpty(params)) {
      return allSkills
    }
    return allSkills.filter(
      (skill: ISkills) =>
        skill.skill.skillName?.toLowerCase().includes(params.toLowerCase()) ||
        skill.skill.skillDescription?.toLowerCase().includes(params.toLowerCase()) ||
        skill.skill.categoryName?.toLowerCase().includes(params.toLowerCase()) ||
        skill.skill.skillTags?.toLowerCase().includes(params.toLowerCase()),
    )
  }
  get dropdownCategories() {
    return this.categories.map((x) => ({
      key: x.id,
      value: x.id,
      text: x.name,
    }))
  }

  get dropdownSkills() {
    return this.allSkills
      .filter((skill) => skill.userHas)
      .map((skill) => ({
        key: skill.skill.skillId,
        value: skill.skill.skillId,
        text: skill.skill.skillName,
      }))
      .sort((a, b) => a.text.localeCompare(b.text))
  }

  get allDropdownSkills() {
    return this.allSkills
      .map((skill) => ({
        key: skill.skill.skillId,
        value: skill.skill.skillId,
        text: skill.skill.skillName,
      }))
      .sort((a, b) => a.text.localeCompare(b.text))
  }

  getFilteredSkillsByText = (text: string) => {
    return this.filteredSkills.filter(
      (skill: ISkills) =>
        skill.skill.skillName?.toLowerCase().includes(text.toLowerCase()) ||
        skill.skill.skillDescription?.toLowerCase().includes(text.toLowerCase()) ||
        skill.skill.categoryName?.toLowerCase().includes(text.toLowerCase()) ||
        skill.skill.skillTags?.toLowerCase().includes(text.toLowerCase()),
    )
  }

  setShowAddProjectToSkillModal = (value: boolean) => {
    this.showAddProjectToSkillModal = value
  }

  setCurrentskill = (value: any) => {
    this.currentSkill = value
  }

  setSaving = (value: boolean) => {
    this.saving = value
  }

  setDeleting = (value: boolean) => {
    this.deleting = value
  }

  setSearchValue = (value: string) => {
    this.searchValue = value
  }

  setSortColumn = (value: string) => {
    this.sortColumn = value
  }

  toggleSortDirection = () => {
    this.sortDirection = this.sortDirection === 'ascending' ? 'descending' : 'ascending'
  }

  setAllSkills = (skills: Array<ISkills>) => {
    this.allSkills = skills
  }

  clearEditCurrent = () => {
    this.editCurrentProject = []
    this.editCurrentSkill = {
      approved: false,
      comment: '',
      experience: 0,
      interest: 0,
      linkedProjects: [],
      skillId: 0,
      skillLevel: 0,
      skillName: '',
    }
  }

  setEditCurrentProject = (value: any) => {
    this.editCurrentProject = value
  }

  setShowCreateNewSkillModalHandler = (show: boolean) => {
    this.showCreateNewSkillModal = show
  }

  setCurrentProject = (cProject: Record<string, unknown>) => {
    this.currentProject = cProject
  }

  toggleSkillHandler = () => {
    this.showSkill = !this.showSkill
  }

  showModalHandler = (val = !this.showModal) => {
    this.showModal = val
  }

  setShowProjectTable = (show: boolean) => {
    this.showProjectTable = show
  }

  showEditSkillModalHandler = (value?: boolean | undefined) => {
    if (value !== undefined) {
      this.showEditSkillModal = value
    } else {
      this.showEditSkillModal = false
    }
  }

  showNewSkillModalHandler = (val = !this.showNewSkillModal) => {
    this.showNewSkillModal = val
    this.selectedSkillLevel = null
  }

  setSelectedSkillLevel = (skillLevel: number | null) => {
    this.selectedSkillLevel = skillLevel
  }

  setIsSearching = (isItSearching: boolean) => {
    this.isSearching = isItSearching
  }

  removeLinkedSkillProject = (projectId: number) => {
    if (this.editCurrentProject != null) {
      this.editCurrentProject = this.editCurrentProject.filter((linkedProject: any) => linkedProject.projectId !== projectId)
    }
  }

  addLinkedProjectToSkill = (linkedProject: any) => {
    const foundSkill = this.editCurrentProject.find((pro) => pro.projectId === linkedProject.projectId)
    if (foundSkill === undefined) {
      this.editCurrentProject = [...this.editCurrentProject, linkedProject]
    }
  }

  getRecentSkills = async (userId: number) => {
    this.loadingRecentSkills = true
    try {
      const fetchedSkills: ISkills[] = await agent.HomePage.getRecentSkills(userId)
      runInAction(() => {
        this.recentSkills = fetchedSkills
        this.loadingRecentSkills = false
      })
    } catch (error) {
      runInAction(() => {
        this.loadingRecentSkills = false
      })
    }
  }

  getAllSkills = async (userId: number, useLoading = true) => {
    if (useLoading) {
      this.loadingSkills = true
    }
    try {
      const fetchedSkills: ISkills[] = await agent.Skills.getAllSkills(userId)
      runInAction(() => {
        this.allSkills = fetchedSkills
        if (useLoading) {
          this.loadingSkills = false
        }
      })
    } catch (error) {
      runInAction(() => {
        if (useLoading) {
          this.loadingSkills = false
        }
      })
    }
  }

  postNewSkillToUser = async (newSkill: INewSkill, userId: number, update = true) => {
    try {
      await agent.Skills.postNewSkillToUser(newSkill)
      runInAction(async () => {
        if (update) await this.getAllSkills(userId)
      })
    } catch (error) {
      return Promise.reject(error)
    }
    return Promise.resolve()
  }

  postNewSkillProject = async (newSkillProject: any) => {
    try {
      await agent.Skills.postPersonSkillProject(newSkillProject)
    } catch (error) {
      return Promise.reject(error)
    }
    return Promise.resolve()
  }
  postNewSkillProjectLinks = async (newSkillLinkProjects: ISkillProject[]) => {
    try {
      await agent.Skills.postPersonLinkedSkills(newSkillLinkProjects)
    } catch (error) {
      return Promise.reject(error)
    }
    return Promise.resolve()
  }

  deleteNewSkillProject = async (userId: number, skillId: number, projectId: number) => {
    try {
      await agent.Skills.deletePersonSkillProject({
        userId,
        skillId,
        projectId,
      })
    } catch (error) {
      return Promise.reject(error)
    }
    return Promise.resolve()
  }

  deleteNewSkillProjectLinks = async (linksToDelete: ISkillProject[]) => {
    try {
      await agent.Skills.deleteLinkedSkills(linksToDelete)
    } catch (error) {
      return Promise.reject(error)
    }
    return Promise.resolve()
  }

  getUserDetailedProjects = async (userId: number) => {
    const fetchedProjects: IProjects[] = await agent.Skills.linkProjectToSkill(userId)
    runInAction(() => {
      this.allUserProjectIds = fetchedProjects
    })
  }

  getUserDetailedSkillLinkedProjects = async (userId: number, skillId: number) => {
    this.loadingInitial = true
    try {
      const detailed = await agent.Skills.getUserLinkedSkillProjects(userId, skillId)
      runInAction(() => {
        this.editCurrentSkill = detailed
        this.editCurrentProject = detailed.linkedProjects
        this.loadingInitial = false
        this.isNewPersonSkill = false
      })
    } catch (error) {
      runInAction(() => {
        this.editCurrentSkill = {
          approved: false,
          comment: '',
          experience: 0,
          interest: 0,
          linkedProjects: [],
          skillId,
          skillLevel: 0,
          skillName: '',
        }
        this.loadingInitial = false
        this.isNewPersonSkill = true
      })
    }
    const skill = this.editCurrentSkill
    const isNew = this.isNewPersonSkill
    return Promise.resolve({ skill, isNew })
  }

  editUserSkill = async (editedSkill: INewSkill, userId: number, getAll = true) => {
    try {
      await agent.Skills.editUserSkill(editedSkill)
      runInAction(async () => {
        if (getAll) await this.getAllSkills(userId)
      })
    } catch (error) {
      return Promise.reject(error)
    }
    return Promise.resolve()
  }

  createNewSkill = async (newSkill: any, addSkillToUser: INewSkill, addToUser: boolean) => {
    try {
      const newSkillId = await agent.Skills.createNewSkill(newSkill)
      addSkillToUser.skillId = newSkillId
      if (addToUser) {
        await agent.Skills.postNewSkillToUser(addSkillToUser)
      }
      const updatedSkills = await agent.Skills.getAllSkills(newSkill.creatorId)
      runInAction(() => {
        this.allSkills = updatedSkills
      })
    } catch (error) {
      return Promise.reject(error)
    }
    return Promise.resolve()
  }

  deleteUserSkill = async (userId: number, skillId: number, update = true) => {
    try {
      await agent.Skills.deleteUserSkill(userId, skillId)
      if (update) {
        const updatedRecentSkills = await agent.HomePage.getRecentSkills(userId)
        const updatedSkills = await agent.Skills.getAllSkills(userId)
        runInAction(() => {
          this.recentSkills = updatedRecentSkills
          this.allSkills = updatedSkills
        })
      }
    } catch (error) {
      return Promise.reject(error)
    }
    return Promise.resolve()
  }

  addInterest = async (userId: number, skillId: number) => {
    this.loadingLikeButton = true
    try {
      await agent.Skills.addInterest(userId, skillId)
      runInAction(async () => {
        const foundSkill = this.allSkills.find((skill) => skill.skill.skillId === skillId)
        if (foundSkill) {
          foundSkill.userInterested = true
        }
        this.loadingLikeButton = false
      })
    } catch (error) {
      runInAction(() => {
        this.loadingLikeButton = false
      })
      return Promise.reject(error)
    }
    return Promise.resolve()
  }
  removeInterest = async (userId: number, skillId: number) => {
    this.loadingLikeButton = true
    try {
      await agent.Skills.removeInterest(userId, skillId)
      runInAction(async () => {
        const foundSkill = this.allSkills.find((skill) => skill.skill.skillId === skillId)
        if (foundSkill) {
          foundSkill.userInterested = false
        }
        this.loadingLikeButton = false
      })
    } catch (error) {
      runInAction(() => {
        this.loadingLikeButton = false
      })
      return Promise.reject(error)
    }
    return Promise.resolve()
  }

  setShowEditInfo = (value: boolean) => {
    this.showEditInfo = value
    this.showUserSkillEdit = !value
  }

  editSkillInfo = async (userId: number, skillId: number, body: IEditedSkillInfo, update = true) => {
    try {
      await agent.Skills.editSkill(skillId, body)
      runInAction(async () => {
        if (update) await this.getAllSkills(userId)
      })
    } catch (error) {
      return Promise.reject(error)
    }
    return Promise.resolve()
  }

  addSkillTag = async (tagId: number, skillId: number) => {
    await agent.Skills.addSkillTag(tagId, skillId)
  }

  removeSkillTag = async (tagId: number, skillId: number) => {
    await agent.Skills.removeSkillTag(tagId, skillId)
  }

  selectEditSkill = (skill: ISkill) => {
    this.selectedEditSkill = { ...skill }
  }

  getTags = async () => {
    this.loadingTags = true
    const resp: Array<ICategoryTag> = await agent.Skills.getTags()
    runInAction(() => {
      this.tags = sortBy(resp, (tag) => tag.name?.toLowerCase())
      this.loadingTags = false
    })
  }

  getCategories = async () => {
    this.loadingCategories = true
    const resp: Array<ICategoryTag> = await agent.Skills.getCats()
    runInAction(() => {
      this.categories = sortBy(resp, (category) => category.name?.toLowerCase())
      this.loadingCategories = false
    })
  }

  getDepSkills = async (userId: number) => {
    this.loadingNeededSkills = true
    try {
      const fetchedNeededSkills: ISkillDepObject[] = await agent.Skills.getDepartmentSkills(userId)
      const fetchedUserDep = await agent.Persons.getUserDep(userId)
      runInAction(() => {
        this.userNeededSkills = fetchedNeededSkills
        this.userDepartment = fetchedUserDep
        this.loadingNeededSkills = false
      })
    } catch (error) {
      return Promise.reject(error)
    }
    return Promise.resolve()
  }

  getAllDepSkills = async (userId: number) => {
    this.loadingAllNeededSkills = true
    try {
      this.sortedNeededSkills = []
      const fetchedAllDepsAndSkills = await agent.Skills.getAllDepartmentSkills(userId)

      runInAction(() => {
        this.sortedNeededSkills = fetchedAllDepsAndSkills
        this.loadingAllNeededSkills = false
      })
    } catch (error) {
      return Promise.reject(error)
    }
    return Promise.resolve()
  }

  getUserDep = async (userId: number) => {
    try {
      this.userDepartment = ''
      const fetchedUserDep = await agent.Persons.getUserDep(userId)

      runInAction(() => {
        this.userDepartment = fetchedUserDep
      })
    } catch (error) {
      return Promise.reject(error)
    }
    return Promise.resolve()
  }
}
