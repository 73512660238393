import { get, isString } from 'lodash'

export const sortByType = (dataRow: unknown, sortColumn: string) => {
  const data = get(dataRow, sortColumn)
  if (isString(data)) {
    const typeData = data as string
    return typeData.toLowerCase()
  }
  return data || ''
}
