import { Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IProfileProjectInfo } from '../../../../interfaces/projects/IProjects'
import ProjectInfo from '../ProfileSkills/ProjectInfo'

const ProfileProjectPerson = (props: { data: IProfileProjectInfo }) => {
  const { t } = useTranslation()
  const { data } = props

  return (
    <Grid container rowGap={2} className='gridcontainer--formcontainer__lastitempaddings'>
      <Grid item xs={12}>
        <span className='myskill--font__titlefont sini'>{t('WorkHistory.ProjectForPerson')}</span>
      </Grid>
      <Grid item xs={12}>
        <ProjectInfo isProject projectInfo={data} />
      </Grid>
    </Grid>
  )
}
export default observer(ProfileProjectPerson)
