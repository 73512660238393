import { ListItemIcon } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { MutableRefObject } from 'react'
import { useTranslation } from 'react-i18next'
import { ISkillFormPackage, ISkills } from '../../../interfaces/skills/ISkills'
import { useStore } from '../../../Provider'
import { OPNewTable } from '../../OPcomponents/OPNewTable'
import OpenSkill from '../../Skills/OpenSkill'
import { HotStar, HotStarFilled } from '../../styles/Icons'

interface NeededSkillsTableProps {
  data: ISkills[]
  clickable?: MutableRefObject<HTMLDivElement | null>
  rowId?: number
}

const NeededSkillsTable = (props: NeededSkillsTableProps) => {
  const { data, clickable, rowId } = props
  const { t } = useTranslation()
  const rootStore = useStore()
  const { getUserDetailedSkillLinkedProjects } = rootStore.skillStore
  const { validatedUser } = rootStore.loginStore
  const { getSkillPersons } = rootStore.seachStore

  const openSkillRow = async (id: number | string) => {
    const curClicked = data.find((skill) => skill.skill.skillId === Number(id))
    const userSkill = await getUserDetailedSkillLinkedProjects(validatedUser, Number(id))
    const skillPersons = await getSkillPersons(Number(id))

    if (curClicked?.skill) {
      const skillInfo: ISkillFormPackage = {
        skill: { skill: curClicked.skill, userInterested: curClicked.userInterested },
        userSkill: userSkill.skill,
        isNew: userSkill.isNew,
        skillPersons: skillPersons,
      }
      return skillInfo
    }
  }

  const skillList = data.map((skill: ISkills) => ({
    ['id']: skill.skill.skillId,
    rows: {
      [t('Skills.SkillName')]: {
        text:
          (
            <>
              {skill.skill.skillTags?.includes('10') && (
                <ListItemIcon className='profile--skillstable__hoticon'>{skill.userHas ? <HotStarFilled /> : <HotStar />}</ListItemIcon>
              )}
              <span className={'profile--skillstable__' + `${skill.skill.skillTags?.includes('10') ? 'hot' : 'reg'}`}>
                {skill.skill.skillName}
              </span>
            </>
          ) ?? '',
        width: '100%',
      },
    },
  }))

  return (
    <OPNewTable
      rows={skillList}
      curLength={skillList.length}
      profileclickedId={rowId}
      onRowClick={openSkillRow}
      FormBase={OpenSkill}
      hideHeader
      isClickable={clickable}
      noBorders={true}
      underLine={true}
    />
  )
}

export default observer(NeededSkillsTable)
