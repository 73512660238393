import { Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ILinkedSkill, IPersonProjectInfo } from '../../../interfaces/projects/IProjects'
import { useStore } from '../../../Provider'
import { OPAccordionItem } from '../../Accordion/OPAccordionItem'
import { OPLoadingCircle } from '../../OPcomponents/OPLoadingcircle'
import { OPNewTable } from '../../OPcomponents/OPNewTable'
import ProfileWorkHistoryForm from './ProfileWorkHistory/ProfileWorkHistoryForm'

interface IProps {
  userId: string
}

const ProfileWorkExperience = (props: IProps): JSX.Element => {
  const { userId } = props
  const { t } = useTranslation()
  const rootStore = useStore()
  const {
    getPersonPrivateProjects,
    getPersonPublicProjects,
    privateWorkHistory,
    privateWorkHistoryLoading,
    publicWorkHistory,
    publicWorkHistoryLoading,
    filteredSkills,
    getPersonSkills,
  } = rootStore.profileStore

  const { filteredProjects, getAllProjects, loadingInitial } = rootStore.projectStore
  const { validatedUser } = rootStore.loginStore

  const onRowClick = async (id: number | string): Promise<IPersonProjectInfo | null> => {
    const personProject = await rootStore.projectStore.getDetailedUserProjects(Number(userId), Number(id))
    const projectInfo = publicWorkHistory?.find((p) => p.projectId === id) ?? privateWorkHistory?.find((p) => p.projectId === id)
    if (personProject && projectInfo) {
      return {
        personInfo: {
          projectName: personProject?.projectName,
          projectId: personProject?.projectId,
          startDate: personProject?.startDate?.toString(),
          endDate: personProject?.endDate?.toString(),
          description: personProject?.description,
          workAmount: personProject?.expDays,
          roles: personProject?.roles.map((role) => role.roleName).join(', '),
          projectSkills: personProject?.linkedSkills.map((s: ILinkedSkill) => {
            const skillInfo = filteredSkills.find((skill) => skill.skillId === s.skillId)
            return {
              skillName: skillInfo?.skillName ?? '',
              skillLevel: skillInfo?.skillLevel,
              skillComment: skillInfo?.comment,
            }
          }),
        },
        projectInfo: {
          name: projectInfo?.projectName,
          customer: projectInfo?.customer,
          description: projectInfo?.description,
          projectId: projectInfo?.projectId,
          startDate: projectInfo?.startDate,
          endDate: projectInfo?.endDate,
          projectOwner: projectInfo?.projectOwner,
          branchName: projectInfo?.branchName,
          contactPerson: projectInfo?.contactPerson,
          approved: true,
          employerId: projectInfo?.employerId,
        },
      }
    }
    return null
  }

  useEffect(() => {
    async function fetchData() {
      if (filteredProjects.length === 0) {
        await getAllProjects(validatedUser)
      }
      if (filteredSkills.length === 0) {
        await getPersonSkills(Number(userId))
      }
      await Promise.allSettled([getPersonPrivateProjects(Number(userId)), getPersonPublicProjects(Number(userId))])
    }
    fetchData()
  }, [])

  const privateWorkHistoryList = privateWorkHistory.map((work) => {
    return {
      ['id']: work.projectId,
      rows: {
        [t('WorkHistory.EmployerName')]: { text: work.empDetails[0]?.name ?? '', width: '23%' },
        [t('Projects.ProjectName')]: { text: work.projectName, width: '22%' },
        [t('WorkHistory.Customer')]: { text: work.customer, width: '22%' },
        [t('WorkHistory.Expirience')]: { text: `${work.daysWorked || 0} ${t('Date.ExperienceDays')}`, width: '11%' },
        [t('WorkHistory.Skills')]: { text: work.linkedSkills.map((s: ILinkedSkill) => s.skillName).join(', '), width: '22%' },
      },
    }
  })

  const publicWorkHistoryList = publicWorkHistory.map((work) => {
    return {
      ['id']: work.projectId,
      rows: {
        [t('Projects.ProjectName')]: { text: work.projectName, width: '30%' },
        [t('WorkHistory.Customer')]: { text: work.customer, width: '30%' },
        [t('WorkHistory.Expirience')]: { text: `${work.daysWorked || 0} ${t('Date.ExperienceDays')}`, width: '11%' },
        [t('WorkHistory.Skills')]: { text: work.linkedSkills.map((s: ILinkedSkill) => s.skillName).join(', '), width: '29%' },
      },
    }
  })

  return (
    <Grid container spacing={5} className='gridcontainer--formcontainer__addnewpaddings'>
      {publicWorkHistoryLoading || privateWorkHistoryLoading || loadingInitial ? (
        <Grid item xs={12}>
          <OPLoadingCircle />
        </Grid>
      ) : (
        <>
          <Grid item xs={12}>
            <OPAccordionItem
              title={`${t('WorkHistory.PersonNetumWorkHistory')} (${publicWorkHistoryLoading ? '\u2026' : publicWorkHistory.length})`}
              content={
                <Grid container spacing={5} className='gridcontainer--formcontainer__addnewpaddings'>
                  <Grid item xs={12}>
                    {publicWorkHistory.length > 0 ? (
                      <OPNewTable rows={publicWorkHistoryList} curLength={publicWorkHistoryList.length} onRowClick={onRowClick} FormBase={ProfileWorkHistoryForm} />
                    ) : (
                      <p className='profile--deptext profpadding'>{t('Profile.NoAddedWorkHistoryPublic')}</p>
                    )}
                  </Grid>
                </Grid>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <OPAccordionItem
              title={`${t('WorkHistory.PersonPrivateWorkHistory')} (${privateWorkHistoryLoading ? '\u2026' : privateWorkHistory.length})`}
              content={
                <Grid container spacing={5} className='gridcontainer--formcontainer__addnewpaddings'>
                  <Grid item xs={12}>
                    {privateWorkHistory.length > 0 ? (
                      <OPNewTable rows={privateWorkHistoryList} curLength={privateWorkHistoryList.length} onRowClick={onRowClick} FormBase={ProfileWorkHistoryForm} />
                    ) : (
                      <p className='profile--deptext profpadding'>{t('Profile.NoAddedWorkHistoryPrivate')}</p>
                    )}
                  </Grid>
                </Grid>
              }
            />
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default observer(ProfileWorkExperience)
