import { action, makeObservable, observable, runInAction } from 'mobx'
import agent from '../../api/agent'

interface sidebarUser {
  name?: string
  department?: string
}

export default class SidebarStore {
  openState = ''
  isOpen = true
  sidebarUser: sidebarUser = {}
  isError = false
  savingText = ''

  isLoading = false

  setSavingText = (text: string) => {
    this.savingText = text
  }

  setIsError = (hasError: boolean) => {
    this.isError = hasError
  }

  fetchSidebarData = async (userId: number, name: string) => {
    this.isLoading = true
    try {
      const department = await agent.Persons.getUserTitleText(userId)
      runInAction(() => {
        this.sidebarUser = { name, department }
        this.isLoading = false
      })
    } catch (error) {
      Promise.reject(error)
    }
    Promise.resolve()
  }

  setOpenState = (newOpened: string | undefined) => {
    this.openState = newOpened ? newOpened : ''
  }

  setIsOpen = (isOpen: boolean) => {
    this.isOpen = isOpen
  }

  constructor() {
    makeObservable(this, {
      openState: observable,
      isOpen: observable,
      sidebarUser: observable,
      isLoading: observable,
      savingText: observable,
      isError: observable,
      fetchSidebarData: action,
      setOpenState: action,
      setIsOpen: action,
      setSavingText: action,
      setIsError: action,
    })
  }
}
