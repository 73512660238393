import { isEmpty, sortBy } from 'lodash'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'

import { decode } from 'html-entities'
import agent from '../../api/agent'
import { sortByType } from '../../common/sortHelper'
import {
  ICertificate,
  IDropdownProps,
  IEducation,
  IExistingCertificate,
  IExistingEducation,
  INewCertificate,
  INewEducation,
  INewPublication,
  IPersonPublications,
} from '../../interfaces/certAndEducation/certAndEducation'
import { ISortDirection } from '../../interfaces/Sort'

export default class CertAndEducationStore {
  certificateSortColumn = 'name'
  certificateSortDirection: ISortDirection = 'ascending'

  educationSortColumn = 'isHighestDegree'
  educationSortDirection: ISortDirection = 'descending'

  /**
   * * Loader observables
   */

  loadingInitial = false
  loadingCertificates = false
  loadingEducations = false
  loadingPublications = false

  /**
   * * Edit observables
   */
  editPublications = false
  newPublications = false

  /**
   * * Certificate and education observables
   */

  certificates: ICertificate[] = []
  educations: IEducation[] = []
  userEducations: IEducation[] = []
  userCertificates: ICertificate[] = []
  currentCertificate: ICertificate = {}
  currentEducation: IEducation = {}
  currentPublication: IPersonPublications = {
    personId: -1,
    publicationId: -1,
    publicationDescription: '',
    publicationTitle: '',
  }
  /**
   * * User and Person observables
   */
  publication: IPersonPublications[] = []
  userPublications = ''

  /**
   * * Modal and other boolean observables
   */

  showEditCertificateModal = false
  showEditEducationModal = false
  newEducationModal = false
  newCertificateModal = false

  constructor() {
    makeObservable(this, {
      certificateSortColumn: observable,
      certificateSortDirection: observable,
      educationSortColumn: observable,
      educationSortDirection: observable,
      loadingInitial: observable,
      loadingCertificates: observable,
      loadingEducations: observable,
      loadingPublications: observable,
      editPublications: observable,
      newPublications: observable,
      certificates: observable,
      educations: observable,
      publication: observable,
      userEducations: observable,
      userCertificates: observable,
      currentCertificate: observable,
      currentEducation: observable,
      userPublications: observable,
      showEditCertificateModal: observable,
      showEditEducationModal: observable,
      newEducationModal: observable,
      newCertificateModal: observable,
      filteredCertificates: computed,
      filteredEducations: computed,
      getFilteredEducationsByText: action,
      getFilteredCertificatesByText: action,
      setCertificateSortColumn: action,
      toggleCertificateSortDirection: action,
      setEducationSortColumn: action,
      toggleEducationSortDirection: action,
      setCurrentCertificate: action,
      setCurrentEducation: action,
      showEditEducationModalHandler: action,
      showEditCertificateModalHandler: action,
      getCertificates: action,
      getEducations: action,
      showNewEducationModal: action,
      showNewCertificateModal: action,
      postNewExistingCertificate: action,
      postNewCertificate: action,
      getUserCertificates: action,
      getUserEducations: action,
      postNewExistingEducation: action,
      postNewEducation: action,
      removeUserCertificate: action,
      removeUserEducation: action,
      editUserCertificate: action,
      editUserEducation: action,
      certsAddNew: action,
      edusAddNew: action,
      isPublicationsEditing: action,
      isNewPublication: action,
      postNewPublications: action,
      getPersonPublications: action,
    })
  }

  /**
   * * Actions
   */

  get filteredCertificates() {
    let allCertificates = sortBy(this.userCertificates, (certificate) => {
      return sortByType(certificate, this.certificateSortColumn)
    })
    if (this.certificateSortDirection === 'descending') {
      allCertificates = allCertificates.reverse()
    }
    return allCertificates
  }

  get filteredEducations() {
    let allEducations = sortBy(this.userEducations, (education) => {
      return sortByType(education, this.educationSortColumn)
    })
    if (this.educationSortDirection === 'descending') {
      allEducations = allEducations.reverse()
    }
    return allEducations
  }

  getFilteredEducationsByText = (text: string) => {
    if (isEmpty(text)) return this.educations

    return this.educations.filter(
      (education) =>
        education.text?.toLowerCase().includes(text.toLowerCase()) || education.value?.toLowerCase().includes(text.toLowerCase()),
    )
  }

  getFilteredCertificatesByText = (text: string) => {
    if (isEmpty(text)) return this.certificates

    return this.certificates.filter(
      (certificate) =>
        certificate.value?.toLowerCase().includes(text.toLowerCase()) || certificate.text?.toLowerCase().includes(text.toLowerCase()),
    )
  }

  setCertificateSortColumn = (value: string) => {
    this.certificateSortColumn = value
  }

  toggleCertificateSortDirection = () => {
    this.certificateSortDirection = this.certificateSortDirection === 'ascending' ? 'descending' : 'ascending'
  }

  setEducationSortColumn = (value: string) => {
    this.educationSortColumn = value
  }

  toggleEducationSortDirection = () => {
    this.educationSortDirection = this.educationSortDirection === 'ascending' ? 'descending' : 'ascending'
  }

  setCurrentCertificate = (cert: ICertificate) => {
    this.currentCertificate = cert
  }

  setCurrentEducation = (education: IEducation) => {
    this.currentEducation = education
  }

  showEditEducationModalHandler = (val = !this.showEditEducationModal) => {
    this.showEditEducationModal = val
  }

  showEditCertificateModalHandler = (value = !this.showEditCertificateModal) => {
    this.showEditCertificateModal = value
  }

  getCertificates = async (userId: number) => {
    this.certificates = []
    this.loadingCertificates = true
    try {
      const fetchedCertificates: ICertificate[] = await agent.MySkills.getAllCertificates(userId)
      runInAction(() => {
        fetchedCertificates.forEach((cert) => {
          this.certificates.push({
            key: cert.sertificateId,
            value: cert.name,
            text: cert.name,
          })
        })
        this.certificates = this.certificates.sort((a, b) => (a.text ?? '').localeCompare(b.text ?? ''))
      })
    } catch (error) {
      runInAction(() => {
        console.log(error)
      })
    }
    runInAction(() => {
      this.loadingCertificates = false
    })
  }

  getEducations = async () => {
    this.loadingEducations = true
    try {
      const fetchedEducations: IEducation[] = await agent.MySkills.getAllEducations()
      runInAction(() => {
        this.educations = []
        fetchedEducations.forEach((education) => {
          this.educations.push({
            key: education.educationId,
            value: education.degree,
            text: education.degree,
          })
        })
        this.educations = this.educations.sort((a, b) => (a.text ?? '').localeCompare(b.text ?? ''))
      })
    } catch (error) {
      runInAction(() => {
        console.log(error)
      })
    }
    runInAction(() => {
      this.loadingEducations = false
    })
  }

  showNewEducationModal = (val = !this.newEducationModal) => {
    this.newEducationModal = val
  }

  showNewCertificateModal = (val = !this.newCertificateModal) => {
    this.newCertificateModal = val
  }

  postNewExistingCertificate = async (newCertificate: IExistingCertificate) => {
    this.loadingCertificates = true
    try {
      const updatedCertificates = await agent.MySkills.postExistingCertificate(newCertificate.certificateId, newCertificate)
      runInAction(() => {
        this.userCertificates = updatedCertificates
      })
    } catch (error) {
      return Promise.reject(error)
    } finally {
      runInAction(() => {
        this.loadingCertificates = false
      })
    }
    return Promise.resolve()
  }

  postNewCertificate = async (newCertificate: INewCertificate) => {
    this.loadingCertificates = true
    try {
      const updatedCertificates = await agent.MySkills.postNewCertificate(newCertificate)
      runInAction(() => {
        this.userCertificates = updatedCertificates
      })
    } catch (error) {
      return Promise.reject(error)
    } finally {
      runInAction(() => {
        this.loadingCertificates = false
      })
    }
    return Promise.resolve()
  }

  getUserCertificates = async (userId: number) => {
    this.loadingCertificates = true
    try {
      if (userId) {
        const fetchedUserCertificates: ICertificate[] = await agent.MySkills.getAllUserCertificates(userId)
        runInAction(() => {
          this.userCertificates = fetchedUserCertificates
        })
      }
    } catch (error) {
      runInAction(() => {
        console.log(error)
      })
    } finally {
      runInAction(() => {
        this.loadingCertificates = false
      })
    }
  }

  getUserEducations = async (userId: number) => {
    this.loadingEducations = true
    try {
      const fetchedEducations: IEducation[] = await agent.MySkills.getUserEducations(userId)
      runInAction(() => {
        this.userEducations = fetchedEducations
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => {
        this.loadingEducations = false
      })
    }
  }

  postNewExistingEducation = async (newEducation: IExistingEducation) => {
    this.loadingEducations = true
    try {
      const updatedEducations = await agent.MySkills.postExistingEducation(newEducation, newEducation.educationId)
      runInAction(() => {
        this.loadingEducations = false
        this.userEducations = updatedEducations
      })
    } catch (error) {
      return Promise.reject(error)
    } finally {
      runInAction(() => {
        this.loadingEducations = false
      })
    }
    return Promise.resolve()
  }

  postNewEducation = async (newEducation: INewEducation) => {
    this.loadingEducations = true
    try {
      const updatedEducations = await agent.MySkills.postNewEducation(newEducation)
      runInAction(() => {
        this.loadingEducations = false
        this.userEducations = updatedEducations
      })
    } catch (error) {
      return Promise.reject(error)
    } finally {
      runInAction(() => {
        this.loadingEducations = false
      })
    }
    return Promise.resolve()
  }

  removeUserCertificate = async (userId: number, certificateId: number) => {
    try {
      await agent.MySkills.deleteUserCertificate(userId, certificateId)
    } catch (error) {
      return Promise.reject(error)
    }
    return Promise.resolve()
  }

  removeUserEducation = async (userId: number, educationId: number) => {
    try {
      await agent.MySkills.deleteUserEducation(userId, educationId)
    } catch (error) {
      return Promise.reject(error)
    }
    return Promise.resolve()
  }

  editUserCertificate = async (certificateId: number, editedCertificate: any) => {
    try {
      await agent.MySkills.editUserCertificate(certificateId, editedCertificate)
    } catch (error) {
      runInAction(() => {
        this.loadingCertificates = false
      })
      return Promise.reject(error)
    }
    return Promise.resolve()
  }

  editUserEducation = async (educationId: number, editedEducation: any) => {
    try {
      await agent.MySkills.editUserEducation(educationId, editedEducation)
    } catch (error) {
      return Promise.reject(error)
    }
    return Promise.resolve()
  }

  certsAddNew = (data: IDropdownProps) => {
    runInAction(() => {
      this.certificates.unshift({
        key: -1,
        value: data.value?.toString(),
        text: data.value?.toString(),
      })
    })
  }

  edusAddNew = (data: IDropdownProps) => {
    runInAction(() => {
      this.educations.unshift({
        key: -1,
        value: data.value?.toString(),
        text: data.value?.toString(),
      })
    })
  }

  removeUserPublication = async (userId: number, publicationId: number) => {
    try {
      await agent.MySkills.deleteUserPublication(userId, publicationId)
    } catch (error) {
      return Promise.reject(error)
    }
    return Promise.resolve()
  }

  editUserPublication = async (publicationId: number, editedPublication: any) => {
    try {
      await agent.MySkills.editUserPublications(publicationId, editedPublication)
    } catch (error) {
      return Promise.reject(error)
    }
    return Promise.resolve()
  }

  isPublicationsEditing = (value: boolean) => {
    this.editPublications = value
  }

  isNewPublication = (value: boolean) => {
    this.newPublications = value
  }

  postNewPublications = async (userId: number, publication: INewPublication) => {
    this.loadingPublications = true
    try {
      await agent.MySkills.postNewPublications(userId, publication)
      runInAction(() => {
        this.loadingPublications = false
      })
    } catch (error) {
      return Promise.reject(error)
    } finally {
      runInAction(() => {
        this.loadingPublications = false
      })
    }
    return Promise.resolve()
  }

  getPersonPublications = async (userId: number) => {
    this.loadingPublications = true
    try {
      const fetchedPublications: IPersonPublications[] = await agent.MySkills.getUserPublications(userId)
      runInAction(() => {
        fetchedPublications.forEach((p: IPersonPublications) => {
          p.publicationDescription = decode(p.publicationDescription)
          p.publicationTitle = decode(p.publicationTitle)
          p.personId
          p.publicationId
        })
        this.publication = fetchedPublications
      })
    } catch (error) {
      runInAction(() => {
        console.log(error)
      })
    } finally {
      runInAction(() => {
        this.loadingPublications = false
      })
    }
  }
}
