import { Grid } from '@mui/material'
import { Form, Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { getErrMsg, toastNeg, toastPos } from '../../../common/toastHelper'
import { IDropdownSkill, INewSkill } from '../../../interfaces/skills/ISkills'
import { useStore } from '../../../Provider'
import { ExitConfirmation } from '../../OPcomponents/ExitConfirmation'
import { OPLoadingCircle } from '../../OPcomponents/OPLoadingcircle'
import { OPMultiline } from '../../OPcomponents/OPMultiline'
import { OPSkillbar } from '../../OPcomponents/OPSkillbar'
import { OPSkillsCombobox } from '../../OPcomponents/OPSkillsCombobox'
import { skillSchema } from '../../OPcomponents/ValidationSchemas'
import '../../styles/grid.scss'
import { OPSkillsButtons } from '../OPSkillsButtons'
import '../profile.scss'

const AddNewSkill = (): JSX.Element => {
  const { t } = useTranslation()
  const rootStore = useStore()
  const { validatedUser } = rootStore.loginStore

  const { filteredSkills, loadingInitial, getAllSkills, postNewSkillToUser, showNewSkillModalHandler } = rootStore.skillStore
  const { getAllUserSkills, loadingSkills, userSkills } = rootStore.personStore

  const initValues: INewSkill = { personId: validatedUser, skillId: -1, skillLevel: 0, interest: 0, comment: '' }

  const filteredDropdownSkills: IDropdownSkill[] = []
  filteredSkills
    ?.filter((i) => userSkills?.every((linkedSkill) => linkedSkill.skillId !== i.skill.skillId))
    .forEach((skill) => {
      filteredDropdownSkills.push({
        key: skill.skill.skillId,
        value: skill.skill.skillId.toString(),
        text: skill.skill.skillName,
      })
    })

  useEffect(() => {
    getAllSkills(validatedUser)
  }, [getAllSkills, getAllUserSkills, validatedUser])

  const onSubmit = async (values: INewSkill) => {
    try {
      await postNewSkillToUser(values, validatedUser)
      await getAllUserSkills(validatedUser)
      toastPos(t('Toasts.SkillUserAdd'), true)
    } catch (error) {
      toastNeg(getErrMsg(error, t) || t('Toasts.SkillAddError'), true)
    } finally {
      showNewSkillModalHandler()
    }
  }

  return (
    <>
      {loadingInitial && loadingSkills ? (
        <OPLoadingCircle />
      ) : (
        <Formik initialValues={initValues} onSubmit={(values) => onSubmit(values)} validationSchema={skillSchema}>
          {({ isValid, dirty, values, setFieldValue, handleChange, isSubmitting, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <ExitConfirmation when={dirty} />
              <Grid container justifyContent='space-between' rowSpacing={4} columnSpacing={8}>
                <Grid item xs={6}>
                  <OPSkillsCombobox
                    placeholder={`${t('Discussions.GoalSkill')}\u2026`}
                    multiple={false}
                    optionList={filteredDropdownSkills}
                    label={`${t('Discussions.GoalSkill')}*`}
                    name='skillId'
                    curState={undefined}
                    onChange={(e, val) => {
                      if (val) setFieldValue('skillId', val.key)
                    }}
                  />
                  <Grid item className='griditem--formitem__formtoppadding'>
                    <OPSkillbar
                      name={'skillLevel'}
                      hasLabel
                      label={`${t('Skills.Level')}*`}
                      value={values.skillLevel ?? 0}
                      onChange={(e, val) => setFieldValue('skillLevel', val)}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <OPMultiline
                    name='comment'
                    placeholder={`${t('Skills.FurtherInfo')}\u2026`}
                    label={t('Skills.FurtherInfo')}
                    hasCounter
                    maxChars={1000}
                    minRows={6}
                    value={values.comment}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <OPSkillsButtons
                    noPadding
                    onClose={showNewSkillModalHandler}
                    onSubmit={handleSubmit}
                    bothDisabled={isSubmitting}
                    disabled={!(isValid && dirty) || isSubmitting}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </>
  )
}

export default observer(AddNewSkill)
