import { Grid } from '@mui/material'
import { useFormikContext } from 'formik'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IDropdownProjects } from '../../../../interfaces/projects/IProjects'
import { useStore } from '../../../../Provider'
import { OPDateField } from '../../../OPcomponents/OPDateField'
import { OPMultiline } from '../../../OPcomponents/OPMultiline'
import { OPSkillsCombobox } from '../../../OPcomponents/OPSkillsCombobox'
import { OPTextField } from '../../../OPcomponents/OPTextField'
import { initValues } from './AddNewPrivateProject'

const AddNewEmployer = (props: { deleteProjectConfirmation: boolean; isEdit: boolean }): JSX.Element => {
  const { deleteProjectConfirmation } = props
  const { t } = useTranslation()
  const rootStore = useStore()
  const { dropdownEmployers, allOtherEmployerDetails } = rootStore.projectStore
  const { validatedUser } = rootStore.loginStore

  const { values, handleChange, setFieldValue, errors, touched, setTouched } = useFormikContext<initValues>()

  const handleAdd = (val: string | IDropdownProjects) => {
    if (typeof val !== 'string' && val.key) {
      const curEmployer = allOtherEmployerDetails.find((emp) => emp.employerId === val.key)
      setFieldValue('employer', {
        name: curEmployer?.name,
        employerId: curEmployer?.employerId,
        userId: validatedUser,
        jobTitle: curEmployer?.jobTitle,
        startDate: curEmployer?.startDate,
        endDate: curEmployer?.endDate,
        jobDescription: curEmployer?.jobDescription,
      })
      setFieldValue('project.employerId', curEmployer?.employerId)
    } else if (typeof val === 'string') {
      setFieldValue('employer', {
        name: val,
        employerId: -1,
        jobTitle: '',
        userId: validatedUser,
        startDate: null,
        endDate: null,
        jobDescription: '',
      })
    }
  }

  return (
    <Grid container gap={4}>
      <Grid item md={5.85} sm={12}>
        <OPSkillsCombobox
          disabled={deleteProjectConfirmation}
          optionList={dropdownEmployers}
          name='employer.employerId'
          label={`${t('Projects.Employer')}*`}
          onChange={(e, val: string | IDropdownProjects) => {
            setTouched({ ...touched, employer: { ...touched.employer, employerId: true } })
            props.isEdit ? '' : handleChange(e)
            if (val) {
              handleAdd(val)
            }
          }}
          curState={dropdownEmployers.find((emp) => emp.key === values.employer.employerId)}
          isAddable
          freeSolo
          manInput={props.isEdit ? false : true}
          multiple={false}
          bold
          error={touched.employer?.employerId && Boolean(errors.employer?.employerId)}
          helperText={t(errors.employer?.employerId || '')}
        />
      </Grid>
      <Grid container spacing={4}>
        <Grid item md={6} sm={12}>
          <OPTextField
            disabled={deleteProjectConfirmation}
            fullWidth
            label={`${t('Projects.JobTitle')}*`}
            onChange={(e) => {
              setTouched({ ...touched, employer: { ...touched.employer, jobTitle: true } })
              handleChange(e)
            }}
            name='employer.jobTitle'
            error={touched.employer?.jobTitle && Boolean(errors.employer?.jobTitle)}
            errorText={t(errors.employer?.jobTitle || '')}
            value={values.employer.jobTitle}
            maxLength={150}
          />
        </Grid>
        <Grid item md={3} sm={12}>
          <OPDateField
            disabled={deleteProjectConfirmation}
            label={`${t('Projects.EmployerStartDate')}*`}
            name='employer.startDate'
            value={values.employer.startDate}
            error={touched.employer?.startDate && Boolean(errors.employer?.startDate)}
            errMessage={t(errors.employer?.startDate || '')}
            onChange={(newValue: Date | null) => {
              setTouched({ ...touched, employer: { ...touched.employer, startDate: true } })
              setFieldValue('employer.startDate', newValue)
            }}
          />
        </Grid>
        <Grid item md={3} sm={12}>
          <OPDateField
            disabled={deleteProjectConfirmation}
            label={t('Projects.EmployerEndDate')}
            name='employer.endDate'
            error={Boolean(errors.employer?.endDate)}
            errMessage={t(errors.employer?.endDate || '')}
            minDate={values.employer.startDate}
            value={values.employer.endDate}
            onChange={(newValue: Date | null) => {
              setFieldValue('employer.endDate', newValue)
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <OPMultiline
          disabled={deleteProjectConfirmation}
          label={t('Projects.JobDescription')}
          name='employer.jobDescription'
          minRows={6}
          hasCounter
          maxChars={1000}
          onChange={handleChange}
          value={values.employer.jobDescription}
        />
      </Grid>
    </Grid>
  )
}

export default observer(AddNewEmployer)
