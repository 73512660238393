import axios, { AxiosResponse } from 'axios'
import FormData from 'form-data'
import {
  IEditColleagueFeedback,
  IEditDevelopmentGoal,
  IEditFeedbackGoal,
  INewColleagueFeedback,
  INewComment,
  INewDevelopmentGoal,
  INewDiscussionVersion,
  INewFeedbackGoal,
} from '../interfaces/discussions/IDiscussion'
import { IPartnerCertificates, IPartnerIndustry, IPartnerInternalComment, IPartnerSector } from '../interfaces/partner/IPartner'
import { ISkillToSubmit } from '../interfaces/partner/IPartnerSkills'
import { IOtherEmployerDetailsForm } from '../interfaces/projects/IProjects'
import { ISkillProject } from '../interfaces/skills/ISkills'
import { getToken } from '../msal/msalConfig'

axios.defaults.baseURL = process.env.REACT_APP_API_URL

const responseBody = (response: AxiosResponse) => response.data

axios.interceptors.response.use(undefined, async (error) => {
  if (error.response?.status === 401) {
    try {
      return axios.request(error.config)
    } catch (e) {
      window.location.reload()
    }
  }
  return Promise.reject(error.response.data)
})

axios.interceptors.request.use(
  async (config) => {
    const token = await getToken()
    if (token && config.headers) config.headers.Authorization = `Bearer ${token}`
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

const requests = {
  get: (url: string) => axios.get(url).then(responseBody),
  post: (url: string, body: {}) => axios.post(url, body).then(responseBody),
  postFormData: (url: string, data: FormData) => axios.post(url, data),
  put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
  putFormData: (url: string, data: FormData) => axios.put(url, data),
  delete: (url: string, body?: {}) => axios.delete(url, { data: body }).then(responseBody),
  getBase64: (url: string) =>
    axios({
      url,
      method: 'GET',
      responseType: 'arraybuffer',
    }).then(responseBody),
  downloadFile: (url: string, defaultFilename: string, selectedCVElements?: boolean[]) =>
    axios(url, {
      data: selectedCVElements,
      method: 'PUT',
      responseType: 'blob',
    }).then((response) => {
      let fileName = defaultFilename
      // Try to get suggested filename from headers if available
      if (response?.headers['content-disposition']) {
        const headerLine = decodeURIComponent(response.headers['content-disposition'])
        if (headerLine.lastIndexOf('UTF-8') !== -1) {
          // Names might contain special characters (äö) so use utf-8 filename if its available
          fileName = headerLine.slice(headerLine.lastIndexOf('UTF-8') + 7)
        } else {
          const startFileNameIndex = headerLine.indexOf('"') + 1
          const endFileNameIndex = headerLine.lastIndexOf('"')
          fileName = headerLine.substring(startFileNameIndex, endFileNameIndex)
        }
      }
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = downloadUrl
      link.setAttribute('download', fileName || defaultFilename)
      document.body.appendChild(link)
      link.click()
    }),
}

const Discussions = {
  getEmployeeDiscussions: (employeeId: number) => requests.get(`/api/DevelopmentDiscussions/Employees/${employeeId}/Discussions`),
  postEmployeeDiscussion: (employeeId: number, body: {}) =>
    requests.post(`/api/DevelopmentDiscussions/Employees/${employeeId}/Discussions`, body),
  postEmployerDiscussion: (employerId: number, employeeId: number, body: {}) =>
    requests.post(`/api/DevelopmentDiscussions/Employers/${employerId}/Discussions/Employees/${employeeId}`, body),
  getEmployeeGoals: (employeeId: number) => requests.get(`/api/DevelopmentDiscussions/Employees/${employeeId}/Goals`),
  getEmployeeGoalsV2: (employeeId: number) => requests.get(`/api/v2/DevelopmentDiscussions/Employees/${employeeId}/Goals`),
  getDiscussion: (discussionId: number) => requests.get(`/api/DevelopmentDiscussions/${discussionId}`),
  approveDiscussionAsEmployee: (employeeId: number, discussionId: number) =>
    requests.put(`/api/DevelopmentDiscussions/Employees/${employeeId}/Discussions/${discussionId}/Approve`, {}),
  approveDiscussionAsEmployer: (employerId: number, discussionId: number) =>
    requests.put(`/api/DevelopmentDiscussions/Employers/${employerId}/Discussions/${discussionId}/Approve`, {}),
  completeDevelopmentGoal: (goalId: number) => requests.put(`/api/DevelopmentDiscussions/Goals/${goalId}/Complete`, {}),
  completeFeedbackGoal: (goalId: number) => requests.put(`/api/DevelopmentDiscussions/FeedbackGoals/${goalId}/Complete`, {}),
  reopenDevelopmentGoal: (goalId: number) => requests.put(`/api/DevelopmentDiscussions/Goals/${goalId}/Reopen`, {}),
  reopenFeedbackGoal: (goalId: number) => requests.put(`/api/DevelopmentDiscussions/FeedbackGoals/${goalId}/Reopen`, {}),
  deleteDevelopmentGoal: (goalId: number) => requests.delete(`/api/DevelopmentDiscussions/Goals/${goalId}`),
  deleteDevelopmentDiscussion: (discussionID: number) => requests.delete(`/api/DevelopmentDiscussions/${discussionID}`),
  deleteFeedbackGoal: (goalId: number) => requests.delete(`/api/DevelopmentDiscussions/FeedbackGoals/${goalId}`),
  deleteColleagueFeedback: (feedbackId: number) => requests.delete(`/api/DevelopmentDiscussions/ColleagueFeedbacks/${feedbackId}`),
  createDevelopmentGoal: (body: INewDevelopmentGoal) => requests.post('/api/DevelopmentDiscussions/Goals', body),
  createFeedbackGoal: (body: INewFeedbackGoal) => requests.post('/api/DevelopmentDiscussions/FeedbackGoals', body),
  createColleagueFeedback: (body: INewColleagueFeedback) => requests.post('/api/DevelopmentDiscussions/ColleagueFeedback', body),
  editDevelopmentGoal: (goalId: number, body: IEditDevelopmentGoal) => requests.put(`/api/DevelopmentDiscussions/Goals/${goalId}`, body),
  editFeedbackGoal: (goalId: number, body: IEditFeedbackGoal) => requests.put(`/api/DevelopmentDiscussions/FeedbackGoals/${goalId}`, body),
  editColleagueFeedback: (body: IEditColleagueFeedback) => requests.put('/api/DevelopmentDiscussions/ColleagueFeedbacks', body),
  getDiscussionVersions: (discussionId: number) => requests.get(`/api/DevelopmentDiscussions/${discussionId}/Versions`),
  postDiscussionVersion: (discussionId: number, draft: boolean, body: INewDiscussionVersion) =>
    requests.post(`/api/DevelopmentDiscussions/${discussionId}/Versions?draft=${draft}`, body),
  getDevelopmentGoals: (discussionId: number) => requests.get(`/api/DevelopmentDiscussions/${discussionId}/DevelopmentGoals`),
  getDiscussionGoals: (discussionId: number) => requests.get(`/api/DevelopmentDiscussions/${discussionId}/Goals`),
  getDiscussionComments: (discussionId: number) => requests.get(`/api/DevelopmentDiscussions/${discussionId}/Comments`),
  postDiscussionComments: (discussionId: number, body: INewComment) =>
    requests.post(`/api/DevelopmentDiscussions/${discussionId}/Comments`, body),
  getFormQuestions: (formId: number) => requests.get(`/api/DevelopmentDiscussions/FormTemplates/${formId}/FormQuestions`),
  getFormTemplates: () => requests.get('/api/DevelopmentDiscussions/FormTemplates'),
  getDevelopmentGoal: (goalId: number) => requests.get(`/api/DevelopmentDiscussions/Goals/${goalId}`),
  getFeedbackGoal: (goalId: number) => requests.get(`/api/DevelopmentDiscussions/FeedbackGoals/${goalId}`),
  postGoalComment: (goalId: number, body: INewComment) => requests.post(`/api/DevelopmentDiscussions/Goals/${goalId}/Comments`, body),
  postFeedbackGoalComment: (goalId: number, body: INewComment) =>
    requests.post(`/api/DevelopmentDiscussions/FeedbackGoals/${goalId}/Comments`, body),
  getManagerDiscussions: () => requests.get('/api/Manager/DevelopmentDiscussions'),
  getHRDiscussions: () => requests.get('/api/HR/DevelopmentDiscussions'),
  getHRDiscussionsV2: () => requests.get('/api/v2/HR/DevelopmentDiscussions'),
  // getManagerEmployees: (userId: number) =>
  //   requests.get(`/api/Manager/Employees/${userId}`),
}

const HomePage = {
  getRecentProjects: () => requests.get('/api/Projects/GetRecentProjects'),
  getRecentSkills: (userId: number) => requests.get(`/api/Skills/GetRecentSkills/${userId}`),
  validateUser: (user: {}) => requests.post('/api/HomePage/ValidateUser', user),
  acceptTerms: (userId: number) => requests.put(`/api/HomePage/AcceptTerms/${userId}`, {}),
  updateUser: (userId: number, mail: string) => requests.put(`/api/HomePage/UpdateUser/${userId}/${mail}`, {}),
  getUserPhoto: (userId: number) => requests.getBase64(`/api/User/Photo/${userId}`),
  getUserLargePhoto: (userId: number) => requests.getBase64(`/api/User/LargePhoto/${userId}`),
  // getUserPermissions: (userId: number) =>
  //   requests.get(`/api/Permissions/${userId}`),
}

const MySkills = {
  getUserOverview: (userId: number) => requests.get(`/api/Overview/User/${userId}`),
  getUserSkills: (userId: number) => requests.get(`/api/MySkills/User/${userId}`),
  getUserNetumProjects: (userId: number) => requests.get(`/api/Workhistory/Public/User/${userId}`),
  getUserPrivateProjects: (userId: number) => requests.get(`/api/Workhistory/Private/User/${userId}`),
  getAllUserCertificates: (userId: number) => requests.get(`/api/Certificates/User/${userId}`),
  getAllCertificates: (userId: number) => requests.get(`/api/Certificates/${userId}`),
  postExistingCertificate: (certificateId: number, body: {}) => requests.post(`/api/Certificates/User/AddExisting/${certificateId}`, body),
  postNewCertificate: (body: {}) => requests.post('/api/Certificates/User/AddNew', body),
  editUserCertificate: (certificateId: number, body: {}) => requests.put(`/api/Certificates/User/Edit/${certificateId}`, body),
  deleteUserCertificate: (userId: number, certificateId: number) =>
    requests.delete(`/api/Certificates/User/Remove/${userId}/${certificateId}`),
  getAllEducations: () => requests.get('/api/Educations'),
  getUserEducations: (userId: number) => requests.get(`/api/Educations/User/${userId}`),
  postExistingEducation: (education: {}, educationId: number) =>
    requests.post(`/api/Educations/User/AddExisting/${educationId}`, education),
  postNewEducation: (body: {}) => requests.post('/api/Educations/User/AddNew', body),
  editUserEducation: (educationId: number, editedEducation: {}) =>
    requests.put(`/api/Educations/User/Edit/${educationId}`, editedEducation),
  deleteUserEducation: (userId: number, educationId: number) => requests.delete(`/api/Educations/User/Remove/${userId}/${educationId}`),
  deleteUserPublication: (userId: number, publicationId: number) =>
    requests.delete(`/api/Publications/User/Remove/${userId}/${publicationId}`),
  getUserPublications: (userId: number) => requests.get(`/api/Publications/User/${userId}`),
  postNewPublications: (userId: number, body: {}) => requests.post(`/api/Publications/User/${userId}`, body),
  editUserPublications: (publicationId: number, body: {}) => requests.put(`/api/Publications/User/Edit/${publicationId}`, body),
  getUserHobbies: (userId: number) => requests.get(`/api/Hobbies/User/${userId}`),
  editUserHobbies: (userId: number, hobbies: {}) => requests.put(`/api/Hobbies/User/${userId}`, hobbies),
  getUserInterests: (userId: number) => requests.get(`/api/Interests/User/${userId}`),
  editUserTitle: (userId: number, title: {}) => requests.put(`/api/Overview/Title/User/Edit/${userId}`, title),
  editUserSummary: (userId: number, summary: {}) => requests.put(`/api/Overview/Summary/User/Edit/${userId}`, summary),
  postExistingLanguage: (language: {}) => requests.post('/api/Overview/Language/User/AddExisting', language),
  getAllLanguages: () => requests.get('/api/Overview/Languages'),
  editPersonLanguage: (body: {}) => requests.put('/api/Overview/Language/User/Edit', body),
  deletePersonLanguage: (userId: number, languageId: number) =>
    requests.delete(`/api/Overview/Language/User/Remove/${userId}/${languageId}`),
  getUserPublicWorkHistory: (userId: number) => requests.get(`/api/Workhistory/Public/User/${userId}`),
  getUserPrivateWorkHistory: (userId: number) => requests.get(`/api/Workhistory/Private/User/${userId}`),
}

const Projects = {
  getAllProjects: (userId: number) => requests.get(`/api/Projects/${userId}`),
  getAllProjectUsers: (projectId: number) => requests.get(`/api/Projects/Persons/${projectId}`),
  getAllBranches: () => requests.get('/api/Branches'),
  getAllEmployers: (userId: number) => requests.get(`/api/Employers?userId=${userId}`),
  getAllEmployerDetails: (userId: number) => requests.get(`/api/Employers/User?userId=${userId}`),
  getAllRoles: () => requests.get('/api/Roles'),
  postEmployer: (employer: {}) => requests.post('/api/Employers/User/Create', employer),
  editEmployerDetails: (employer: IOtherEmployerDetailsForm) => requests.put('/api/Employers/User/Edit', employer),
  postProject: (project: {}) => requests.post('/api/Projects/User/Create', project),
  editUserProject: (project: {}) => requests.put('/api/Projects/User/Edit', project),
  editUserOwnedProject: (project: {}) => requests.put('/api/Projects/User/EditPrivate', project),
  postUserProject: (project: {}) => requests.post('/api/Projects/User/Add', project),
  deleteUserProject: (userId: number, projectId: number) => requests.delete(`/api/Projects/User/Remove/${userId}/${projectId}`),
  getDetailedUserProjects: (userId: number, projectId: number) => requests.get(`/api/Projects/${userId}/${projectId}`),
}

const Skills = {
  getAllSkills: (userId: number) => requests.get(`/api/Skills/${userId}`),
  postNewSkillToUser: (newSkill: {}) => requests.post('/api/Skills/User/Add', newSkill),
  postPersonSkillProject: (newSkillProject: {}) => requests.post('/api/User/ProjectSkillLinks/Create', newSkillProject),
  postPersonLinkedSkills: (newLinkedSkills: ISkillProject[]) => requests.post('/api/User/ProjectSkillLinks/CreateLinks', newLinkedSkills),
  deleteLinkedSkills: (linksToRemove: ISkillProject[]) => requests.delete('/api/User/ProjectSkillLinks/RemoveLinks', linksToRemove),
  deletePersonSkillProject: (skillProject: {}) => requests.delete('/api/User/ProjectSkillLinks/Remove', skillProject),
  linkProjectToSkill: (userId: number) => requests.get(`/api/Skills/Projects/User/${userId}`),
  getUserLinkedSkillProjects: (userId: number, skillId: number) => requests.get(`/api/Skills/${userId}/${skillId}`),
  editUserSkill: (editedSkill: {}) => requests.put('/api/Skills/User/Edit', editedSkill),
  createNewSkill: (newSkill: {}) => requests.post('/api/Skills/Create', newSkill),
  deleteUserSkill: (userId: number, skillId: number) => requests.delete(`/api/Skills/User/Remove/${userId}/${skillId}`),
  addInterest: (userId: number, skillId: number) => requests.post(`/api/Skills/User/AddInterest/${userId}/${skillId}`, {}),
  removeInterest: (userId: number, skillId: number) => requests.delete(`/api/Skills/User/RemoveInterest/${userId}/${skillId}`),
  getSkillPersons: (skillId: number) => requests.get(`/api/v2/Skills/Persons/${skillId}`),
  editSkill: (id: number, body: {}) => requests.put(`/admin/Skills/Edit/${id}`, body),
  addSkillTag: (tagId: number, skillId: number) => requests.post(`/admin/Skills/Tags/AddToSkill/${tagId}/${skillId}`, tagId),
  removeSkillTag: (tagId: number, skillId: number) => requests.delete(`/admin/Skills/Tags/RemoveFromSkill/${tagId}/${skillId}`),
  getTags: () => requests.get('/admin/Tags'),
  getCats: () => requests.get('/admin/Categories'),
  getDepartmentSkills: (userId: number) => requests.get(`/api/Skills/NeededSkills/${userId}`),
  getAllDepartmentSkills: (userId: number) => requests.get(`api/Skills/AllNeededSkills/${userId}`),
}

const Partners = {
  getPartnerList: () => requests.get('/api/Partners'),
  getBasicInformation: (partnerId: number) => requests.get(`/api/Partner/Planmill/${partnerId}`),
  getExperts: (partnerId: number) => requests.get(`/api/Partner/${partnerId}/Persons`),
  getExpertCv: (personId: number, fileName: string) => requests.downloadFile(`/api/Partner/Person/${personId}/Cv`, fileName),
  postExpert: (expert: FormData) => requests.postFormData('/api/Partner/Person', expert),
  putExpert: (expert: FormData) => requests.putFormData('/api/Partner/Person', expert),
  deleteExpert: (partnerId: number, expertId: number) => requests.delete(`/api/Partner/${partnerId}/${expertId}`),
  deleteExpertCv: (personId: number) => requests.delete(`/api/Partner/Person/${personId}/Cv`),
  getSkills: (partnerId: number) => requests.get(`/api/Partner/${partnerId}/Skills`),
  getSkillExperts: (skillId: number) => requests.get(`/api/Partner/Skill/${skillId}/Experts`),
  postNewSkillExperts: (data: ISkillToSubmit) => requests.post('/api/Partner/Skill', data),
  putSkillExperts: (data: ISkillToSubmit) => requests.put('/api/Partner/Skill/Experts', data),
  deleteSkillExpert: (skillId: number, personId: number) => requests.delete(`/api/Partner/Skill/Experts/${skillId}/${personId}`),
  deletePartnerSkill: (partnerId: number, skillId: number) => requests.delete(`/api/Partner/Skill/${partnerId}/${skillId}`),
  getSector: (partnerId: number) => requests.get(`/api/Partner/Sector/${partnerId}`),
  postSector: (sector: IPartnerSector) => requests.post('/api/Partner/Sector', sector),
  getIndustry: (partnerId: number) => requests.get(`/api/Partner/Industry/${partnerId}`),
  getIndustryPersons: (partnerId: number) => requests.get(`/api/Partner/${partnerId}/Industry/Persons`),
  getCertificates: (partnerId: number) => requests.get(`/api/Partner/${partnerId}/Certificates`),
  postPartnerIndustry: (partnerIndustry: IPartnerIndustry) => requests.post('/api/Partner/Industry', partnerIndustry),
  putPartnerIndustry: (partnerIndustry: IPartnerIndustry) => requests.put('/api/Partner/Industry', partnerIndustry),
  deletePartnerIndustry: (partnerId: number, industryId: number) => requests.delete(`/api/Partner/Industry/${partnerId}/${industryId}`),
  getPartnerDescription: (partnerId: number): any => requests.get(`/api/Partner/${partnerId}/Description`),
  editPartnerDescription: (body: {}) => requests.put('/api/Partner/Description', body),
  addPartnerDescription: (description: {}) => requests.post('/api/Partner/Description', { description }),
  postCertificates: (certificates: IPartnerCertificates) => requests.post('/api/partner/Certificates', certificates),
  getInternalRemarks: (partnerId: number) => requests.get(`/api/Partner/${partnerId}/NetumInternalComment`),
  editInternalRemarks: (internalRemark: IPartnerInternalComment) => requests.put('/api/Partner/NetumInternalComment', internalRemark),
  putCertificates: (certificates: IPartnerCertificates) => requests.put('/api/partner/Certificates', certificates),
}

const Persons = {
  getAllUsers: () => requests.get('/api/Persons/List'),
  getAllUsersWithSkills: () => requests.get('/api/Persons/ListWithSkills'),
  getPersonProfileOverview: (personId: number) => requests.get(`/api/Persons/Profile/ProfileOverview/${personId}`),
  getPersonDetails: (personId: number) => requests.get(`/api/Persons/Details/${personId}`),
  getPersonSkills: (personId: number) => requests.get(`/api/Persons/Profile/Skills/${personId}`),
  getPersonPublicProjects: (personId: number) => requests.get(`/api/Persons/Profile/Projects/Public/${personId}`),
  getPersonPrivateProjects: (personId: number) => requests.get(`/api/Persons/Profile/Projects/Private/${personId}`),
  getPersonCertificates: (personId: number) => requests.get(`/api/Persons/Profile/Certificates/${personId}`),
  getPersonEducations: (personId: number) => requests.get(`/api/Persons/Profile/Educations/${personId}`),
  getPersonPublications: (personId: number) => requests.get(`/api/Persons/Profile/Publications/${personId}`),
  getPersonHobbies: (personId: number) => requests.get(`/api/Persons/Profile/Hobbies/${personId}`),
  getPersonInterests: (personId: number) => requests.get(`/api/Persons/Profile/Interests/${personId}`),
  putSelectedElementsAndgetCV: (personId: number, personName: string, selectedCVElements: boolean[], language: string) =>
    requests.downloadFile(`/api/Persons/${personId}/CV?lang=${language}`, `${personName}.docx`, selectedCVElements),
  getCVInfo: (personId: number) => requests.get(`/api/Persons/CV/${personId}`),
  getAllDepartments: () => requests.get('/api/Persons/Departments/'),
  getUserDep: (userId: number) => requests.get(`/api/Persons/UserDepartment/${userId}`),
  getUserTitleText: (userId: number) => requests.get(`/api/Persons/UserDepartmentText/${userId}`),
}

const Search = {
  search: () => requests.get('/api/Search'),
  getProjectPersons: (projectId: number) => requests.get(`/api/Search/Projects/Persons/${projectId}`),
  getEducationPersons: (educationId: number) => requests.get(`/api/Search/Educations/Persons/${educationId}`),
  getCertificatePersons: (certificateId: number) => requests.get(`/api/Search/Certificates/Persons/${certificateId}`),
  getLanguagePersons: (languageId: number) => requests.get(`/api/Search/Languages/Persons/${languageId}`),
  getEducationPersonsWithPhoto: (educationId: number) => requests.get(`/api/v2/Search/Educations/Persons/${educationId}`),
  getCertificatePersonsWithPhoto: (certificateId: number) => requests.get(`/api/v2/Search/Certificates/Persons/${certificateId}`),
  getLanguagePersonsWithPhoto: (languageId: number) => requests.get(`/api/v2/Search/Languages/Persons/${languageId}`),
}

export default {
  MySkills,
  HomePage,
  Partners,
  Projects,
  Skills,
  Persons,
  Search,
  Discussions,
}
