import { observer } from 'mobx-react-lite'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { localizedLanguageName } from '../../../common/languageHelper'
import { ILanguage } from '../../../interfaces/language/ILanguage'
import { useStore } from '../../../Provider'
import { OPAccordionItem } from '../../Accordion/OPAccordionItem'
import { OPLoadMore } from '../../OPcomponents/OPLoadMore'
import { Data, OPNewTable } from '../../OPcomponents/OPNewTable'
import CertEduLangBase from './CertEduLangBase'

interface LangSearchAccordionProps {
  search: string
}

const LangSearchAccordion = (props: LangSearchAccordionProps) => {
  const { search } = props
  const { t } = useTranslation()
  const rootStore = useStore()
  const { allLanguages, getFilteredLanguagesByText, loadingLanguages } = rootStore.personStore
  const { getLanguagePersons, setCertEduLangBase } = rootStore.seachStore
  const [tableLength, setTableLength] = useState(10)

  const [tableRows, setTableRows] = useState<Data[]>([])

  const filteredSearchLanguages = useMemo(() => {
    if (loadingLanguages) return []
    return allLanguages ? getFilteredLanguagesByText(search) : []
  }, [allLanguages, getFilteredLanguagesByText, loadingLanguages, search])

  const onRowClick = (id: number) => {
    setCertEduLangBase({
      id,
      type: 'language',
    })
  }

  useEffect(() => {
    const newRows: Data[] = []
    filteredSearchLanguages.forEach((language: ILanguage) => {
      newRows.push({
        ['id']: language.key ?? -1,
        rows: {
          [t('Persons.Name')]: { text: localizedLanguageName(language.text, t) ?? '' },
        },
      })
      setTableRows(newRows)
    })
  }, [t, filteredSearchLanguages])

  return (
    <OPAccordionItem
      title={`${t('Language.Header')} (${loadingLanguages ? '\u2026' : filteredSearchLanguages.length})`}
      content={
        <>
          {filteredSearchLanguages.length > 0 ? (
            <>
              <OPNewTable
                rows={tableRows}
                curLength={tableLength}
                onRowClick={(id) => onRowClick(Number(id))}
                FormBase={CertEduLangBase}
                isCloseable
              />
              <OPLoadMore
                stepper={10}
                curLength={tableLength}
                maxLength={filteredSearchLanguages.length}
                filteredArray={filteredSearchLanguages}
                onLoadMore={(data) => setTableLength(data)}
              />
            </>
          ) : (
            <span className='profile--notfound'>{t('NotFound.NoResults')}</span>
          )}
        </>
      }
    />
  )
}
export default observer(LangSearchAccordion)
