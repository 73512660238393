import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { Location } from 'history'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Prompt, useHistory, useLocation } from 'react-router'
import '../styles/exitconfirmation.scss'
import { MuiButtonVariants, OPButton } from './OPButton'

interface ExitConfirmationProps {
  when?: boolean | undefined
}

export const ExitConfirmation = observer((props: ExitConfirmationProps): JSX.Element => {
  const { when } = props
  const { t } = useTranslation()

  const [confirmVisible, setConfirmVisible] = useState(false)
  const [lastLoc, setLastLoc] = useState<Location | null>(null)
  const [confirmNav, setConfirmNav] = useState(false)

  const history = useHistory()
  const { pathname } = useLocation()

  const closeModal = () => setConfirmVisible(false)

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmNav && pathname) {
      setConfirmVisible(true)
      setLastLoc(nextLocation)
      return false
    }
    return true
  }

  const handleConfirmNavigationClick = () => {
    setConfirmNav(true)
    setConfirmVisible(false)
  }

  useEffect(() => {
    if (confirmNav && lastLoc) {
      history.push(lastLoc.pathname)
    }
  }, [confirmNav, history, lastLoc])

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <Dialog
        className='exitconfirmation__dialog'
        open={confirmVisible}
        aria-labelledby='exitconfirmation'
        aria-describedby='exitconfirmation'
      >
        <DialogTitle>{t('ExitConfirmation.ExitTitle')}</DialogTitle>
        <DialogContent>
          <div>{t('ExitConfirmation.ExitContent')}</div>
        </DialogContent>
        <DialogActions>
          <OPButton label={t('Buttons.ExitPage')} buttonVariant={MuiButtonVariants.error_outlined} onClick={handleConfirmNavigationClick} />
          <OPButton label={t('Buttons.Back')} buttonVariant={MuiButtonVariants.secondary} onClick={closeModal} />
        </DialogActions>
      </Dialog>
    </>
  )
})
