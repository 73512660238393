import { Form, Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { getErrMsg, toastNeg, toastPos } from '../../common/toastHelper'
import { useStore } from '../../Provider'
import '../styles/developmentdiscussion.scss'
import { ExitConfirmation } from '../OPcomponents/ExitConfirmation'
import { MuiButtonVariants, OPButton } from '../OPcomponents/OPButton'
import { OPDateField } from '../OPcomponents/OPDateField'
import { OPDivider } from '../OPcomponents/OPDivider'
import { OPMultiline } from '../OPcomponents/OPMultiline'
import { OPSnackbar } from '../OPcomponents/OPSnackbar'

export const NewDiscussion = observer((): JSX.Element => {
  const { t } = useTranslation()
  const rootStore = useStore()
  const { validatedUser } = rootStore.loginStore
  const {
    setIsNewDevDiscussionVisible,
    setIsNewMiniDiscussionVisible,
    postEmployeeDiscussion,
    postEmployerDiscussion,
    selectedTemplate,
    selectTemplate,
    formTemplates,
  } = rootStore.kekeStore
  const { userId } = useParams() as {
    userId?: string
  }
  const isEmployee = userId === undefined

  const divRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setTimeout(() => divRef.current?.scrollIntoView({ behavior: 'smooth' }), 100)
  }, [])

  return (
    <Formik
      initialValues={{
        date: new Date(),
        note: '',
        formTemplateId: selectedTemplate?.formTemplateId ?? formTemplates[0].formTemplateId,
      }}
      onSubmit={async (values) => {
        try {
          if (isEmployee) {
            await postEmployeeDiscussion(validatedUser, values)
          } else {
            await postEmployerDiscussion(validatedUser, Number(userId), values)
          }
          toastPos(t('Toasts.DiscussionCreate'))
          setIsNewDevDiscussionVisible(false)
        } catch (error) {
          toastNeg(getErrMsg(error, t) || t('Toasts.DiscussionCreateError'))
        }
        setIsNewDevDiscussionVisible(false)
        setIsNewMiniDiscussionVisible(false)
        selectTemplate(undefined)
      }}
    >
      {({ setFieldValue, values, handleChange, dirty }) => (
        <Form>
          <ExitConfirmation when={dirty} />
          <OPDivider />
          <div ref={divRef} className='newDiscTitle'>
            {selectedTemplate?.type === 'Kehityskeskustelu' ? t('Discussions.NewDiscussion') : t('Discussions.NewMiniDiscussion')}
          </div>
          <div className='fieldPadding'>
            <OPSnackbar
              text1={
                selectedTemplate?.type === 'Mini kehityskeskustelu'
                  ? t('Discussions.InfoSnack9')
                  : isEmployee
                  ? t('Discussions.InfoSnack1')
                  : t('Discussions.InfoSnack3')
              }
              type='discussionInfo'
            />
          </div>
          <div className='horizontalPadding'>
            <OPDateField
              label={t('Discussions.DiscussionSchedule')}
              value={values.date}
              onChange={(newDate) => {
                if (newDate !== null) setFieldValue('date', newDate)
              }}
            />
          </div>
          <div className='fieldPadding'>
            <OPMultiline
              resizeable
              name='note'
              label={selectedTemplate?.type === 'Kehityskeskustelu' ? t('Discussions.DiscussionNote') : t('Discussions.DiscussionMiniNote')}
              placeholder={
                selectedTemplate?.type === 'Kehityskeskustelu'
                  ? t('Discussions.DiscussionNotePlaceholder')
                  : t('Discussions.DiscussionMiniNotePlaceholder')
              }
              value={values.note}
              onChange={handleChange}
              rows={4}
            />
          </div>
          <div className='saveDeleteButtonsDiv'>
            <div className='saveButton'>
              <OPButton
                label={t('Buttons.Cancel')}
                buttonVariant={MuiButtonVariants.error_outlined}
                onClick={() => {
                  setIsNewDevDiscussionVisible(false)
                  setIsNewMiniDiscussionVisible(false)
                  selectTemplate(undefined)
                }}
              />
            </div>
            <div>
              <OPButton label={t('Buttons.Save')} buttonVariant={MuiButtonVariants.primary} type='submit' />
            </div>
          </div>
          {selectedTemplate?.type === 'Kehityskeskustelu' && <OPDivider />}
        </Form>
      )}
    </Formik>
  )
})
