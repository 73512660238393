import { Grid } from '@mui/material'
import { useFormikContext } from 'formik'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../../../Provider'
import { OPCheckBox } from '../../../OPcomponents/OPCheckBox'
import { OPMultiline } from '../../../OPcomponents/OPMultiline'
import { OPRadioButton } from '../../../OPcomponents/OPRadioButton'
import { OPSkillsCombobox } from '../../../OPcomponents/OPSkillsCombobox'
import { OPTextField } from '../../../OPcomponents/OPTextField'
import { initValues } from './AddNewPrivateProject'

const NewPrivateProject = (props: { deleteProjectConfirmation: boolean }): JSX.Element => {
  const { deleteProjectConfirmation } = props
  const { t } = useTranslation()
  const rootStore = useStore()
  const { dropdownBranches, getAllBranches } = rootStore.projectStore

  const { values, handleChange, setFieldValue, errors, touched, setTouched } = useFormikContext<initValues>()

  useEffect(() => {
    getAllBranches()
  }, [getAllBranches])

  const radioLabels = [
    { label: `${t('Projects.Public')}`, value: 'true' },
    { label: `${t('Projects.Private')}`, value: 'false' },
  ]

  return (
    <Grid container gap={4}>
      <Grid container gap={4}>
        <Grid container spacing={4}>
          <Grid item md={6} sm={12}>
            <OPTextField
              label={`${t('Projects.ProjectName')}*`}
              value={values.project.name}
              name='project.name'
              onChange={(e) => {
                setTouched({ ...touched, project: { ...touched.project, name: true } })
                handleChange(e)
              }}
              fullWidth
              maxLength={100}
              error={touched.project?.name && Boolean(errors.project?.name)}
              errorText={t(errors.project?.name || '')}
              disabled={deleteProjectConfirmation}
            />
          </Grid>
          <Grid item md={6} sm={12} />
        </Grid>
        <Grid container spacing={4}>
          <Grid item md={6} sm={12}>
            <OPTextField
              label={t('Projects.Customer')}
              value={values.project.customer}
              name='project.customer'
              onChange={handleChange}
              fullWidth
              maxLength={100}
              disabled={deleteProjectConfirmation}
            />
          </Grid>
          <Grid item md={6} sm={12}>
            <OPSkillsCombobox
              multiple={false}
              name='project.branchId'
              label={`${t('Projects.CustomerBranch')}*`}
              onChange={(e, v) => {
                setTouched({ ...touched, project: { ...touched.project, branchId: true } })
                setFieldValue('project.branchId', v.key)
              }}
              disabled={deleteProjectConfirmation}
              optionList={dropdownBranches}
              error={touched.project?.branchId && Boolean(errors.project?.branchId)}
              helperText={t(errors.project?.branchId || '')}
              curState={dropdownBranches.find((b) => b.key === values.project.branchId) ?? null}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <OPMultiline
            name='project.description'
            label={t('Projects.ProjectDescription')}
            onChange={handleChange}
            value={values.project.description}
            disabled={deleteProjectConfirmation}
            minRows={5}
            hasCounter
            maxChars={1000}
            placeholder={t('Projects.ProjectDescription')}
          />
        </Grid>
        <Grid item xs={12}>
          <OPRadioButton
            name='project.public'
            radioOptions={radioLabels}
            disabled={deleteProjectConfirmation}
            label={''}
            defaultVal={values.project.public ? 'true' : 'false'}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <OPCheckBox
            name='project.canUseAsReference'
            label={t('Projects.NoReference')}
            value={!values.project.canUseAsReference ?? false}
            disabled={deleteProjectConfirmation}
            onChangeState={(e, c) => setFieldValue('project.canUseAsReference', !c)}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default observer(NewPrivateProject)
