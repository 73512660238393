import { BrowserUtils } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5'

import gdprfile from '../../assets/documents/Netum_Osaamispankki_tietosuojaseloste_sis_v2.1_2022.pdf'
import { getErrMsg, toastNeg } from '../../common/toastHelper'
import { useStore } from '../../Provider'
import '../styles/gdpr.scss'
import { Cross } from '../styles/Icons'
import { MuiButtonVariants, OPButton } from './OPButton'

export const GDPR = observer((): JSX.Element => {
  const { instance } = useMsal()

  const { t } = useTranslation()
  const rootStore = useStore()

  const [showTerms, setShowTerms] = useState<boolean>(false)
  const [numPages, setNumPages] = useState<null | number>(null)

  const { validatedUser, acceptTerms, showTermsModal, setShowTermsModal } = rootStore.loginStore

  const accept = async () => {
    try {
      await acceptTerms(validatedUser)
      setShowTermsModal(false)
    } catch (error) {
      toastNeg(getErrMsg(error, t) || t('Toasts.TermsError'))
    }
  }

  if (!showTermsModal) {
    return <></>
  }

  return showTerms ? (
    <Dialog className='gdpr__pdf' open={showTerms} onClose={() => setShowTerms(false)}>
      <DialogTitle className='gdpr__pdf__title'>
        <IconButton disableRipple aria-label='close' onClick={() => setShowTerms(false)} className='crossbutton'>
          <Cross />
        </IconButton>
      </DialogTitle>
      <DialogContent className='gdpr__pdf__container'>
        <Document
          loading={
            <div className='gdpr__pdf__container--loading'>
              <CircularProgress />
            </div>
          }
          file={gdprfile}
          onLoadSuccess={({ numPages }) => {
            setNumPages(numPages)
          }}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={96 / 72} />
          ))}
        </Document>
      </DialogContent>
    </Dialog>
  ) : (
    <Dialog className='gdpr__dialog' open={!showTerms} aria-labelledby='privacy-policy' aria-describedby='privacy-policy'>
      <DialogTitle className='gdpr__dialog__title'>{t('Terms.Title')}</DialogTitle>
      <DialogContent className='gdpr__dialog__content'>
        <div>
          <div>{t('Terms.ContentText')}</div>
          <div>
            {`${showTerms ? t('Terms.HidePolicy') : t('Terms.ShowPolicy')} `}
            <span className='gdpr__dialog__content--clickable' onClick={() => setShowTerms(!showTerms)}>
              {t('Terms.ShowTermsText')}
            </span>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <OPButton
          label={t('Buttons.Decline')}
          buttonVariant={MuiButtonVariants.error}
          onClick={() =>
            instance.logoutRedirect({
              account: instance.getActiveAccount(),
              onRedirectNavigate: () => !BrowserUtils.isInIframe(),
            })
          }
        />
        <OPButton label={t('Buttons.ApproveVersion')} buttonVariant={MuiButtonVariants.primary} onClick={() => accept()} />
      </DialogActions>
    </Dialog>
  )
})
