import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import React, { useEffect, useState } from 'react'
import '../styles/multiline.scss'

interface OPMultilineProps {
  name?: string
  label: string
  maxChars?: number
  width?: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
  value?: string
  hasCounter?: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  rows?: number
  minRows?: number
  maxRows?: number
  error?: boolean
  readOnly?: boolean
  type?: 'text' | 'password'
  dataTestId?: string
  tabIndex?: number
  resizeable?: boolean
  errorText?: string
}

export const OPMultiline = (props: OPMultilineProps): JSX.Element => {
  const {
    name,
    label,
    value,
    maxChars,
    rows,
    minRows,
    maxRows,
    placeholder,
    width,
    hasCounter,
    required,
    disabled,
    onChange,
    error,
    readOnly,
    type,
    dataTestId,
    tabIndex,
    resizeable,
    errorText,
  } = props

  const [counterValue, setCounterValue] = useState<number | undefined>(maxChars)
  const [focused, setFocused] = useState<boolean>(false)

  useEffect(() => {
    if (hasCounter && maxChars && value) setCounterValue(maxChars - value.length)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (hasCounter && maxChars) setCounterValue(maxChars - e.target.value.length)
    onChange(e)
  }

  return (
    <div style={{ width: width }}>
      <InputLabel className='multilineLabel' required={required} disabled={disabled} error={error}>
        {label}
      </InputLabel>
      <Input
        name={name}
        className={`inputstyling ${error && !disabled ? 'error' : ''}`}
        type={type ? type : 'text'}
        inputProps={{ 'data-testid': dataTestId, maxLength: maxChars, className: `textArea ${resizeable ? 'resize' : ''}` }}
        required={required}
        disabled={disabled}
        placeholder={focused ? '' : placeholder}
        fullWidth
        error={error}
        disableUnderline
        multiline
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        readOnly={readOnly}
        rows={rows}
        minRows={minRows}
        maxRows={maxRows}
        value={value}
        onChange={handleChange}
        tabIndex={tabIndex ?? 0}
      />
      <div className={error && !disabled ? 'helperbox' : ''}>
        {error && !disabled && <div className='errortext'>{errorText}</div>}
        {hasCounter && (
          <div
            className={`multiCounter ${error && !disabled ? 'counterError' : ''} ${disabled ? 'counterDisabled' : ''} ${
              counterValue && counterValue < 21 ? 'counterMedium' : ''
            } ${counterValue === 0 ? 'counterZero' : ''}`}
          >{`${counterValue}/${maxChars}`}</div>
        )}
      </div>
    </div>
  )
}
