import { Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useTranslation } from 'react-i18next'
import history from '../../history'
import { Toast } from '../OPcomponents/Toast'
import './ceretedu.scss'

import { ICertEduClicked } from '../../interfaces/certAndEducation/certAndEducation'
import Certificates from './certedu/Certificates'
import Educations from './certedu/Educations'
import Publications from './certedu/Publications'

const CertEdu = (): JSX.Element => {
  const { t } = useTranslation()

  const profileClicked = history.location.state as ICertEduClicked

  return (
    <Grid spacing={4} container>
      <Toast />
      <Grid item xs={12}>
        <span className='profile--titlebigfont'>{t('Buttons.CertEduPublic')}</span>
      </Grid>
      <Grid item xs={12}>
        <Certificates
          isOpen={profileClicked?.whatClicked !== 'education' && profileClicked?.whatClicked !== 'publication'}
          rowId={
            profileClicked?.whatClicked !== 'education' && profileClicked?.whatClicked !== 'publication' ? profileClicked?.rowId : undefined
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Educations
          isOpen={profileClicked?.whatClicked === 'education'}
          rowId={profileClicked?.whatClicked === 'education' ? profileClicked?.rowId : undefined}
        />
      </Grid>
      <Grid item xs={12}>
        <Publications isOpen={profileClicked?.whatClicked === 'publication'} rowId={profileClicked?.rowId} />
      </Grid>
    </Grid>
  )
}

export default observer(CertEdu)
