import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import React from 'react'
import '../styles/switch.scss'

interface SwitchProps {
  isChecked: boolean
  label: string
  value: string
  ariaLabel?: string
  dataTestId?: string
  disabled?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const OPSwitch = (props: SwitchProps): JSX.Element => {
  const { isChecked, value, label, ariaLabel, dataTestId, disabled, onChange } = props

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(e)
  }

  return (
    <FormControlLabel
      className='switchLabel'
      control={
        <Switch
          className='OPSwitch'
          checked={isChecked}
          value={value}
          onChange={handleChange}
          inputProps={{ 'aria-label': ariaLabel }}
          data-testid={dataTestId}
          disableRipple
        />
      }
      label={label}
      disabled={disabled}
    />
  )
}
