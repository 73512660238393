import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ICertificate } from '../../interfaces/certAndEducation/certAndEducation'
import { IPartnerList } from '../../interfaces/partner/IPartner'
import { IListPersonWithSkills } from '../../interfaces/persons/IPerson'
import { IProjects } from '../../interfaces/projects/IProjects'
import { ISkills } from '../../interfaces/skills/ISkills'
import { IEmployee } from '../../stores/managerStore/managerStore'
import '../styles/loadmore.scss'

import { MuiButtonVariants, OPButton } from './OPButton'

interface OPLoadMoreProps {
  maxLength: number
  curLength: number
  startingStepper?: number
  stepper: number
  filteredArray: IEmployee[] | IProjects[] | ISkills[] | IListPersonWithSkills[] | ICertificate[] | IPartnerList[]
  onLoadMore: (newVal: number) => void
}

export const OPLoadMore = observer((props: OPLoadMoreProps): JSX.Element => {
  const { stepper, onLoadMore, startingStepper, curLength, maxLength } = props
  const { t } = useTranslation()
  const [showing, setShowing] = useState<number>(startingStepper ? startingStepper : curLength)
  const [firstLoad, setFirstLoad] = useState<boolean>(true)

  const handleClick = () => {
    const nextStep = curLength + stepper
    onLoadMore(nextStep >= maxLength ? maxLength : nextStep)
  }

  useEffect(() => {
    if (curLength < stepper && curLength < maxLength) {
      onLoadMore(stepper)
    } else if (curLength >= maxLength) {
      onLoadMore(maxLength)
    }
  }, [curLength, maxLength, onLoadMore, stepper])

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false)
      if (startingStepper) {
        onLoadMore(startingStepper)
      }
    }
  }, [curLength, firstLoad, onLoadMore, showing, startingStepper])

  return (
    <div className='loadmore__content'>
      <div className='loadmore__content--text'>{`${t('Loading.NowShowing')} ${curLength} / ${maxLength}`}</div>
      {maxLength > 10 && (
        <div>
          <OPButton
            disabled={curLength === maxLength}
            label={t('Buttons.ShowMore')}
            buttonVariant={MuiButtonVariants.secondary_outlined}
            onClick={handleClick}
          />
        </div>
      )}
    </div>
  )
})
