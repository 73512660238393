import { Grid } from '@mui/material'
import { useFormikContext } from 'formik'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ISkillProject } from '../../../../interfaces/skills/ISkills'
import { useStore } from '../../../../Provider'
import { OPDateField } from '../../../OPcomponents/OPDateField'
import { OPMultiline } from '../../../OPcomponents/OPMultiline'
import { OPNumberField } from '../../../OPcomponents/OPNumberField'
import { OPRadioButton } from '../../../OPcomponents/OPRadioButton'
import { OPSkillsCombobox } from '../../../OPcomponents/OPSkillsCombobox'
import ProjectSkillLink from '../newpubproject/ProjectSkillLink'
import { initValues } from './AddNewPrivateProject'

interface NewPersonalPrivateProjectProps {
  setProjectSkills: (skills: ISkillProject[]) => void
  projectSkills: ISkillProject[]
  deleteProjectConfirmation?: boolean
  isNewProject?: boolean
}

const NewPersonalPrivateProject = (props: NewPersonalPrivateProjectProps): JSX.Element => {
  const { setProjectSkills, projectSkills, deleteProjectConfirmation, isNewProject } = props

  const { t } = useTranslation()
  const rootStore = useStore()
  const { dropdownRoles, getAllRoles } = rootStore.projectStore

  useEffect(() => {
    getAllRoles()
  }, [getAllRoles])

  const radioOptions = [
    {
      value: '1',
      label: t('Projects.ManDays'),
    },
    {
      value: '0',
      label: t('Projects.Percentage'),
    },
  ]

  const { values, handleChange, setFieldValue, errors, touched, setTouched } = useFormikContext<initValues>()

  return (
    <Grid container gap={4}>
      <Grid xs={12} item>
        <span className='myskill--font__titlefont sini'>{t('Projects.ProjectForMe')}</span>
      </Grid>
      <Grid container spacing={4}>
        <Grid item md={6} sm={12}>
          <OPSkillsCombobox
            name='userProject.roleIds'
            label={t('Projects.ProjectRoles')}
            placeholder={t('Placeholders.Choose')}
            multiple
            disabled={deleteProjectConfirmation}
            onDelete={(v) =>
              setFieldValue(
                'userProject.roleIds',
                values.userProject.roleIds.filter((role) => role.key !== v.option.key),
              )
            }
            optionList={dropdownRoles}
            onChange={(e, v) => setFieldValue('userProject.roleIds', v)}
            curState={values.userProject.roleIds}
          />
        </Grid>
        <Grid item md={3} sm={12}>
          <OPDateField
            name='userProject.startDate'
            label={`${t('Projects.EmployerStartDate')}*`}
            value={values.userProject.startDate}
            error={touched.userProject?.startDate && Boolean(errors.userProject?.startDate)}
            errMessage={t(errors.userProject?.startDate || '')}
            disabled={deleteProjectConfirmation}
            onChange={(newValue: Date | null) => {
              setTouched({ ...touched, userProject: { ...touched.userProject, startDate: true } })
              setFieldValue('userProject.startDate', newValue)
            }}
          />
        </Grid>
        <Grid item md={3} sm={12}>
          <OPDateField
            name='userProject.endDate'
            label={t('Projects.EmployerEndDate')}
            minDate={values.userProject.startDate}
            value={values.userProject.endDate}
            error={touched.userProject?.endDate && Boolean(errors.userProject?.endDate)}
            errMessage={t(errors.userProject?.endDate || '')}
            disabled={deleteProjectConfirmation}
            onChange={(newValue: Date | null) => {
              setTouched({ ...touched, userProject: { ...touched.userProject, endDate: true } })
              setFieldValue('userProject.endDate', newValue)
            }}
          />
        </Grid>
        <Grid item md={4} sm={12}>
          {values.userProject.usingManDays ? (
            <OPNumberField
              label={t('Projects.ProjectWorkAmount')}
              name='userProject.manDays'
              value={values.userProject.manDays}
              error={Boolean(errors.userProject?.manDays)}
              errorText={t(errors.userProject?.manDays || '')}
              disabled={deleteProjectConfirmation}
              onChange={(e) => setFieldValue('userProject.manDays', e ?? 0)}
            />
          ) : (
            <OPNumberField
              name='userProject.quota'
              label={t('Projects.ProjectWorkAmount')}
              minValue={0}
              maxValue={100}
              error={Boolean(errors.userProject?.quota)}
              errorText={t(errors.userProject?.quota || '')}
              value={values.userProject.quota}
              disabled={deleteProjectConfirmation}
              onChange={(e) => setFieldValue('userProject.quota', e ?? 0)}
            />
          )}
        </Grid>
        <Grid item xs className='griditem--formitem__radiomargin'>
          <OPRadioButton
            direction='row'
            name='userProject.usingManDays'
            radioOptions={radioOptions}
            label={''}
            disabled={deleteProjectConfirmation}
            onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('userProject.usingManDays', v.target.value === '1')
            }}
            defaultVal={values.userProject.usingManDays ? '1' : '0'}
          />
        </Grid>
        <Grid xs={12} item>
          <OPMultiline
            name='userProject.description'
            label={t('Projects.WorkDescription')}
            onChange={handleChange}
            maxChars={1000}
            placeholder={t('Projects.WorkDescription')}
            minRows={6}
            value={values.userProject.description}
            disabled={deleteProjectConfirmation}
            hasCounter
          />
        </Grid>
        <Grid xs={12} item>
          <ProjectSkillLink
            isNewProject={isNewProject}
            projectId={values.userProject.projectId}
            deleteProjectConfirmation={deleteProjectConfirmation}
            setProjectSkills={setProjectSkills}
            curProjectSkills={projectSkills}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default observer(NewPersonalPrivateProject)
