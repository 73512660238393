import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IProjectFormPackage, IProjects } from '../../../interfaces/projects/IProjects'
import { useStore } from '../../../Provider'
import { OPAccordionItem } from '../../Accordion/OPAccordionItem'
import { OPLoadMore } from '../../OPcomponents/OPLoadMore'
import { Data, OPNewTable } from '../../OPcomponents/OPNewTable'
import ProjectInfo from '../../Projects/ProjectInfo'

interface ProjectSearchAccordionProps {
  search: string
}

const ProjectSearchAccordion = (props: ProjectSearchAccordionProps) => {
  const { search } = props
  const { t, i18n } = useTranslation()
  const rootStore = useStore()
  const { validatedUser } = rootStore.loginStore
  const { filteredProjects, loadingInitial, getDetailedUserProjects, getUserProjectTitle, getFilteredProjectsBytext } =
    rootStore.projectStore

  const lang = i18n.language
  const [tableRows, setTableRows] = useState<Data[]>([])
  const [tableLength, setTableLength] = useState(10)

  const filteredSearchProjects = useMemo(() => {
    if (loadingInitial) return []
    return filteredProjects ? getFilteredProjectsBytext(search) : []
  }, [loadingInitial, filteredProjects, getFilteredProjectsBytext, search])

  useEffect(() => {
    const newRows: Data[] = []
    filteredSearchProjects.forEach((project: IProjects) => {
      newRows.push({
        ['id']: project.project.projectId,
        rows: {
          [t('Projects.ProjectName')]: { text: project.project.name ?? '', width: '22%' },
          [t('Projects.ProjectDescription')]: { text: project.project.description ?? '', width: '21%' },
          [t('Projects.Customer')]: { text: project.project.customer ?? '', width: '21%' },
          [t('Projects.ProjectStartDate')]: {
            text: project.project.startDate != null ? moment(project.project.startDate).locale(lang).format('L') : '',
            width: '13%',
          },
          [t('Projects.ProjectEndDate')]: {
            text: project.project.endDate != null ? moment(project.project.endDate).locale(lang).format('L') : '',
            width: '13%',
          },
          [t('Projects.Members')]: { text: project.project.osaajat ?? 0, width: '10%' },
        },
      })
    })
    setTableRows(newRows)
  }, [lang, t, filteredSearchProjects])

  const onRowClickHandler = async (id: number | string) => {
    const clickedProject = filteredSearchProjects.find((proj) => proj.project.projectId === id)
    if (clickedProject?.project) {
      const userDetails = await getDetailedUserProjects(validatedUser, clickedProject.project.projectId ?? -1)
      const projectUsers = await getUserProjectTitle(clickedProject.project.projectId ?? -1)

      const projectInfo: IProjectFormPackage = {
        project: clickedProject.project,
        userHas: clickedProject.userHas ?? false,
        projectUsers: projectUsers,
        userDetails: {
          details: userDetails,
          projectOwner: userDetails.projectOwner !== 0,
        },
      }
      return projectInfo
    }
  }

  return (
    <OPAccordionItem
      title={`${t('Projects.Header')} (${loadingInitial ? '\u2026' : filteredSearchProjects.length})`}
      content={
        <>
          {filteredSearchProjects.length > 0 ? (
            <>
              <OPNewTable rows={tableRows} curLength={tableLength} onRowClick={onRowClickHandler} FormBase={ProjectInfo} />
              <OPLoadMore
                stepper={10}
                filteredArray={filteredSearchProjects}
                curLength={tableLength}
                maxLength={filteredSearchProjects.length}
                onLoadMore={(data) => setTableLength(data)}
              />
            </>
          ) : (
            <span className='profile--notfound'>{t('NotFound.NoResults')}</span>
          )}
        </>
      }
    />
  )
}

export default observer(ProjectSearchAccordion)
