import React from 'react'

type ConditonalWrapperProps = {
  children: React.ReactElement
  condition: boolean
  wrapper: (children: React.ReactElement) => JSX.Element
}

const ConditionalWrapper: React.FC<ConditonalWrapperProps> = ({ condition, wrapper, children }) => {
  return condition ? wrapper(children) : children
}

export default ConditionalWrapper
