import { Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../../Provider'
import { OPLoadingCircle } from '../../OPcomponents/OPLoadingcircle'
import { OPNewTable } from '../../OPcomponents/OPNewTable'

interface IProps {
  userId: string
}

const ProfileInterests = (props: IProps): JSX.Element => {
  const { userId } = props
  const { t } = useTranslation()
  const rootStore = useStore()
  const { getPersonInterests, personInterests, loadingInterests, loadingHobbies, getPersonHobbies, personHobbies } = rootStore.profileStore

  useEffect(() => {
    async function fetchData() {
      await getPersonInterests(Number(userId))
      await getPersonHobbies(Number(userId))
    }
    fetchData()
  }, [getPersonHobbies, getPersonInterests, userId])

  const interestsList = personInterests.map((interest) => {
    return {
      id: interest.name,
      rows: {
        [t('Buttons.Interests')]: { text: interest.name },
      },
    }
  })

  return (
    <Grid container spacing={5} className='gridcontainer--formcontainer__addnewpaddings'>
      {loadingHobbies || loadingInterests ? (
        <Grid item xs={12}>
          <OPLoadingCircle />
        </Grid>
      ) : (
        <>
          <Grid item xs={12}>
            <Grid item xs={12}>
              {interestsList.length > 0 ? (
                <OPNewTable rows={interestsList} curLength={interestsList.length} />
              ) : (
                <p className='profile--deptext profpadding'>{t('Profile.NoInterests')}</p>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <p className='profile--titlecaption profpadding'>{t('Interests.HobbiesHeader')}:</p>
              </Grid>
              <Grid item xs={12}>
                {personHobbies.length > 0 ? (
                  <p className='profile--breakword profile--deptext profpadding'>{personHobbies}</p>
                ) : (
                  <p className='profile--deptext profpadding'>{t('Profile.NoHobbies')}</p>
                )}
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default observer(ProfileInterests)
