import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { localizedLanguageName, localizedLanguageSkills } from '../../../common/languageHelper'
import history from '../../../history'
import { useStore } from '../../../Provider'
import { Data, OPNewTable } from '../../OPcomponents/OPNewTable'

import EditLanguage from './EditLanguage'

const LanguageTable = (): JSX.Element => {
  const { t } = useTranslation()

  const rootStore = useStore()

  const { filteredLanguages, getCurrentLanguageToEdit, setOpenLanguageEditModal } = rootStore.personStore

  const profileClickedId = history.location.state as string

  const onRowClick = (id: number | string) => {
    setOpenLanguageEditModal(true)
    return id
  }
  const languageSkillOptions = localizedLanguageSkills(t)

  useEffect(() => {
    return () => {
      setOpenLanguageEditModal(false)
      getCurrentLanguageToEdit('')
    }
  }, [setOpenLanguageEditModal, getCurrentLanguageToEdit])

  const langRows: Data[] = []

  filteredLanguages.map((lang) => {
    langRows.push({
      ['id']: lang.languageName,
      rows: {
        [t('Language.Language')]: { text: localizedLanguageName(lang.languageName, t), width: '22%' },
        [t('Language.SkillLevel')]: {
          text:
            languageSkillOptions.find((language) => {
              return language.key === lang.languageLevel
            })?.text ?? lang.languageLevel,
          width: '17%',
        },
        [t('Language.Description')]: { text: lang.description, width: '61%' },
      },
    })
  })

  return <OPNewTable profileclickedId={profileClickedId} rows={langRows} curLength={langRows.length} FormBase={EditLanguage} onRowClick={onRowClick} />
}

export default observer(LanguageTable)
