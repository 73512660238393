import { Grid } from '@mui/material'
import { t } from 'i18next'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useStore } from '../../../Provider'
import { OPAccordionItem } from '../../Accordion/OPAccordionItem'
import { MuiButtonVariants, OPButton } from '../../OPcomponents/OPButton'
import { OPLoadingCircle } from '../../OPcomponents/OPLoadingcircle'
import '../profile.scss'

import AddNewPrivateProject from './newprivproject/AddNewPrivateProject'
import PrivateWorkHistoryTable from './PrivateWorkHistoryTable'

const PrivateWorkhistory = (props: { rowId: number | undefined }): JSX.Element => {
  const rootStore = useStore()
  const { rowId } = props
  const { filteredPrivateWorkHistories, privateWorkHistoryLoading } = rootStore.personStore
  const { showAddPrivateProjectModalHandler, showAddPrivateProjectModal, showAddProjectModal, loadingEmployerDetails, loadingInitial } =
    rootStore.projectStore
  return (
    <Grid container item display='flex'>
      <Grid container item className='gridcontainer--certedu__addbuttonmargin'>
        <Grid item className='griditem--addbutton'>
          <OPButton
            disabled={showAddPrivateProjectModal || showAddProjectModal}
            label={t('Buttons.AddNew')}
            buttonVariant={MuiButtonVariants.primary_outlined}
            onClick={() => showAddPrivateProjectModalHandler(true)}
          />
        </Grid>
      </Grid>
      <OPAccordionItem
        setOutsideExpanded={showAddPrivateProjectModal || rowId !== undefined}
        title={`${t('WorkHistory.PrivateHeader')} (${privateWorkHistoryLoading ? '\u2026' : filteredPrivateWorkHistories.length})`}
        content={
          <Grid container className='gridcontainer--workhistory__formpadding'>
            {showAddPrivateProjectModal && (
              <>
                <Grid item xs={12} className='griditem--formitem__zindex'>
                  <AddNewPrivateProject isCreateNewProject={true} />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              {loadingEmployerDetails || privateWorkHistoryLoading || loadingInitial ? (
                <OPLoadingCircle />
              ) : (
                <PrivateWorkHistoryTable rowId={rowId} />
              )}
            </Grid>
          </Grid>
        }
      />
    </Grid>
  )
}

export default observer(PrivateWorkhistory)
