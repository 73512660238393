import { Grid, IconButton, ListItemButton, ListItemText } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import history from '../../../history'
import { ISkillFormPackage, IUserInterest } from '../../../interfaces/skills/ISkills'
import { useStore } from '../../../Provider'
import { MuiButtonVariants, OPButton } from '../../OPcomponents/OPButton'
import { Data, OPNewTable } from '../../OPcomponents/OPNewTable'
import OpenSkill from '../../Skills/OpenSkill'
import { DeleteIconBlack } from '../../styles/Icons'

const InterestTable = (): JSX.Element => {
  const { t } = useTranslation()
  const rootStore = useStore()
  const { getSkillPersons } = rootStore.seachStore
  const { getAllSkills, allSkills, removeInterest, setCurrentskill, getUserDetailedSkillLinkedProjects } = rootStore.skillStore

  const { getAllProjects } = rootStore.projectStore

  const { getPersonInterests, userInterests } = rootStore.personStore

  const { validatedUser } = rootStore.loginStore

  useEffect(() => {
    getPersonInterests(validatedUser)
    getAllSkills(validatedUser)
    getAllProjects(validatedUser)
  }, [validatedUser, getPersonInterests, getAllSkills, getAllProjects])

  const tableRows: Data[] = []

  const onLikeRemoval = (id: number) => {
    const foundSkill = allSkills.find((s) => s.skill.skillId === id)

    if (foundSkill) {
      removeInterest(validatedUser, foundSkill.skill.skillId).then(() => {
        setCurrentskill({
          ...foundSkill.skill,
          userInterested: false,
        })
        getSkillPersons(foundSkill.skill.skillId)
        getPersonInterests(validatedUser)
      })
    }
  }

  const onRowClick = async (id: number | string) => {
    const curClicked = allSkills.find((skill) => skill.skill.skillId === Number(id))
    const userSkill = await getUserDetailedSkillLinkedProjects(validatedUser, Number(id))
    const skillPersons = await getSkillPersons(Number(id))

    if (curClicked?.skill) {
      const skillInfo: ISkillFormPackage = {
        skill: { skill: curClicked.skill, userInterested: curClicked.userInterested },
        userSkill: userSkill.skill,
        isNew: userSkill.isNew,
        skillPersons: skillPersons,
      }
      return skillInfo
    }
  }

  userInterests.forEach((interest: IUserInterest) => {
    tableRows.push({
      ['id']: interest.skillId,
      rows: {
        [t('Buttons.Interests')]: {
          text: (
            <ListItemButton className='profile--interest__cellitem' disableRipple>
              <ListItemText
                primary={interest.name}
                className='profile--interest__itemtext'
                primaryTypographyProps={{ className: 'profile--listitembtn__overflow' }}
              />
              <IconButton
                className='profile--listitembtn__deletebutton'
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  event.preventDefault()
                  event.stopPropagation()
                  onLikeRemoval(interest.skillId)
                }}
              >
                <DeleteIconBlack />
              </IconButton>
            </ListItemButton>
          ),
        },
      },
    })
  })

  if (userInterests.length === 0) {
    return (
      <Grid container rowGap={4}>
        <Grid item xs={12}>
          <span className='profile--interestplaceholder'>{t('Skills.NoAddedInterests')}</span>
        </Grid>
        <Grid container justifyContent='flex-end'>
          <OPButton
            label={t('Navbar.Skills')}
            onClick={() => history.push('/skills')}
            buttonVariant={MuiButtonVariants.secondary_outlined}
          />
        </Grid>
      </Grid>
    )
  }

  return <OPNewTable onRowClick={onRowClick} FormBase={OpenSkill} rows={tableRows} curLength={tableRows.length} noPadding />
}

export default observer(InterestTable)
