import { InputLabel, TextField } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import dayjs, { Dayjs } from 'dayjs'
import enGB from 'dayjs/locale/en-gb'
import fi from 'dayjs/locale/fi'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'
import React from 'react'
import { useTranslation } from 'react-i18next'
import '../styles/datefield.scss'
import { DateFieldDisabled, DatefieldDrop, DateFieldEnabled, DateFieldError, DatefieldLeft, DatefieldRight } from '../styles/Icons'

interface OPDateField {
  label: string
  name?: string
  minDate?: Date
  maxDate?: Date
  disabled?: boolean
  value: Date | null | undefined
  onChange: (newValue: Date | null) => void
  disablePast?: boolean
  disableFuture?: boolean
  errMessage?: string
  error?: boolean
  helperText?: string
  onBlured?: (e: React.FocusEvent<any, Element>) => void
}

export const OPDateField = (props: OPDateField): JSX.Element => {
  const { t, i18n } = useTranslation()
  const {
    errMessage,
    label,
    minDate = new Date('1900-01-01'),
    maxDate,
    disabled,
    value,
    onChange,
    disableFuture,
    disablePast,
    name,
    error,
    onBlured,
  } = props

  dayjs.extend(customParseFormat)
  dayjs.extend(utc)

  const handleChange = (newValue: Dayjs | null) => onChange(newValue?.utc(true).toDate() ?? null)

  return (
    <>
      <InputLabel>
        <div className={disabled ? 'typoLabelDate disabled' : error ? 'typoLabelDate error' : 'typoLabelDate'}>{label}</div>
      </InputLabel>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language === 'fi-FI' ? fi : enGB}>
        <DesktopDatePicker
          PaperProps={{ className: 'datepicker' }}
          className='datefield'
          disableHighlightToday
          minDate={dayjs(minDate)}
          maxDate={maxDate ? dayjs(maxDate) : undefined}
          inputFormat={'DD/MM/YYYY'}
          disableFuture={disableFuture}
          disablePast={disablePast}
          disabled={disabled}
          components={{
            OpenPickerIcon: disabled ? DateFieldDisabled : error ? DateFieldError : DateFieldEnabled,
            LeftArrowIcon: DatefieldLeft,
            RightArrowIcon: DatefieldRight,
            SwitchViewIcon: DatefieldDrop,
          }}
          value={value ?? null}
          onChange={handleChange}
          renderInput={(params) => <TextField onBlur={onBlured} error={error} name={name} {...params} />}
        />
        {error && (
          <div>
            <p className='helpertext'>{errMessage}</p>
          </div>
        )}
      </LocalizationProvider>
    </>
  )
}
