import { Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { IPersonProfileSkill } from '../../../../interfaces/skills/ISkills'
import ProfileSkillInfo from './ProfileSkillInfo'
import ProfileSkillOverview from './ProfileSkillOverview'

const ProfileSkillForm = (props: { data: IPersonProfileSkill }): JSX.Element => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <ProfileSkillOverview skill={props.data?.skill} />
      </Grid>
      <Grid item xs={12}>
        <hr className='profile--form__editdivider' />
      </Grid>
      <Grid item xs={12}>
        <ProfileSkillInfo skillInfo={props.data?.personSkill} />
      </Grid>
    </Grid>
  )
}

export default observer(ProfileSkillForm)
