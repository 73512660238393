import { Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import history from '../../../history'
import { useStore } from '../../../Provider'
import { OPAccordionItem } from '../../Accordion/OPAccordionItem'
import { scrollHelper } from '../../common/scrollHelper'
import { MuiButtonVariants, OPButton } from '../../OPcomponents/OPButton'
import { OPLoadingCircle } from '../../OPcomponents/OPLoadingcircle'
import { Toast } from '../../OPcomponents/Toast'

import ProfileCertEduPub from './ProfileCertEduPub'
import ProfileInterests from './ProfileInterests'
import ProfileOverview from './ProfileOverview'
import ProfileSkills from './ProfileSkills/ProfileSkills'
import ProfileWorkExperience from './ProfileWorkExperience'

interface IParams {
  userId: string
}

const PersonProfile = (): JSX.Element => {
  const { t } = useTranslation()
  const rootStore = useStore()
  const { userPermission } = rootStore.loginStore
  const { setSearchValue } = rootStore.personStore
  const { setFromPersonSearch } = rootStore.seachStore
  const { personProfileOverview, getUserProfileOverview, loadingOverview } = rootStore.profileStore

  const { userId } = useParams<IParams>()

  useEffect(() => {
    return () => {
      scrollHelper('/persons' || `/persons/${userId}/cv`, setSearchValue, window.location.pathname !== `/persons/${userId}/cv`, rootStore)
      setTimeout(() => {
        setFromPersonSearch(false)
      }, 100)
    }
  }, [])

  useEffect(() => {
    async function fetchData() {
      if (Number(userId) !== personProfileOverview?.id) await getUserProfileOverview(Number(userId))
    }
    fetchData()
  }, [])

  return (
    <Grid container spacing={4}>
      {loadingOverview ? (
        <Grid item xs={12}>
          <OPLoadingCircle />
        </Grid>
      ) : (
        <>
          {personProfileOverview.isEmpty ? (
            <Grid item xs={12}>
              <p className='profile--titlebigfont'>{t('Persons.NotFound')}</p>
            </Grid>
          ) : (
            <>
              <Toast />
              <Grid item xs={12}>
                <Grid container justifyContent={'space-between'}>
                  <Grid item>
                    <p className='profile--titlebigfont'>{personProfileOverview.name}</p>
                  </Grid>
                  {userPermission && (
                    <Grid item>
                      <OPButton
                        buttonVariant={MuiButtonVariants.primary_outlined}
                        onClick={() => {
                          history.push(`/persons/${userId}/cv`)
                        }}
                        label={t('Buttons.DownloadCV')}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <OPAccordionItem isOpenOnInit={true} title={t('Persons.Overview')} content={<ProfileOverview />} />
              </Grid>
              <Grid item xs={12}>
                <OPAccordionItem title={t('Skills.PersonSkills')} content={<ProfileSkills userId={userId} />} />
              </Grid>
              <Grid item xs={12}>
                <OPAccordionItem title={t('CertAndEducation.PersonTitle')} content={<ProfileCertEduPub userId={userId} />} />
              </Grid>
              <Grid item xs={12}>
                <OPAccordionItem title={t('WorkHistory.PersonTitle')} content={<ProfileWorkExperience userId={userId} />} />
              </Grid>
              <Grid item xs={12}>
                <OPAccordionItem title={t('Interests.PersonTitle')} content={<ProfileInterests userId={userId} />} />
              </Grid>
            </>
          )}
        </>
      )}
    </Grid>
  )
}

export default observer(PersonProfile)
