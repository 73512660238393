import { Grid, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import history from '../../../history'
import { useStore } from '../../../Provider'
import { OPLoadingCircle } from '../../OPcomponents/OPLoadingcircle'
import { HotStar, HotStarFilled } from '../../styles/Icons'

const NeededSkills = (): JSX.Element => {
  const { t } = useTranslation()
  const rootStore = useStore()

  const { validatedUser } = rootStore.loginStore
  const { userNeededSkills, loadingNeededSkills, getDepSkills, getUserDep, userDepartment } = rootStore.skillStore

  useEffect(() => {
    if (userNeededSkills.length === 0) getDepSkills(validatedUser)

    if (userDepartment === '') getUserDep(validatedUser)
  }, [])

  return (
    <Grid>
      {!loadingNeededSkills ? (
        <Grid>
          <Grid container rowGap={2.25} className='profile--pointer' onClick={() => history.push('/neededcompetence')}>
            <Grid item xs={12}>
              <div data-testid='profiletitletext' className='profile--titlefont'>
                {t('HomePage.Box1')}
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className='profile--neededskills__text'>{t('Profile.NeededSkills')}</div>
            </Grid>
          </Grid>
          {userNeededSkills.map((depSkill) => (
            <Grid item key={_.uniqueId('neededskillbase-')}>
              {depSkill.skills.length > 0 ? (
                depSkill.skills.map((skill, index) =>
                  index < 5 ? (
                    <ListItem
                      className='profile--pointer'
                      onClick={() => history.push('/neededcompetence', { userDepartment: depSkill.department, rowId: skill.skill.skillId })}
                      key={_.uniqueId('neededskill-')}
                      disableGutters
                      alignItems='center'
                    >
                      {skill.skill.skillTags?.includes(userDepartment) && (
                        <ListItemIcon className='profile--neededskills__hotstar'>
                          {skill.userHas ? <HotStarFilled /> : <HotStar />}
                        </ListItemIcon>
                      )}
                      <ListItemText primary={skill.skill.skillName} className={'profile--skillitemtext'} />
                    </ListItem>
                  ) : null,
                )
              ) : (
                <Grid>
                  <div className='profile--neededskills__text'>{t('Profile.NoGoals')}</div>
                </Grid>
              )}
            </Grid>
          ))}
        </Grid>
      ) : (
        <OPLoadingCircle />
      )}
    </Grid>
  )
}

export default observer(NeededSkills)
