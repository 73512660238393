import { Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import '../styles/deleteconfirmation.scss'
import '../styles/grid.scss'
import { DeleteIcon } from '../styles/Icons'
import { MuiButtonVariants, OPButton } from './OPButton'

interface DeleteConfirmationProps {
  open: boolean
  whatToDelete: 'Project' | 'Skill' | 'Language' | 'Education' | 'Certificate' | 'Publication'
  handleClose: () => void
  deleteHandler: () => Promise<void>
}

const DeleteConfirmation = (props: DeleteConfirmationProps) => {
  const { t } = useTranslation()
  const { open, whatToDelete, handleClose, deleteHandler } = props

  const [isDeleting, setIsDeleting] = useState(false)

  const isOpenRef = useRef<HTMLDivElement>(null)

  const handleDelete = async () => {
    setIsDeleting(true)
    await deleteHandler()
    setIsDeleting(false)
    handleClose()
  }

  useEffect(() => {
    if (open) {
      isOpenRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [open])

  return (
    <>
      {open && (
        <>
          <hr className='profile--form__editdivider' />
          <Grid container ref={isOpenRef} spacing={1} className='gridcontainer--formcontainer__addnewpaddings' textAlign='center'>
            <Grid item xs={12}>
              <p className='deleteconfirmation__textfont'>{t(`DeleteConfirmation.Delete${whatToDelete}`)}</p>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent='center' columnSpacing={1}>
                <Grid item>
                  <OPButton
                    disabled={isDeleting}
                    buttonVariant={MuiButtonVariants.secondary_outlined}
                    label={t('Buttons.Cancel')}
                    onClick={handleClose}
                  />
                </Grid>
                <Grid item>
                  <OPButton
                    disabled={isDeleting}
                    endIcon={<DeleteIcon />}
                    buttonVariant={MuiButtonVariants.error}
                    onClick={handleDelete}
                    label={t('Buttons.Delete')}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  )
}

export default observer(DeleteConfirmation)
