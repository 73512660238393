import { observer } from 'mobx-react-lite'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ILinkedSkill, IProjectFormPackage } from '../../../interfaces/projects/IProjects'
import { useStore } from '../../../Provider'
import { Data, OPNewTable } from '../../OPcomponents/OPNewTable'
import EditPubProject from './newpubproject/EditPubProject'

const PublicWorkHistoryTable = (props: { rowId: number | undefined }): JSX.Element => {
  const { rowId } = props
  const rootStore = useStore()
  const { t } = useTranslation()
  const { validatedUser } = rootStore.loginStore
  const { getUserProjectTitle, projects, getDetailedUserProjects } = rootStore.projectStore
  const { filteredPublicWorkHistories } = rootStore.personStore

  const tableRows: Data[] = []

  const onRowClickHandler = async (id: number | string) => {
    const clickedProject = projects.find((proj) => proj.project.projectId === id)
    if (clickedProject?.project) {
      const userDetails = await getDetailedUserProjects(validatedUser, clickedProject.project.projectId ?? -1)
      const projectUsers = await getUserProjectTitle(clickedProject.project.projectId ?? -1)

      const projectInfo: IProjectFormPackage = {
        project: clickedProject.project,
        userHas: clickedProject.userHas ?? false,
        projectUsers: projectUsers,
        userDetails: {
          details: userDetails,
          projectOwner: userDetails.projectOwner !== 0,
        },
      }
      return projectInfo as IProjectFormPackage
    }
  }

  filteredPublicWorkHistories.forEach((work) => {
    tableRows.push({
      ['id']: work.projectId,
      rows: {
        [t('Projects.ProjectName')]: { text: work.projectName, width: '30%' },
        [t('WorkHistory.Customer')]: { text: work.customer, width: '30%' },
        [t('WorkHistory.Expirience')]: { text: `${work.daysWorked || 0} ${t('Date.ExperienceDays')}`, width: '11%' },
        [t('WorkHistory.Skills')]: { text: work.linkedSkills.map((s: ILinkedSkill) => s.skillName).join(', '), width: '29%' },
      },
    })
  })

  return tableRows.length ? (
    <OPNewTable rows={tableRows} curLength={tableRows.length} profileclickedId={rowId} onRowClick={onRowClickHandler} FormBase={EditPubProject} />
  ) : (
    <span>{t('WorkHistory.NoPublicWorkHistory')}</span>
  )
}

export default observer(PublicWorkHistoryTable)
