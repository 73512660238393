import { Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IProfileProjectInfo } from '../../../../interfaces/projects/IProjects'
import OPTooltip from '../../../OPcomponents/OPTooltip'

const ProjectInfo = (props: { projectInfo: IProfileProjectInfo; isProject?: boolean }) => {
  const { projectInfo, isProject = false } = props

  const {
    t,
    i18n: { language },
  } = useTranslation()

  return (
    <Grid container rowGap={1}>
      {!isProject && (
        <Grid item xs={12}>
          <span className='myskill--font__multilinetext title'>{`${t('WorkHistory.ProjectName')}: `}</span>
          <span className='myskill--font__multilinetext'>
            {projectInfo?.projectName !== '' ? projectInfo?.projectName : t('Projects.NotDefined')}
          </span>
        </Grid>
      )}
      <Grid item xs={12}>
        <span className='myskill--font__multilinetext title'>{`${t('Projects.ProjectStartDate')}: `}</span>
        <span className='myskill--font__multilinetext'>
          {projectInfo?.startDate ? moment(projectInfo?.startDate).locale(language).format('L') : t('Projects.NotDefined')}
        </span>
      </Grid>
      <Grid item xs={12}>
        <span className='myskill--font__multilinetext title'>{`${t('Projects.ProjectEndDate')}: `}</span>
        <span className='myskill--font__multilinetext'>
          {projectInfo?.endDate ? moment(projectInfo?.endDate).locale(language).format('L') : t('Projects.NotDefined')}
        </span>
      </Grid>
      <Grid item xs={12}>
        <span className='myskill--font__multilinetext title'>{`${t('Projects.UserWorkDescription')}: `}</span>
        <span className='myskill--font__multilinetext'>
          {projectInfo?.description !== '' ? projectInfo?.description : t('Projects.NotDefined')}
        </span>
      </Grid>
      <Grid item xs={12}>
        <span className='myskill--font__multilinetext title'>{`${t('Projects.ProjectWorkAmount')}: `}</span>
        <span>
          {projectInfo?.workAmount !== null
            ? `${projectInfo?.workAmount?.toString()} ${t('Date.ExperienceDays')}`
            : t('Projects.NotDefined')}
        </span>
      </Grid>
      <Grid item xs={12} container wrap='nowrap' overflow={'hidden'}>
        <Grid item>
          <span className='myskill--font__multilinetext title'>{`${t('Projects.UserProjectRoles')}: `}</span>
        </Grid>
        <OPTooltip title={projectInfo?.roles ?? t('Projects.NotDefined')}>
          <Grid item className='myskill--font__multilinetext roles'>
            <span>{projectInfo?.roles !== '' ? projectInfo?.roles : t('Projects.NotDefined')}</span>
          </Grid>
        </OPTooltip>
      </Grid>
    </Grid>
  )
}
export default observer(ProjectInfo)
