import { observer } from 'mobx-react-lite'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ISkillFormPackage, ISkills } from '../../../interfaces/skills/ISkills'
import { useStore } from '../../../Provider'
import { OPAccordionItem } from '../../Accordion/OPAccordionItem'
import { OPLoadMore } from '../../OPcomponents/OPLoadMore'
import { Data, OPNewTable } from '../../OPcomponents/OPNewTable'
import OpenSkill from '../../Skills/OpenSkill'

interface SkillSearchAccordionProps {
  search: string
}

const SkillSearchAccordion = (props: SkillSearchAccordionProps): JSX.Element => {
  const { search } = props
  const { t } = useTranslation()
  const rootStore = useStore()
  const { validatedUser } = rootStore.loginStore
  const { getUserDetailedSkillLinkedProjects, getFilteredSkillsByText, filteredSkills, loadingSkills } = rootStore.skillStore
  const { getSkillPersons } = rootStore.seachStore

  const [tableRows, setTableRows] = useState<Data[]>([])
  const [tableLength, setTableLength] = useState(10)

  const filteredSearchSkills = useMemo(() => {
    if (loadingSkills) return []
    return filteredSkills ? getFilteredSkillsByText(search) : []
  }, [filteredSkills, getFilteredSkillsByText, loadingSkills, search])

  const openSkillRow = async (id: number | string) => {
    const curClicked = filteredSearchSkills.find((skill) => skill.skill.skillId === Number(id))
    const userSkill = await getUserDetailedSkillLinkedProjects(validatedUser, Number(id))
    const skillPersons = await getSkillPersons(Number(id))

    if (curClicked?.skill) {
      const skillInfo: ISkillFormPackage = {
        skill: { skill: curClicked.skill, userInterested: curClicked.userInterested },
        userSkill: userSkill.skill,
        isNew: userSkill.isNew,
        skillPersons: skillPersons,
      }
      return skillInfo
    }
  }
  useEffect(() => {
    const newRows: Data[] = []

    filteredSearchSkills.forEach((skill: ISkills) => {
      newRows.push({
        ['id']: skill.skill.skillId,
        rows: {
          [t('Skills.SkillName')]: { text: skill.skill.skillName ?? '', width: '30%' },
          [t('Skills.Description')]: { text: skill.skill.skillDescription ?? '', width: '30%' },
          [t('Skills.Category')]: { text: skill.skill.categoryName ?? '', width: '30%' },
          [t('Skills.Experts')]: { text: skill.skill.osaajat ?? 0, width: '10%' },
        },
      })
    })
    setTableRows(newRows)
  }, [filteredSearchSkills, t])

  return (
    <OPAccordionItem
      title={`${t('Skills.Header')} (${loadingSkills ? '\u2026' : filteredSearchSkills.length})`}
      content={
        <>
          {filteredSearchSkills.length > 0 ? (
            <>
              <OPNewTable rows={tableRows} curLength={tableLength} onRowClick={openSkillRow} FormBase={OpenSkill} />
              <OPLoadMore
                stepper={10}
                filteredArray={filteredSearchSkills}
                curLength={tableLength}
                maxLength={filteredSearchSkills.length}
                onLoadMore={(data) => setTableLength(data)}
              />
            </>
          ) : (
            <span className='profile--notfound'>{t('NotFound.NoResults')}</span>
          )}
        </>
      }
    />
  )
}

export default observer(SkillSearchAccordion)
