import { Grid } from '@mui/material'
import { Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getErrMsg, toastNeg, toastPos } from '../../../common/toastHelper'
import { IEditPublication, IPersonPublications } from '../../../interfaces/certAndEducation/certAndEducation'
import { useStore } from '../../../Provider'
import TextEditor from '../../MySkills/PersonalCertificates/Editor'
import DeleteConfirmation from '../../OPcomponents/DeleteConfirmation'
import { ExitConfirmation } from '../../OPcomponents/ExitConfirmation'
import { OPMultiline } from '../../OPcomponents/OPMultiline'
import '../../styles/grid.scss'
import { OPSkillsButtons } from '../OPSkillsButtons'

interface EditPublications {
  title: string
  desc: string
  publicationId: number
}

const EditPublication = (props: EditPublications): JSX.Element => {
  const { t } = useTranslation()
  const rootStore = useStore()

  const { validatedUser } = rootStore.loginStore
  const { userPublications, removeUserPublication, isPublicationsEditing, editUserPublication, getPersonPublications } =
    rootStore.certAndEducationStore

  const [userPublication, setUserPublication] = useState(userPublications)
  const [deletePubConfirmation, setDeletePubConfirmation] = useState(false)

  const initialValues: IPersonPublications = {
    personId: validatedUser,
    publicationId: props.publicationId ?? -1,
    publicationTitle: props.title ?? '',
    publicationDescription: props.desc ?? '',
  }

  const editPublication = async (values: IPersonPublications) => {
    try {
      const editedPublication: IEditPublication = {
        personId: validatedUser,
        title: values.publicationTitle ?? '',
        description: userPublication ?? '',
      }
      await editUserPublication(props.publicationId ?? -1, editedPublication)
      toastPos(t('Toasts.EditSuccess'))
    } catch (err) {
      toastNeg(getErrMsg(err, t) || t('Toasts.EditError'))
    } finally {
      getPersonPublications(validatedUser)
      isPublicationsEditing(false)
    }
  }

  const deletePublication = async () => {
    try {
      if (props?.publicationId) {
        await removeUserPublication(validatedUser, props.publicationId)
        toastPos(t('Toasts.DeleteSuccess'))
      }
    } catch (error) {
      toastNeg(getErrMsg(error, t) || t('Toasts.DeleteError'))
    } finally {
      getPersonPublications(validatedUser)
      isPublicationsEditing(false)
    }
  }

  return (
    <Formik initialValues={initialValues} onSubmit={editPublication}>
      {({ values, handleChange, submitForm, isSubmitting, dirty }) => (
        <Grid container>
          <ExitConfirmation when={dirty} />
          <Grid item xs={12} className='griditem--formitem__zindex griditem--certedu__pubtextpadding'>
            <Grid item xs={12}>
              <OPMultiline
                width='13.75rem'
                name='publicationTitle'
                value={values.publicationTitle}
                label={t('CertAndEducation.PublicationTitle')}
                onChange={handleChange}
                maxChars={100}
              />
            </Grid>
            <ExitConfirmation when={userPublications !== props.desc} />
            <Grid className='griditem--formitem__formtoppadding griditem--texteditor'>
              <TextEditor
                charLimit={4000}
                placeholderString={t('CertAndEducation.PublicationsEditorPlaceholder')}
                setValue={setUserPublication}
                value={props.desc ?? ''}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} className='griditem--addbutton__removebutton'>
            <OPSkillsButtons
              paddingRight
              bottomPadding
              isDelete
              onDelete={() => setDeletePubConfirmation(true)}
              disabled={values.publicationTitle === '' || isSubmitting}
              bothDisabled={isSubmitting || deletePubConfirmation}
              onClose={() => isPublicationsEditing(false)}
              onSubmit={submitForm}
            />
          </Grid>
          <DeleteConfirmation
            whatToDelete='Publication'
            open={deletePubConfirmation}
            deleteHandler={deletePublication}
            handleClose={() => setDeletePubConfirmation(false)}
          />
        </Grid>
      )}
    </Formik>
  )
}

export default observer(EditPublication)
