import { Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { IPersonProjectInfo } from '../../../../interfaces/projects/IProjects'
import ProjectHeader from '../../../Profile/workhistory/ProjectHeader'
import ProfileProjectPerson from './ProfileProjectPerson'
import ProfileProjectSkills from './ProfileProjectSkills'

const ProfileWorkHistoryForm = (props: { data: IPersonProjectInfo }) => {
  const { data } = props

  return (
    <Grid container>
      <Grid item xs={12}>
        <ProjectHeader data={data?.projectInfo} />
      </Grid>
      <hr className='profile--form__editdivider' />
      <Grid container rowGap={4}>
        <Grid item xs={12}>
          <ProfileProjectPerson data={data?.personInfo} />
        </Grid>
        <Grid item xs={12}>
          <ProfileProjectSkills data={data?.personInfo.projectSkills} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default observer(ProfileWorkHistoryForm)
