import { Grid } from '@mui/material'
import { t } from 'i18next'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useStore } from '../../../Provider'
import { OPAccordionItem } from '../../Accordion/OPAccordionItem'
import { MuiButtonVariants, OPButton } from '../../OPcomponents/OPButton'
import { OPLoadingCircle } from '../../OPcomponents/OPLoadingcircle'
import '../profile.scss'
import AddNewProject from './newpubproject/AddNewProject'
import PublicWorkHistoryTable from './PublicWorkHistoryTable'

const PublicWorkhistory = (props: { rowId: number | undefined }): JSX.Element => {
  const rootStore = useStore()
  const { filteredPublicWorkHistories, publicWorkHistoryLoading } = rootStore.personStore
  const { showAddProjectModalHandler, showAddProjectModal, showAddPrivateProjectModal, projects, getAllProjects, loadingInitial } =
    rootStore.projectStore
  const { validatedUser } = rootStore.loginStore
  const { rowId } = props

  useEffect(() => {
    if (projects.length === 0) getAllProjects(validatedUser)
  }, [])

  return (
    <Grid container item display='flex'>
      <Grid container item className='gridcontainer--certedu__addbuttonmargin'>
        <Grid item className='griditem--addbutton'>
          <OPButton
            onClick={() => showAddProjectModalHandler(true)}
            label={t('Buttons.AddNew')}
            buttonVariant={MuiButtonVariants.primary_outlined}
            disabled={showAddPrivateProjectModal || showAddProjectModal}
          />
        </Grid>
      </Grid>
      <OPAccordionItem
        setOutsideExpanded={showAddProjectModal || rowId !== undefined}
        title={`${t('WorkHistory.NetumHeader')}  (${publicWorkHistoryLoading ? '\u2026' : filteredPublicWorkHistories.length})`}
        content={
          <Grid container className='gridcontainer--workhistory__formpadding'>
            {showAddProjectModal && (
              <>
                <Grid item xs={12} className='griditem--formitem__zindex'>
                  <AddNewProject />
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              {publicWorkHistoryLoading || loadingInitial ? <OPLoadingCircle /> : <PublicWorkHistoryTable rowId={rowId} />}
            </Grid>
          </Grid>
        }
      />
    </Grid>
  )
}

export default observer(PublicWorkhistory)
