import { decode } from 'html-entities'
import { isEmpty, orderBy, sortBy } from 'lodash'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import agent from '../../api/agent'
import { sortByType } from '../../common/sortHelper'
import { ILanguage, IUserLanguage } from '../../interfaces/language/ILanguage'
import { IListPerson, IListPersonWithSkills, IPerson, IPersonCVInfo } from '../../interfaces/persons/IPerson'
import { IWorkHistoryDetails, IWorkHistoryDetailsOther } from '../../interfaces/projects/IProjects'
import { IUserInterest, IUserSkills } from '../../interfaces/skills/ISkills'
import { ISortDirection } from '../../interfaces/Sort'

export default class PersonStore {
  personSortColumn = 'name'
  personSortDirection: ISortDirection = 'ascending'

  topSkillsSortColumn = 'skillLevel'
  topSkillsSortDirection: ISortDirection = 'descending'

  languagesSortColumn = 'languageName'
  languagesSortDirection: ISortDirection = 'ascending'

  userSkillsSortColumn = 'skillLevel'
  userSkillsSortDirection: ISortDirection = 'descending'

  privateWorkHistorySortColumn = 'projectName'
  privateWorkHistorySortDirection: ISortDirection = 'ascending'

  publicWorkHistorySortColumn = 'projectName'
  publicWorkHistorySortDirection: ISortDirection = 'ascending'

  /**
   * * Loader observables
   */

  loadingOverview = false
  loadingInitial = false
  loadingLanguages = false
  loadingSkills = false
  loadingHobbies = false
  loadingInterests = false
  loadingCV = false
  loadingCVInfo = false

  /**
   * * Edit observables
   */

  editTitle = false
  editSummary = false
  editedTitle = ''
  editedSummary = ''
  editLanguage: IUserLanguage[] = []
  editHobbies = false
  editedHobbies = ''
  editLanguageDescription = ''

  /**
   * * Modal observables
   */

  showUserModal = false
  showLanguageModal = false
  openLanguageEditModal = false
  openLanguageConfirm = false
  openInterestConfirm = false
  /**
   * * User and Person observables
   */

  selectedPerson: IPerson = {} as IPerson
  personModalTabIndex = 0
  persons: IListPerson[] = []
  person: IPerson[] = []
  userObject: IPerson = {} as IPerson
  userSkills: any[] = []
  allLanguages: any[] = []
  privateWorkHistory: IWorkHistoryDetailsOther[] = []
  privateWorkHistoryLoading = false
  publicWorkHistory: IWorkHistoryDetails[] = []
  publicWorkHistoryLoading = false
  userHobbies = ''
  userInterests: Array<IUserInterest> = []
  userLanguages: IUserLanguage[] = []
  allDepartments: string[] = []
  PersonCVInfo: IPersonCVInfo = {} as IPersonCVInfo

  /**
   * * Search observables
   */

  searchValue = ''
  isSearching = false

  constructor() {
    makeObservable(this, {
      personSortColumn: observable,
      personSortDirection: observable,
      topSkillsSortColumn: observable,
      topSkillsSortDirection: observable,
      languagesSortColumn: observable,
      languagesSortDirection: observable,
      userSkillsSortColumn: observable,
      userSkillsSortDirection: observable,
      privateWorkHistorySortColumn: observable,
      privateWorkHistorySortDirection: observable,
      publicWorkHistorySortColumn: observable,
      publicWorkHistorySortDirection: observable,
      loadingOverview: observable,
      loadingInitial: observable,
      loadingLanguages: observable,
      loadingSkills: observable,
      loadingHobbies: observable,
      loadingInterests: observable,
      loadingCV: observable,
      editTitle: observable,
      editSummary: observable,
      editedTitle: observable,
      editedSummary: observable,
      editLanguage: observable,
      editHobbies: observable,
      editedHobbies: observable,
      editLanguageDescription: observable,
      showUserModal: observable,
      showLanguageModal: observable,
      openLanguageEditModal: observable,
      openLanguageConfirm: observable,
      openInterestConfirm: observable,
      selectedPerson: observable,
      personModalTabIndex: observable,
      persons: observable,
      person: observable,
      PersonCVInfo: observable,
      userObject: observable,
      userSkills: observable,
      allLanguages: observable,
      privateWorkHistory: observable,
      privateWorkHistoryLoading: observable,
      publicWorkHistory: observable,
      publicWorkHistoryLoading: observable,
      userHobbies: observable,
      userInterests: observable,
      userLanguages: observable,
      allDepartments: observable,
      searchValue: observable,
      isSearching: observable,
      filteredPrivateWorkHistories: computed,
      filteredPublicWorkHistories: computed,
      filteredPersons: computed,
      dropdownPersons: computed,
      filteredSkills: computed,
      filteredTopSkills: computed,
      filteredLanguages: computed,
      selectableNewLanguages: computed,
      getFilteredLanguagesByText: action,
      getFilteredPersonsByText: action,
      setOpenInterestConfirm: action,
      setOpenLanguageConfirm: action,
      setPersonSortColumn: action,
      togglePersonSortDirection: action,
      setTopSkillsSortColumn: action,
      toggleTopSkillsSortDirection: action,
      setLanguagesSortColumn: action,
      toggleLanguagesSortDirection: action,
      setUserSkillsSortColumn: action,
      toggleUserSkillsSortDirection: action,
      setPrivateWorkHistorySortColumn: action,
      togglePrivateWorkHistorySortDirection: action,
      setPublicWorkHistorySortColumn: action,
      togglePublicWorkHistorySortDirection: action,
      setSearchValue: action,
      setSelectedPerson: action,
      setPersonModalTabIndex: action,
      getUserOverview: action,
      setIsSearching: action,
      isHobbiesEditing: action,
      editHobbiesHandler: action,
      postNewHobbies: action,
      getPersonHobbies: action,
      getPersonInterests: action,
      getAllLanguages: action,
      postNewUserLanguage: action,
      setShowUserModal: action,
      setOpenLanguageEditModal: action,
      setShowLanguageModal: action,
      getCurrentLanguageToEdit: action,
      getAllUserSkills: action,
      setEditTitle: action,
      editTitleHandler: action,
      editLanguageDescriptionHandler: action,
      setEditSummary: action,
      editSummaryHandler: action,
      postNewTitle: action,
      postNewSummary: action,
      putEditedPersonLanguage: action,
      deletePersonLanguage: action,
      getUserPublicWorkHistory: action,
      getUserPrivateWorkHistory: action,
      getAllPersons: action,
      getAllPersonsWithSkills: action,
      getCV: action,
      resetCVInfo: action,
      getCVInfo: action,
      getDeps: action,
    })
  }

  get filteredPrivateWorkHistories() {
    let privWorkHistories = sortBy(this.privateWorkHistory, (workHistory) => {
      return sortByType(workHistory, this.privateWorkHistorySortColumn)
    })
    if (this.privateWorkHistorySortDirection === 'descending') {
      privWorkHistories = privWorkHistories.reverse()
    }
    return privWorkHistories
  }

  get filteredPublicWorkHistories() {
    let publicWorkHistories = sortBy(this.publicWorkHistory, (workHistory) => {
      return sortByType(workHistory, this.publicWorkHistorySortColumn)
    })
    if (this.publicWorkHistorySortDirection === 'descending') {
      publicWorkHistories = publicWorkHistories.reverse()
    }
    return publicWorkHistories
  }

  get filteredPersons() {
    let persons = sortBy(this.persons, (person) => {
      return sortByType(person, this.personSortColumn)
    })
    if (this.personSortDirection === 'descending') {
      persons = persons.reverse()
    }
    const params = this.searchValue
    if (isEmpty(params)) {
      return persons
    }
    return persons.filter(
      (person: IListPerson) =>
        person.name?.toLowerCase().includes(params.toLowerCase()) ||
        person.department?.toLowerCase().includes(params.toLowerCase()) ||
        person.title?.toLowerCase().includes(params.toLowerCase()),
    )
  }

  get dropdownPersons() {
    return this.persons.map((x) => ({
      key: x.personId,
      value: x.personId,
      text: x.name,
    }))
  }

  get filteredSkills() {
    let allSkills = sortBy(this.userSkills, (skill) => {
      return sortByType(skill, this.userSkillsSortColumn)
    })
    if (this.userSkillsSortDirection === 'descending') {
      allSkills = allSkills.reverse()
    }
    return allSkills
  }

  get filteredTopSkills() {
    let topSkills = sortBy(this.userObject.topSkills, (skill) => {
      return sortByType(skill, this.topSkillsSortColumn)
    })
    if (this.topSkillsSortDirection === 'descending') {
      topSkills = topSkills.reverse()
    }
    return topSkills
  }

  get filteredLanguages() {
    let languages = sortBy(this.userLanguages, (language) => {
      return sortByType(language, this.languagesSortColumn)
    })
    if (this.languagesSortDirection === 'descending') {
      languages = languages.reverse()
    }
    return languages
  }

  get selectableNewLanguages() {
    const userLangIds = this.userLanguages?.map((lang) => {
      return lang.languageId
    })
    return this.allLanguages.filter((lang) => {
      return !userLangIds?.includes(lang.key)
    })
  }

  /**
   * * Actions
   */

  getFilteredPersonsByText = (text: string) => {
    if (isEmpty(text)) return this.filteredPersons

    return this.filteredPersons.filter(
      (person) =>
        person.name?.toLowerCase().includes(text.toLowerCase()) ||
        person.department?.toLowerCase().includes(text.toLowerCase()) ||
        person.title?.toLowerCase().includes(text.toLowerCase()),
    )
  }

  getFilteredLanguagesByText = (text: string) => {
    if (isEmpty(text)) return this.allLanguages

    return this.allLanguages.filter((lang) => {
      return lang.text.toLowerCase().includes(text.toLowerCase())
    })
  }

  setOpenInterestConfirm = (value: boolean) => {
    this.openInterestConfirm = value
  }

  setOpenLanguageConfirm = (value: boolean) => {
    this.openLanguageConfirm = value
  }

  setPersonSortColumn = (value: string) => {
    this.personSortColumn = value
  }

  togglePersonSortDirection = () => {
    this.personSortDirection = this.personSortDirection === 'ascending' ? 'descending' : 'ascending'
  }

  setTopSkillsSortColumn = (value: string) => {
    this.topSkillsSortColumn = value
  }

  toggleTopSkillsSortDirection = () => {
    this.topSkillsSortDirection = this.topSkillsSortDirection === 'ascending' ? 'descending' : 'ascending'
  }

  setLanguagesSortColumn = (value: string) => {
    this.languagesSortColumn = value
  }

  toggleLanguagesSortDirection = () => {
    this.languagesSortDirection = this.languagesSortDirection === 'ascending' ? 'descending' : 'ascending'
  }

  setUserSkillsSortColumn = (value: string) => {
    this.userSkillsSortColumn = value
  }

  toggleUserSkillsSortDirection = () => {
    this.userSkillsSortDirection = this.userSkillsSortDirection === 'ascending' ? 'descending' : 'ascending'
  }

  setPrivateWorkHistorySortColumn = (value: string) => {
    this.privateWorkHistorySortColumn = value
  }

  togglePrivateWorkHistorySortDirection = () => {
    this.privateWorkHistorySortDirection = this.privateWorkHistorySortDirection === 'ascending' ? 'descending' : 'ascending'
  }

  setPublicWorkHistorySortColumn = (value: string) => {
    this.publicWorkHistorySortColumn = value
  }

  togglePublicWorkHistorySortDirection = () => {
    this.publicWorkHistorySortDirection = this.publicWorkHistorySortDirection === 'ascending' ? 'descending' : 'ascending'
  }

  setSearchValue = (value: string) => {
    this.searchValue = value
  }

  setSelectedPerson = (value: IPerson) => {
    this.selectedPerson = { ...value }
  }

  setPersonModalTabIndex = (index: number) => {
    this.personModalTabIndex = index
  }

  getUserOverview = async (userId: number) => {
    this.loadingOverview = true
    try {
      const fetchedUserOverview: IPerson = await agent.MySkills.getUserOverview(userId)
      runInAction(() => {
        if (fetchedUserOverview) {
          this.userObject = {
            title: decode(fetchedUserOverview.title),
            summary: decode(fetchedUserOverview.summary),
            languages: fetchedUserOverview.languages,
            topSkills: fetchedUserOverview.topSkills,
          }
          this.editedTitle = fetchedUserOverview.title!
          this.editedSummary = fetchedUserOverview.summary!
          this.userLanguages = fetchedUserOverview.languages!
        }
      })
    } finally {
      runInAction(() => {
        this.loadingOverview = false
      })
    }
  }

  setIsSearching = (isItSearching: boolean) => {
    this.isSearching = isItSearching
  }

  isHobbiesEditing = (value: boolean) => {
    this.editHobbies = value
  }

  editHobbiesHandler = (hobbies: any) => {
    this.editedHobbies = hobbies
  }

  postNewHobbies = async (userId: number, hobbies: string) => {
    this.loadingHobbies = true
    this.userHobbies = hobbies
    try {
      await agent.MySkills.editUserHobbies(userId, {
        text: hobbies,
      })
    } catch (error) {
      return Promise.reject(error)
    } finally {
      runInAction(() => {
        this.loadingHobbies = false
      })
    }
    return Promise.resolve()
  }

  getPersonHobbies = async (userId: number) => {
    this.loadingHobbies = true
    try {
      const fetchedHobbies = await agent.MySkills.getUserHobbies(userId)
      runInAction(() => {
        this.userHobbies = decode(fetchedHobbies)
        this.editedHobbies = fetchedHobbies
      })
    } catch (error) {
      runInAction(() => {
        console.log(error)
      })
    } finally {
      runInAction(() => {
        this.loadingHobbies = false
      })
    }
  }

  getPersonInterests = async (userId: number) => {
    this.loadingInterests = true
    try {
      const fetchedInterests = await agent.MySkills.getUserInterests(userId)
      runInAction(() => {
        this.userInterests = fetchedInterests
      })
    } catch (error) {
      runInAction(() => {
        console.log(error)
      })
    } finally {
      runInAction(() => {
        this.loadingInterests = false
      })
    }
  }

  getAllLanguages = async () => {
    this.allLanguages = []
    this.loadingLanguages = true
    try {
      const fetchedLanguages: ILanguage[] = await agent.MySkills.getAllLanguages()
      runInAction(() => {
        fetchedLanguages.forEach((language) => {
          this.allLanguages.push({
            key: language.id,
            value: language.name,
            text: language.name,
          })
        })
      })
    } catch (error) {
      console.log(error)
    }
    runInAction(() => {
      this.loadingLanguages = false
    })
  }

  postNewUserLanguage = async (lang: IUserLanguage) => {
    this.loadingLanguages = true
    try {
      await agent.MySkills.postExistingLanguage(lang)
      runInAction(() => {
        this.userLanguages.push(lang)
      })
    } catch (error) {
      return Promise.reject(error)
    } finally {
      runInAction(() => {
        this.loadingLanguages = false
      })
    }
    return Promise.resolve()
  }

  setShowUserModal = (value: boolean) => {
    this.showUserModal = value
  }

  setOpenLanguageEditModal = (value: boolean) => {
    this.openLanguageEditModal = value
  }

  setShowLanguageModal = (val = !this.showLanguageModal) => {
    this.showLanguageModal = val
  }

  getCurrentLanguageToEdit = (language: string) => {
    this.editLanguage = this.userLanguages!.filter((lang: IUserLanguage) => lang.languageName === language)

    return this.userLanguages!.filter((lang: IUserLanguage) => lang.languageName === language)
  }

  getAllUserSkills = async (userId: number, useLoading = true) => {
    if (useLoading) {
      this.loadingSkills = true
    }
    try {
      const fetchedSkills: IUserSkills[] = await agent.MySkills.getUserSkills(userId)
      runInAction(() => {
        this.userSkills = fetchedSkills
        if (useLoading) {
          this.loadingSkills = false
        }
      })
    } catch (error) {
      runInAction(() => {
        if (useLoading) {
          this.loadingSkills = false
        }
      })
    }
  }

  setEditTitle = (value = !this.editTitle) => {
    this.editTitle = value
  }
  editTitleHandler = (title: any) => {
    this.editedTitle = title
    this.userObject.title = title
  }

  editLanguageDescriptionHandler = (description: any) => {
    if (description) {
      this.editLanguageDescription = description
    } else {
      this.editLanguageDescription = ''
    }
  }

  setEditSummary = (value = !this.editSummary) => {
    this.editSummary = value
  }

  editSummaryHandler = (summary: any) => {
    this.editedSummary = summary
    this.userObject.summary = summary
  }

  postNewTitle = async (userId: number, newTitle: string) => {
    try {
      await agent.MySkills.editUserTitle(userId, { text: newTitle })
    } catch (error) {
      return Promise.reject(error)
    }
    return Promise.resolve()
  }

  postNewSummary = async (userId: number, newSummary: string) => {
    try {
      await agent.MySkills.editUserSummary(userId, { text: newSummary })
    } catch (error) {
      return Promise.reject(error)
    }
    return Promise.resolve()
  }

  putEditedPersonLanguage = async (editedLang: IUserLanguage) => {
    this.loadingLanguages = true
    try {
      await agent.MySkills.editPersonLanguage(editedLang)
    } catch (error) {
      return Promise.reject(error)
    } finally {
      runInAction(() => {
        this.loadingLanguages = false
      })
    }
    return Promise.resolve()
  }

  deletePersonLanguage = async (userId: number, languageId: number) => {
    try {
      this.loadingLanguages = true
      await agent.MySkills.deletePersonLanguage(userId, languageId)
      runInAction(() => {
        this.userLanguages = this.userLanguages.filter((lang) => {
          return lang.languageId !== languageId
        })
      })
    } catch (error) {
      return Promise.reject(error)
    } finally {
      runInAction(() => {
        this.loadingLanguages = false
      })
    }
    return Promise.resolve()
  }

  getUserPublicWorkHistory = async (userId: number, useLoading = true) => {
    if (useLoading) {
      this.publicWorkHistoryLoading = true
    }
    const publicWork: IWorkHistoryDetails[] = await agent.MySkills.getUserPublicWorkHistory(userId)
    runInAction(() => {
      this.publicWorkHistory = publicWork
      if (useLoading) {
        this.publicWorkHistoryLoading = false
      }
    })
  }

  getUserPrivateWorkHistory = async (userId: number, useLoading = true) => {
    if (useLoading) {
      this.privateWorkHistoryLoading = true
    }
    const privateWork: IWorkHistoryDetailsOther[] = await agent.MySkills.getUserPrivateWorkHistory(userId)
    runInAction(() => {
      this.privateWorkHistory = privateWork
      if (useLoading) {
        this.privateWorkHistoryLoading = false
      }
    })
  }

  getAllPersons = async () => {
    if (this.persons.length === 0) {
      this.loadingInitial = true
      try {
        const fetchedPersons = await agent.Persons.getAllUsers()
        runInAction(() => {
          fetchedPersons.forEach((p: IListPerson) => {
            p.summary = decode(p.summary)
            p.title = decode(p.title)
          })

          this.persons = fetchedPersons
        })
      } catch (error) {
        console.log(error)
      } finally {
        runInAction(() => {
          this.loadingInitial = false
        })
      }
    }
  }

  getAllPersonsWithSkills = async () => {
    this.loadingInitial = true
    try {
      const fetchedPersons = await agent.Persons.getAllUsersWithSkills()
      runInAction(() => {
        fetchedPersons.forEach((p: IListPersonWithSkills) => {
          p.summary = decode(p.summary)
          p.title = decode(p.title)
        })

        this.persons = fetchedPersons
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => {
        this.loadingInitial = false
      })
    }
  }

  getCV = async (personId: number, personName: string, selectedCVElements: boolean[], language = 'fi') => {
    this.loadingCV = true
    try {
      await agent.Persons.putSelectedElementsAndgetCV(personId, personName, selectedCVElements, language)
    } catch (error) {
      return Promise.reject(error)
    } finally {
      runInAction(() => {
        this.loadingCV = false
      })
    }
    return Promise.resolve()
  }

  resetCVInfo = () => {
    this.PersonCVInfo = {} as IPersonCVInfo
  }

  getCVInfo = async (personId: number) => {
    this.loadingCVInfo = true
    try {
      const cvInfo: IPersonCVInfo = await agent.Persons.getCVInfo(personId)
      runInAction(() => {
        if (cvInfo) {
          this.PersonCVInfo = {
            personDetails: cvInfo.personDetails,
            skills: cvInfo.skills,
            projects: orderBy(cvInfo.projects, ['projectOwner', 'projectName'], ['asc', 'asc']),
            educations: orderBy(cvInfo.educations, ['startDate', 'degree'], ['desc', 'desc']),
            certificates: orderBy(cvInfo.certificates, ['expirationDate', 'name'], ['asc', 'asc']),
            languages: cvInfo.languages,
            projectSkills: cvInfo.projectSkills,
            projectRoles: cvInfo.projectRoles,
          }
        }
      })
    } catch (error) {
      runInAction(() => {
        return Promise.reject(error)
      })
    } finally {
      runInAction(() => {
        this.loadingCVInfo = false
      })
    }
    return Promise.resolve()
  }

  getDeps = async () => {
    try {
      this.allDepartments = []
      const fetchedDeps = await agent.Persons.getAllDepartments()
      runInAction(async () => {
        this.allDepartments = fetchedDeps
      })
    } catch (error) {
      return Promise.reject(error)
    }
    return Promise.resolve()
  }
}
