import { observer } from 'mobx-react-lite'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ILinkedSkill, IOtherEmployerDetails, IPrivateProjectFormPackage } from '../../../interfaces/projects/IProjects'
import { useStore } from '../../../Provider'
import { Data, OPNewTable } from '../../OPcomponents/OPNewTable'
import AddNewPrivateProject from './newprivproject/AddNewPrivateProject'

const PrivateWorkHistoryTable = (props: { rowId: number | undefined }): JSX.Element => {
  const rootStore = useStore()
  const { rowId } = props
  const { t } = useTranslation()
  const { validatedUser } = rootStore.loginStore
  const { projects, getDetailedUserProjects, allOtherEmployerDetails } = rootStore.projectStore

  const { filteredPrivateWorkHistories } = rootStore.personStore

  const tableRows: Data[] = []

  const onRowClickHandler = async (id: number | string) => {
    const clickedProject = projects.find((proj) => proj.project.projectId === id)?.project
    if (clickedProject) {
      const userDetails = await getDetailedUserProjects(validatedUser, clickedProject?.projectId)
      const employerDetails =
        allOtherEmployerDetails.find((emp) => emp.employerId === clickedProject?.employerId) ?? ({} as IOtherEmployerDetails)

      const projectInfo: IPrivateProjectFormPackage = {
        project: clickedProject,
        userDetails,
        employerDetails,
      }
      return projectInfo
    }
  }

  filteredPrivateWorkHistories.forEach((work) => {
    tableRows.push({
      ['id']: work.projectId,
      rows: {
        [t('WorkHistory.EmployerName')]: { text: work.empDetails[0]?.name ?? '', width: '23%' },
        [t('Projects.ProjectName')]: { text: work.projectName, width: '22%' },
        [t('WorkHistory.Customer')]: { text: work.customer, width: '22%' },
        [t('WorkHistory.Expirience')]: { text: `${work.daysWorked || 0} ${t('Date.ExperienceDays')}`, width: '11%' },
        [t('WorkHistory.Skills')]: { text: work.linkedSkills.map((s: ILinkedSkill) => s.skillName).join(', '), width: '22%' },
      },
    })
  })

  return tableRows.length ? (
    <OPNewTable rows={tableRows} curLength={tableRows.length} profileclickedId={rowId} onRowClick={onRowClickHandler} FormBase={AddNewPrivateProject} />
  ) : (
    <span>{t('WorkHistory.NoPrivateWorkHistory')}</span>
  )
}

export default observer(PrivateWorkHistoryTable)
