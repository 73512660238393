import { Grid } from '@mui/material'
import { Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getErrMsg, toastNeg, toastPos } from '../../../../common/toastHelper'
import { IEditUserProject, IUserProject } from '../../../../interfaces/projects/IProjects'
import { ISkillProject } from '../../../../interfaces/skills/ISkills'
import { useStore } from '../../../../Provider'
import { ExitConfirmation } from '../../../OPcomponents/ExitConfirmation'
import { ITableForm } from '../../../OPcomponents/OPNewTable'
import { newPersonalProjectSchema } from '../../../OPcomponents/ValidationSchemas'
import { OPSkillsButtons } from '../../OPSkillsButtons'
import UserSkillForm from './UserSkillForm'

const UserSkillPublic = (props: ITableForm): JSX.Element => {
  const { collapseHandler, projectId, isTable } = props
  const { t } = useTranslation()
  const rootStore = useStore()
  const { validatedUser } = rootStore.loginStore
  const { postNewSkillProjectLinks } = rootStore.skillStore
  const { setCurrentProject, postUserProject, showAddProjectModalHandler, getAllProjects, showModalHandler, showAddProjectModal } =
    rootStore.projectStore
  const { getUserPublicWorkHistory } = rootStore.personStore
  const [projectSkills, setProjectSkills] = useState<ISkillProject[]>([])

  const handleClose = async (isAdded = false) => {
    showAddProjectModalHandler(false)
    setCurrentProject({})
    showModalHandler(false)
    if (collapseHandler) {
      collapseHandler()
      if (isAdded) {
        setTimeout(async () => {
          await getAllProjects(validatedUser, false)
        }, 500)
      }
    }
  }

  const onSubmitClick = async (values: IUserProject) => {
    const linkedSkills: ISkillProject[] = []

    projectSkills.forEach((skill: ISkillProject) => {
      linkedSkills.push({
        userId: validatedUser,
        projectId: skill.projectId,
        skillId: skill.skillId,
      })
    })
    const personProject: IEditUserProject = {
      userId: validatedUser,
      projectId: values.projectId,
      startDate: values.startDate,
      endDate: values.endDate,
      workAmount: values.usingManDays ? values.manDays : values.quota,
      usingManDays: !!values.usingManDays,
      description: values.description,
      roleIds: values.roleIds.map((role) => role.key) || [],
    }

    try {
      await postUserProject(personProject, true)
      if (linkedSkills.length !== 0) await postNewSkillProjectLinks(linkedSkills)
      toastPos(t('Toasts.ProjectUserAdd'))
    } catch (err) {
      toastNeg(getErrMsg(err, t) || t('Toasts.ProjectUserAddError'))
    } finally {
      handleClose(true)
      await getUserPublicWorkHistory(validatedUser, false)
    }
  }

  const projectInit: IUserProject = {
    userId: validatedUser,
    projectId: projectId ?? -1,
    startDate: undefined,
    endDate: undefined,
    manDays: 0,
    quota: 0,
    usingManDays: true,
    description: '',
    roleIds: [],
  }

  return (
    <Formik initialValues={projectInit} validationSchema={newPersonalProjectSchema} onSubmit={(values) => onSubmitClick(values)}>
      {({ isValid, handleSubmit, isSubmitting, dirty }) => (
        <>
          <Grid container>
            <ExitConfirmation when={dirty} />
            <Grid xs={12} item>
              <UserSkillForm isTable={isTable} isNewProject={true} setProjectSkills={setProjectSkills} projectSkills={projectSkills} />
            </Grid>
          </Grid>
          <Grid xs={12} item className='gridcontainer--formcontainer__lastitempaddings'>
            <OPSkillsButtons
              onClose={handleClose}
              bothDisabled={isSubmitting}
              onSubmit={handleSubmit}
              disabled={!isValid || isSubmitting}
            />
          </Grid>
        </>
      )}
    </Formik>
  )
}

export default observer(UserSkillPublic)
