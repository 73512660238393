import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import React, { useEffect } from 'react'
import '../styles/radiobutton.scss'

interface RadioButtonProps {
  className?: string
  fontStyle?: string
  radioOptions: RadioButtonOption[]
  label: string
  name?: string
  direction?: 'row' | 'column'
  ariaLabel?: string
  dataTestId?: string
  disabled?: boolean
  defaultVal?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

interface RadioButtonOption {
  value: string
  label: string
}

export const OPRadioButton = (props: RadioButtonProps): JSX.Element => {
  const { radioOptions, direction, name, label, ariaLabel, dataTestId, disabled, onChange, defaultVal, className, fontStyle } = props

  const [value, setValue] = React.useState(defaultVal ?? '')

  useEffect(() => {
    setValue(defaultVal ?? '')
  }, [defaultVal])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue((e.target as HTMLInputElement).value)
    if (onChange) onChange(e)
  }

  return (
    <FormControl className={fontStyle ? fontStyle + ' radioComponent' : 'radioComponent'} disabled={disabled}>
      <FormLabel>{label}</FormLabel>
      <RadioGroup
        aria-labelledby={ariaLabel}
        row={direction === 'row' ? true : false}
        name={name}
        value={value}
        onChange={handleChange}
        data-testid={dataTestId}
      >
        {radioOptions.map((opt: RadioButtonOption) => (
          <FormControlLabel
            className={className ? className + ' radioOption' : 'radioOption'}
            key={opt.value}
            value={opt.value}
            control={<Radio disableRipple />}
            label={opt.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}
