import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IProjectFormPackage, IProjects } from '../../interfaces/projects/IProjects'
import { useStore } from '../../Provider'
import { OPLoadMore } from '../OPcomponents/OPLoadMore'
import { Data, OPNewTable } from '../OPcomponents/OPNewTable'
import ProjectInfo from './ProjectInfo'

const ProjectTable = (): JSX.Element => {
  const rootStore = useStore()
  const { t, i18n } = useTranslation()
  const { validatedUser } = rootStore.loginStore
  const { approvedFilteredProjects, projects, getUserProjectTitle, setCurrentProject, setEditingOld, getDetailedUserProjects } =
    rootStore.projectStore

  const lang = i18n.language
  const [tableRows, setTableRows] = useState<Data[]>([])
  const [tableLength, setTableLength] = useState(10)

  useEffect(() => {
    const newRows: Data[] = []
    approvedFilteredProjects.forEach((project: IProjects) => {
      newRows.push({
        ['id']: project.project.projectId,
        rows: {
          [t('Projects.ProjectName')]: { text: project.project.name ?? '', width: '22%' },
          [t('Projects.ProjectDescription')]: { text: project.project.description ?? '', width: '21%' },
          [t('Projects.Customer')]: { text: project.project.customer ?? '', width: '21%' },
          [t('Projects.ProjectStartDate')]: {
            text: project.project.startDate != null ? moment(project.project.startDate).locale(lang).format('L') : '',
            width: '13%',
          },
          [t('Projects.ProjectEndDate')]: {
            text: project.project.endDate != null ? moment(project.project.endDate).locale(lang).format('L') : '',
            width: '13%',
          },
          [t('Projects.Members')]: { text: project.project.osaajat ?? 0, width: '10%' },
        },
      })
    })
    setTableRows(newRows)
  }, [lang, t, approvedFilteredProjects])

  const onRowClickHandler = async (id: number | string) => {
    const clickedProject = projects.find((proj) => proj.project.projectId === id)
    if (clickedProject?.project) {
      const userDetails = await getDetailedUserProjects(validatedUser, clickedProject.project.projectId ?? -1)
      const projectUsers = await getUserProjectTitle(clickedProject.project.projectId ?? -1)

      const projectInfo: IProjectFormPackage = {
        project: clickedProject.project,
        userHas: clickedProject.userHas ?? false,
        projectUsers: projectUsers,
        userDetails: {
          details: userDetails,
          projectOwner: userDetails.projectOwner !== 0,
        },
      }
      return projectInfo as IProjectFormPackage
    }
  }

  return approvedFilteredProjects.length ? (
    <>
      <OPNewTable rows={tableRows} curLength={tableLength} onRowClick={onRowClickHandler} FormBase={ProjectInfo} />
      <OPLoadMore
        stepper={10}
        filteredArray={approvedFilteredProjects}
        onLoadMore={(data) => setTableLength(data)}
        curLength={tableLength}
        maxLength={approvedFilteredProjects.length}
      />
    </>
  ) : (
    <span className='profile--notfound'>{t('NotFound.NoResults')}</span>
  )
}

export default observer(ProjectTable)
