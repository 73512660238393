import { Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ICertEduProps } from '../../../interfaces/certAndEducation/certAndEducation'
import { useStore } from '../../../Provider'
import { OPAccordionItem } from '../../Accordion/OPAccordionItem'
import { MuiButtonVariants, OPButton } from '../../OPcomponents/OPButton'
import '../../styles/grid.scss'
import '../ceretedu.scss'
import EduTable from './EduTable'
import NewEdu from './NewEdu'

const Educations = (props: ICertEduProps): JSX.Element => {
  const { t } = useTranslation()

  const rootStore = useStore()
  const { isOpen, rowId } = props
  const { validatedUser } = rootStore.loginStore
  const {
    loadingEducations,
    getUserEducations,
    filteredEducations,
    showEditEducationModalHandler,
    showNewEducationModal,
    newEducationModal,
    getEducations,
    setCurrentEducation,
  } = rootStore.certAndEducationStore

  useEffect(() => {
    if (filteredEducations.length === 0) getUserEducations(validatedUser)
  }, [])

  const onAddClickHandler = () => {
    getEducations()
    showNewEducationModal()
  }

  useEffect(() => {
    return () => {
      showEditEducationModalHandler(false)
      showNewEducationModal(false)
      setCurrentEducation({})
    }
  }, [setCurrentEducation, showEditEducationModalHandler, showNewEducationModal])

  return (
    <Grid container item display='flex'>
      <Grid container item className='gridcontainer--certedu__addbuttonmargin'>
        <Grid item className='griditem--addbutton'>
          <OPButton label={t('Buttons.AddNew')} buttonVariant={MuiButtonVariants.primary_outlined} onClick={onAddClickHandler} />
        </Grid>
      </Grid>
      <OPAccordionItem
        title={`${t('CertAndEducation.EducationHeader')} (${loadingEducations ? '\u2026' : filteredEducations.length})`}
        setOutsideExpanded={newEducationModal || isOpen}
        content={
          <Grid container>
            <Grid container className='gridcontainer--certedu__tablepadding'>
              {newEducationModal && (
                <Grid container className='gridcontainer--certedu__containerpadding'>
                  <Grid item xs={12} className='griditem--formitem__zindex'>
                    <NewEdu />
                  </Grid>
                </Grid>
              )}
              {filteredEducations.length > 0 ? (
                <>
                  <Grid item xs={12}>
                    <EduTable rowId={rowId} />
                  </Grid>
                </>
              ) : (
                <>{!newEducationModal && <div className='ceretedu--fonttext'>{t('CertAndEducation.NoEduFound')}</div>}</>
              )}
            </Grid>
          </Grid>
        }
      />
    </Grid>
  )
}

export default observer(Educations)
