import { Divider, Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { IProjectFormPackage } from '../../interfaces/projects/IProjects'
import { ITableForm } from '../OPcomponents/OPNewTable'
import '../Profile/myskills.scss'
import '../Profile/profile.scss'
import UserSkillEdit from '../Profile/workhistory/newpubproject/UserSkillEdit'
import UserSkillPublic from '../Profile/workhistory/newpubproject/UserSkillPublic'
import ProjectHeader from '../Profile/workhistory/ProjectHeader'
import '../styles/grid.scss'
import ProjectMembers from './ProjectMembers'

const ProjectInfo = (props: ITableForm): JSX.Element => {
  const { collapseHandler } = props
  const projectData = props.data as IProjectFormPackage

  return (
    <Grid container rowSpacing={4}>
      <Grid item xs={8}>
        <ProjectHeader data={projectData?.project} />
      </Grid>
      <Grid item xs={4}>
        <ProjectMembers data={projectData?.projectUsers} />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        {projectData?.userHas ? (
          <UserSkillEdit data={projectData} collapseHandler={collapseHandler} />
        ) : (
          <UserSkillPublic projectId={projectData?.project?.projectId} collapseHandler={collapseHandler} isTable />
        )}
      </Grid>
    </Grid>
  )
}

export default observer(ProjectInfo)
