import { IconButton, InputBase } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { ChangeEvent, KeyboardEvent, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useStore } from '../../Provider'
import { SearchGlass } from '../styles/Icons'
import '../styles/search.scss'

const OPNavbarSearch = (): JSX.Element => {
  const { t } = useTranslation()
  const rootStore = useStore()
  const history = useHistory()

  const [fillState, setFillState] = useState('#757575')
  const ref = useRef(null)

  const { setIsSearching, setSearchParams, isSearching, searchParams, stepperAmount, setStepperAmount } = rootStore.seachStore

  const { setOpenState } = rootStore.sidebarStore

  const setActive = () => setFillState('#3C3C3B')
  const setDefault = () => {
    if (document.activeElement !== ref.current && !isSearching) setFillState('#757575')
  }

  const searchBase = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchParams(e.target.value)
    if (typeof stepperAmount === 'number') {
      setStepperAmount(undefined)
    }
    e.target.value.length > 0 ? setIsSearching(true) : setIsSearching(false)
  }
  const resetSidebar = () => {
    history.push('/search')
    setOpenState('8')
  }

  return (
    <InputBase
      inputRef={ref}
      onMouseEnter={setActive}
      onMouseLeave={setDefault}
      onFocus={setActive}
      onBlur={setDefault}
      onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => searchBase(e)}
      endAdornment={
        <IconButton
          disableRipple
          sx={{ padding: '0' }}
          onClick={() => {
            if (isSearching) resetSidebar()
          }}
        >
          <SearchGlass fill={fillState} />
        </IconButton>
      }
      className='searchfield'
      placeholder={t('Search.Find')}
      type='search'
      onKeyDown={(e: KeyboardEvent) => {
        if (e.key === 'Enter' && isSearching) resetSidebar()
        if (e.key === 'Escape') {
          setIsSearching(false)
          setSearchParams('')
        }
      }}
      value={searchParams}
    />
  )
}
export default observer(OPNavbarSearch)
