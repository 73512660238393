import { Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { HelpItem } from './HelpItem'

const DevHelp = () => {
  const { t } = useTranslation()

  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <HelpItem title={t('Help.DevTitle')} meta={t('Help.DevMeta')} />
      </Grid>
      <Grid item xs={12}>
        <Grid container wrap='nowrap' whiteSpace='break-spaces'>
          <span className='help--metafont'>
            <strong>{t('Help.DevGoalsBold')}</strong>
            {t('Help.DevGoalsText')}
          </span>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container wrap='nowrap' whiteSpace='break-spaces'>
          <span className='help--metafont'>
            <strong>{t('Help.DevDiscBold')}</strong>
            {t('Help.DevDiscText')}
          </span>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <ul className='help--listmargin'>
          <li className='help--metafont'>{t('Help.DevList1')}</li>
          <li className='help--metafont'>{t('Help.DevList2')}</li>
          <li className='help--metafont'>{t('Help.DevList3')}</li>
          <li className='help--metafont'>{t('Help.DevList4')}</li>
          <li className='help--metafont'>{t('Help.DevList5')}</li>
          <li className='help--metafont'>{t('Help.DevList6')}</li>
          <li className='help--metafont'>{t('Help.DevList7')}</li>
        </ul>
      </Grid>
      <Grid item xs={12}>
        <Grid container wrap='nowrap' whiteSpace='break-spaces'>
          <span className='help--metafont'>
            <strong>{t('Help.MiniDevTitle')}</strong>
            {t('Help.MiniDevText')}
          </span>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container wrap='nowrap' whiteSpace='break-spaces'>
          <span className='help--metafont'>
            <strong>{t('Help.ColFeedbackTitle')}</strong>
            {t('Help.ColFeedbackText')}
          </span>
        </Grid>
      </Grid>
    </Grid>
  )
}
export default observer(DevHelp)
