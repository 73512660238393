import { decode } from 'html-entities'
import { sortBy } from 'lodash'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import agent from '../../api/agent'
import { sortByType } from '../../common/sortHelper'
import { IInterests, IProfilePerson } from '../../interfaces/persons/IPerson'
import { IProjectPersonDetails, IProjectPersonDetailsOther } from '../../interfaces/projects/IProjects'
import { ISortDirection } from '../../interfaces/Sort'

interface IPersonOverview {
  photo?: string
  languages?: []
  summary?: string
  title?: string
  topSkills?: []
}

export default class ProfileStore {
  userSkillsSortColumn = 'skillLevel'
  userSkillsSortDirection: ISortDirection = 'descending'

  privateWorkHistorySortColumn = 'projectName'
  privateWorkHistorySortDirection: ISortDirection = 'ascending'

  publicWorkHistorySortColumn = 'projectName'
  publicWorkHistorySortDirection: ISortDirection = 'ascending'

  certificateSortColumn = 'name'
  certificateSortDirection: ISortDirection = 'ascending'

  educationSortColumn = 'degree'
  educationSortDirection: ISortDirection = 'ascending'

  interestSortColumn = 'name'
  interestSortDirection: ISortDirection = 'ascending'

  personOverviewSkillSortColumn = 'skillLevel'
  personOverviewSkillSortDirection: ISortDirection = 'descending'

  personOverviewLanguageSortColumn = 'languageName'
  personOverviewLanguageSortDirection: ISortDirection = 'ascending'

  /**
   * * Loader observables
   */

  loadingSkills = false
  loadingEducations = false
  loadingCertificates = false
  loadingOverview = false
  loadingHobbies = false
  loadingInterests = false
  loadingPublications = false

  /**
   * * Profile observables
   */

  personId = 0
  personProfileOverview: IProfilePerson = {}
  personOverview: IPersonOverview = {}
  publicWorkHistory: IProjectPersonDetails[] = []
  publicWorkHistoryLoading = false
  privateWorkHistory: IProjectPersonDetailsOther[] = []
  privateWorkHistoryLoading = false
  personSkills: any[] = []
  personCertificates: [] = []
  personEducations: [] = []
  personHobbies = ''
  personPublications = ''
  personInterests: IInterests[] = []
  selectedWorkHistory: IProjectPersonDetails | undefined = undefined

  constructor() {
    makeObservable(this, {
      userSkillsSortColumn: observable,
      userSkillsSortDirection: observable,
      privateWorkHistorySortColumn: observable,
      privateWorkHistorySortDirection: observable,
      publicWorkHistorySortColumn: observable,
      publicWorkHistorySortDirection: observable,
      certificateSortColumn: observable,
      certificateSortDirection: observable,
      educationSortColumn: observable,
      educationSortDirection: observable,
      interestSortColumn: observable,
      interestSortDirection: observable,
      personOverviewSkillSortColumn: observable,
      personOverviewSkillSortDirection: observable,
      personOverviewLanguageSortColumn: observable,
      personOverviewLanguageSortDirection: observable,
      loadingSkills: observable,
      loadingEducations: observable,
      loadingCertificates: observable,
      loadingOverview: observable,
      loadingHobbies: observable,
      loadingInterests: observable,
      loadingPublications: observable,
      personId: observable,
      personOverview: observable,
      personProfileOverview: observable,
      publicWorkHistory: observable,
      publicWorkHistoryLoading: observable,
      privateWorkHistory: observable,
      privateWorkHistoryLoading: observable,
      personSkills: observable,
      personCertificates: observable,
      personEducations: observable,
      personHobbies: observable,
      personPublications: observable,
      personInterests: observable,
      selectedWorkHistory: observable,
      filteredPrivateWorkHistories: computed,
      filteredPublicWorkHistories: computed,
      filteredSkills: computed,
      filteredCertificates: computed,
      filteredEducations: computed,
      filteredInterests: computed,
      filteredPersonOverviewSkills: computed,
      filteredPersonOverviewLanguages: computed,
      setSelectedWorkHistory: action,
      setUserSkillsSortColumn: action,
      toggleUserSkillsSortDirection: action,
      setPrivateWorkHistorySortColumn: action,
      togglePrivateWorkHistorySortDirection: action,
      setPublicWorkHistorySortColumn: action,
      togglePublicWorkHistorySortDirection: action,
      setCertificateSortColumn: action,
      toggleCertificateSortDirection: action,
      setEducationSortColumn: action,
      toggleEducationSortDirection: action,
      setInterestSortColumn: action,
      toggleInterestSortDirection: action,
      setPersonOverviewSkillSortColumn: action,
      togglePersonOverviewSkillSortDirection: action,
      setPersonOverviewLanguageSortColumn: action,
      togglePersonOverviewLanguageSortDirection: action,
      setPersonId: action,
      getPersonOverview: action,
      getPersonSkills: action,
      getPersonPublicProjects: action,
      getPersonPrivateProjects: action,
      getPersonCertificates: action,
      getPersonEducations: action,
      getPersonPublications: action,
      getPersonHobbies: action,
      getPersonInterests: action,
      resetPersonProfileOverview: action,
    })
  }

  /**
   * * Actions
   */

  get filteredPrivateWorkHistories() {
    let privWorkHistories = sortBy(this.privateWorkHistory, (workHistory) => {
      return sortByType(workHistory, this.privateWorkHistorySortColumn)
    })
    if (this.privateWorkHistorySortDirection === 'descending') {
      privWorkHistories = privWorkHistories.reverse()
    }
    return privWorkHistories
  }

  get filteredPublicWorkHistories() {
    let publicWorkHistories = sortBy(this.publicWorkHistory, (workHistory) => {
      return sortByType(workHistory, this.publicWorkHistorySortColumn)
    })
    if (this.publicWorkHistorySortDirection === 'descending') {
      publicWorkHistories = publicWorkHistories.reverse()
    }
    return publicWorkHistories
  }

  get filteredSkills() {
    let allSkills = sortBy(this.personSkills, (skill) => {
      return sortByType(skill, this.userSkillsSortColumn)
    })
    if (this.userSkillsSortDirection === 'descending') {
      allSkills = allSkills.reverse()
    }
    return allSkills
  }

  get filteredCertificates() {
    let allCertificates = sortBy(this.personCertificates, (certificate) => {
      return sortByType(certificate, this.certificateSortColumn)
    })
    if (this.certificateSortDirection === 'descending') {
      allCertificates = allCertificates.reverse()
    }
    return allCertificates
  }

  get filteredEducations() {
    let allEducations = sortBy(this.personEducations, (education) => {
      return sortByType(education, this.educationSortColumn)
    })
    if (this.educationSortDirection === 'descending') {
      allEducations = allEducations.reverse()
    }
    return allEducations
  }

  get filteredInterests() {
    let allInterests = sortBy(this.personInterests, (interest) => {
      return sortByType(interest, this.interestSortColumn)
    })
    if (this.interestSortDirection === 'descending') {
      allInterests = allInterests.reverse()
    }
    return allInterests
  }

  get filteredPersonOverviewSkills() {
    let topSkills = sortBy(this.personOverview.topSkills, (skill) => {
      return sortByType(skill, this.personOverviewSkillSortColumn)
    })
    if (this.personOverviewSkillSortDirection === 'descending') {
      topSkills = topSkills.reverse()
    }
    return topSkills
  }

  get filteredPersonOverviewLanguages() {
    let languages = sortBy(this.personOverview.languages, (language) => {
      return sortByType(language, this.personOverviewLanguageSortColumn)
    })
    if (this.personOverviewLanguageSortDirection === 'descending') {
      languages = languages.reverse()
    }
    return languages
  }

  setSelectedWorkHistory = (value: IProjectPersonDetails | undefined) => {
    this.selectedWorkHistory = value
  }

  setUserSkillsSortColumn = (value: string) => {
    this.userSkillsSortColumn = value
  }

  toggleUserSkillsSortDirection = () => {
    this.userSkillsSortDirection = this.userSkillsSortDirection === 'ascending' ? 'descending' : 'ascending'
  }

  setPrivateWorkHistorySortColumn = (value: string) => {
    this.privateWorkHistorySortColumn = value
  }

  togglePrivateWorkHistorySortDirection = () => {
    this.privateWorkHistorySortDirection = this.privateWorkHistorySortDirection === 'ascending' ? 'descending' : 'ascending'
  }

  setPublicWorkHistorySortColumn = (value: string) => {
    this.publicWorkHistorySortColumn = value
  }

  togglePublicWorkHistorySortDirection = () => {
    this.publicWorkHistorySortDirection = this.publicWorkHistorySortDirection === 'ascending' ? 'descending' : 'ascending'
  }

  setCertificateSortColumn = (value: string) => {
    this.certificateSortColumn = value
  }

  toggleCertificateSortDirection = () => {
    this.certificateSortDirection = this.certificateSortDirection === 'ascending' ? 'descending' : 'ascending'
  }

  setEducationSortColumn = (value: string) => {
    this.educationSortColumn = value
  }

  toggleEducationSortDirection = () => {
    this.educationSortDirection = this.educationSortDirection === 'ascending' ? 'descending' : 'ascending'
  }

  setInterestSortColumn = (value: string) => {
    this.interestSortColumn = value
  }

  toggleInterestSortDirection = () => {
    this.interestSortDirection = this.interestSortDirection === 'ascending' ? 'descending' : 'ascending'
  }

  setPersonOverviewSkillSortColumn = (value: string) => {
    this.personOverviewSkillSortColumn = value
  }

  togglePersonOverviewSkillSortDirection = () => {
    this.personOverviewSkillSortDirection = this.personOverviewSkillSortDirection === 'ascending' ? 'descending' : 'ascending'
  }

  setPersonOverviewLanguageSortColumn = (value: string) => {
    this.personOverviewLanguageSortColumn = value
  }

  togglePersonOverviewLanguageSortDirection = () => {
    this.personOverviewLanguageSortDirection = this.personOverviewLanguageSortDirection === 'ascending' ? 'descending' : 'ascending'
  }

  setPersonId = (id: number) => {
    this.personId = id
  }

  resetPersonProfileOverview = () => {
    this.personProfileOverview = {}
  }

  getPersonOverview = async (personId: number) => {
    this.loadingOverview = true
    this.personOverview = {}
    try {
      const fetchedPerson = await agent.MySkills.getUserOverview(personId)
      runInAction(() => {
        this.personOverview = fetchedPerson
      })
    } finally {
      runInAction(() => {
        this.loadingOverview = false
      })
    }
  }
  getUserProfileOverview = async (userId: number) => {
    runInAction(() => {
      this.loadingOverview = true
      this.personProfileOverview = {}
    })
    try {
      const fetchedUserOverview: IProfilePerson = await agent.Persons.getPersonProfileOverview(userId)
      runInAction(() => {
        this.personProfileOverview = fetchedUserOverview
        this.personProfileOverview.id = userId
        this.personProfileOverview.title = decode(this.personProfileOverview.title)
        this.personProfileOverview.summary = decode(this.personProfileOverview.summary)
      })
    } catch (err) {
      runInAction(() => {
        this.personProfileOverview = { isEmpty: true }
      })
    } finally {
      runInAction(() => {
        this.loadingOverview = false
      })
    }
  }

  getPersonSkills = async (personId: number) => {
    this.personSkills = []
    this.loadingSkills = true
    try {
      const fetchedSkills: any[] = await agent.Persons.getPersonSkills(personId)
      runInAction(() => {
        this.personSkills = fetchedSkills
        this.loadingSkills = false
      })
    } catch (error) {
      runInAction(() => {
        this.loadingSkills = false
      })
    }
  }

  getPersonPublicProjects = async (personId: number) => {
    this.publicWorkHistoryLoading = true
    try {
      const fetchedPublicProjects: IProjectPersonDetails[] = await agent.Persons.getPersonPublicProjects(personId)
      runInAction(() => {
        this.publicWorkHistory = fetchedPublicProjects
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => {
        this.publicWorkHistoryLoading = false
      })
    }
  }

  getPersonPrivateProjects = async (personId: number) => {
    this.privateWorkHistoryLoading = true
    try {
      const fetchedPrivateProjects: IProjectPersonDetailsOther[] = await agent.Persons.getPersonPrivateProjects(personId)
      runInAction(() => {
        this.privateWorkHistory = fetchedPrivateProjects
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => {
        this.privateWorkHistoryLoading = false
      })
    }
  }

  getPersonCertificates = async (personId: number) => {
    this.loadingCertificates = true
    try {
      const fetchedCertificates = await agent.Persons.getPersonCertificates(personId)
      runInAction(() => {
        this.personCertificates = fetchedCertificates
        this.loadingCertificates = false
      })
    } catch (error) {
      runInAction(() => {
        this.loadingCertificates = false
      })
      console.log(error)
    }
  }

  getPersonEducations = async (personId: number) => {
    this.loadingEducations = true
    try {
      const fetchedEducations = await agent.Persons.getPersonEducations(personId)
      runInAction(() => {
        this.personEducations = fetchedEducations.sort((a: any, b: any) => (b.isHighestDegree ? 1 : -1))
        this.loadingEducations = false
      })
    } catch (error) {
      runInAction(() => {
        this.loadingEducations = false
      })
      console.log(error)
    }
  }

  getPersonPublications = async (personId: number) => {
    this.loadingPublications = true
    try {
      const fetchedPublications = await agent.Persons.getPersonPublications(personId)
      runInAction(() => {
        this.personPublications = fetchedPublications
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => {
        this.loadingPublications = false
      })
    }
  }

  getPersonHobbies = async (personId: number) => {
    this.loadingHobbies = true
    try {
      const fetchedHobbies = await agent.Persons.getPersonHobbies(personId)
      runInAction(() => {
        this.personHobbies = decode(fetchedHobbies)
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => {
        this.loadingHobbies = false
      })
    }
  }
  getPersonInterests = async (personId: number) => {
    this.loadingInterests = true
    try {
      const fetchedInterests: IInterests[] = await agent.Persons.getPersonInterests(personId)
      runInAction(() => {
        this.personInterests = fetchedInterests
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => {
        this.loadingInterests = false
      })
    }
  }
}
