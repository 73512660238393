import { Grid, Paper } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React from 'react'
import CertEdPub from './landing/CertEdPub'
import Goals from './landing/Goals'
import MySkills from './landing/MySkills'
import NeededSkills from './landing/NeededSkills'
import Overview from './landing/Overview'
import WorkHistory from './landing/WorkHistory'
import './profile.scss'

const Profile = (): JSX.Element => {
  return (
    <Grid container columnSpacing={4} rowSpacing={4}>
      <Grid xs={12} lg={6} item>
        <Grid>
          <Paper className='profile--paper__sidebarwrapper'>
            <Overview />
          </Paper>
        </Grid>
      </Grid>
      <Grid xs={12} lg={6} item>
        <Grid>
          <Paper className='profile--paper__sidebarwrapper'>
            <MySkills />
          </Paper>
        </Grid>
      </Grid>
      <Grid xs={12} lg={6} item>
        <Grid>
          <Paper className='profile--paper__sidebarwrapper'>
            <CertEdPub />
          </Paper>
        </Grid>
      </Grid>
      <Grid xs={12} lg={6} item>
        <Grid>
          <Paper className='profile--paper__sidebarwrapper'>
            <WorkHistory />
          </Paper>
        </Grid>
      </Grid>
      <Grid xs={12} lg={6} item>
        <Grid>
          <Paper className='profile--paper__sidebarwrapper'>
            <NeededSkills />
          </Paper>
        </Grid>
      </Grid>
      <Grid xs={12} lg={6} item>
        <Grid>
          <Paper className='profile--paper__sidebarwrapper profile--breakword'>
            <Goals />
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default observer(Profile)
