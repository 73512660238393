import { Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IProfilePersonSkill } from '../../../../interfaces/skills/ISkills'
import { OPSkillbar } from '../../../OPcomponents/OPSkillbar'
import ProjectInfo from './ProjectInfo'

const ProfileSkillInfo = (props: { skillInfo: IProfilePersonSkill }) => {
  const { skillInfo } = props
  const { t } = useTranslation()

  return (
    <Grid container rowGap={4} className='gridcontainer--formcontainer__lastitempaddings'>
      <Grid xs={12} item container rowGap={2}>
        <Grid item xs={12}>
          <span className='myskill--font__titlefont sini'>{t('Persons.PersonSkill')}</span>
        </Grid>
        <Grid xs={12} item>
          <OPSkillbar readonly value={skillInfo?.skillLevel ?? 0} label={t('Skills.Level')} hasLabel name={'skilllvl'} />
        </Grid>
      </Grid>
      <Grid xs={12} item container rowGap={1}>
        <Grid item xs={12}>
          <span className='myskill--font__multilinelabel'>{t('Persons.PersonSkillInfo')}</span>
        </Grid>
        <Grid item xs={12}>
          <span className='myskill--font__multilinetext'>
            {skillInfo?.skillComment !== '' ? skillInfo?.skillComment : t('Persons.NotDefined')}
          </span>
        </Grid>
      </Grid>
      <Grid item xs={12} container rowGap={2}>
        <Grid item xs={12}>
          <span className='myskill--font__multilinelabel'>{t('Skills.AddedProjects')}</span>
        </Grid>
        {skillInfo?.linkedProjects && skillInfo?.linkedProjects?.length > 0 ? (
          <>
            {skillInfo?.linkedProjects?.map((project) => (
              <Grid item xs={12} key={project.projectId}>
                <ProjectInfo projectInfo={project} />
              </Grid>
            ))}
          </>
        ) : (
          <Grid item xs={12}>
            <span className='myskill--font__multilinetext'>{t('Persons.NoLinkedProjects')}</span>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default observer(ProfileSkillInfo)
