import { Grid } from '@mui/material'
import { Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { localizedLanguageNames, localizedLanguageSkills } from '../../../common/languageHelper'
import { getErrMsg, toastNeg, toastPos } from '../../../common/toastHelper'
import { IUserLanguage } from '../../../interfaces/language/ILanguage'
import { useStore } from '../../../Provider'
import '../../styles/grid.scss'
import { ExitConfirmation } from '../../OPcomponents/ExitConfirmation'
import { OPMultiline } from '../../OPcomponents/OPMultiline'
import { OPSkillsCombobox } from '../../OPcomponents/OPSkillsCombobox'
import { langSchema } from '../../OPcomponents/ValidationSchemas'
import { OPSkillsButtons } from '../OPSkillsButtons'

interface AddNewLanguage {
  collapseHandler?: () => void
  isEdit?: boolean
}

const AddNewLanguage = (props: AddNewLanguage): JSX.Element => {
  const { t } = useTranslation()
  const { collapseHandler, isEdit } = props

  const rootStore = useStore()

  const { validatedUser } = rootStore.loginStore

  const {
    openLanguageEditModal,
    setShowLanguageModal,
    showLanguageModal,
    getAllLanguages,
    postNewUserLanguage,
    selectableNewLanguages,
    getUserOverview,
  } = rootStore.personStore

  const languageSkillOptions = localizedLanguageSkills(t)
  const i18languageNames = localizedLanguageNames(selectableNewLanguages, t)

  const initialValues: IUserLanguage = {
    personId: validatedUser,
    languageId: -1,
    languageLevel: '',
    description: '',
    languageName: '',
  }

  useEffect(() => {
    getAllLanguages()
  }, [getAllLanguages])

  const formSubmit = async (values: IUserLanguage) => {
    try {
      await postNewUserLanguage(values)
      toastPos(t('Toasts.CreateSuccess'))
    } catch (error) {
      toastNeg(getErrMsg(error, t) || t('Toasts.CreateError'))
    } finally {
      await getUserOverview(validatedUser)
      handleClose()
    }
  }

  const handleClose = () => {
    if (showLanguageModal) setShowLanguageModal()
    if (collapseHandler) collapseHandler()
  }

  return (
    <Formik initialValues={initialValues} onSubmit={formSubmit} validationSchema={langSchema}>
      {({ values, setFieldValue, handleChange, submitForm, isSubmitting, dirty, isValid }) => (
        <Grid container spacing={2}>
          <ExitConfirmation when={dirty} />
          <Grid item lg={6} xs={12}>
            <Grid item xs={12}>
              <OPSkillsCombobox
                multiple={false}
                curState={i18languageNames.find((lang) => Number(lang.key) === values.languageId)?.text}
                onChange={async (_e, val) => {
                  if (val) {
                    await setFieldValue('languageName', val.value)
                    await setFieldValue('languageId', val.key)
                  }
                }}
                placeholder={t('Placeholders.Choose')}
                disabled={isEdit}
                label={t('Language.ChooseLanguage')}
                optionList={i18languageNames}
              />
            </Grid>
            <Grid item xs={12} className='griditem--formitem__formtoppadding'>
              <OPSkillsCombobox
                placeholder={t('Placeholders.Choose')}
                multiple={false}
                curState={languageSkillOptions.find((lang) => lang.key === values.languageLevel)?.text}
                onChange={(_e, val) => {
                  if (val) setFieldValue('languageLevel', val.key)
                }}
                label={t('Language.SkillLevel')}
                optionList={languageSkillOptions}
              />
            </Grid>
          </Grid>
          <Grid item lg={6} xs={12}>
            <OPMultiline
              name='description'
              placeholder={t('Placeholders.FurtherInfo')}
              label={t('Language.Description')}
              value={values.description}
              onChange={handleChange}
              hasCounter
              maxChars={200}
              minRows={6}
            />
          </Grid>
          <Grid item xs={12}>
            <OPSkillsButtons
              noPadding
              disabled={!(isValid && dirty) || isSubmitting}
              bothDisabled={isSubmitting}
              onClose={handleClose}
              onSubmit={submitForm}
            />
          </Grid>
        </Grid>
      )}
    </Formik>
  )
}

export default observer(AddNewLanguage)
