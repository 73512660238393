import { Grid } from '@mui/material'
import { Form, Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getErrMsg, toastNeg, toastPos } from '../../../common/toastHelper'
import { IEducation, IExistingEducation, INewEducation } from '../../../interfaces/certAndEducation/certAndEducation'
import { useStore } from '../../../Provider'
import DeleteConfirmation from '../../OPcomponents/DeleteConfirmation'
import { ExitConfirmation } from '../../OPcomponents/ExitConfirmation'
import AutoSave from '../../OPcomponents/FormikAutosave'
import { OPCheckBox } from '../../OPcomponents/OPCheckBox'
import { OPDateField } from '../../OPcomponents/OPDateField'
import { OPMultiline } from '../../OPcomponents/OPMultiline'
import { ITableForm } from '../../OPcomponents/OPNewTable'
import { OPSkillsCombobox } from '../../OPcomponents/OPSkillsCombobox'
import { schoolSchema } from '../../OPcomponents/ValidationSchemas'
import '../../styles/grid.scss'
import '../ceretedu.scss'
import { OPSkillsButtons } from '../OPSkillsButtons'

const NewEdu = (props: ITableForm): JSX.Element => {
  const { collapseHandler, isEdit, data } = props

  const { t } = useTranslation()

  const [deleteEduConfirmation, setDeleteEduConfirmation] = useState(false)

  const rootStore = useStore()
  const {
    showEditEducationModal,
    showEditEducationModalHandler,
    removeUserEducation,
    editUserEducation,
    educations,
    postNewExistingEducation,
    showNewEducationModal,
    newEducationModal,
    postNewEducation,
    setCurrentEducation,
    userEducations,
    getUserEducations,
    filteredEducations,
  } = rootStore.certAndEducationStore
  const { validatedUser } = rootStore.loginStore

  const curEdu = filteredEducations.find((edu) => edu.educationId === data)
  const initVal: IEducation = {
    degree: curEdu?.degree ?? '',
    school: curEdu?.school ?? '',
    startDate: curEdu?.startDate ?? undefined,
    endDate: curEdu?.endDate ?? undefined,
    comment: curEdu?.comment ?? '',
    educationId: curEdu?.educationId ?? -1,
    isHighestDegree: curEdu?.isHighestDegree ?? false,
  }

  const filteredEdus = educations.filter((allEdu) => userEducations.every((edu) => edu.educationId !== allEdu.key))

  const isNewEdu = (edu: IEducation): edu is INewEducation => {
    return 'degree' in edu && 'school' in edu && 'startDate' in edu && 'comment' in edu && 'isHighestDegree' in edu
  }
  const isNewExistingEdu = (edu: IEducation): edu is IExistingEducation => {
    return (
      'educationId' in edu &&
      edu.educationId !== -1 &&
      'school' in edu &&
      'startDate' in edu &&
      'comment' in edu &&
      'endDate' in edu &&
      'isHighestDegree' in edu
    )
  }

  const onSubmit = async (values: IEducation) => {
    const eduId = curEdu?.educationId ?? values.educationId
    try {
      if (isEdit) {
        if (eduId && isNewEdu(values)) {
          const editedEdu: INewEducation = {
            personId: validatedUser,
            degree: values.degree,
            school: values.school,
            comment: values.comment,
            startDate: values.startDate,
            endDate: values.endDate,
            isHighestDegree: values.isHighestDegree,
          }
          if (isEdit) {
            await editUserEducation(eduId, editedEdu)
          }
        }
      } else if (newEducationModal && eduId) {
        if (eduId === -1 && isNewEdu(values)) {
          const formValues: INewEducation = {
            personId: validatedUser,
            degree: values.degree,
            school: values.school,
            comment: values.comment,
            startDate: values.startDate,
            endDate: values.endDate,
            isHighestDegree: values.isHighestDegree,
          }
          await postNewEducation(formValues)
        } else if (isNewExistingEdu(values)) {
          const formValues: IExistingEducation = {
            educationId: eduId,
            personId: validatedUser,
            school: values.school,
            comment: values.comment,
            startDate: values.startDate,
            endDate: values.endDate,
            isHighestDegree: values.isHighestDegree,
          }
          await postNewExistingEducation(formValues)
        }
        toastPos(t('Toasts.CreateSuccess'))
      }
    } catch (err) {
      toastNeg(getErrMsg(err, t) || showEditEducationModal ? t('Toasts.EditError') : t('Toasts.CreateError'))
    } finally {
      if (!isEdit) closeHandler()
    }
  }

  const closeHandler = () => {
    if (newEducationModal) showNewEducationModal()
    if (showEditEducationModal) showEditEducationModalHandler()
    if (isEdit && collapseHandler) collapseHandler()
    setCurrentEducation({})
    setTimeout(() => getUserEducations(validatedUser), 550)
  }

  const deleteHandler = async () => {
    try {
      if (curEdu?.educationId) {
        await removeUserEducation(validatedUser, curEdu?.educationId)
        toastPos(t('Toasts.DeleteSuccess'))
      }
    } catch (err) {
      toastNeg(getErrMsg(err, t) || t('Toasts.DeleteError'))
    } finally {
      closeHandler()
    }
  }

  return (
    <Formik enableReinitialize initialValues={initVal} validationSchema={schoolSchema} onSubmit={(values) => onSubmit(values)}>
      {({ setFieldValue, errors, values, handleChange, isValid, dirty, handleSubmit, isSubmitting }) => (
        <Form className='profile--form__formwidth' onSubmit={handleSubmit}>
          <Grid
            container
            rowSpacing={2}
            columnSpacing={3}
            className={isEdit ? 'gridcontainer--formcontainer__itempaddings' : 'gridcontainer--certedu__containerpadding'}
          >
            {!isEdit && (
              <Grid item xs={12}>
                <span className='profile--addnewfont nopadding'>{t('CertAndEducation.NewEducation')}</span>
              </Grid>
            )}
            <ExitConfirmation when={dirty} />
            <Grid container rowGap={4} item xs={6}>
            {!isEdit ? (
              <Grid item xs={6}>
              <OPSkillsCombobox
                freeSolo
                disabled={isEdit}
                curState={values.degree}
                name='degree'
                optionList={filteredEdus}
                label={`${t('CertAndEducation.EducationName')}*`}
                onChange={async (_e, v) => {
                  if (v && _e) {
                    setFieldValue('degree', v.text ?? v)
                    setFieldValue('educationId', filteredEdus.find((edu) => edu.text === v.text)?.key ?? -1)
                  }
                }}
                multiple={false}
                manInput
              /></Grid>)
            : (
              <Grid container item xs={12} rowGap={0.5}>
                <Grid item xs={12}><label className='profile--titlecaption'>{`${t('CertAndEducation.EducationName')}*`}</label></Grid>
                <Grid item xs={12}><label className='certedu--fonttext'>{values.degree}</label></Grid>
              </Grid>
            )}
              <Grid item xs={12} className='griditem--formitem__itemtitlemargin'>
                <OPMultiline
                  name='school'
                  value={values.school}
                  label={`${t('CertAndEducation.EducationGranterName')}*`}
                  onChange={handleChange}
                  maxChars={50}
                  hasCounter
                  error={Boolean(errors.school)}
                  errorText={t(errors.school || '')}
                  disabled={deleteEduConfirmation}
                />
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <OPMultiline
                name='comment'
                value={values.comment}
                label={t('CertAndEducation.FurtherInfo')}
                onChange={handleChange}
                minRows={6}
                maxChars={250}
                hasCounter
                disabled={deleteEduConfirmation}
              />
            </Grid>
            <Grid item xs={3}>
              <OPDateField
                name='startDate'
                label={`${t('CertAndEducation.EducationStartDate')}*`}
                value={values.startDate ?? null}
                onChange={(v) => setFieldValue('startDate', v)}
                error={Boolean(errors.startDate)}
                errMessage={t(errors.startDate || '')}
                disabled={deleteEduConfirmation}
              />
            </Grid>
            <Grid item xs={3}>
              <OPDateField
                name='endDate'
                label={t('CertAndEducation.EducationEndDate')}
                value={values.endDate ?? null}
                minDate={values.startDate}
                onChange={(v) => setFieldValue('endDate', v)}
                error={Boolean(errors.endDate)}
                errMessage={t(errors.endDate || '')}
                disabled={deleteEduConfirmation}
              />
            </Grid>
            <Grid item container xs={6} alignItems='flex-end'>
              <OPCheckBox
                label={t('CertAndEducation.HighestDegree')}
                value={values.isHighestDegree ?? false}
                onChangeState={(v, c) => setFieldValue('isHighestDegree', c ?? false)}
                disabled={deleteEduConfirmation}
              />
            </Grid>
            <Grid container item xs={12} className='gridcontainer--certedu__flexend'>
              <Grid item>
                <OPSkillsButtons
                  noPadding
                  isDelete={isEdit}
                  onDelete={() => setDeleteEduConfirmation(true)}
                  onClose={closeHandler}
                  bothDisabled={deleteEduConfirmation || isSubmitting}
                  disabled={!(isValid && dirty) || isSubmitting}
                  onSubmit={handleSubmit}
                  onAutosave={isEdit}
                />
                {isEdit && <AutoSave fetchFunction={getUserEducations} />}
              </Grid>
              {isEdit && (
                <DeleteConfirmation
                  whatToDelete='Education'
                  open={deleteEduConfirmation}
                  handleClose={() => setDeleteEduConfirmation(false)}
                  deleteHandler={deleteHandler}
                />
              )}
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default observer(NewEdu)
