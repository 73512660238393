import { CircularProgress } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import agent from '../../api/agent'
import { IListDevelopmentGoal, IListDiscussion, IListFeedbackGoal } from '../../interfaces/discussions/IDiscussion'
import { useStore } from '../../Provider'
import '../styles/developmentdiscussion.scss'
import { OPAccordionItem } from '../Accordion/OPAccordionItem'
import { MuiButtonVariants, OPButton } from '../OPcomponents/OPButton'
import { OPDivider } from '../OPcomponents/OPDivider'
import { DevelopmentGoal } from './DevelopmentGoal'
import { DevelopmentGoalForm } from './DevelopmentGoalForm'
import { FeedbackGoal } from './FeedbackGoal'
import { FeedbackGoalForm } from './FeedbackGoalForm'

interface DiscussionGoalsProps {
  discussion?: IListDiscussion
  devGoals?: IListDevelopmentGoal[]
  fbGoals?: IListFeedbackGoal[]
}

export const DiscussionGoals = observer((props: DiscussionGoalsProps): JSX.Element => {
  const { discussion, devGoals = [], fbGoals = [] } = props
  const { t } = useTranslation()
  const rootStore = useStore()
  const {
    isConfirmReadySectionVisible,
    isConfirmDeleteGoalVisible,
    isAddCommentVisible,
    isNewDiscussionDevGoalVisible,
    setIsNewDiscussionDevGoalVisible,
    isNewDiscussionFBGoalVisible,
    setIsNewDiscussionFBGoalVisible,
    isEditDiscussionGoal,
    isEditFeedbackGoal,
  } = rootStore.kekeStore

  const [loadingGoals, setLoadingGoals] = useState<boolean>(false)
  const [readyDevGoals, setReadyDevGoals] = useState<IListDevelopmentGoal[]>(devGoals.filter((g) => g.completed))
  const [notReadyDevGoals, setNotReadyDevGoals] = useState<IListDevelopmentGoal[]>(devGoals.filter((g) => !g.completed))
  const [readyFbGoals, setReadyFbGoals] = useState<IListFeedbackGoal[]>(fbGoals.filter((g) => g.completed))
  const [notReadyFbGoals, setNotReadyFbGoals] = useState<IListFeedbackGoal[]>(fbGoals.filter((g) => !g.completed))

  const refreshGoals = async () => {
    setLoadingGoals(true)
    if (discussion) {
      const response = await agent.Discussions.getDiscussionGoals(discussion?.developmentDiscussionId)
      setReadyDevGoals(response.developmentGoals.filter((g: IListDevelopmentGoal) => g.completed))
      setNotReadyDevGoals(response.developmentGoals.filter((g: IListDevelopmentGoal) => !g.completed))
      setReadyFbGoals(response.feedbackGoals.filter((g: IListFeedbackGoal) => g.completed))
      setNotReadyFbGoals(response.feedbackGoals.filter((g: IListFeedbackGoal) => !g.completed))
      setLoadingGoals(false)
    } else {
      setLoadingGoals(false)
    }
  }

  return (
    <>
      <OPAccordionItem
        title={`${t('Discussions.DevelopmentAccordionGoals')} (${notReadyDevGoals.length})`}
        content={
          <div>
            {loadingGoals ? (
              <div className='loaderDiv'>
                <CircularProgress />
              </div>
            ) : notReadyDevGoals.length > 0 ? (
              notReadyDevGoals.map((goal) => {
                return (
                  <DevelopmentGoal
                    key={goal.goalId}
                    setGoalReady={(g) => {
                      const filtered = notReadyDevGoals.filter((goal) => goal.goalId !== g.goalId)
                      setNotReadyDevGoals(filtered)
                      setReadyDevGoals([...readyDevGoals, g])
                    }}
                    goal={goal}
                    onDelete={(goal) => setNotReadyDevGoals(notReadyDevGoals.filter((g) => g.goalId !== goal.goalId))}
                  />
                )
              })
            ) : (
              <div className='noContent'>{t('Discussions.NoGoalsShort')}</div>
            )}
            {discussion?.state !== 'Completed' && (
              <div className='addGoalDiv'>
                <OPButton
                  label={t('Buttons.AddDevGoal')}
                  disabled={
                    isConfirmReadySectionVisible ||
                    isNewDiscussionDevGoalVisible ||
                    isNewDiscussionFBGoalVisible ||
                    isEditDiscussionGoal ||
                    isEditFeedbackGoal ||
                    isAddCommentVisible ||
                    isConfirmDeleteGoalVisible
                  }
                  onClick={() => setIsNewDiscussionDevGoalVisible(true)}
                  buttonVariant={MuiButtonVariants.primary}
                />
              </div>
            )}
            {isNewDiscussionDevGoalVisible && !isEditDiscussionGoal && (
              <DevelopmentGoalForm
                developmentDiscussionId={discussion?.developmentDiscussionId}
                onCancel={() => setIsNewDiscussionDevGoalVisible(false)}
                onSave={() => {
                  refreshGoals()
                  setIsNewDiscussionDevGoalVisible(false)
                }}
              />
            )}
            {readyDevGoals.length > 0 && (
              <OPAccordionItem
                insideAccordion
                finished
                title={`${t('Discussions.ReadyDevGoals')} (${readyDevGoals.length})`}
                content={
                  <div className='accordionContent'>
                    {readyDevGoals.map((goal) => {
                      return (
                        <DevelopmentGoal
                          key={goal.goalId}
                          reOpenGoal={(g) => {
                            const filtered = readyDevGoals.filter((goal) => goal.goalId !== g.goalId)
                            setReadyDevGoals(filtered)
                            setNotReadyDevGoals([...notReadyDevGoals, g])
                          }}
                          goal={goal}
                        />
                      )
                    })}
                  </div>
                }
              />
            )}
            <OPDivider />
          </div>
        }
      />

      <OPAccordionItem
        title={`${t('Discussions.DevelopmentAccordionFeedbackGoals')} (${notReadyFbGoals.length})`}
        content={
          <div className='accordionContent'>
            {notReadyFbGoals.length > 0 ? (
              notReadyFbGoals.map((goal) => {
                return (
                  <FeedbackGoal
                    key={goal.goalId}
                    setGoalReady={(g) => {
                      const filtered = notReadyFbGoals.filter((goal) => goal.goalId !== g.goalId)
                      setNotReadyFbGoals(filtered)
                      setReadyFbGoals([...readyFbGoals, g])
                    }}
                    goal={goal}
                    onDelete={(goal) => setNotReadyFbGoals(notReadyFbGoals.filter((g) => g.goalId !== goal.goalId))}
                  />
                )
              })
            ) : (
              <div className='noContent'>{t('Discussions.NoGoalsShort')}</div>
            )}
            {discussion?.state !== 'Completed' && (
              <div className='addGoalDiv'>
                <OPButton
                  label={t('Buttons.AddFeedbackGoal')}
                  disabled={
                    isConfirmReadySectionVisible ||
                    isNewDiscussionDevGoalVisible ||
                    isNewDiscussionFBGoalVisible ||
                    isEditFeedbackGoal ||
                    isEditDiscussionGoal ||
                    isAddCommentVisible ||
                    isConfirmDeleteGoalVisible
                  }
                  buttonVariant={MuiButtonVariants.primary}
                  onClick={() => setIsNewDiscussionFBGoalVisible(true)}
                />
              </div>
            )}
            {isNewDiscussionFBGoalVisible && !isEditFeedbackGoal && (
              <FeedbackGoalForm
                developmentDiscussionId={discussion?.developmentDiscussionId}
                onCancel={() => setIsNewDiscussionFBGoalVisible(false)}
                onSave={() => {
                  refreshGoals()
                  setIsNewDiscussionFBGoalVisible(false)
                }}
              />
            )}
            {readyFbGoals.length > 0 && (
              <OPAccordionItem
                insideAccordion
                finished
                title={`${t('Discussions.ReadyFeedbackGoals')} (${readyFbGoals.length})`}
                content={
                  <div className='accordionContent'>
                    {readyFbGoals.map((goal) => {
                      return (
                        <FeedbackGoal
                          key={goal.goalId}
                          reOpenGoal={(g) => {
                            const filtered = readyFbGoals.filter((goal) => goal.goalId !== g.goalId)
                            setReadyFbGoals(filtered)
                            setNotReadyFbGoals([...notReadyFbGoals, g])
                          }}
                          goal={goal}
                        />
                      )
                    })}
                  </div>
                }
              />
            )}
          </div>
        }
      />
    </>
  )
})
