import { head } from 'lodash'
import { action, makeObservable, observable, runInAction } from 'mobx'
import agent from '../../api/agent'
import history from '../../history'
import {
  IColleagueFeedback,
  IComment,
  IDevelopmentGoal,
  IDevelopmentGoalDetailed,
  IDiscussion,
  IDiscussionVersion,
  IEditColleagueFeedback,
  IEditDevelopmentGoal,
  IEditFeedbackGoal,
  IFeedbackGoal,
  IFeedbackGoalDetailed,
  IFormQuestionCategory,
  IFormTemplate,
  IListColleagueFeedback,
  IListDevelopmentGoal,
  IListDiscussion,
  IListFeedbackGoal,
  INewColleagueFeedback,
  INewComment,
  INewDevelopmentGoal,
  INewDiscussion,
  INewDiscussionVersion,
  INewFeedbackGoal,
  IUserDiscussion,
} from '../../interfaces/discussions/IDiscussion'

export default class KekeStore {
  // Is keke questions form pristine
  isPristine = true

  // Common goals
  developmentGoals: IListDevelopmentGoal[] = []
  feedbackGoals: IListFeedbackGoal[] = []
  colleagueFeedbacks: IListColleagueFeedback[] = []
  loadingUserGoals = false

  formTemplates: Array<IFormTemplate> = []
  loadingFormTemplates = false

  userDiscussion: IUserDiscussion | undefined = undefined
  loadingDiscussions = false

  // Specific discussion goals
  discussionDevelopmentGoals: IDevelopmentGoal[] = []
  discussionFeedbackGoals: IFeedbackGoal[] = []
  loadingDiscussionGoals = false

  loadingGoal = false
  loadingFeedbackGoal = false

  showNewKekeModal = false
  showDevelopmentGoalModal = false
  showFeedbackGoalModal = false
  showColleagueFeedbackModal = false

  selectedDiscussionCategories: Array<IFormQuestionCategory> = []

  selectedDiscussion: IListDiscussion | undefined = undefined
  selectedDiscussionDetails: IDiscussion | undefined = undefined
  loadingSelectedDiscussionDetails = false
  selectedMiniDiscussionId: number | undefined = undefined

  loadingDiscussionQuestions = false
  selectedDiscussionVersion: IDiscussionVersion | undefined = undefined

  selectedTemplate: IFormTemplate | undefined = undefined

  // Selected goal for selectedDevelopmentGoal and SelectedFeedbackGoal components. From developmentGoals and feedbackGoals.
  // also used for editing in editDevelopmentGoal and EditFeedbackGoal
  selectedDevelopmentGoalDetailed: IDevelopmentGoalDetailed | undefined = undefined
  selectedFeedbackGoalDetailed: IFeedbackGoalDetailed | undefined = undefined

  // Selected discussion goals (development & feedback) for editing/creating a goal in modal. From SelectedDiscussion's goal table.
  selectedDevelopmentGoal: IDevelopmentGoal | undefined = undefined
  selectedFeedbackGoal: IFeedbackGoal | undefined = undefined
  selectedColleagueFeedback: IColleagueFeedback | undefined = undefined

  isEditDiscussionGoal = false
  isEditFeedbackGoal = false
  isEditColleagueFeedback = false

  isConfirmDeleteGoalVisible = false
  isConfirmDeleteDiscussionVisible = false
  isConfirmReadySectionVisible = false
  isAddCommentVisible = false
  isNewDevGoalVisible = false
  isNewFBGoalVisible = false

  isNewDevDiscussionVisible = false
  isNewMiniDiscussionVisible = false
  isNewColleagueFeedbackVisible = false
  isDeleteColleagueFeedbackVisible = false
  isNewDiscussionDevGoalVisible = false
  isNewDiscussionFBGoalVisible = false

  isConfirmSendDiscussionVisible = false

  savingDiscussion = false

  setIsDeleteColleagueFeedbackVisible = (value: boolean): void => {
    this.isDeleteColleagueFeedbackVisible = value
  }

  setSelectedMiniDiscussionId = (id: number | undefined): void => {
    this.selectedMiniDiscussionId = id
  }

  setIsConfirmSendDiscussionVisible = (value: boolean): void => {
    this.isConfirmSendDiscussionVisible = value
  }

  setIsNewDevDiscussionVisible = (value: boolean): void => {
    this.isNewDevDiscussionVisible = value
  }

  setIsNewMiniDiscussionVisible = (value: boolean): void => {
    this.isNewMiniDiscussionVisible = value
  }

  setIsNewColleagueFeedbackVisible = (value: boolean): void => {
    this.isNewColleagueFeedbackVisible = value
  }

  setIsNewDiscussionDevGoalVisible = (value: boolean): void => {
    this.isNewDiscussionDevGoalVisible = value
  }

  setIsNewDiscussionFBGoalVisible = (value: boolean): void => {
    this.isNewDiscussionFBGoalVisible = value
  }

  setIsNewDevGoalVisible = (value: boolean): void => {
    this.isNewDevGoalVisible = value
  }

  setIsNewFBGoalVisible = (value: boolean): void => {
    this.isNewFBGoalVisible = value
  }

  setIsConfirmDeleteGoalVisible = (value: boolean): void => {
    this.isConfirmDeleteGoalVisible = value
  }

  setIsConfirmDeleteDiscussionVisible = (value: boolean): void => {
    this.isConfirmDeleteDiscussionVisible = value
  }

  setIsConfirmReadySectionVisible = (value: boolean): void => {
    this.isConfirmReadySectionVisible = value
  }

  setIsAddCommentVisible = (value: boolean): void => {
    this.isAddCommentVisible = value
  }

  setIsPristine = (value: boolean): void => {
    this.isPristine = value
  }

  setIsEditDevelopmentGoal = (value: boolean): void => {
    this.isEditDiscussionGoal = value
  }

  setIsEditFeedbackGoal = (value: boolean): void => {
    this.isEditFeedbackGoal = value
  }

  setIsEditColleagueFeedback = (value: boolean): void => {
    this.isEditColleagueFeedback = value
  }

  setSavingDiscussion = (value: boolean): void => {
    this.savingDiscussion = value
  }

  selectDevelopmentGoal = (goal?: IDevelopmentGoal): void => {
    this.selectedDevelopmentGoal = goal
  }

  selectFeedbackGoal = (goal?: IFeedbackGoal): void => {
    this.selectedFeedbackGoal = goal
  }

  selectColleagueFeedback = (feedback?: IColleagueFeedback): void => {
    this.selectedColleagueFeedback = feedback
  }

  selectDiscussionVersion = (version?: IDiscussionVersion): void => {
    this.selectedDiscussionVersion = version
  }

  selectDevelopmentGoalDetailed = (goal?: IDevelopmentGoalDetailed): void => {
    this.selectedDevelopmentGoalDetailed = goal
  }

  selectFeedbackGoalDetailed = (goal?: IFeedbackGoalDetailed): void => {
    this.selectedFeedbackGoalDetailed = goal
  }

  selectDiscussion = (discussion?: IListDiscussion): void => {
    this.selectedDiscussion = discussion
  }

  selectDiscussionDetails = (discussion?: IDiscussion): void => {
    this.selectedDiscussionDetails = discussion
  }

  selectTemplate = (formTemplate?: IFormTemplate): void => {
    this.selectedTemplate = formTemplate
  }

  setShowNewKekeModal = (value: boolean): void => {
    this.showNewKekeModal = value
  }

  setShowDevelopmentGoalModal = (value: boolean): void => {
    this.showDevelopmentGoalModal = value
  }

  setShowFeedbackGoalModal = (value: boolean): void => {
    this.showFeedbackGoalModal = value
  }

  setShowColleagueFeedbackModal = (value: boolean): void => {
    this.showColleagueFeedbackModal = value
  }

  postEmployerDiscussion = async (employerId: number, employeeId: number, discussion: INewDiscussion): Promise<void> => {
    try {
      await agent.Discussions.postEmployerDiscussion(employerId, employeeId, discussion)
      runInAction(async () => {
        await this.getEmployeeDiscussions(employeeId)
      })
    } catch (error) {
      return Promise.reject(error)
    }
    return Promise.resolve()
  }

  postEmployeeDiscussion = async (employeeId: number, discussion: INewDiscussion): Promise<void> => {
    try {
      await agent.Discussions.postEmployeeDiscussion(employeeId, discussion)
      runInAction(async () => {
        await this.getEmployeeDiscussions(employeeId)
      })
    } catch (error) {
      return Promise.reject(error)
    }
    return Promise.resolve()
  }

  deleteDevelopmentGoal = async (goalId: number): Promise<void> => {
    try {
      await agent.Discussions.deleteDevelopmentGoal(goalId)
    } catch (error) {
      return Promise.reject(error)
    }
    return Promise.resolve()
  }

  deleteDevelopmentDiscussion = async (employeeId: number, discussionID: number): Promise<void> => {
    try {
      await agent.Discussions.deleteDevelopmentDiscussion(discussionID)
      runInAction(async () => {
        await this.getEmployeeDiscussions(employeeId)
      })
    } catch (error) {
      return Promise.reject(error)
    }
    return Promise.resolve()
  }

  deleteFeedbackGoal = async (goalId: number): Promise<void> => {
    try {
      await agent.Discussions.deleteFeedbackGoal(goalId)
    } catch (error) {
      return Promise.reject(error)
    }
    return Promise.resolve()
  }

  deleteColleagueFeedback = async (feedbackId: number): Promise<void> => {
    try {
      await agent.Discussions.deleteColleagueFeedback(feedbackId)
    } catch (error) {
      return Promise.reject(error)
    }
    return Promise.resolve()
  }

  reopenDevelopmentGoal = async (goalId: number): Promise<void> => {
    try {
      await agent.Discussions.reopenDevelopmentGoal(goalId)
      runInAction(() => {
        const foundGoal = this.developmentGoals.find((g) => g.goalId === goalId)
        if (foundGoal) {
          foundGoal.completed = false
        }
      })
    } catch (error) {
      return Promise.reject(error)
    }
    return Promise.resolve()
  }

  reopenFeedbackGoal = async (goalId: number): Promise<void> => {
    try {
      await agent.Discussions.reopenFeedbackGoal(goalId)
      runInAction(() => {
        const foundGoal = this.feedbackGoals.find((g) => g.goalId === goalId)
        if (foundGoal) {
          foundGoal.completed = false
        }
      })
    } catch (error) {
      return Promise.reject(error)
    }
    return Promise.resolve()
  }

  completeDevelopmentGoal = async (goalId: number): Promise<void> => {
    try {
      await agent.Discussions.completeDevelopmentGoal(goalId)
      runInAction(() => {
        const foundGoal = this.developmentGoals.find((g) => g.goalId === goalId)
        if (foundGoal) {
          foundGoal.completed = true
        }
      })
    } catch (error) {
      return Promise.reject(error)
    }
    return Promise.resolve()
  }

  completeFeedbackGoal = async (goalId: number): Promise<void> => {
    try {
      await agent.Discussions.completeFeedbackGoal(goalId)
      runInAction(() => {
        const foundGoal = this.feedbackGoals.find((g) => g.goalId === goalId)
        if (foundGoal) {
          foundGoal.completed = true
        }
      })
    } catch (error) {
      return Promise.reject(error)
    }
    return Promise.resolve()
  }

  createDevelopmentGoal = async (developmentGoal: INewDevelopmentGoal): Promise<void> => {
    try {
      await agent.Discussions.createDevelopmentGoal(developmentGoal)
      runInAction(async () => {
        if (developmentGoal.developmentDiscussionId !== undefined) {
          await this.getDiscussionGoals(developmentGoal.developmentDiscussionId)
        }
      })
    } catch (error) {
      return Promise.reject(error)
    }
    return Promise.resolve()
  }

  CreateFeedbackGoal = async (feedbackGoal: INewFeedbackGoal): Promise<void> => {
    try {
      await agent.Discussions.createFeedbackGoal(feedbackGoal)
      runInAction(async () => {
        if (feedbackGoal.developmentDiscussionId !== undefined) {
          await this.getDiscussionGoals(feedbackGoal.developmentDiscussionId)
        }
      })
    } catch (error) {
      return Promise.reject(error)
    }
    return Promise.resolve()
  }

  createColleagueFeedback = async (colleagueFeedback: INewColleagueFeedback): Promise<void> => {
    try {
      await agent.Discussions.createColleagueFeedback(colleagueFeedback)
    } catch (error) {
      return Promise.reject(error)
    }
    return Promise.resolve()
  }

  editDevelopmentGoal = async (goalId: number, developmentGoal: IEditDevelopmentGoal, discussionId?: number): Promise<void> => {
    try {
      await agent.Discussions.editDevelopmentGoal(goalId, developmentGoal)
      runInAction(async () => {
        if (discussionId) {
          await this.getDiscussionGoals(discussionId)
        }
      })
    } catch (error) {
      return Promise.reject(error)
    }
    return Promise.resolve()
  }

  editFeedbackGoal = async (goalId: number, feedbackGoal: IEditFeedbackGoal, discussionId?: number): Promise<void> => {
    try {
      await agent.Discussions.editFeedbackGoal(goalId, feedbackGoal)
      runInAction(async () => {
        if (discussionId) {
          await this.getDiscussionGoals(discussionId)
        }
      })
    } catch (error) {
      return Promise.reject(error)
    }
    return Promise.resolve()
  }

  editColleagueFeedback = async (body: IEditColleagueFeedback): Promise<void> => {
    try {
      await agent.Discussions.editColleagueFeedback(body)
    } catch (error) {
      return Promise.reject(error)
    }
    return Promise.resolve()
  }

  getDiscussionGoals = async (discussionId: number): Promise<void> => {
    this.loadingDiscussionGoals = true
    try {
      const goals: {
        developmentGoals: IDevelopmentGoal[]
        feedbackGoals: IFeedbackGoal[]
      } = await agent.Discussions.getDiscussionGoals(discussionId)
      runInAction(() => {
        this.discussionDevelopmentGoals = goals.developmentGoals
        this.discussionFeedbackGoals = goals.feedbackGoals
      })
    } catch (error) {
      return Promise.reject(error)
    } finally {
      runInAction(() => {
        this.loadingDiscussionGoals = false
      })
    }
    return Promise.resolve()
  }

  getDiscussion = async (discussionId: number, reload = false): Promise<void> => {
    if (!reload) {
      this.selectedDiscussionDetails = undefined
      this.selectedDiscussionVersion = undefined
    }
    this.loadingSelectedDiscussionDetails = true
    try {
      const discussion: IDiscussion = await agent.Discussions.getDiscussion(discussionId)
      runInAction(() => {
        this.selectedDiscussionDetails = discussion
        this.selectedDiscussionVersion = head(discussion.versions)
      })
    } catch (error) {
      history.replace('/discussions')
      return Promise.reject(error)
    } finally {
      runInAction(() => {
        this.loadingSelectedDiscussionDetails = false
      })
    }
    return Promise.resolve()
  }

  resetUser = () => {
    this.userDiscussion = undefined
  }

  approveDiscussionAsEmployee = async (employeeId: number, discussionId: number): Promise<void> => {
    try {
      await agent.Discussions.approveDiscussionAsEmployee(employeeId, discussionId)
      runInAction(() => {
        this.getDiscussion(discussionId, true).then(() => {
          runInAction(() => {
            const foundDiscussion = this.userDiscussion?.discussions.find(
              (d) => d.developmentDiscussionId === this.selectedDiscussionDetails?.developmentDiscussionId,
            )
            if (foundDiscussion) {
              foundDiscussion.state = this.selectedDiscussionDetails?.state
            }
          })
        })
      })
    } catch (error) {
      return Promise.reject(error)
    }
    return Promise.resolve()
  }

  approveDiscussionAsEmployer = async (employerId: number, discussionId: number): Promise<void> => {
    try {
      await agent.Discussions.approveDiscussionAsEmployer(employerId, discussionId)
      runInAction(() => {
        this.getDiscussion(discussionId, true).then(() => {
          runInAction(() => {
            const foundDiscussion = this.userDiscussion?.discussions.find(
              (d) => d.developmentDiscussionId === this.selectedDiscussionDetails?.developmentDiscussionId,
            )
            if (foundDiscussion) {
              foundDiscussion.state = this.selectedDiscussionDetails?.state
            }
          })
        })
      })
    } catch (error) {
      return Promise.reject(error)
    }
    return Promise.resolve()
  }

  getGoals = async (userId: number): Promise<void> => {
    this.loadingUserGoals = true
    this.developmentGoals = []
    try {
      const goals = await agent.Discussions.getEmployeeGoalsV2(userId)
      runInAction(() => {
        this.developmentGoals = goals.developmentGoals
        this.feedbackGoals = goals.feedbackGoals
        this.colleagueFeedbacks = goals.colleagueFeedbacks
      })
    } catch (error) {
      return Promise.reject(error)
    } finally {
      runInAction(() => {
        this.loadingUserGoals = false
      })
    }
    return Promise.resolve()
  }

  getEmployeeDiscussions = async (employeeId: number): Promise<void> => {
    this.loadingDiscussions = true
    this.userDiscussion = undefined
    try {
      const userDiscussion: IUserDiscussion = await agent.Discussions.getEmployeeDiscussions(employeeId)
      runInAction(() => {
        this.userDiscussion = userDiscussion
      })
    } catch (error) {
      console.log(error)
      history.replace('/')
    } finally {
      runInAction(() => {
        this.loadingDiscussions = false
      })
    }
    return Promise.resolve()
  }

  getFormTemplates = async (): Promise<void> => {
    this.loadingFormTemplates = true
    try {
      const formTemplates: Array<IFormTemplate> = await agent.Discussions.getFormTemplates()
      runInAction(() => {
        this.formTemplates = formTemplates
      })
    } catch (error) {
      return Promise.reject(error)
    } finally {
      runInAction(() => {
        this.loadingFormTemplates = false
      })
    }
    return Promise.resolve()
  }

  getFormQuestions = async (formId: number): Promise<void> => {
    this.selectedDiscussionCategories = []
    this.loadingDiscussionQuestions = true
    try {
      const selectedDiscussionCategories: Array<IFormQuestionCategory> = await agent.Discussions.getFormQuestions(formId)
      runInAction(() => {
        this.selectedDiscussionCategories = selectedDiscussionCategories
      })
    } catch (error) {
      Promise.reject(error)
    } finally {
      runInAction(() => {
        this.loadingDiscussionQuestions = false
      })
    }
    return Promise.resolve()
  }

  postDiscussionVersion = async (developmentdiscussionId: number, draft: boolean, newVersion: INewDiscussionVersion): Promise<void> => {
    try {
      await agent.Discussions.postDiscussionVersion(developmentdiscussionId, draft, newVersion)
      runInAction(() => {
        this.getDiscussion(developmentdiscussionId, true).then(() => {
          runInAction(() => {
            const foundDiscussion = this.userDiscussion?.discussions.find(
              (d) => d.developmentDiscussionId === this.selectedDiscussionDetails?.developmentDiscussionId,
            )
            if (foundDiscussion) {
              foundDiscussion.state = this.selectedDiscussionDetails?.state
            }
          })
        })
      })
    } catch (error) {
      return Promise.reject(error)
    }
    return Promise.resolve()
  }

  postGoalComment = async (goalId: number, message: INewComment): Promise<void> => {
    try {
      await agent.Discussions.postGoalComment(goalId, message)
      runInAction(() => {
        this.getDevelopmentGoal(goalId, true)
        const foundGoal = this.developmentGoals.find((g) => g.goalId === goalId)
        if (foundGoal) {
          foundGoal.commentCount += 1
        }
      })
    } catch (error) {
      return Promise.reject(error)
    }
    return Promise.resolve()
  }

  postFeedbackGoalComment = async (goalId: number, message: INewComment): Promise<void> => {
    try {
      await agent.Discussions.postFeedbackGoalComment(goalId, message)
      runInAction(() => {
        this.getFeedbackGoal(goalId, true)
        const foundGoal = this.feedbackGoals.find((g) => g.goalId === goalId)
        if (foundGoal) {
          foundGoal.commentCount += 1
        }
      })
    } catch (error) {
      return Promise.reject(error)
    }
    return Promise.resolve()
  }

  getDevelopmentGoal = async (goalId: number, reload = false): Promise<void> => {
    this.loadingGoal = true
    if (!reload) {
      this.selectedDevelopmentGoalDetailed = undefined
    }
    try {
      const goal: IDevelopmentGoalDetailed = await agent.Discussions.getDevelopmentGoal(goalId)
      runInAction(() => {
        this.selectedDevelopmentGoalDetailed = goal
      })
    } catch (error) {
      history.replace('/discussions')
      return Promise.reject(error)
    } finally {
      runInAction(() => {
        this.loadingGoal = false
      })
    }
    return Promise.resolve()
  }

  getFeedbackGoal = async (goalId: number, reload = false): Promise<void> => {
    this.loadingFeedbackGoal = true
    if (!reload) {
      this.selectedFeedbackGoalDetailed = undefined
    }
    try {
      const feedbackGoal: IFeedbackGoalDetailed = await agent.Discussions.getFeedbackGoal(goalId)
      runInAction(() => {
        this.selectedFeedbackGoalDetailed = feedbackGoal
      })
    } catch (error) {
      history.replace('/discussions')
      return Promise.reject(error)
    } finally {
      runInAction(() => {
        this.loadingFeedbackGoal = false
      })
    }
    return Promise.resolve()
  }

  getDiscussionComments = async (discussionId: number): Promise<void> => {
    this.loadingSelectedDiscussionDetails = true
    try {
      const selectedDiscussionComments: Array<IComment> = await agent.Discussions.getDiscussionComments(discussionId)
      runInAction(() => {
        if (this.selectedDiscussionDetails) {
          this.selectedDiscussionDetails.comments = selectedDiscussionComments
        }
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => {
        this.loadingSelectedDiscussionDetails = false
      })
    }
    return Promise.resolve()
  }

  postDiscussionComment = async (discussionId: number, message: INewComment): Promise<void> => {
    try {
      await agent.Discussions.postDiscussionComments(discussionId, message)
      runInAction(() => {
        this.getDiscussionComments(discussionId)
      })
    } catch (error) {
      return Promise.reject(error)
    }
    return Promise.resolve()
  }

  constructor() {
    makeObservable(this, {
      selectedDiscussionDetails: observable,
      loadingSelectedDiscussionDetails: observable,
      selectedMiniDiscussionId: observable,
      savingDiscussion: observable,
      setSelectedMiniDiscussionId: action,
      setIsConfirmSendDiscussionVisible: action,
      isNewDevDiscussionVisible: observable,
      isNewMiniDiscussionVisible: observable,
      isNewDiscussionDevGoalVisible: observable,
      isNewDiscussionFBGoalVisible: observable,
      isConfirmSendDiscussionVisible: observable,
      isNewColleagueFeedbackVisible: observable,
      isDeleteColleagueFeedbackVisible: observable,
      setIsNewDevDiscussionVisible: action,
      setIsNewMiniDiscussionVisible: action,
      setIsDeleteColleagueFeedbackVisible: action,
      setIsNewColleagueFeedbackVisible: action,
      setIsNewDiscussionDevGoalVisible: action,
      setIsNewDiscussionFBGoalVisible: action,
      setIsNewDevGoalVisible: action,
      isPristine: observable,
      developmentGoals: observable,
      feedbackGoals: observable,
      colleagueFeedbacks: observable,
      loadingUserGoals: observable,
      formTemplates: observable,
      loadingFormTemplates: observable,
      userDiscussion: observable,
      loadingDiscussions: observable,
      discussionDevelopmentGoals: observable,
      discussionFeedbackGoals: observable,
      loadingDiscussionGoals: observable,
      loadingGoal: observable,
      loadingFeedbackGoal: observable,
      showNewKekeModal: observable,
      showDevelopmentGoalModal: observable,
      showFeedbackGoalModal: observable,
      showColleagueFeedbackModal: observable,
      selectedDiscussionCategories: observable,
      loadingDiscussionQuestions: observable,
      selectedDiscussion: observable,
      selectedDiscussionVersion: observable,
      selectedTemplate: observable,
      selectedDevelopmentGoalDetailed: observable,
      selectedFeedbackGoalDetailed: observable,
      selectedDevelopmentGoal: observable,
      selectedFeedbackGoal: observable,
      selectedColleagueFeedback: observable,
      isEditDiscussionGoal: observable,
      isEditFeedbackGoal: observable,
      isEditColleagueFeedback: observable,
      isConfirmDeleteGoalVisible: observable,
      isConfirmDeleteDiscussionVisible: observable,
      isConfirmReadySectionVisible: observable,
      isAddCommentVisible: observable,
      isNewDevGoalVisible: observable,
      isNewFBGoalVisible: observable,
      setIsNewFBGoalVisible: action,
      setIsConfirmDeleteGoalVisible: action,
      setIsConfirmReadySectionVisible: action,
      setIsAddCommentVisible: action,
      setIsPristine: action,
      setIsEditDevelopmentGoal: action,
      setIsEditFeedbackGoal: action,
      setIsEditColleagueFeedback: action,
      setSavingDiscussion: action,
      selectDevelopmentGoal: action,
      selectFeedbackGoal: action,
      selectColleagueFeedback: action,
      selectDiscussionVersion: action,
      selectDiscussion: action,
      selectDevelopmentGoalDetailed: action,
      selectFeedbackGoalDetailed: action,
      selectDiscussionDetails: action,
      selectTemplate: action,
      setShowNewKekeModal: action,
      setShowDevelopmentGoalModal: action,
      setShowFeedbackGoalModal: action,
      setShowColleagueFeedbackModal: action,
      postEmployerDiscussion: action,
      postEmployeeDiscussion: action,
      deleteDevelopmentGoal: action,
      deleteFeedbackGoal: action,
      deleteColleagueFeedback: action,
      reopenDevelopmentGoal: action,
      reopenFeedbackGoal: action,
      completeDevelopmentGoal: action,
      completeFeedbackGoal: action,
      createDevelopmentGoal: action,
      CreateFeedbackGoal: action,
      createColleagueFeedback: action,
      editDevelopmentGoal: action,
      editFeedbackGoal: action,
      editColleagueFeedback: action,
      getDiscussionGoals: action,
      getDiscussion: action,
      approveDiscussionAsEmployee: action,
      approveDiscussionAsEmployer: action,
      getGoals: action,
      getEmployeeDiscussions: action,
      getFormTemplates: action,
      getFormQuestions: action,
      postDiscussionVersion: action,
      postGoalComment: action,
      postFeedbackGoalComment: action,
      getDevelopmentGoal: action,
      getFeedbackGoal: action,
      getDiscussionComments: action,
      postDiscussionComment: action,
      resetUser: action,
    })
  }
}
