import { Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IPersonEducation } from '../../../../interfaces/certAndEducation/certAndEducation'
import { HighestDegree } from '../../../styles/Icons'

const ProfileEdu = (props: { data: IPersonEducation }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const { data } = props

  return (
    <Grid container rowGap={4} className='gridcontainer--formcontainer__lastitempaddings'>
      <Grid xs={12} item container rowGap={2}>
        <Grid item xs={12}>
          <span className='myskill--font__titlefont sini'>{t('Persons.EduInfo')}</span>
        </Grid>
        <Grid xs={12} item container maxHeight={'2.25rem'}>
          <Grid item xs={6} container rowGap={0.5} maxHeight={'2.25rem'}>
            <Grid item xs={12}>
              <span className='myskill--font__multilinelabel'>{t('CertAndEducation.EducationName')}</span>
            </Grid>
            <Grid item xs={12}>
              <span className='myskill--font__multilinetext'>{data?.degree ? data?.degree : t('Persons.NotDefined')}</span>
            </Grid>
          </Grid>
          <Grid item xs={6} container rowGap={0.5}>
            <Grid item xs={12}>
              <span className='myskill--font__multilinelabel'>{t('CertAndEducation.FurtherInfo')}</span>
            </Grid>
            <Grid item xs={12}>
              <span className='myskill--font__multilinetext'>{data?.comment ? data?.comment : t('Persons.NotDefined')}</span>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12} item container rowGap={0.5}>
        <Grid item xs={12}>
          <span className='myskill--font__multilinelabel'>{t('CertAndEducation.EducationSchool')}</span>
        </Grid>
        <Grid item xs={12}>
          <span className='myskill--font__multilinetext'>{data?.school ? data?.school : t('Persons.NotDefined')}</span>
        </Grid>
      </Grid>
      <Grid xs={6} item container columnGap={4} wrap='nowrap'>
        <Grid item container rowGap={0.5} whiteSpace='nowrap' maxWidth={'4.375rem'}>
          <Grid item xs={12}>
            <span className='myskill--font__multilinelabel'>{t('CertAndEducation.EducationStartDate')}</span>
          </Grid>
          <Grid item xs={12}>
            <span className='myskill--font__multilinetext'>
              {data?.startDate ? moment(data?.startDate).locale(language).format('L').toString() : t('Persons.NotDefined')}
            </span>
          </Grid>
        </Grid>
        <Grid item container rowGap={0.5} whiteSpace='nowrap' maxWidth={'4.375rem'}>
          <Grid item xs={12}>
            <span className='myskill--font__multilinelabel'>{t('CertAndEducation.EducationEndDate')}</span>
          </Grid>
          <Grid item xs={12}>
            <span className='myskill--font__multilinetext'>
              {data?.endDate ? moment(data?.endDate).locale(language).format('L').toString() : t('Persons.NotDefined')}
            </span>
          </Grid>
        </Grid>
      </Grid>
      {data?.isHighestDegree && (
        <Grid item xs={6} columnGap={1} display='flex' alignItems='center' container>
          <Grid item>
            <HighestDegree />
          </Grid>
          <Grid item>
            <span className='myskill--font__multilinetext'>{t('Persons.HighestDegree')}</span>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default observer(ProfileEdu)
