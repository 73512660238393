import { Button, Drawer, Grid } from '@mui/material'
import React from 'react'
import history from '../../history'
import { useStore } from '../../Provider'
import { MiniSideBarLogo, SideBarExpand } from '../styles/Icons'
import { IconButton } from './IconButton'
import { sideBar } from './SideBar'
import { SideBarItem } from './SideBarItem'

export const MiniSideBar = (props: sideBar): JSX.Element => {
  const rootStore = useStore()

  const { data } = props
  const { setIsOpen } = rootStore.sidebarStore
  return (
    <Drawer PaperProps={{ className: 'drawer mini' }} className='sidebarresp mini' variant='permanent'>
      <Grid container className='gridjustify'>
        <Grid item xs={12} className='miniAvatarCenter'>
          <MiniSideBarLogo className='logolink' onClick={() => history.push('')} />
        </Grid>
        <Grid item xs={12}>
          {data.map((iconButton: SideBarItem) => (
            <IconButton
              styleName={'nestedButtonStyling'}
              key={iconButton.text}
              isExact={iconButton.isExact}
              link={iconButton.link}
              icon={iconButton.icon}
              text={iconButton.text}
              dataTestId={iconButton.dataTestId}
              ariaLabel={iconButton.ariaLabel}
              disabled={iconButton.disabled}
              isNested={iconButton.isNested}
              nestedItems={iconButton.nestedItems}
              menuID={iconButton.menuID}
            />
          ))}
        </Grid>
      </Grid>
      <Grid container className='restBox'>
        <Button className='sideBarCollapse miniResp' onClick={() => setIsOpen(true)}>
          <SideBarExpand />
        </Button>
      </Grid>
    </Drawer>
  )
}
