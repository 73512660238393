import { Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import history from '../../../history'
import { IWorkHistoryDetails } from '../../../interfaces/projects/IProjects'
import { useStore } from '../../../Provider'
import { OPLoadingCircle } from '../../OPcomponents/OPLoadingcircle'
import { Data, OPNewTable } from '../../OPcomponents/OPNewTable'

const WorkHistory = (): JSX.Element => {
  const { t } = useTranslation()
  const rootStore = useStore()

  const { validatedUser } = rootStore.loginStore
  const {
    getUserPrivateWorkHistory,
    getUserPublicWorkHistory,
    publicWorkHistoryLoading,
    privateWorkHistoryLoading,
    filteredPrivateWorkHistories,
    filteredPublicWorkHistories,
  } = rootStore.personStore

  useEffect(() => {
    if (filteredPrivateWorkHistories.length === 0) getUserPrivateWorkHistory(validatedUser)
    if (filteredPublicWorkHistories.length === 0) getUserPublicWorkHistory(validatedUser)
  }, [])

  const workRows: Data[] = []

  filteredPublicWorkHistories
    .concat(filteredPrivateWorkHistories)
    .slice(0, 5)
    .forEach((project: IWorkHistoryDetails) => {
      workRows.push({
        id: project.projectId,
        rows: {
          [t('Projects.ProjectName')]: { text: project.projectName, width: '50%' },
          [t('WorkHistory.Expirience')]: { text: `${project.daysWorked || 0} ${t('Date.ExperienceDays')}`, width: '50%' },
        },
      })
    })

  return (
    <Grid>
      {!publicWorkHistoryLoading && !privateWorkHistoryLoading ? (
        <Grid container rowGap={2.25}>
          <Grid item xs={12} onClick={() => history.push('/profile/workhistory')} className='profile--pointer'>
            <div className='profile--titlefont'>{t('Buttons.WorkHistory')}</div>
          </Grid>
          <Grid item xs={12}>
            {workRows.length ? (
              <OPNewTable onRowClick={(id) => history.push('/profile/workhistory', id)} rows={workRows} curLength={workRows.length} />
            ) : (
              <div data-testid='myskillsplaceholdertext' className='profile--cert__regtext'>
                {t('WorkHistory.WorkPlaceholder')}
              </div>
            )}
          </Grid>
        </Grid>
      ) : (
        <OPLoadingCircle />
      )}
    </Grid>
  )
}
export default observer(WorkHistory)
