import { observer } from 'mobx-react-lite'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IEducation } from '../../../interfaces/certAndEducation/certAndEducation'
import { useStore } from '../../../Provider'
import { OPAccordionItem } from '../../Accordion/OPAccordionItem'
import { OPLoadMore } from '../../OPcomponents/OPLoadMore'
import { Data, OPNewTable } from '../../OPcomponents/OPNewTable'
import CertEduLangBase from './CertEduLangBase'

interface EduSearchAccordionProps {
  search: string
}

const EduSearchAccordion = (props: EduSearchAccordionProps) => {
  const { search } = props
  const { t } = useTranslation()
  const rootStore = useStore()
  const { educations, getFilteredEducationsByText, loadingEducations } = rootStore.certAndEducationStore
  const { setCertEduLangBase } = rootStore.seachStore

  const [tableRows, setTableRows] = useState<Data[]>([])
  const [tableLength, setTableLength] = useState(10)
  const [curFilteredArray, setCurFilteredArray] = useState<IEducation[]>([])

  const filteredSearchEducations = useMemo(() => {
    if (loadingEducations) return []
    return educations ? getFilteredEducationsByText(search) : []
  }, [educations, getFilteredEducationsByText, loadingEducations, search])

  useEffect(() => {
    const newRows: Data[] = []
    filteredSearchEducations.forEach((education: IEducation) => {
      newRows.push({
        ['id']: education.key ?? -1,
        rows: {
          [t('CertAndEducation.EducationName')]: { text: education.text ?? '' },
        },
      })
      setTableRows(newRows)
    })
  }, [t, filteredSearchEducations])

  const onRowClick = (id: number) => {
    setCertEduLangBase({
      id,
      type: 'education',
    })
  }

  return (
    <OPAccordionItem
      title={`${t('CertAndEducation.EducationHeader')} (${loadingEducations ? '\u2026' : filteredSearchEducations.length})`}
      content={
        <>
          {filteredSearchEducations.length > 0 ? (
            <>
              <OPNewTable
                rows={tableRows}
                curLength={tableLength}
                onRowClick={(id) => onRowClick(Number(id))}
                FormBase={CertEduLangBase}
                isCloseable
              />
              <OPLoadMore
                stepper={10}
                curLength={tableLength}
                maxLength={filteredSearchEducations.length}
                filteredArray={filteredSearchEducations}
                onLoadMore={(data) => setTableLength(data)}
              />
            </>
          ) : (
            <span className='profile--notfound'>{t('NotFound.NoResults')}</span>
          )}
        </>
      }
    />
  )
}

export default observer(EduSearchAccordion)
