import { Grid } from '@mui/material'
import { Formik } from 'formik'
import { head } from 'lodash'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { localizedLanguageSkills } from '../../../common/languageHelper'
import { getErrMsg, toastNeg, toastPos } from '../../../common/toastHelper'
import { IUserLanguage } from '../../../interfaces/language/ILanguage'
import { useStore } from '../../../Provider'
import DeleteConfirmation from '../../OPcomponents/DeleteConfirmation'
import { ExitConfirmation } from '../../OPcomponents/ExitConfirmation'
import AutoSave from '../../OPcomponents/FormikAutosave'
import { OPMultiline } from '../../OPcomponents/OPMultiline'
import { OPSkillsCombobox } from '../../OPcomponents/OPSkillsCombobox'
import { langSchema } from '../../OPcomponents/ValidationSchemas'
import '../../styles/grid.scss'
import { OPSkillsButtons } from '../OPSkillsButtons'

interface EditLanguage {
  collapseHandler?: () => void
  data: string
}

const EditLanguage = (props: EditLanguage): JSX.Element => {
  const { t } = useTranslation()
  const { collapseHandler, data } = props

  const rootStore = useStore()

  const [deleteLangConfirmation, setDeleteLangConfirmation] = useState(false)

  const { validatedUser } = rootStore.loginStore

  const {
    setOpenLanguageEditModal,
    getCurrentLanguageToEdit,
    openLanguageEditModal,
    getUserOverview,
    putEditedPersonLanguage,
    deletePersonLanguage,
  } = rootStore.personStore

  const curLang = getCurrentLanguageToEdit(data)

  const lang = head(curLang)
  const languageSkillOptions = localizedLanguageSkills(t)

  const initialValues: IUserLanguage = {
    personId: validatedUser,
    languageId: lang?.languageId ?? -1,
    languageLevel: lang?.languageLevel ?? '',
    description: lang?.description ?? '',
    languageName: lang?.languageName ?? '',
  }

  const formSubmit = async (values: IUserLanguage) => {
    try {
      await putEditedPersonLanguage(values)
    } catch (error) {
      toastNeg(getErrMsg(error, t) || t('Toasts.EditError'))
    }
  }

  const deleteLanguage = async () => {
    try {
      if (lang?.languageId) {
        await deletePersonLanguage(validatedUser, lang.languageId)
        toastPos(t('Toasts.DeleteSuccess'))
      }
    } catch (error) {
      toastNeg(getErrMsg(error, t) || t('Toasts.DeleteError'))
    } finally {
      handleClose()
    }
  }

  const handleClose = () => {
    if (openLanguageEditModal) setOpenLanguageEditModal(false)
    if (collapseHandler) collapseHandler()
  }

  return (
    <Formik initialValues={initialValues} onSubmit={formSubmit} validationSchema={langSchema}>
      {({ values, setFieldValue, handleChange, submitForm, isSubmitting, dirty, isValid }) => (
        <Grid container columnSpacing={2} rowSpacing={2} className='gridcontainer--formcontainer__itempaddings'>
          <ExitConfirmation when={dirty} />
          <Grid item lg={6} xs={12}>
            <Grid container rowSpacing={2}>
              <Grid item xs={12}>
                <Grid container rowSpacing={1}>
                  <Grid item xs={12}>
                    <span className='profile--titlecaption nopadding'>{`${t('Language.Language')}`}</span>
                  </Grid>
                  <Grid item xs={12}>
                    <span className='profile--deptext nopadding'>{lang?.languageName} </span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <OPSkillsCombobox
                  placeholder={t('Placeholders.Choose')}
                  multiple={false}
                  curState={languageSkillOptions.find((lang) => lang.key === values.languageLevel)?.text}
                  onChange={(_e, val) => {
                    if (val) setFieldValue('languageLevel', val.key)
                  }}
                  label={t('Language.SkillLevel')}
                  optionList={languageSkillOptions}
                  disabled={deleteLangConfirmation}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={6} xs={12}>
            <OPMultiline
              name='description'
              placeholder={t('Placeholders.FurtherInfo')}
              label={t('Language.Description')}
              value={values.description}
              onChange={handleChange}
              hasCounter
              maxChars={200}
              minRows={5}
              disabled={deleteLangConfirmation}
            />
          </Grid>
          <Grid item xs={12}>
            <OPSkillsButtons
              isDelete
              onDelete={() => setDeleteLangConfirmation(true)}
              noPadding
              disabled={!(isValid && dirty) || isSubmitting}
              bothDisabled={isSubmitting || deleteLangConfirmation}
              onClose={handleClose}
              onSubmit={submitForm}
              onAutosave
            />
          </Grid>
          <DeleteConfirmation
            whatToDelete='Language'
            open={deleteLangConfirmation}
            deleteHandler={deleteLanguage}
            handleClose={() => setDeleteLangConfirmation(false)}
          />
          <AutoSave fetchFunction={getUserOverview} />
        </Grid>
      )}
    </Formik>
  )
}

export default observer(EditLanguage)
