import { Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { OPSkillbar } from '../OPcomponents/OPSkillbar'

const SkillsHelp = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <p className='help--titlefont'>{t('Help.SkillLevelTitle')}</p>
      </Grid>
      <Grid item xs={12}>
        <Grid container wrap='nowrap' columnSpacing={2} alignItems='center'>
          <Grid item>
            <OPSkillbar value={1} readonly name={'help-1'} />
          </Grid>
          <Grid item>
            <p className='help--metafont'>{t('Help.SkillLevel1') + t('Help.SkillLevel1Text')}</p>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container wrap='nowrap' columnSpacing={2} alignItems='center'>
          <Grid item>
            <OPSkillbar value={2} readonly name={'help-2'} />
          </Grid>
          <Grid item>
            <p className='help--metafont'>{t('Help.SkillLevel2') + t('Help.SkillLevel2Text')}</p>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container wrap='nowrap' columnSpacing={2} alignItems='center'>
          <Grid item>
            <OPSkillbar value={3} readonly name={'help-3'} />
          </Grid>
          <Grid item>
            <p className='help--metafont'>{t('Help.SkillLevel3') + t('Help.SkillLevel3Text')}</p>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container wrap='nowrap' columnSpacing={2} alignItems='center'>
          <Grid item>
            <OPSkillbar value={4} readonly name={'help-4'} />
          </Grid>
          <Grid item>
            <p className='help--metafont'>{t('Help.SkillLevel4') + t('Help.SkillLevel4Text')}</p>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container wrap='nowrap' columnSpacing={2} alignItems='center'>
          <Grid item>
            <OPSkillbar value={5} readonly name={'help-5'} />
          </Grid>
          <Grid item>
            <p className='help--metafont'>{t('Help.SkillLevel5') + t('Help.SkillLevel5Text')}</p>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
export default observer(SkillsHelp)
