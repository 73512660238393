import { CircularProgress } from '@mui/material'
import DOMPurify from 'dompurify'
import { Form, Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { getErrMsg, toastNeg, toastPos } from '../../common/toastHelper'
import Editor from '../MySkills/PersonalCertificates/Editor'
import { IEditColleagueFeedback, IListColleagueFeedback, INewColleagueFeedback } from '../../interfaces/discussions/IDiscussion'
import { IDropdownSkill } from '../../interfaces/skills/ISkills'
import { useStore } from '../../Provider'
import '../styles/developmentPage.scss'
import { DeleteIcon } from '../styles/Icons'
import { ExitConfirmation } from '../OPcomponents/ExitConfirmation'
import { MuiButtonVariants, OPButton } from '../OPcomponents/OPButton'
import { OPDateField } from '../OPcomponents/OPDateField'
import { OPDivider } from '../OPcomponents/OPDivider'
import { OPSkillsCombobox } from '../OPcomponents/OPSkillsCombobox'

interface ColleagueFeedbackFormProps {
  colleagueFeedback?: IListColleagueFeedback
  onSave?: (value: string) => void
}

export const ColleagueFeedbackForm = observer((props: ColleagueFeedbackFormProps): JSX.Element => {
  const { colleagueFeedback, onSave } = props
  const { t } = useTranslation()
  const rootStore = useStore()
  const { validatedUser } = rootStore.loginStore
  const {
    setIsNewColleagueFeedbackVisible,
    isEditColleagueFeedback,
    setIsEditColleagueFeedback,
    createColleagueFeedback,
    editColleagueFeedback,
    getGoals,
    selectColleagueFeedback,
    setIsDeleteColleagueFeedbackVisible,
    isDeleteColleagueFeedbackVisible,
    isNewColleagueFeedbackVisible,
  } = rootStore.kekeStore
  const { getAllPersons, persons, loadingInitial } = rootStore.personStore
  const { userId } = useParams() as {
    userId?: string
  }
  const selectedUser = userId ? Number(userId) : validatedUser

  const divRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!loadingInitial) {
      setTimeout(() => divRef.current?.scrollIntoView({ behavior: 'smooth' }), 100)
    }
  }, [loadingInitial])

  const initialValues = {
    creatorId: selectedUser,
    colleagueId: colleagueFeedback?.colleagueId ?? -1,
    colleagueName: colleagueFeedback?.colleagueName ?? '',
    schedule: colleagueFeedback?.schedule ?? new Date(),
    topic: colleagueFeedback?.topic ?? '',
  }

  const disabledButtons = isDeleteColleagueFeedbackVisible || isNewColleagueFeedbackVisible

  const [comboPersons, setComboPersons] = useState<IDropdownSkill[]>([])

  useEffect(() => {
    if (!isEditColleagueFeedback) getAllPersons()
  }, [getAllPersons, isEditColleagueFeedback])

  useEffect(() => {
    if (!isEditColleagueFeedback) {
      if (persons.length > 0) {
        const dropdownPersons = persons.map((person) => {
          return { key: person.personId, value: person.name, text: person.name }
        })
        dropdownPersons.sort((a, b) => {
          if (a.value && b.value) return a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1
          return 1
        })
        setComboPersons(dropdownPersons)
      }
    }
  }, [isEditColleagueFeedback, persons])

  const handleSubmit = async (values: INewColleagueFeedback) => {
    const fetchedId = persons.find((p) => p.name === values.colleagueName)?.personId
    if (fetchedId) values.colleagueId = fetchedId
    if (!isEditColleagueFeedback && values.colleagueName !== '') {
      const newColleagueFeedback: INewColleagueFeedback = {
        creatorId: values.creatorId,
        colleagueId: values.colleagueId,
        colleagueName: values.colleagueName,
        schedule: values.schedule,
        topic: values.topic,
      }
      try {
        await createColleagueFeedback(newColleagueFeedback)
        toastPos(t('Toasts.ColleagueFeedbackSendSuccess'))
        setIsNewColleagueFeedbackVisible(false)
        getGoals(selectedUser)
      } catch (error) {
        toastNeg(getErrMsg(error, t) || t('Toasts.ColleagueFeedbackSendError'))
      }
    } else {
      if (colleagueFeedback) {
        const editedColleagueFeedback: IEditColleagueFeedback = {
          id: colleagueFeedback.id,
          creatorId: colleagueFeedback.creatorId,
          topic: values.topic,
        }
        try {
          await editColleagueFeedback(editedColleagueFeedback)
          if (onSave) onSave(editedColleagueFeedback.topic)
          toastPos(t('Toasts.ColleagueFeedbackEditSuccess'))
          setIsEditColleagueFeedback(false)
          selectColleagueFeedback(undefined)
        } catch (error) {
          toastNeg(getErrMsg(error, t) || t('Toasts.ColleagueFeedbackEditError'))
        }
      }
    }
  }

  return loadingInitial ? (
    <div data-testid='feedback.form.loader' className='loaderDiv'>
      <CircularProgress />
    </div>
  ) : (
    <Formik initialValues={initialValues} onSubmit={async (values) => handleSubmit(values)}>
      {({ setFieldValue, values, dirty }) => (
        <Form>
          <ExitConfirmation when={dirty} />
          {!isEditColleagueFeedback && (
            <div data-testid='feedback.form' ref={divRef}>
              <OPDivider />
              <div className='newDiscTitle'>{t('Discussions.NewColleagueFeedback')}</div>
              <div className='fieldPadding'>
                <OPSkillsCombobox
                  optionList={comboPersons}
                  label={t('Discussions.ColleagueFeedbackGiver')}
                  onChange={(e, v) => setFieldValue('colleagueName', v.value)}
                  placeholder={t('Discussions.ColleagueFeedbackGiverPlaceholder')}
                />
              </div>
              <div className='horizontalPadding'>
                <OPDateField
                  label={t('Discussions.ColleagueSchedule')}
                  value={values.schedule}
                  onChange={(newDate) => {
                    if (newDate !== null) setFieldValue('schedule', newDate)
                  }}
                />
              </div>
            </div>
          )}
          <div className='fieldPadding'>
            <Editor
              setValue={(value) =>
                setFieldValue(
                  'topic',
                  DOMPurify.sanitize(value, {
                    ALLOWED_TAGS: ['b', 'i', 'u', 'ul', 'li', 'p', 'strong', 'br', 'em', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
                  }),
                )
              }
              value={DOMPurify.sanitize(colleagueFeedback?.topic ?? '')}
              placeholderString={!isEditColleagueFeedback ? t('Discussions.ColleagueTopicPlaceholder') : ''}
              charLimit={2000}
            />
          </div>
          <div className='modifyButtonDiv'>
            {colleagueFeedback && (
              <div className='deleteButton'>
                <OPButton
                  disabled={disabledButtons}
                  label={t('Buttons.Delete')}
                  endIcon={<DeleteIcon fill={disabledButtons ? '#757575' : 'white'} />}
                  buttonVariant={MuiButtonVariants.error}
                  onClick={() => {
                    setIsDeleteColleagueFeedbackVisible(true)
                    selectColleagueFeedback(colleagueFeedback)
                  }}
                />
              </div>
            )}
            <div className='modifyButton'>
              <OPButton
                disabled={isEditColleagueFeedback && disabledButtons}
                label={t('Buttons.Cancel')}
                buttonVariant={MuiButtonVariants.error_outlined}
                onClick={() => {
                  setIsNewColleagueFeedbackVisible(false)
                  selectColleagueFeedback(undefined)
                  setIsEditColleagueFeedback(false)
                }}
              />
            </div>
            <div>
              <OPButton
                disabled={isEditColleagueFeedback && disabledButtons}
                label={t('Buttons.Save')}
                buttonVariant={MuiButtonVariants.primary}
                type='submit'
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
})
