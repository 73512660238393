import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { toastPos } from '../../common/toastHelper'
import { IEditFeedbackGoal, IListFeedbackGoal, INewFeedbackGoal } from '../../interfaces/discussions/IDiscussion'
import { useStore } from '../../Provider'
import { DeleteIcon } from '../styles/Icons'
import '../styles/newgoalform.scss'
import { MuiButtonVariants, OPButton } from '../OPcomponents/OPButton'
import { OPDivider } from '../OPcomponents/OPDivider'
import { OPMultiline } from '../OPcomponents/OPMultiline'

interface FeedbackGoalFormProps {
  onCancel: () => void
  onSave: (values?: IEditFeedbackGoal) => void
  goal?: IListFeedbackGoal
  developmentDiscussionId?: number
}

interface FeedbackGoalValues {
  teamFeedback: string
  employerFeedback: string
  customerFeedback: string
}

export const FeedbackGoalForm = observer((props: FeedbackGoalFormProps): JSX.Element => {
  const { onCancel, onSave, goal, developmentDiscussionId } = props
  const { t } = useTranslation()
  const rootStore = useStore()
  const {
    isConfirmDeleteGoalVisible,
    setIsConfirmDeleteGoalVisible,
    CreateFeedbackGoal,
    getGoals,
    isEditFeedbackGoal,
    editFeedbackGoal,
    selectedFeedbackGoal,
  } = rootStore.kekeStore
  const { validatedUser } = rootStore.loginStore

  const { userId } = useParams() as {
    userId?: string
  }

  const selectedUser = userId !== undefined ? Number(userId) : validatedUser

  const divRef = useRef<HTMLDivElement>(null)

  const initialValues = {
    teamFeedback: goal?.teamFeedback ?? '',
    employerFeedback: goal?.employerFeedback ?? '',
    customerFeedback: goal?.customerFeedback ?? '',
  }
  const [values, setValues] = useState<FeedbackGoalValues>(initialValues)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!isEditFeedbackGoal) {
      const newFeedbackGoal: INewFeedbackGoal = {
        creatorId: validatedUser,
        employeeId: selectedUser,
        teamFeedback: values.teamFeedback,
        employerFeedback: values.employerFeedback,
        customerFeedback: values.customerFeedback,
        developmentDiscussionId: developmentDiscussionId,
      }
      await CreateFeedbackGoal(newFeedbackGoal)
      toastPos(t('Toasts.FeedbackGoalCreate'))
      onSave()
    } else {
      const editFeedbackGoalValues: IEditFeedbackGoal = {
        teamFeedback: values.teamFeedback,
        employerFeedback: values.employerFeedback,
        customerFeedback: values.customerFeedback,
      }
      if (selectedFeedbackGoal) {
        await editFeedbackGoal(selectedFeedbackGoal.goalId, editFeedbackGoalValues, developmentDiscussionId)
        toastPos(t('Toasts.DiscussionGoalEdit'))
      }
      onSave(editFeedbackGoalValues)
    }
    await getGoals(selectedUser)
  }

  const validValues = values.teamFeedback.length > 1 || values.employerFeedback.length > 1 || values.customerFeedback.length > 1
  const valuesChanged =
    values.teamFeedback !== goal?.teamFeedback ||
    values.employerFeedback !== goal?.employerFeedback ||
    values.customerFeedback !== goal?.customerFeedback

  useEffect(() => {
    setTimeout(() => {
      if (divRef.current) {
        divRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    }, 500)
  }, [])

  return (
    <form data-testid='feedbackgoal.form' className='newGoalForm' onSubmit={handleSubmit}>
      <div className='newGoalHeader'>
        <p>{isEditFeedbackGoal ? t('Discussions.EditFeedbackGoal') : t('Discussions.NewFeedbackGoal')}</p>
      </div>
      <div ref={divRef} className='formFields'>
        <div className='bottomPadding'>
          <OPMultiline
            resizeable
            label={t('Discussions.FeedbackGoalTeam')}
            placeholder={t('Discussions.TeamPlaceholder')}
            name='teamFeedback'
            rows={4}
            value={values.teamFeedback}
            onChange={handleChange}
            maxChars={4000}
            hasCounter
          />
        </div>
        <div className='bottomPadding'>
          <OPMultiline
            resizeable
            label={t('Discussions.FeedbackGoalEmployer')}
            placeholder={t('Discussions.EmployerPlaceholder')}
            name='employerFeedback'
            rows={4}
            value={values.employerFeedback}
            onChange={handleChange}
            maxChars={4000}
            hasCounter
          />
        </div>
        <div className='bottomPadding'>
          <OPMultiline
            resizeable
            label={t('Discussions.FeedbackGoalCustomer')}
            placeholder={t('Discussions.CustomerPlaceholder')}
            name='customerFeedback'
            rows={4}
            value={values.customerFeedback}
            onChange={handleChange}
            maxChars={4000}
            hasCounter
          />
        </div>
      </div>
      {isEditFeedbackGoal ? (
        <div>
          <div className='buttonsDiv'>
            <div className='deleteButton'>
              <OPButton
                label={t('Buttons.Delete')}
                disabled={isConfirmDeleteGoalVisible}
                buttonVariant={MuiButtonVariants.error}
                onClick={() => setIsConfirmDeleteGoalVisible(true)}
                endIcon={<DeleteIcon fill={isConfirmDeleteGoalVisible ? '#757575' : 'white'} />}
              />
            </div>
            <div className='cancelButton'>
              <OPButton
                label={t('Buttons.Cancel')}
                disabled={isConfirmDeleteGoalVisible}
                buttonVariant={MuiButtonVariants.error_outlined}
                onClick={() => onCancel()}
              />
            </div>
            <div>
              <OPButton
                label={t('Buttons.Save')}
                disabled={!valuesChanged || !validValues || isConfirmDeleteGoalVisible}
                type='submit'
                buttonVariant={MuiButtonVariants.primary}
              />
            </div>
          </div>
          <OPDivider />
        </div>
      ) : (
        <div className='buttonsDiv'>
          <div className='cancelButton'>
            <OPButton label={t('Buttons.Cancel')} buttonVariant={MuiButtonVariants.error_outlined} onClick={() => onCancel()} />
          </div>
          <div>
            <OPButton label={t('Buttons.Save')} disabled={!validValues} type='submit' buttonVariant={MuiButtonVariants.primary} />
          </div>
        </div>
      )}
    </form>
  )
})
