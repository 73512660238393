import { BrowserUtils } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import { AppBar, Box, Breadcrumbs, Link, Toolbar } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../Provider'
import { CheckForm, CheckMark } from '../styles/Icons'
import '../styles/navbar.scss'

import OPBreadcrumbs from './OPBreadcrumbs'
import OPNavbarSearch from './OPNavbarSearch'

const OPNavbar = (): JSX.Element => {
  const { t, i18n } = useTranslation()
  const { instance } = useMsal()
  const rootStore = useStore()

  const { savingText, isError } = rootStore.sidebarStore

  const handleClick = (lang: string) => {
    i18n.changeLanguage(lang)
  }

  const langs = [
    {
      key: 'fi-FI',
      text: 'FI',
    },
    {
      key: 'en-US',
      text: 'EN',
    },
  ]

  return (
    <AppBar position='sticky' className='zindex'>
      <Toolbar className='base' disableGutters>
        <Box className='crumbpadding'>
          <OPBreadcrumbs />
        </Box>
        {savingText && (
          <Box className={'saving-spinner-container' + (isError ? ' error' : '')}>
            <Box display='flex'>
              {isError ? (
                <CheckForm />
              ) : (
                <>{savingText === t('Buttons.ChangesSaved') ? <CheckMark /> : <CircularProgress className='saving-spinner' />}</>
              )}
            </Box>
            <Box>
              <p>{savingText}</p>
            </Box>
          </Box>
        )}
        <Box sx={{ flexGrow: 5 }} />
        <Box className='boxpadding'>
          <OPNavbarSearch />
        </Box>
        <Box className='boxpadding'>
          <Breadcrumbs separator='|'>
            {langs.map((lang) => (
              <Link
                key={lang.key}
                component='button'
                disabled={i18n.language === lang.key}
                onClick={() => handleClick(lang.key)}
                underline='none'
                className={'breadFonts' + (i18n.language === lang.key ? ' active' : '')}
              >
                {lang.text}
              </Link>
            ))}
          </Breadcrumbs>
        </Box>
        <Box className='boxpadding logbox'>
          <Link
            component='button'
            underline='none'
            className='breadFonts'
            onClick={() =>
              instance.logoutRedirect({
                account: instance.getActiveAccount(),
                onRedirectNavigate: () => !BrowserUtils.isInIframe(),
              })
            }
          >
            {t('Navbar.Logout')}
          </Link>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default observer(OPNavbar)
