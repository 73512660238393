import { Checkbox, FormControlLabel } from '@mui/material'
import React from 'react'
import '../styles/checkbox.scss'
import { CheckedCheckBox, DisabledCheckBox, UnCheckedCheckBox } from '../styles/Icons'

interface CheckBox {
  textStyle?: string
  label: string
  leftPadding?: boolean
  disabled?: boolean
  value: boolean
  ariaLabel?: string
  name?: string
  dataTestId?: string
  onChangeState: (e: React.ChangeEvent<HTMLInputElement>, c: boolean) => void
}

export const OPCheckBox = (props: CheckBox): JSX.Element => {
  const { label, disabled, value, ariaLabel, dataTestId, onChangeState, name, textStyle, leftPadding } = props

  const onChange = (e: React.ChangeEvent<HTMLInputElement>, c: boolean) => {
    onChangeState(e, c)
  }

  const getCssClass = () => {
    if (disabled) {
      return 'label Disabled'
    } else if (leftPadding) {
      return 'label leftPadding'
    } else if (textStyle) {
      return 'label ' + { textStyle }
    } else {
      return 'label'
    }
  }

  return (
    <FormControlLabel
      control={
        <Checkbox
          id={label}
          className={'checkboxStyling'}
          checkedIcon={<CheckedCheckBox />}
          icon={disabled ? <DisabledCheckBox /> : <UnCheckedCheckBox />}
          checked={value}
          onChange={onChange}
        />
      }
      name={name}
      id={label}
      aria-label={ariaLabel}
      label={<p className={getCssClass()}>{label} </p>}
      disabled={disabled}
      data-testid={dataTestId}
    />
  )
}
