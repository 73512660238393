import { Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import history from '../../history'
import { useStore } from '../../Provider'
import { scrollHelper, scrollSaver } from '../common/scrollHelper'
import { Toast } from '../OPcomponents/Toast'
import { OPLoadingCircle } from './OPLoadingcircle'
import { OPLoadMore } from './OPLoadMore'
import { Data, OPNewTable } from './OPNewTable'
import OPSearch from './OPSearch'

export const ManagerPage = observer((): JSX.Element => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const rootStore = useStore()
  const [tableLength, setTableLength] = useState(10)

  const { isManager } = rootStore.loginStore
  const { scrollPosition, stepperAmount } = rootStore.seachStore
  const {
    getManagerDiscussions,
    setEmployeeSortColumn,
    filteredManagerEmployees,
    managerSearchValue,
    setManagerSearchValue,
    loadingInitial,
  } = rootStore.managerStore

  const [tableRows, setTableRows] = useState<Data[]>([])

  const onRowClick = (id: number | string) => {
    scrollSaver(tableLength, rootStore)
    history.push(`/manager/development/${id}`, 'managerPage')
  }

  useEffect(() => {
    const rows: Data[] = []
    filteredManagerEmployees.forEach((employee) => {
      rows.push({
        ['id']: employee.personId.toString(),
        rows: {
          [t('Discussions.DiscussionEmployee')]: { text: employee.personName },
          [t('Discussions.DiscussionEmployer')]: { text: employee.managerName },
          [t('Discussions.LatestKeke')]: {
            text: employee.latestCompleteDiscussionTime ? moment(employee.latestCompleteDiscussionTime).locale(language).format('L') : '',
          },
          [t('Discussions.LatestMiniKeke')]: {
            text: employee.latestMiniDiscussionTime ? moment(employee.latestMiniDiscussionTime).locale(language).format('L') : '',
          },
          // [t('Discussions.LatestGoal')]:
          //   employee.goalsToComplete !== null && employee.goalsToComplete.length > 0
          //     ? moment(employee.goalsToComplete[0].created).locale(language).format('L')
          //     : '',
          [t('Discussions.NewestKekeDate')]: {
            text: employee.latestOpenDiscussionTime ? moment(employee.latestOpenDiscussionTime).locale(language).format('L') : '',
          },
          [t('Discussions.NewestKekeState')]: {
            text:
              employee.latestOpenDiscussionState !== undefined
                ? t(`DiscussionsStates.${employee.latestOpenDiscussionState}`)
                : t('Discussions.'),
          },
        },
      })
    })
    setTableRows(rows)
  }, [filteredManagerEmployees, language, t])

  useEffect(() => {
    return () => {
      scrollHelper('/manager/development/:userId', setManagerSearchValue, false, rootStore)
    }
  }, [])

  useEffect(() => {
    if (scrollPosition) {
      setTimeout(() => window.scrollTo(0, scrollPosition), 100)
    }
  }, [])

  useEffect(() => {
    if (isManager && filteredManagerEmployees.length === 0) getManagerDiscussions()
  }, [filteredManagerEmployees.length, getManagerDiscussions, isManager, setEmployeeSortColumn, setManagerSearchValue])

  return (
    <Grid container spacing={4}>
      <Toast />
      <Grid item xs={12} marginBottom={'-1rem'}>
        <p className='profile--titlebigfont'>{t('Discussions.DiscussionEmployer')}</p>
      </Grid>
      <Grid item xs={12}>
        <Grid container direction='row' justifyContent='space-between' alignItems='center'>
          <OPSearch
            placeholder={t('Discussions.FindPerson')}
            searchValue={managerSearchValue}
            onSearchChange={(value) => setManagerSearchValue(value)}
            resetValue={() => setManagerSearchValue('')}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {loadingInitial ? (
          <OPLoadingCircle />
        ) : filteredManagerEmployees.length ? (
          <>
            <OPNewTable rows={tableRows} curLength={tableLength} onRowClick={onRowClick} />
            <OPLoadMore
              startingStepper={stepperAmount}
              stepper={10}
              filteredArray={filteredManagerEmployees}
              curLength={tableLength}
              maxLength={filteredManagerEmployees.length}
              onLoadMore={(data) => setTableLength(data)}
            />
          </>
        ) : (
          <span className='profile--notfound'>{t('NotFound.NoResults')}</span>
        )}
      </Grid>
    </Grid>
  )
})
