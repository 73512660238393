import { Tooltip, Zoom } from '@mui/material'
import React, { ReactElement, useEffect, useLayoutEffect, useRef, useState } from 'react'

import '../styles/tooltip.scss'

export interface OPTooltipProps {
  title: string | number | ReactElement<any, any>
  placement?: 'top' | 'right' | 'bottom' | 'left'
  children: ReactElement<any, any>
  style?: 'error' | 'alternate' | 'default'
  alwaysVisible?: boolean
}

const OPTooltip = (props: OPTooltipProps) => {
  const { children, title, placement, alwaysVisible, style = 'default', ...rest } = props

  const overflowRef = useRef<HTMLDivElement>(null)
  const [tooltipVisible, setTooltipVisible] = useState(style === 'error' || alwaysVisible ? true : false)

  const isOverflown = () => {
    if (overflowRef.current) {
      setTooltipVisible(overflowRef.current.scrollWidth > overflowRef.current.offsetWidth)
    }
  }

  const getTooltipStyle = () => {
    switch (style) {
      case 'error':
        return 'tooltipstyle error'
      case 'alternate':
        return 'tooltipstyle alternate'
      default:
        return 'tooltipstyle'
    }
  }

  useEffect(() => {
    if (style === 'error' || alwaysVisible) return
    isOverflown()
  }, [])

  useLayoutEffect(() => {
    if (style === 'error' || alwaysVisible) return
    window.addEventListener('resize', () => {
      isOverflown()
    })

    return () => {
      window.removeEventListener('resize', () => {
        isOverflown()
      })
    }
  }, [])

  if (!tooltipVisible) return <children.type {...children.props} ref={overflowRef} />

  return (
    <Tooltip
      PopperProps={{
        className: getTooltipStyle(),
      }}
      followCursor={true}
      TransitionComponent={Zoom}
      title={title}
      placement={placement}
      {...rest}
    >
      <children.type {...children.props} ref={overflowRef} />
    </Tooltip>
  )
}

export default OPTooltip
