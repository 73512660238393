import React from 'react'
import { ToastContainer } from 'react-toastify'
import '../../styles/modal.scss'

export const Toast = (): JSX.Element => {
  return (
    <ToastContainer
      position='bottom-right'
      // TODO: proper position for narrow screens?
      className='modal-toast--container'
    />
  )
}
