import { TFunction } from 'i18next'
import { languageSkillOptions } from '../components/MySkills/PersonLanguages/languageSkillOptions'

export function localizedLanguageSkills(t: TFunction) {
  return languageSkillOptions.map((lang) => {
    return {
      key: lang.key,
      text: t(`LanguageOptions.${lang.key.replace(':', '')}`) || lang.text,
      value: lang.value,
    }
  })
}

export function localizedLanguageNames(
  languages: Array<{
    key: string
    text: string
    value: string
  }>,
  t: TFunction,
) {
  return languages.map((lang) => {
    return {
      key: lang.key,
      text: localizedLanguageName(lang.text, t),
      value: lang.value,
    }
  })
}

export function localizedLanguageName(name: string, t: TFunction) {
  return (
    t(`LanguageNames.${name?.trim()}`, {
      defaultValue: name,
    }) || name
  )
}
