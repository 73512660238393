import { AppBar, Box, Grid, Link, Stack } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../Provider'
import '../styles/footer.scss'
import { NetumFooter, SlackIcon } from '../styles/Icons'

const OPFooter = (): JSX.Element => {
  const rootStore = useStore()
  const { isOpen } = rootStore.sidebarStore
  const { t } = useTranslation()
  return (
    <AppBar position='fixed' className='footer'>
      <Stack className={'centerLinks' + (!isOpen ? ' collapsed' : '')}>
        <Grid item xs={12} className='displayflex'>
          <Grid item className='slackicon'>
            <SlackIcon />
          </Grid>
          <Link
            className='opFont'
            href='https://netum-dev.slack.com/archives/CMHPSNRDX'
            target='_blank'
            rel='noopener noreferrer'
            underline='none'
          >
            #osaamispankki
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Link className='tsFont' underline='none' href='/privacypolicy' rel='noopener noreferrer'>
            {t('HomePage.PrivacyPolicy')}
          </Link>
        </Grid>
      </Stack>
      <Box className='footerPic'>
        <NetumFooter />
      </Box>
    </AppBar>
  )
}
export default observer(OPFooter)
