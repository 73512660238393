import { ListItem, ListItemButton, ListItemIcon, Tooltip } from '@mui/material'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { useStore } from '../../Provider'
import { CollapsedList } from './CollapsedList'
import { SideBarItem } from './SideBarItem'

export const IconButton = (props: SideBarItem): JSX.Element => {
  const { link, icon, text, dataTestId, ariaLabel, disabled, isNested, nestedItems, menuID, isExact = false } = props

  const rootStore = useStore()

  const { openState } = rootStore.sidebarStore

  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    if (isNested) e.preventDefault()
  }

  return (
    <ListItem key={link} disablePadding aria-label={ariaLabel} data-testid={dataTestId} className='iconButton'>
      <Tooltip
        PopperProps={{ className: 'tooltipStyle' + (isNested ? '' : ' onlyTip') }}
        title={<CollapsedList title={text} sideBarNestedItem={nestedItems} isClick={openState === menuID} />}
        placement='right-start'
      >
        <div className='fullWidthPopper'>
          <ListItemButton
            component={NavLink}
            exact={isExact}
            onClick={onClick}
            to={link}
            activeClassName='iconButton active'
            disabled={disabled}
            disableRipple
          >
            <ListItemIcon>{icon}</ListItemIcon>
          </ListItemButton>
        </div>
      </Tooltip>
    </ListItem>
  )
}
