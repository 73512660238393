import { Grid } from '@mui/material'
import { Formik } from 'formik'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getErrMsg, toastNeg, toastPos } from '../../../../common/toastHelper'
import {
  IEditUserProject,
  ILinkedSkill,
  INewEmployer,
  INewProject,
  IOtherEmployerDetailsForm,
  IPrivateProjectFormPackage,
  IUserProject,
} from '../../../../interfaces/projects/IProjects'
import { ISkillProject } from '../../../../interfaces/skills/ISkills'
import { useStore } from '../../../../Provider'
import DeleteConfirmation from '../../../OPcomponents/DeleteConfirmation'
import { ExitConfirmation } from '../../../OPcomponents/ExitConfirmation'
import AutoSave from '../../../OPcomponents/FormikAutosave'
import { OPDivider } from '../../../OPcomponents/OPDivider'
import { privateWorkHistorySchema } from '../../../OPcomponents/ValidationSchemas'
import { OPSkillsButtons } from '../../OPSkillsButtons'
import AddNewEmployer from './AddNewEmployer'
import NewPersonalPrivateProject from './NewPersonalPrivateProject'
import NewPrivateProject from './NewPrivateProject'

interface IAddNewPrivateProject {
  isProjectSite?: boolean
  isCreateNewProject?: boolean
  isEdit?: boolean
  collapseHandler?: () => void
  data?: IPrivateProjectFormPackage
}

export interface initValues {
  employer: IOtherEmployerDetailsForm
  project: INewProject
  userProject: IUserProject
}

const AddNewPrivateProject = (props: IAddNewPrivateProject): JSX.Element => {
  const { isProjectSite = false, isCreateNewProject = false, isEdit = false, collapseHandler, data } = props
  const { t } = useTranslation()
  const rootStore = useStore()
  const { validatedUser } = rootStore.loginStore
  const {
    currentEmployerDetails,
    newEmployerName,
    getAllEmployerDetails,
    allOtherEmployerDetails,
    postEmployer,
    postProject,
    postUserProject,
    editUserOwnedProject,
    editEmployer,
    deleteUserProject,
    getAllProjects,
    showAddPrivateProjectModalHandler,
    showModalHandler,
  } = rootStore.projectStore

  const { postNewSkillProjectLinks, deleteNewSkillProjectLinks } = rootStore.skillStore

  const { getUserPrivateWorkHistory } = rootStore.personStore

  const [projectSkills, setProjectSkills] = useState<ISkillProject[]>([])
  const [deleteProjectConfirmation, setDeleteProjectConfirmation] = useState(false)

  const curLinked: ISkillProject[] = useMemo(() => {
    const links: ISkillProject[] = []

    if (data?.userDetails.linkedSkills) {
      data.userDetails.linkedSkills.forEach((skill: ILinkedSkill) => {
        links.push({
          userId: validatedUser,
          projectId: data?.project.projectId,
          skillId: skill.skillId,
          text: skill.skillName,
        })
      })
    }

    setProjectSkills(links)
    return links
  }, [data?.project.projectId, data?.userDetails.linkedSkills, validatedUser])

  const linksModified = _.isEqual(_.sortBy(projectSkills, ['skillId']), _.sortBy(curLinked, ['skillId']))

  const initValues: initValues = {
    employer: {
      name: data?.employerDetails.name || newEmployerName || '',
      userId: validatedUser,
      jobTitle: data?.employerDetails.jobTitle || '',
      jobDescription: data?.employerDetails.jobDescription || '',
      startDate: data?.employerDetails.startDate || undefined,
      endDate: data?.employerDetails.endDate || undefined,
      employerId: data?.employerDetails.employerId || -1,
    },
    project: {
      userId: validatedUser,
      name: data?.project.name || '',
      branchId: data?.project.branchId || -1,
      description: data?.project.description || '',
      customer: data?.project.customer || '',
      public: data?.project.public || false,
      isNetum: false,
      canUseAsReference: data?.project.canUseAsReference || false,
      employerId: data?.project.employerId || currentEmployerDetails.employerId || -1,
    },
    userProject: {
      userId: validatedUser,
      projectId: data?.userDetails.projectId || -1,
      startDate: data?.userDetails.startDate || undefined,
      endDate: data?.userDetails.endDate || undefined,
      manDays: data?.userDetails.manDays || 0,
      quota: data?.userDetails.quota || 0,
      usingManDays: data?.userDetails.usingManDays ?? true,
      description: data?.userDetails.description || '',
      roleIds: data?.userDetails.roles.map((role) => ({ value: role.roleId.toString(), key: role.roleId, text: role.roleName })) || [],
    },
  }

  useEffect(() => {
    if (allOtherEmployerDetails.length === 0) {
      getAllEmployerDetails(validatedUser)
    }
  }, [allOtherEmployerDetails.length, getAllEmployerDetails, validatedUser])

  const handleSubmitNew = async (values: initValues) => {
    try {
      if (values.employer.employerId === -1 && values.project.employerId === -1) {
        const newEmp = await postEmployer(values.employer as INewEmployer)
        values.project.employerId = newEmp
      }
      const projId = await postProject(values.project)

      const personProject: IEditUserProject = {
        userId: validatedUser,
        projectId: projId,
        startDate: values.userProject.startDate,
        endDate: values.userProject.endDate,
        workAmount: values.userProject.usingManDays ? values.userProject.manDays : values.userProject.quota,
        usingManDays: !!values.userProject.usingManDays,
        description: values.userProject.description,
        roleIds: values.userProject.roleIds.map((role) => role.key) || [],
      }
      await postUserProject(personProject)
      if (projectSkills.length > 0) {
        projectSkills.forEach((skill) => {
          skill.projectId = projId
        })
        await postNewSkillProjectLinks(projectSkills)
      }
      toastPos(t('Toasts.ProjectCreate'))
    } catch (error) {
      toastNeg(getErrMsg(error, t) || t('Toasts.ProjectCreateError'))
    } finally {
      handleClose()
    }
  }

  const handleSubmitEdit = async (values: initValues) => {
    try {
      if (values.employer.employerId === -1) {
        const newEmp = await postEmployer(values.employer as INewEmployer)
        values.employer.employerId = newEmp
      } else {
        const editedEmployer: IOtherEmployerDetailsForm = {
          employerId: values.employer.employerId,
          name: values.employer.name,
          userId: validatedUser,
          jobTitle: values.employer.jobTitle,
          jobDescription: values.employer.jobDescription,
          startDate: values.employer.startDate,
          endDate: values.employer.endDate,
        }
        await editEmployer(editedEmployer)
      }

      if (values.employer.employerId !== values.project.employerId) {
        values.project.employerId = values.employer.employerId
      }

      const editedProject = {
        userId: validatedUser,
        projectId: values.userProject.projectId,
        name: values.project.name,
        projectDescription: values.project.description,
        customer: values.project.customer,
        branchId: values.project.branchId,
        employerId: values.project.employerId,
        canUseAsReference: values.project.canUseAsReference,
        startDate: values.employer.startDate,
        endDate: values.employer.endDate,
        public: values.project.public,
        workAmount: values.userProject.usingManDays ? values.userProject.manDays : values.userProject.quota,
        usingManDays: !!values.userProject.usingManDays,
        userDescription: values.userProject.description,
        roleIds: values.userProject.roleIds.map((role) => role.key) || [],
      }

      const removedLinks = _.differenceBy(curLinked, projectSkills, 'skillId')
      const addedLinks = _.differenceBy(projectSkills, curLinked, 'skillId')

      await editUserOwnedProject(editedProject)
      if (removedLinks.length > 0) await deleteNewSkillProjectLinks(removedLinks)
      if (addedLinks.length > 0) await postNewSkillProjectLinks(addedLinks)
    } catch (error) {
      toastNeg(getErrMsg(error, t) || t('Toasts.ProjectEditError'))
    }
  }

  const handleClose = async () => {
    showAddPrivateProjectModalHandler(false)
    setTimeout(async () => {
      await getAllProjects(validatedUser, false)
      await getUserPrivateWorkHistory(validatedUser, false)
      await getAllEmployerDetails(validatedUser, false)
    }, 500)
  }

  const deleteProject = async (id: number) => {
    try {
      await deleteUserProject(validatedUser, id, true)
      toastPos(t('Toasts.ProjectUserDelete'), true)
    } catch (error) {
      toastNeg(getErrMsg(error, t) || t('Toasts.ProjectUserDeleteError'), true)
    } finally {
      handleClose()
    }
  }

  useEffect(() => {
    return () => {
      handleClose()
    }
  }, [])

  const submitHandler = (values: initValues) => (isEdit ? handleSubmitEdit(values) : handleSubmitNew(values))

  return (
    <Grid container rowSpacing={4} className={isProjectSite || isCreateNewProject ? '' : 'gridcontainer--formcontainer__itempaddings'}>
      <Formik initialValues={initValues} validationSchema={privateWorkHistorySchema} onSubmit={submitHandler}>
        {({ isValid, dirty, handleSubmit, isSubmitting, values }) => (
          <>
            <ExitConfirmation when={dirty} />
            <Grid item xs={12}>
              <AddNewEmployer deleteProjectConfirmation={deleteProjectConfirmation} isEdit={isEdit} />
            </Grid>
            <Grid item xs={12}>
              <OPDivider />
            </Grid>
            <Grid item xs={12}>
              <NewPrivateProject deleteProjectConfirmation={deleteProjectConfirmation} />
            </Grid>
            <Grid item xs={12}>
              <NewPersonalPrivateProject
                isNewProject={!isEdit}
                deleteProjectConfirmation={deleteProjectConfirmation}
                setProjectSkills={setProjectSkills}
                projectSkills={projectSkills}
              />
            </Grid>
            <Grid xs={12} item>
              <OPSkillsButtons
                isDelete={isEdit}
                onDelete={() => setDeleteProjectConfirmation(true)}
                bothDisabled={isSubmitting || deleteProjectConfirmation}
                disabled={!isValid || (!dirty && linksModified) || isSubmitting}
                noPadding
                bottomPadding
                onSubmit={handleSubmit}
                onClose={() => {
                  showModalHandler(false)
                  showAddPrivateProjectModalHandler(false)
                }}
                onAutosave={isEdit}
              />
            </Grid>
            {isEdit && (
              <DeleteConfirmation
                open={deleteProjectConfirmation}
                whatToDelete='Project'
                deleteHandler={() => deleteProject(values.userProject.projectId)}
                handleClose={() => setDeleteProjectConfirmation(false)}
              />
            )}
            {isEdit && <AutoSave />}
          </>
        )}
      </Formik>
    </Grid>
  )
}
export default observer(AddNewPrivateProject)
