import { Grid, ListItem, useMediaQuery } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useTranslation } from 'react-i18next'
import history from '../../history'
import { IProjectListPerson } from '../../interfaces/persons/IPerson'
import { useStore } from '../../Provider'
import { theme } from '../../theme/CustomTheme'
import ConditionalWrapper from '../OPcomponents/ConditionalWrapper'
import OPTooltip from '../OPcomponents/OPTooltip'
import '../Profile/myskills.scss'
import '../Profile/profile.scss'

enum locations {
  'profile' = 'profileProject',
  'project' = 'project',
  'search' = 'search',
}

const ProjectMembers = (props: { data: IProjectListPerson[] | undefined }): JSX.Element => {
  const { data } = props
  const { t } = useTranslation()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const rootStore = useStore()
  const { resetPersonProfileOverview } = rootStore.profileStore

  const fromWhere = () => Object.entries(locations).find((entry) => history.location.pathname.includes(entry[0]))?.[1]

  const profileLink = (id: number) => {
    resetPersonProfileOverview()
    history.push(`/persons/${id}`, fromWhere())
  }

  return (
    <>
      {data && data?.length > 0 && (
        <Grid container>
          <Grid item xs={6} className='griditem--projectmembers'>
            <span className='myskill--font__titlefont sini'>{t('Projects.Members')}</span>
          </Grid>
          {isMdUp && (
            <Grid item xs={6}>
              <span className='myskill--font__titlefont sini'>{t('Persons.Role')}</span>
            </Grid>
          )}
          <Grid container className='gridcontainer--projectmembers'>
            {data?.map((person) => (
              <ListItem key={person.name} className='profile--pointer' onClick={() => profileLink(person.id)}>
                <Grid item xs={6}>
                  <span className={'myskill--font__regtext'}>{person.name}</span>
                </Grid>
                {isMdUp && (
                  <ConditionalWrapper
                    condition={person.roles.length > 1}
                    wrapper={(children) => (
                      <OPTooltip alwaysVisible title={person.roles.map((role) => role.roleName).join(', ')}>
                        {children}
                      </OPTooltip>
                    )}
                  >
                    <Grid item xs={6} className='myskill--font__multilinetext roles'>
                      <span className={'myskill--font__regtext'}>{person.title}</span>
                    </Grid>
                  </ConditionalWrapper>
                )}
              </ListItem>
            ))}
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default observer(ProjectMembers)
