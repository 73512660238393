import { Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import history from '../../../history'
import { IUserSkills } from '../../../interfaces/skills/ISkills'
import { useStore } from '../../../Provider'
import { OPLoadingCircle } from '../../OPcomponents/OPLoadingcircle'
import { Data, OPNewTable } from '../../OPcomponents/OPNewTable'
import { OPSkillbar } from '../../OPcomponents/OPSkillbar'

const MySkills = (): JSX.Element => {
  const { t } = useTranslation()
  const rootStore = useStore()

  const { validatedUser } = rootStore.loginStore
  const { getAllUserSkills, loadingSkills, filteredSkills } = rootStore.personStore

  useEffect(() => {
    if (filteredSkills.length === 0) getAllUserSkills(validatedUser)
  }, [])

  const skillRows: Data[] = []

  filteredSkills.slice(0, 5).forEach((skill: IUserSkills) => {
    skillRows.push({
      id: skill.skillId,
      rows: {
        [t('Skills.SkillName')]: { text: skill.skillName, width: '50%' },
        [t('Skills.Level')]: { text: <OPSkillbar name={skill.skillName} value={skill.skillLevel} readonly />, width: '50%' },
      },
    })
  })

  return (
    <Grid>
      {!loadingSkills ? (
        <Grid container rowGap={2.25} className='profile--pointer'>
          <Grid item xs={12} onClick={() => history.push('/profile/myskill')}>
            <p data-testid='profiletitletext' className='profile--titlefont'>
              {t('Buttons.MySkills')}
            </p>
          </Grid>
          <Grid item xs={12}>
            {skillRows.length ? (
              <OPNewTable rows={skillRows} curLength={skillRows.length} onRowClick={(id) => history.push('/profile/myskill', id)} />
            ) : (
              <div data-testid='myskillsplaceholdertext' className='profile--cert__regtext'>
                {t('Skills.SkillsPlaceholder')}
              </div>
            )}
          </Grid>
        </Grid>
      ) : (
        <OPLoadingCircle />
      )}
    </Grid>
  )
}
export default observer(MySkills)
