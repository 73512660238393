import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../../Provider'
import { Data, OPNewTable } from '../../OPcomponents/OPNewTable'
import NewCert from './NewCert'

const CertTable = (props: { rowId?: number }): JSX.Element => {
  const { t, i18n } = useTranslation()
  const lang = i18n.language
  const rootStore = useStore()

  const { rowId } = props

  const { filteredCertificates, showEditCertificateModalHandler } = rootStore.certAndEducationStore

  const newRows: Data[] = []

  filteredCertificates.forEach((cert) =>
    newRows.push({
      ['id']: cert.sertificateId ?? -1,
      rows: {
        [t('CertAndEducation.CertificateName')]: { text: cert.name ?? '', width: '38%' },
        [t('CertAndEducation.Granter')]: { text: cert.school ?? '', width: '38%' },
        [t('CertAndEducation.CertificateGrantDate')]: {
          text: cert.startDate != null ? moment(cert.startDate).locale(lang).format('L') : '',
          width: '12%',
        },
        [t('CertAndEducation.CertificateExpirationDate')]: {
          text: cert.expirationDate != null ? moment(cert.expirationDate).locale(lang).format('L') : '',
          width: '12%',
        },
      },
    }),
  )

  const onRowClickHandler = async (rowId: number | string) => {
    showEditCertificateModalHandler(true)
    return rowId
  }

  return <OPNewTable rows={newRows} curLength={newRows.length} profileclickedId={rowId} onRowClick={onRowClickHandler} FormBase={NewCert} />
}

export default observer(CertTable)
