import { AutocompleteChangeDetails, Grid } from '@mui/material'
import { Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getErrMsg, toastNeg, toastPos } from '../../../common/toastHelper'
import { IDropdownProjects, IEditUserProject, IProjectPersonDetails, IUserProject } from '../../../interfaces/projects/IProjects'
import { ILinkedProject, ISkillProject } from '../../../interfaces/skills/ISkills'
import { useStore } from '../../../Provider'
import { ExitConfirmation } from '../../OPcomponents/ExitConfirmation'
import { OPDateField } from '../../OPcomponents/OPDateField'
import { OPLoadingCircle } from '../../OPcomponents/OPLoadingcircle'
import { OPMultiline } from '../../OPcomponents/OPMultiline'
import { OPNumberField } from '../../OPcomponents/OPNumberField'
import { OPRadioButton } from '../../OPcomponents/OPRadioButton'
import { OPSkillsCombobox } from '../../OPcomponents/OPSkillsCombobox'
import { newPersonalProjectSchema } from '../../OPcomponents/ValidationSchemas'
import '../../styles/grid.scss'
import { OPSkillsButtons } from '../OPSkillsButtons'
import '../profile.scss'

const ProjectSkillLink = (props: {
  setLinkingProject: (val: boolean) => void
  setLinkedProjects: (projets: ILinkedProject[]) => void
}): JSX.Element => {
  const { setLinkingProject, setLinkedProjects } = props
  const rootStore = useStore()
  const { t } = useTranslation()
  const { validatedUser } = rootStore.loginStore
  const { editCurrentSkill, postNewSkillProject, getUserDetailedSkillLinkedProjects, setShowAddProjectToSkillModal, editCurrentProject } =
    rootStore.skillStore

  const {
    postUserProject,
    editUserProject,
    getAllRoles,
    dropdownRoles,
    showAddSkillToProjectModal,
    dropdownProjects,
    getDetailedUserProjects,
    clearUserProjectDetails,
    loadingProjectPersonDetails,
  } = rootStore.projectStore

  const [chosenDropdownProject, setChosenDropdownProject] = useState<IDropdownProjects>()
  const [selectedProject, setSelectedProject] = useState<IProjectPersonDetails>()

  useEffect(() => {
    getAllRoles()

    return () => {
      clearUserProjectDetails()
    }
  }, [clearUserProjectDetails, getAllRoles])

  useEffect(() => {
    return () => {
      clearUserProjectDetails()
      setShowAddProjectToSkillModal(false)
    }
  }, [clearUserProjectDetails, setShowAddProjectToSkillModal])

  const filteredDropdownProjects = dropdownProjects?.filter((i) =>
    editCurrentProject?.every((linkedProject) => linkedProject.projectId !== i.key),
  )

  const initValues: IUserProject = {
    userId: validatedUser,
    manDays: selectedProject?.manDays ?? 0,
    quota: selectedProject?.quota ?? 0,
    projectId: selectedProject?.projectId ?? (chosenDropdownProject?.key || -1),
    startDate: selectedProject?.startDate,
    endDate: selectedProject?.endDate,
    usingManDays: selectedProject?.usingManDays ? true : false ?? false,
    description: selectedProject?.description ?? '',
    roleIds: selectedProject?.roles.map((role) => ({ value: role.roleId.toString(), key: role.roleId, text: role.roleName })) ?? [],
  }

  const radioOptions = [
    {
      value: '1',
      label: t('Projects.ManDays'),
    },
    {
      value: '0',
      label: t('Projects.Percentage'),
    },
  ]

  const submitForm = async (values: IUserProject) => {
    const roles = values.roleIds.map((role: IDropdownProjects) => role.key)
    const workLoad = values.usingManDays ? values.manDays : values.quota

    const newPersonSkillProject: ISkillProject = {
      userId: validatedUser,
      projectId: values.projectId,
      skillId: editCurrentSkill.skillId,
    }

    const newProject: IEditUserProject = {
      userId: validatedUser,
      projectId: values.projectId ?? selectedProject?.projectId,
      startDate: values.startDate,
      endDate: values.endDate,
      workAmount: workLoad,
      usingManDays: values.usingManDays,
      description: values.description,
      roleIds: roles || [],
    }

    try {
      if (selectedProject?.projectId === undefined) {
        await postUserProject(newProject, true)
      } else {
        await editUserProject(newProject, true)
      }
      await postNewSkillProject(newPersonSkillProject)
      toastPos(t('Toasts.SkillProjectAdd'), true)
    } catch (err) {
      toastNeg(getErrMsg(err, t) || t('Toasts.SkillProjectAddError'), true)
    } finally {
      await handleClose()
    }
  }

  const handleClose = async () => {
    setLinkingProject(false)
    const projectLinks = await getUserDetailedSkillLinkedProjects(validatedUser, editCurrentSkill.skillId)
    setLinkedProjects(projectLinks.skill.linkedProjects)
  }

  const onRoleDelete = (event: AutocompleteChangeDetails<any>, roleIds: IDropdownProjects[]) => {
    return roleIds.filter((role) => role.key !== event.option.key)
  }
  const onProjectChange = async (projectId: number) => {
    const changedProject = await getDetailedUserProjects(validatedUser, projectId)
    setSelectedProject(changedProject)
  }

  return (
    <Grid container className='gridcontainer--scrollmargin gridcontainer--formcontainer__itempaddings'>
      <hr className='profile--form__editdivider' />
      {loadingProjectPersonDetails ? (
        <Grid xs={12} item>
          <OPLoadingCircle />
        </Grid>
      ) : (
        <Formik
          enableReinitialize
          initialValues={initValues}
          onSubmit={(values) => submitForm(values)}
          validationSchema={newPersonalProjectSchema}
        >
          {({ setFieldValue, values, handleChange, isSubmitting, isValid, setTouched, handleSubmit, dirty, errors, touched }) => (
            <>
              <ExitConfirmation when={dirty} />
              <Grid xs={12} item className='griditem--formitem__titlemargin'>
                <span className='myskill--font__titlefont sini'>{t('Skills.AddProject')}</span>
              </Grid>
              <Grid container rowSpacing={4} columnSpacing={4}>
                <Grid xs={12} lg={6} item>
                  <Grid container spacing={4}>
                    <Grid xs={12} item>
                      <OPSkillsCombobox
                        name='projectId'
                        label={`${t('Projects.Project')}*`}
                        onChange={(e, data) => {
                          setChosenDropdownProject(data)
                          onProjectChange(data.value)
                        }}
                        curState={chosenDropdownProject}
                        optionList={filteredDropdownProjects}
                      />
                    </Grid>
                    <Grid xs={12} lg={6} item>
                      <OPDateField
                        disabled={chosenDropdownProject === undefined}
                        name='startDate'
                        label={`${t('Projects.EmployerStartDate')}*`}
                        value={values.startDate ?? null}
                        onChange={(newValue: Date | null) => {
                          setTouched({ ...touched, startDate: true })
                          setFieldValue('startDate', newValue)
                        }}
                        error={touched.startDate && Boolean(errors.startDate)}
                        errMessage={t(errors.startDate || '')}
                      />
                    </Grid>
                    <Grid xs={12} lg={6} item>
                      <OPDateField
                        disabled={chosenDropdownProject === undefined}
                        name='endDate'
                        label={t('Projects.EmployerEndDate')}
                        value={values.endDate ?? null}
                        minDate={values.startDate}
                        onChange={(newValue: Date | null) => {
                          setFieldValue('endDate', newValue)
                        }}
                        error={Boolean(errors.endDate)}
                        errMessage={t(errors.endDate || '')}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid xs={12} lg={6} item>
                  <OPMultiline
                    disabled={chosenDropdownProject === undefined}
                    name='description'
                    label={t('Projects.WorkDescription')}
                    onChange={handleChange}
                    maxChars={1000}
                    minRows={6}
                    value={values.description}
                    hasCounter
                  />
                </Grid>
                <Grid xs={12} item>
                  <Grid container spacing={4}>
                    <Grid xs={6} item order={{ lg: 1, xs: 2 }}>
                      {values.usingManDays ? (
                        <OPNumberField
                          disabled={chosenDropdownProject === undefined}
                          label={t('Projects.ProjectWorkAmount')}
                          name='manDays'
                          error={Boolean(errors.manDays)}
                          errorText={t(errors.manDays || '')}
                          value={values.manDays ?? 0}
                          onChange={(e) => setFieldValue('manDays', e ?? 0)}
                        />
                      ) : (
                        <OPNumberField
                          disabled={chosenDropdownProject === undefined}
                          name='quota'
                          label={t('Projects.ProjectWorkAmount')}
                          error={Boolean(errors.quota)}
                          errorText={t(errors.quota || '')}
                          minValue={0}
                          maxValue={100}
                          value={values.quota ?? 0}
                          onChange={(e) => setFieldValue('quota', e ?? 0)}
                        />
                      )}
                    </Grid>
                    <Grid xs={12} lg={6} item order={{ lg: 2, xs: 1 }}>
                      <OPSkillsCombobox
                        disabled={chosenDropdownProject === undefined}
                        name='roleIds'
                        label={t('Projects.ProjectRoles')}
                        placeholder={t('Placeholders.Choose')}
                        curState={values.roleIds}
                        multiple
                        optionList={dropdownRoles}
                        onDelete={(e) => {
                          const newVals = onRoleDelete(e, values.roleIds)
                          setFieldValue('roleIds', newVals)
                        }}
                        onChange={(_e, v) => setFieldValue('roleIds', v)}
                      />
                    </Grid>
                    <Grid item xs={6} className='griditem--skilllink__radiopos' order={{ lg: 3, xs: 3 }}>
                      <OPRadioButton
                        disabled={chosenDropdownProject === undefined}
                        name='usingManDays'
                        radioOptions={radioOptions}
                        label={''}
                        onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldValue('usingManDays', v.target.value === '1')
                        }}
                        defaultVal={values.usingManDays ? '1' : '0'}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <OPSkillsButtons
                  noPadding
                  bothDisabled={showAddSkillToProjectModal || isSubmitting}
                  disabled={!isValid || showAddSkillToProjectModal || isSubmitting}
                  onClose={handleClose}
                  onSubmit={handleSubmit}
                />
              </Grid>
            </>
          )}
        </Formik>
      )}
    </Grid>
  )
}

export default observer(ProjectSkillLink)
