import { Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IProjects } from '../../../interfaces/projects/IProjects'
import '../../Profile/myskills.scss'
import '../../Profile/profile.scss'

const ProjectHeader = (props: { data: IProjects['project'] | undefined; isNewProject?: boolean }): JSX.Element => {
  const { data, isNewProject } = props
  const { t, i18n } = useTranslation()
  const lang = i18n.language

  return (
    <Grid container rowSpacing={2} className={isNewProject ? '' : 'gridcontainer--formcontainer__itempaddings'}>
      <Grid xs={12} item>
        <span className='myskill--font__titlefont subTitle'>{`${t('Projects.ProjectName')}: `}</span>
        <span className={'myskill--font__regtext'}>{data?.name}</span>
      </Grid>
      <Grid xs={12} item>
        <span className='myskill--font__titlefont subTitle'>{`${t('Projects.ProjectDescription')}: `}</span>
        <span className={'myskill--font__regtext' + (data?.description !== '' && data?.description !== null ? '' : ' placeholder')}>
          {data?.description !== '' && data?.description !== null ? data?.description : t('Projects.NotDefined')}
        </span>
      </Grid>
      <Grid xs={12} item>
        <span className='myskill--font__titlefont subTitle'>{`${t('Projects.ContactPerson')}: `}</span>
        <span className={'myskill--font__regtext' + (data?.contactPerson !== '' && data?.contactPerson !== null ? '' : ' placeholder')}>
          {data?.contactPerson !== '' && data?.contactPerson !== null ? data?.contactPerson : t('Projects.NotDefined')}
        </span>
      </Grid>
      <Grid xs={12} item>
        <span className='myskill--font__titlefont subTitle'>{`${t('Projects.Customer')}: `}</span>
        {data?.customer && (
          <span className={'myskill--font__regtext'}>
            {`${data?.customer} (`}
            {data?.public ? t('Projects.PublicCustomer') : t('Projects.PrivateCustomer')}
            {')'}
          </span>
        )}
        {!data?.customer && <span className='myskill--font__regtext placeholder'>{t('Projects.NotDefined')}</span>}
      </Grid>
      <Grid xs={12} item>
        <span className='myskill--font__titlefont subTitle'>{`${t('Projects.ProjectTimeline')}: `}</span>
        <span className={'myskill--font__regtext'}>
          {data?.startDate != null ? moment(data?.startDate).locale(lang).format('L') : t('Projects.NotDefined')}
        </span>
        <span className={'myskill--font__regtext'}> - </span>
        <span className={'myskill--font__regtext'}>
          {data?.endDate != null ? moment(data?.endDate).locale(lang).format('L') : t('Projects.Ongoing')}
        </span>
      </Grid>
      <Grid xs={12} item>
        <span className='myskill--font__titlefont subTitle'>{`${t('Projects.ProjectBranch')}: `}</span>
        <span className={'myskill--font__regtext' + (data?.branchName ? '' : ' placeholder')}>
          {data?.branchName ?? t('Projects.NotDefined')}
        </span>
      </Grid>
    </Grid>
  )
}
export default observer(ProjectHeader)
