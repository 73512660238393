import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { getErrMsg, toastNeg, toastPos } from '../../common/toastHelper'
import { IEditDevelopmentGoal, IListDevelopmentGoal, INewDevelopmentGoal } from '../../interfaces/discussions/IDiscussion'
import { IDropdownSkill } from '../../interfaces/skills/ISkills'
import { useStore } from '../../Provider'
import { DeleteIcon } from '../styles/Icons'
import '../styles/newgoalform.scss'
import { MuiButtonVariants, OPButton } from '../OPcomponents/OPButton'
import { OPDivider } from '../OPcomponents/OPDivider'
import { OPMultiline } from '../OPcomponents/OPMultiline'
import { OPSkillsCombobox } from '../OPcomponents/OPSkillsCombobox'
import { OPTextField } from '../OPcomponents/OPTextField'

interface DevGoalFormProps {
  onSave: (values?: IEditDevelopmentGoal, skillName?: string) => void
  onCancel: () => void
  goal?: IListDevelopmentGoal
  developmentDiscussionId?: number
}

interface DevGoalValues {
  name: string
  description: string
  schedule: string
  skillId: number | undefined
  skillName?: string
}

export const DevelopmentGoalForm = observer((props: DevGoalFormProps): JSX.Element => {
  const { onSave, onCancel, goal, developmentDiscussionId } = props
  const { t } = useTranslation()
  const rootStore = useStore()
  const {
    isConfirmDeleteGoalVisible,
    setIsConfirmDeleteGoalVisible,
    createDevelopmentGoal,
    getGoals,
    selectedDevelopmentGoal,
    editDevelopmentGoal,
    selectDevelopmentGoal,
    isEditDiscussionGoal,
  } = rootStore.kekeStore
  const { filteredSkills, allSkills, getAllSkills } = rootStore.skillStore
  const { validatedUser } = rootStore.loginStore

  const { userId } = useParams() as {
    userId?: string
  }

  const selectedUser = userId !== undefined ? Number(userId) : validatedUser

  const initialValues = {
    name: goal?.name ?? '',
    description: goal?.description ?? '',
    schedule: goal?.schedule ?? '',
    skillId: goal?.skillId ?? undefined,
  }
  const [values, setValues] = useState<DevGoalValues>(initialValues)
  const [skillOptions, setSkillOptions] = useState<IDropdownSkill[]>([])

  const divRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    getAllSkills(selectedUser)
  }, [selectedUser, getAllSkills])

  useEffect(() => {
    setSkillOptions(
      filteredSkills.map((s) => {
        return {
          key: s.skill.skillId,
          text: s.skill.skillName,
          value: s.skill.skillName,
        }
      }),
    )
  }, [filteredSkills, selectedDevelopmentGoal])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  const getSkillName = (skillId: number): string | undefined => {
    if (skillId === undefined) return undefined
    const skill = filteredSkills.filter((s) => s.skill.skillId === skillId)
    return skill.length > 0 ? skill[0].skill.skillName : undefined
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!isEditDiscussionGoal) {
      const newDevelopmentGoal: INewDevelopmentGoal = {
        developmentDiscussionId: developmentDiscussionId,
        description: values.description,
        name: values.name,
        schedule: values.schedule,
        skillId: values.skillId,
        creatorId: validatedUser,
        employeeId: selectedUser,
      }
      try {
        await createDevelopmentGoal(newDevelopmentGoal)
        toastPos(t('Toasts.DiscussionGoalCreate'))
      } catch (error) {
        toastNeg(getErrMsg(error, t) || t('Toasts.DiscussionGoalCreateError'))
      }
      await getGoals(selectedUser)
      onSave()
    } else {
      const editDiscussionGoalValues: IEditDevelopmentGoal = {
        description: values.description,
        name: values.name,
        schedule: values.schedule,
        skillId: values.skillId,
      }
      if (selectedDevelopmentGoal) {
        try {
          await editDevelopmentGoal(selectedDevelopmentGoal.goalId, editDiscussionGoalValues, developmentDiscussionId)
          toastPos(t('Toasts.DiscussionGoalEdit'))
        } catch (error) {
          toastNeg(getErrMsg(error, t) || t('Toasts.DiscussionGoalEditError'))
        }
      }
      onSave(editDiscussionGoalValues, getSkillName(Number(editDiscussionGoalValues.skillId)))
    }
  }

  useEffect(() => {
    setTimeout(() => {
      if (divRef.current) {
        divRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    }, 500)
  }, [])

  const validValues = values.name.length > 1 && values.description.length > 1 && values.schedule.length > 1
  const valuesChanged =
    values.name !== goal?.name ||
    values.description !== goal?.description ||
    values.schedule !== goal?.schedule ||
    values.skillId !== goal?.skillId

  return (
    <form data-testid='devGoalForm' className='newGoalForm' onSubmit={handleSubmit}>
      <div className='newGoalHeader'>
        <p>{isEditDiscussionGoal ? t('Buttons.EditGoal') : t('Discussions.NewGoal')}</p>
      </div>
      <div ref={divRef} className='formFields'>
        <div className='bottomPadding'>
          <OPTextField
            maxLength={100}
            dataTestId='devGoalForm.goalname'
            label={t('Discussions.Goal')}
            placeholder={t('Discussions.DevGoalPlaceholder')}
            fullWidth
            required
            name='name'
            value={values.name}
            onChange={handleChange}
          />
        </div>
        <div className='bottomPadding'>
          <OPMultiline
            resizeable
            dataTestId='devGoalForm.goaldesc'
            label={t('Discussions.GoalDesc')}
            placeholder={t('Discussions.DevGoalDescPlaceholder')}
            required
            rows={5}
            name='description'
            value={values.description}
            onChange={handleChange}
            maxChars={2000}
            hasCounter
          />
        </div>
        <div className='bottomPadding'>
          <OPTextField
            maxLength={250}
            dataTestId='devGoalForm.goalschedule'
            label={t('Discussions.GoalSchedule')}
            placeholder={t('Discussions.DevGoalSchedulePlaceholder')}
            fullWidth
            required
            name='schedule'
            value={values.schedule}
            onChange={handleChange}
          />
        </div>
        <div>
          <OPSkillsCombobox
            dataTestId='devGoalForm.goalskill'
            optionList={skillOptions}
            label={t('Discussions.GoalSkill')}
            curState={skillOptions.find((s) => s.key === values.skillId) ?? null}
            onChange={(e, v) => setValues({ ...values, skillId: v.key })}
          />
        </div>
      </div>
      {isEditDiscussionGoal ? (
        <div>
          <div className='buttonsDiv'>
            <div className='deleteButton'>
              <OPButton
                label={t('Buttons.Delete')}
                disabled={isConfirmDeleteGoalVisible}
                buttonVariant={MuiButtonVariants.error}
                onClick={() => {
                  setIsConfirmDeleteGoalVisible(true)
                  selectDevelopmentGoal(goal)
                }}
                endIcon={<DeleteIcon fill={isConfirmDeleteGoalVisible ? '#757575' : 'white'} />}
              />
            </div>
            <div className='cancelButton'>
              <OPButton
                label={t('Buttons.Cancel')}
                disabled={isConfirmDeleteGoalVisible}
                buttonVariant={MuiButtonVariants.error_outlined}
                onClick={() => onCancel()}
              />
            </div>
            <div>
              <OPButton
                label={t('Buttons.Save')}
                disabled={!valuesChanged || !validValues || isConfirmDeleteGoalVisible}
                type='submit'
                buttonVariant={MuiButtonVariants.primary}
              />
            </div>
          </div>
          <OPDivider />
        </div>
      ) : (
        <div className='buttonsDiv'>
          <div className='cancelButton'>
            <OPButton label={t('Buttons.Cancel')} buttonVariant={MuiButtonVariants.error_outlined} onClick={() => onCancel()} />
          </div>
          <div>
            <OPButton
              dataTestId='saveDevGoal.button'
              label={t('Buttons.Save')}
              disabled={!validValues}
              type='submit'
              buttonVariant={MuiButtonVariants.primary}
            />
          </div>
        </div>
      )}
    </form>
  )
})
