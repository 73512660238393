import { Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useStore } from '../../../Provider'
import { Data, OPNewTable } from '../../OPcomponents/OPNewTable'
import { HighestDegree } from '../../styles/Icons'
import NewEdu from './NewEdu'

const EduTable = (props: { rowId?: number }): JSX.Element => {
  const { t, i18n } = useTranslation()
  const lang = i18n.language
  const rootStore = useStore()
  const { rowId } = props

  const { filteredEducations, showEditEducationModalHandler } = rootStore.certAndEducationStore

  const onRowClickHandler = async (id: number | string) => {
    showEditEducationModalHandler()
    return id
  }

  const newRows: Data[] = []

  filteredEducations.forEach((cert) =>
    newRows.push({
      ['id']: cert.educationId ?? -1,
      rows: {
        [t('CertAndEducation.EducationName')]: {
          text: (
            <Grid container columnGap={1} wrap='nowrap'>
              {cert.isHighestDegree && (
                <Grid item marginLeft='-0.3125rem'>
                  <HighestDegree />
                </Grid>
              )}
              <Grid display='flex' alignItems='center' item>
                {cert?.degree ?? ''}
              </Grid>
            </Grid>
          ),
          width: '41%',
        },
        [t('CertAndEducation.Granter')]: { text: cert.school ?? '', width: '41%' },
        [t('CertAndEducation.EducationStartDate')]: {
          text: cert.startDate != null ? moment(cert.startDate).locale(lang).format('L') : '',
          width: '8%',
        },
        [t('CertAndEducation.EducationEndDate')]: {
          text: cert.endDate != null ? moment(cert.endDate).locale(lang).format('L') : '',
          width: '10%',
        },
      },
    }),
  )

  return <OPNewTable rows={newRows} curLength={newRows.length} profileclickedId={rowId} onRowClick={onRowClickHandler} FormBase={NewEdu} />
}

export default observer(EduTable)
