import { Grid } from '@mui/material'
import { Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { getErrMsg, toastNeg } from '../../../common/toastHelper'
import { useStore } from '../../../Provider'
import { ExitConfirmation } from '../../OPcomponents/ExitConfirmation'
import { MuiButtonVariants, OPButton } from '../../OPcomponents/OPButton'
import { OPLoadingCircle } from '../../OPcomponents/OPLoadingcircle'
import { OPMultiline } from '../../OPcomponents/OPMultiline'
import { OPSkillsButtons } from '../OPSkillsButtons'

const Hobbies = (): JSX.Element => {
  const { t } = useTranslation()
  const rootStore = useStore()

  const { editHobbies, getPersonHobbies, isHobbiesEditing, postNewHobbies, userHobbies, loadingHobbies } = rootStore.personStore
  const { validatedUser } = rootStore.loginStore

  useEffect(() => {
    getPersonHobbies(validatedUser)
  }, [getPersonHobbies, validatedUser])

  const onEditSubmit = async (newHobbies: string) => {
    try {
      await postNewHobbies(validatedUser, newHobbies)
    } catch (error) {
      toastNeg(getErrMsg(error, t) || t('Toasts.EditError'))
    } finally {
      isHobbiesEditing(false)
    }
  }
  useEffect(() => {
    return () => {
      if (editHobbies) {
        isHobbiesEditing(false)
      }
    }
  }, [isHobbiesEditing, editHobbies])

  if (editHobbies) {
    return (
      <Grid container rowGap={2}>
        <Formik initialValues={{ hobbies: userHobbies }} onSubmit={(val) => onEditSubmit(val.hobbies)}>
          {({ values, handleChange, handleSubmit, dirty }) => (
            <>
              <ExitConfirmation when={dirty} />
              <Grid item xs={12}>
                <OPMultiline
                  name='hobbies'
                  label={t('Interests.HobbiesHeader')}
                  value={values.hobbies}
                  placeholder={t('Interests.HobbiesTextboxPlaceholder')}
                  hasCounter
                  maxChars={4000}
                  minRows={5}
                  onChange={handleChange}
                />
              </Grid>
              <OPSkillsButtons onClose={() => isHobbiesEditing(false)} onSubmit={handleSubmit} disabled={!dirty} noPadding />
            </>
          )}
        </Formik>
      </Grid>
    )
  }

  if (userHobbies.length === 0) {
    return (
      <Grid container rowGap={4}>
        <Grid item xs={12}>
          <span className='profile--interestplaceholder'>{t('Profile.HobbiesPlaceholder')}</span>
        </Grid>
        <Grid container justifyContent='flex-end'>
          <OPButton buttonVariant={MuiButtonVariants.primary_outlined} onClick={() => isHobbiesEditing(true)} label={t('Buttons.Edit')} />
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      {loadingHobbies ? (
        <OPLoadingCircle />
      ) : (
        <Grid container rowGap={4}>
          <Grid item xs={12}>
            <span className='profile--breakword'>{userHobbies}</span>
          </Grid>
          <Grid container justifyContent='flex-end'>
            <OPButton
              onClick={() => isHobbiesEditing(true)}
              label={userHobbies.length > 0 ? t('Buttons.Edit') : t('Buttons.Add')}
              buttonVariant={MuiButtonVariants.primary_outlined}
            />
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default observer(Hobbies)
