import DOMPurify from 'dompurify'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { getErrMsg, toastNeg, toastPos } from '../../common/toastHelper'
import { IListColleagueFeedback } from '../../interfaces/discussions/IDiscussion'
import { useStore } from '../../Provider'
import '../styles/developmentPage.scss'
import { DeleteIcon } from '../styles/Icons'
import { OPAccordionContent } from '../Accordion/OPAccordionContent'
import { OPAccordionItem } from '../Accordion/OPAccordionItem'
import { MuiButtonVariants, OPButton } from '../OPcomponents/OPButton'
import { OPDivider } from '../OPcomponents/OPDivider'
import { ColleagueFeedbackForm } from './ColleagueFeedbackForm'

interface ColleagueFeedbackProps {
  colleagueFeedback: IListColleagueFeedback
  onDelete?: (fb: IListColleagueFeedback) => void
  isCurrentUser: boolean
}

export const ColleagueFeedback = observer((props: ColleagueFeedbackProps): JSX.Element => {
  const { colleagueFeedback, onDelete, isCurrentUser } = props
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const rootStore = useStore()
  const { validatedUser } = rootStore.loginStore
  const {
    isEditColleagueFeedback,
    setIsEditColleagueFeedback,
    selectColleagueFeedback,
    setIsDeleteColleagueFeedbackVisible,
    isDeleteColleagueFeedbackVisible,
    selectedColleagueFeedback,
    deleteColleagueFeedback,
    getGoals,
    isConfirmReadySectionVisible,
    isNewDevGoalVisible,
    isNewFBGoalVisible,
    isEditFeedbackGoal,
    isEditDiscussionGoal,
    isAddCommentVisible,
    isNewDevDiscussionVisible,
    isConfirmDeleteGoalVisible,
    isNewMiniDiscussionVisible,
    isNewColleagueFeedbackVisible,
    isConfirmSendDiscussionVisible,
  } = rootStore.kekeStore

  const [colleagueFeedbackValues, setColleagueFeedbackValues] = useState<IListColleagueFeedback>(colleagueFeedback)
  const { userId } = useParams() as {
    userId?: string
  }

  const selectedUser = userId !== undefined ? Number(userId) : validatedUser

  const [accordionOpen, setAccordionOpen] = useState<boolean>(false)

  const divRef = useRef<HTMLDivElement>(null)

  const handleDelete = async () => {
    try {
      await deleteColleagueFeedback(colleagueFeedback.id)
    } catch (error) {
      toastNeg(getErrMsg(error, t) || t('Toasts.ColleagueFeedbackDeleteError'))
    } finally {
      setIsEditColleagueFeedback(false)
      selectColleagueFeedback(undefined)
      setIsDeleteColleagueFeedbackVisible(false)
      toastPos(t('Toasts.ColleagueFeedbackDeleteSuccess'))
    }
    try {
      await getGoals(selectedUser)
    } catch (error) {
      toastNeg(getErrMsg(error, t) || t('Toasts.FetchGoalsError'))
    }
  }
  useEffect(() => {
    if (accordionOpen) {
      divRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [accordionOpen])

  const disabledButtons =
    isConfirmReadySectionVisible ||
    isNewDevGoalVisible ||
    isNewFBGoalVisible ||
    isEditFeedbackGoal ||
    isEditDiscussionGoal ||
    isAddCommentVisible ||
    isNewDevDiscussionVisible ||
    isConfirmDeleteGoalVisible ||
    isNewMiniDiscussionVisible ||
    isNewColleagueFeedbackVisible ||
    isDeleteColleagueFeedbackVisible ||
    isEditColleagueFeedback ||
    isConfirmSendDiscussionVisible ||
    (isEditColleagueFeedback && selectedColleagueFeedback?.id !== colleagueFeedback.id)

  const cleanedCollagueFeedback = DOMPurify.sanitize(colleagueFeedbackValues?.topic.replaceAll('<p></p>', '<br />'), {
    ALLOWED_TAGS: ['b', 'i', 'u', 'ul', 'li', 'p', 'strong', 'br', 'em', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
  })

  return (
    <OPAccordionItem
      lockedOpen={selectedColleagueFeedback?.id === colleagueFeedback.id && (isEditColleagueFeedback || isDeleteColleagueFeedbackVisible)}
      insideAccordion
      setIsOpen={setAccordionOpen}
      isLocked={!isCurrentUser}
      title={`${t('Discussions.ColleagueFeedbackHeader')} (${colleagueFeedbackValues.colleagueName})`}
      created={`${t('Discussions.ColleagueFeedbackRequested')} ${moment(colleagueFeedbackValues.schedule).locale(language).format('L')}`}
      content={
        <div ref={divRef}>
          <OPAccordionContent
            listContent={{ label: t('Discussions.ColleagueFeedbackGiver'), specs: colleagueFeedbackValues.colleagueName ?? '' }}
          />
          <OPAccordionContent
            listContent={{
              label: t('Discussions.ColleagueSchedule'),
              specs: moment(colleagueFeedbackValues.schedule).locale(language).format('L'),
            }}
          />
          <OPAccordionContent listContent={{ label: t('Discussions.ColleagueFeedbackNotes'), specs: '' }} />
          {isEditColleagueFeedback && selectedColleagueFeedback?.id === colleagueFeedback.id ? (
            <ColleagueFeedbackForm
              colleagueFeedback={colleagueFeedbackValues}
              onSave={(value) => setColleagueFeedbackValues({ ...colleagueFeedbackValues, topic: value })}
            />
          ) : (
            <div>
              <div
                className='topic'
                dangerouslySetInnerHTML={{
                  __html: cleanedCollagueFeedback,
                }}
              />
              <div className='modifyButtonDiv'>
                <div className='deleteButton'>
                  <OPButton
                    disabled={disabledButtons}
                    label={t('Buttons.Delete')}
                    endIcon={<DeleteIcon fill={disabledButtons ? '#757575' : 'white'} />}
                    buttonVariant={MuiButtonVariants.error}
                    onClick={() => {
                      setIsDeleteColleagueFeedbackVisible(true)
                      selectColleagueFeedback(colleagueFeedback)
                    }}
                  />
                </div>
                <OPButton
                  disabled={disabledButtons}
                  label={t('Buttons.EditColleagueFeedback')}
                  buttonVariant={MuiButtonVariants.secondary_outlined}
                  onClick={() => {
                    setIsEditColleagueFeedback(true)
                    selectColleagueFeedback(colleagueFeedback)
                  }}
                />
              </div>
            </div>
          )}

          {isDeleteColleagueFeedbackVisible && selectedColleagueFeedback?.id === colleagueFeedback?.id && (
            <div>
              <OPDivider />
              <div className='confirmDiv'>
                <div className='confirmHeader'>
                  <p>{`${t('Discussions.ColleagueFeedbackDeleteConfirm')}?`}</p>
                </div>
                <div className='confirmButtons'>
                  <div className='cancelConfirmButton'>
                    <OPButton
                      label={t('Buttons.Cancel')}
                      buttonVariant={MuiButtonVariants.error_outlined}
                      onClick={() => {
                        setIsDeleteColleagueFeedbackVisible(false)
                        if (!isEditColleagueFeedback) selectColleagueFeedback(undefined)
                      }}
                    />
                  </div>
                  <div>
                    <OPButton
                      label={t('Buttons.Delete')}
                      buttonVariant={MuiButtonVariants.error}
                      endIcon={<DeleteIcon />}
                      onClick={handleDelete}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          <OPDivider />
        </div>
      }
    />
  )
})
