import { get, mapValues, orderBy, set, sortBy } from 'lodash'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import agent from '../../api/agent'
import { sortByType } from '../../common/sortHelper'
import { IPersonCertificate, IPersonEducation, IPersonSkillResponse } from '../../interfaces/certAndEducation/certAndEducation'
import { ISearchLanguage } from '../../interfaces/language/ILanguage'
import { IProjectPerson } from '../../interfaces/projects/IProjects'
import { ISortDirection } from '../../interfaces/Sort'

export interface ISearch {
  name: string
  id: number
  type: string
}

export interface ICertEduLangBase {
  id: number
  type: string
}

export default class SearchStore {
  searchParams = ''
  searchFilters = {
    skills: true,
    projects: true,
    persons: true,
    certifications: true,
    languages: true,
    educations: true,
  }
  searchItems: ISearch[] = []
  isSearching = false
  loadingSearchItems = false
  CertEduLangBase?: ICertEduLangBase

  projectPersonsSortColumn = 'personName'
  projectPersonsSortDirection: ISortDirection = 'ascending'
  projectPersons: IProjectPerson[] = []
  loadingProjectPersons = false
  showSearchProjectModal = false
  selectedProject?: ISearch

  selectedEducation?: ISearch
  educationPersons: IPersonEducation[] = []
  showSearchEducationModal = false
  loadingEducationPersons = false

  selectedCertificate?: ISearch
  certificatePersons: IPersonCertificate[] = []
  showSearchCertificateModal = false
  loadingCertificatePersons = false

  selectedLanguage?: ISearch
  languagePersons: ISearchLanguage[] = []
  showSearchLanguageModal = false
  loadingLanguagePersons = false

  selectedSkill?: ISearch
  skillPersons: IPersonSkillResponse | null = null
  showSearchSkillModal = false
  loadingSkillPersons = false

  fromPersonSearch = false
  stepperAmount: undefined | number = undefined
  scrollPosition: undefined | number = undefined

  constructor() {
    makeObservable(this, {
      searchParams: observable,
      searchFilters: observable,
      searchItems: observable,
      isSearching: observable,
      fromPersonSearch: observable,
      stepperAmount: observable,
      scrollPosition: observable,
      loadingSearchItems: observable,
      CertEduLangBase: observable,
      projectPersonsSortColumn: observable,
      projectPersonsSortDirection: observable,
      projectPersons: observable,
      loadingProjectPersons: observable,
      showSearchProjectModal: observable,
      selectedProject: observable,
      selectedEducation: observable,
      educationPersons: observable,
      showSearchEducationModal: observable,
      loadingEducationPersons: observable,
      selectedCertificate: observable,
      certificatePersons: observable,
      showSearchCertificateModal: observable,
      loadingCertificatePersons: observable,
      selectedLanguage: observable,
      languagePersons: observable,
      showSearchLanguageModal: observable,
      loadingLanguagePersons: observable,
      selectedSkill: observable,
      skillPersons: observable,
      showSearchSkillModal: observable,
      loadingSkillPersons: observable,
      filteredSearchItems: computed,
      filteredProjectPersons: computed,
      setCertEduLangBase: action,
      setProjectPersonsSortColumn: action,
      toggleProjectPersonsSortDirection: action,
      toggleSearchFilter: action,
      resetSearchFilters: action,
      emptySearchFilters: action,
      setSearchParams: action,
      setIsSearching: action,
      setFromPersonSearch: action,
      setStepperAmount: action,
      setScrollPosition: action,
      setShowSearchSkillModal: action,
      setSelectedProject: action,
      setSelectedSkill: action,
      setSelectedCertificate: action,
      setShowSearchLanguageModal: action,
      setSelectedLanguage: action,
      setShowSearchCertificateModal: action,
      setSelectedEducation: action,
      setShowSearchEducationModal: action,
      setShowSearchProjectModal: action,
      getSkillPersons: action,
      getLanguagePersons: action,
      getCertificatePersons: action,
      getEducationPersons: action,
      getProjectPersons: action,
      getSearchItems: action,
    })
  }

  get filteredSearchItems() {
    return this.searchItems.filter((item) => item.name.toLowerCase().includes(this.searchParams.toLowerCase()))
  }

  get filteredProjectPersons() {
    let projectPersons = sortBy(this.projectPersons, (person) => {
      return sortByType(person, this.projectPersonsSortColumn)
    })
    if (this.projectPersonsSortDirection === 'descending') {
      projectPersons = projectPersons.reverse()
    }
    return projectPersons
  }

  setCertEduLangBase = (value: ICertEduLangBase) => {
    this.CertEduLangBase = value
  }

  setProjectPersonsSortColumn = (value: string) => {
    this.projectPersonsSortColumn = value
  }

  setFromPersonSearch = (value: boolean) => {
    this.fromPersonSearch = value
  }

  setStepperAmount = (value: undefined | number) => {
    this.stepperAmount = value
  }

  setScrollPosition = (value: undefined | number) => {
    this.scrollPosition = value
  }

  toggleProjectPersonsSortDirection = () => {
    this.projectPersonsSortDirection = this.projectPersonsSortDirection === 'ascending' ? 'descending' : 'ascending'
  }

  toggleSearchFilter = (field: string) => {
    set(this.searchFilters, field, !get(this.searchFilters, field))
  }

  resetSearchFilters = () => {
    this.searchFilters = mapValues(this.searchFilters, () => true)
  }
  emptySearchFilters = () => {
    this.searchFilters = mapValues(this.searchFilters, () => false)
  }

  setSearchParams = (value: string) => {
    this.searchParams = value
  }

  setIsSearching = (value: boolean) => {
    this.isSearching = value
  }

  setShowSearchSkillModal = (value: boolean) => {
    this.showSearchSkillModal = value
  }

  setSelectedProject = (value: ISearch) => {
    this.selectedProject = { ...value }
  }

  setSelectedSkill = (value: ISearch) => {
    this.selectedSkill = { ...value }
  }

  setSelectedCertificate = (value: ISearch) => {
    this.selectedCertificate = { ...value }
  }

  setShowSearchLanguageModal = (value: boolean) => {
    this.showSearchLanguageModal = value
  }

  setSelectedLanguage = (value: ISearch) => {
    this.selectedLanguage = { ...value }
  }

  setShowSearchCertificateModal = (value: boolean) => {
    this.showSearchCertificateModal = value
  }

  setSelectedEducation = (value: ISearch) => {
    this.selectedEducation = { ...value }
  }

  setShowSearchEducationModal = (value: boolean) => {
    this.showSearchEducationModal = value
  }

  setShowSearchProjectModal = (value: boolean) => {
    this.showSearchProjectModal = value
  }

  getSkillPersons = async (skillId: number) => {
    this.loadingSkillPersons = true
    try {
      const skillPersons: IPersonSkillResponse = await agent.Skills.getSkillPersons(skillId)
      runInAction(() => {
        skillPersons.skilled = orderBy(
          skillPersons.skilled,
          ['personSkill.skillLevel', (person) => person.personSkill.personName?.toLowerCase()],
          ['desc', 'asc'],
        )
        skillPersons.interested = orderBy(skillPersons.interested, ['personName'])
        this.skillPersons = skillPersons
        this.loadingSkillPersons = false
      })
    } catch (error) {
      runInAction(() => {
        this.loadingSkillPersons = false
      })
    }
    return Promise.resolve(this.skillPersons)
  }

  getLanguagePersons = async (languageId: number) => {
    this.loadingLanguagePersons = true
    try {
      const languagePersons: Array<ISearchLanguage> = await agent.Search.getLanguagePersonsWithPhoto(languageId)
      runInAction(() => {
        this.languagePersons = orderBy(languagePersons, (item) => item.personName?.toLowerCase())
        this.loadingLanguagePersons = false
      })
      return orderBy(languagePersons, (item) => item.personName?.toLowerCase())
    } catch (error) {
      runInAction(() => {
        this.loadingLanguagePersons = false
      })
    }
  }

  getCertificatePersons = async (certificateId: number) => {
    this.loadingCertificatePersons = true
    try {
      const certificatePersons: Array<IPersonCertificate> = await agent.Search.getCertificatePersonsWithPhoto(certificateId)
      runInAction(() => {
        this.certificatePersons = orderBy(certificatePersons, (item) => item.personName?.toLowerCase())
        this.loadingCertificatePersons = false
      })
      return certificatePersons
    } catch (error) {
      runInAction(() => {
        this.loadingCertificatePersons = false
      })
    }
  }

  getEducationPersons = async (educationId: number) => {
    this.loadingEducationPersons = true
    try {
      const educationPersons: Array<IPersonEducation> = await agent.Search.getEducationPersonsWithPhoto(educationId)
      runInAction(() => {
        this.educationPersons = orderBy(educationPersons, (item) => item.personName?.toLowerCase())
        this.loadingEducationPersons = false
      })
      return educationPersons
    } catch (error) {
      runInAction(() => {
        this.loadingEducationPersons = false
      })
    }
  }

  getProjectPersons = async (projectId: number) => {
    this.loadingProjectPersons = true
    try {
      const projectPersons = await agent.Search.getProjectPersons(projectId)
      runInAction(() => {
        this.projectPersons = projectPersons
        this.loadingProjectPersons = false
      })
    } catch (error) {
      runInAction(() => {
        this.loadingProjectPersons = false
      })
    }
  }

  getSearchItems = async () => {
    this.loadingSearchItems = true
    try {
      const searchResults: Array<ISearch> = await agent.Search.search()
      runInAction(() => {
        this.searchItems = orderBy(searchResults, (item) => item.name.toLowerCase())
        this.loadingSearchItems = false
      })
    } catch (error) {
      runInAction(() => {
        this.loadingSearchItems = false
      })
    }
  }
}
