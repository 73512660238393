import { Grid, Paper } from '@mui/material'
import { Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { getErrMsg, toastNeg, toastPos } from '../../common/toastHelper'
import { IEditedSkillInfo, INewSkill, INewSkillInit } from '../../interfaces/skills/ISkills'
import { useStore } from '../../Provider'
import { ExitConfirmation } from '../OPcomponents/ExitConfirmation'
import { OPCheckBox } from '../OPcomponents/OPCheckBox'
import { OPDivider } from '../OPcomponents/OPDivider'
import { OPMultiline } from '../OPcomponents/OPMultiline'
import { OPSkillbar } from '../OPcomponents/OPSkillbar'
import { OPSkillsButtons } from '../Profile/OPSkillsButtons'
import { newSkillSchema } from '../OPcomponents/ValidationSchemas'

const AddSkill = (): JSX.Element => {
  const { t } = useTranslation()
  const rootStore = useStore()
  const { validatedUser } = rootStore.loginStore
  const { showModalHandler, createNewSkill } = rootStore.skillStore

  const newSkillInit: INewSkillInit = {
    name: '',
    description: '',
    categoryId: 14,
    creatorId: validatedUser,
    personId: validatedUser,
    skillLevel: 0,
    interest: 0,
    comment: '',
    skillId: 0,
    addSkillToUser: false,
  }

  const handleSubmit = async (values: INewSkillInit) => {
    const newSkill: IEditedSkillInfo = {
      name: values.name,
      description: values.description,
      categoryId: values.categoryId,
      creatorId: values.creatorId,
    }
    const skillToUser: INewSkill = {
      personId: values.personId,
      skillId: values.skillId,
      skillLevel: values.skillLevel,
      interest: values.interest,
      comment: values.comment,
    }

    try {
      await createNewSkill(newSkill, skillToUser, values.addSkillToUser)
      toastPos(t('Toasts.SkillCreate'))
    } catch (error) {
      toastNeg(getErrMsg(error, t) || t('Toasts.SkillCreateError'))
    } finally {
      showModalHandler()
    }
  }

  return (
    <Paper className='profile--paper__skillpadding'>
      <Formik
        initialValues={newSkillInit}
        validateOnMount
        onSubmit={(values) => handleSubmit(values)}
        validationSchema={newSkillSchema}
        enableReinitialize
      >
        {({ isValid, values, setFieldValue, handleChange, isSubmitting, handleSubmit, resetForm, dirty }) => (
          <Grid container rowSpacing={2} columnSpacing={4}>
            <ExitConfirmation when={dirty} />
            <Grid item xs={12} lg={6}>
              <Grid item xs={12} className='projects--skillheader'>
                <span>{t('Skills.NewSkill')}</span>
              </Grid>
              <Grid item xs={12}>
                <OPMultiline
                  label={`${t('Skills.SkillName')}*`}
                  name='name'
                  value={values.name}
                  onChange={handleChange}
                  hasCounter
                  maxChars={100}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} lg={6}>
              <OPMultiline
                label={`${t('Skills.SkillOverview')}*`}
                maxChars={250}
                hasCounter
                minRows={5}
                value={values.description}
                name='description'
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} className='projects--checkboxmargin'>
              <OPCheckBox
                label={t('Skills.AddSkillToMe')}
                value={values.addSkillToUser}
                onChangeState={(e, val) => {
                  resetForm({ values: { ...values, comment: '', skillLevel: 0 } })
                  setFieldValue('addSkillToUser', val)
                }}
              />
            </Grid>
            {values.addSkillToUser && (
              <>
                <Grid item xs={12}>
                  <OPDivider />
                </Grid>
                <Grid item xs={12}>
                  <span className='projects--skillforme'>{t('Skills.SkillForMe')}</span>
                </Grid>
                <Grid item xs={12}>
                  <OPSkillbar
                    name='skillLevel'
                    value={values.skillLevel}
                    hasLabel
                    label={`${t('Skills.Level')}*`}
                    onChange={(e, val) => setFieldValue('skillLevel', val)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <OPMultiline
                    name='comment'
                    placeholder={`${t('Skills.FurtherInfo')}\u2026`}
                    label={t('Skills.FurtherInfo')}
                    hasCounter
                    maxChars={1000}
                    minRows={6}
                    value={values.comment}
                    onChange={handleChange}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <OPSkillsButtons
                noPadding
                disabled={!isValid || isSubmitting}
                bothDisabled={isSubmitting}
                onClose={showModalHandler}
                onSubmit={handleSubmit}
              />
            </Grid>
          </Grid>
        )}
      </Formik>
    </Paper>
  )
}
export default observer(AddSkill)
