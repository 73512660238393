import { Grid } from '@mui/material'
import { Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { getErrMsg, toastNeg, toastPos } from '../../../../common/toastHelper'
import { INewProject, IProjects } from '../../../../interfaces/projects/IProjects'
import { useStore } from '../../../../Provider'
import { ExitConfirmation } from '../../../OPcomponents/ExitConfirmation'
import { OPCheckBox } from '../../../OPcomponents/OPCheckBox'
import { OPDateField } from '../../../OPcomponents/OPDateField'
import { OPMultiline } from '../../../OPcomponents/OPMultiline'
import { OPNumberField } from '../../../OPcomponents/OPNumberField'
import { OPRadioButton } from '../../../OPcomponents/OPRadioButton'
import { OPSkillsCombobox } from '../../../OPcomponents/OPSkillsCombobox'
import { OPTextField } from '../../../OPcomponents/OPTextField'
import { newNetumProjectSchema } from '../../../OPcomponents/ValidationSchemas'
import { OPSkillsButtons } from '../../OPSkillsButtons'

export interface NewProjInterface {
  setCreatingNewProject: (val: boolean) => void
  setNewProject: (val: IProjects['project']) => void
}

const NewProject = (props: NewProjInterface): JSX.Element => {
  const { setCreatingNewProject, setNewProject } = props
  const { t } = useTranslation()
  const rootStore = useStore()
  const { validatedUser } = rootStore.loginStore
  const { showAddProjectModalHandler, newProjectName, showModalHandler } = rootStore.projectStore
  const { dropdownBranches, getAllBranches, getAllProjects, postProject, setCurrentProject } = rootStore.projectStore

  useEffect(() => {
    getAllBranches()
  }, [getAllBranches])

  const radioLabels = [
    { label: `${t('Projects.Public')}`, value: 'true' },
    { label: `${t('Projects.Private')}`, value: 'false' },
  ]

  const initValuesNetum: INewProject = {
    userId: validatedUser,
    name: newProjectName,
    branchId: -1,
    description: '',
    customer: '',
    startDate: undefined,
    endDate: undefined,
    contactPerson: '',
    worth: 0,
    totalWorkAmount: 0,
    canUseAsReference: true,
    public: false,
    isNetum: true,
  }

  const handleClose = () => {
    showAddProjectModalHandler(false)
    showModalHandler(false)
  }

  const onSubmit = async (values: INewProject) => {
    try {
      const projId = await postProject(values)
      const curProjects = await getAllProjects(validatedUser)
      const newProject = curProjects.find((project) => project.project.projectId === projId)?.project
      if (newProject) {
        setNewProject(newProject)
        setCreatingNewProject(false)
        toastPos(t('Toasts.ProjectCreate'))
      }
    } catch (err) {
      toastNeg(getErrMsg(err, t) || t('Toasts.ProjectCreateError'))
    }
  }

  return (
    <Formik initialValues={initValuesNetum} onSubmit={(values) => onSubmit(values)} validationSchema={newNetumProjectSchema}>
      {({ setFieldValue, values, handleChange, isSubmitting, isValid, handleSubmit, dirty }) => (
        <Grid container gap={4}>
          <ExitConfirmation when={dirty} />
          <Grid xs={12} item>
            <span className='myskill--font__titlefont sini'>{t('Projects.ProjectDescription')}</span>
          </Grid>
          <Grid xs={6} item>
            <OPMultiline
              label={`${t('Projects.ProjectName')}*`}
              value={values.name}
              name='name'
              onChange={handleChange}
              hasCounter
              maxChars={200}
            />
          </Grid>
          <Grid xs={12} item>
            <OPMultiline
              name='description'
              label={t('Projects.ProjectDescription')}
              onChange={handleChange}
              value={values.description}
              minRows={5}
              hasCounter
              maxChars={4000}
              placeholder={t('Projects.ProjectDescription')}
            />
          </Grid>
          <Grid xs={12} item>
            <span className='myskill--font__titlefont sini'>{t('Projects.AddNewCustomer')}</span>
          </Grid>
          <Grid container spacing={4}>
            <Grid md={6} sm={12} item>
              <OPTextField
                label={t('Projects.Customer')}
                value={values.customer}
                name='customer'
                onChange={handleChange}
                fullWidth
                maxLength={100}
              />
            </Grid>
            <Grid md={6} sm={12} item>
              <OPSkillsCombobox
                multiple={false}
                name='branchId'
                label={`${t('Projects.CustomerBranch')}*`}
                onChange={(e, v) => setFieldValue('branchId', v.key)}
                optionList={dropdownBranches}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <OPRadioButton
              name='public'
              radioOptions={radioLabels}
              label={''}
              defaultVal={values.public ? 'true' : 'false'}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <OPTextField
              label={t('Projects.ContactPerson')}
              name='contactPerson'
              value={values.contactPerson}
              fullWidth
              onChange={handleChange}
              maxLength={150}
            />
          </Grid>
          <Grid item xs={12}>
            <span className='myskill--font__titlefont sini'>{t('Projects.OtherInfo')}</span>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={3} sm={6}>
              <OPDateField
                label={`${t('Projects.ProjectStartDate')}*`}
                name='startDate'
                value={values.startDate}
                onChange={(newDate) => {
                  setFieldValue('startDate', newDate)
                }}
              />
            </Grid>
            <Grid item md={3} sm={6}>
              <OPDateField
                label={t('Projects.ProjectEndDate')}
                name='endDate'
                minDate={values.startDate}
                value={values.endDate}
                onChange={(newDate) => {
                  setFieldValue('endDate', newDate)
                }}
              />
            </Grid>
            <Grid item md={3} sm={6}>
              <OPNumberField
                label={t('Projects.Worth')}
                name='worth'
                value={values.worth}
                onChange={(val) => setFieldValue('worth', val ?? 0)}
              />
            </Grid>
            <Grid item md={3} sm={6}>
              <OPNumberField
                label={t('Projects.TotalWorkAmount')}
                name='totalWorkAmount'
                value={values.totalWorkAmount}
                onChange={(val) => setFieldValue('totalWorkAmount', val ?? 0)}
              />
            </Grid>
          </Grid>
          <Grid xs={12} item>
            <OPCheckBox
              name='canUseAsReference'
              label={t('Projects.NoReference')}
              value={!values.canUseAsReference ?? false}
              onChangeState={(e, c) => setFieldValue('canUseAsReference', !c)}
            />
          </Grid>
          <Grid xs={12} item>
            <OPSkillsButtons
              noPadding
              bothDisabled={isSubmitting}
              onClose={handleClose}
              disabled={!isValid || isSubmitting}
              onSubmit={handleSubmit}
            />
          </Grid>
        </Grid>
      )}
    </Formik>
  )
}

export default observer(NewProject)
