import { createTheme } from '@mui/material'
import { Shadows } from '@mui/material/styles/shadows'
import { fontTheme } from './Fonts'

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    primary: true
    primary_outlined: true
    secondary: true
    secondary_outlined: true
    error: true
    error_outlined: true
    interested: true
  }
}
declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true
    sm: true
    md: true
    cvwrap: true
    lg: true
    xl: true
  }
}

const breakpoints = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      cvwrap: 1500,
      lg: 1470,
      xl: 1920,
    },
  },
})

const theme = createTheme(fontTheme, {
  ...breakpoints,
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          fontFamily: '"Fira Sans", sans-serif',
          borderRadius: '8px',
          textTransform: 'capitalize',
          '&:disabled': {
            backgroundColor: '#D1D1D1',
            background: '#D1D1D1',
            color: '#757575',
            border: 'none',
          },
        },
        sizeSmall: {
          fontSize: '0.8125rem',
          height: '1.875rem',
        },
        sizeLarge: {
          fontSize: '0.9375rem',
          height: '2.5rem',
        },
      },
      variants: [
        {
          props: { variant: 'primary' },
          style: {
            background: 'linear-gradient(180deg, rgba(41, 126, 38, 0) 0%, rgba(41, 126, 38, 0.3) 100%), #3AA935',
            color: 'white',
            '&:hover': {
              background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #3AA935',
            },
            '&:active': {
              background: 'linear-gradient(180deg, rgba(41, 126, 38, 0) 0%, rgba(41, 126, 38, 0.3) 100%), #3AA935',
            },
          },
        },
        {
          props: { variant: 'interested' },
          style: {
            background: 'white',
            color: '#297E26',
            border: '1px solid #297E26',
            '&:hover': {
              color: '#297E26',
              border: '1px solid #297E26',
              background: 'white',
            },
            '&:active': {
              color: '#297E26',
              border: '1px solid #297E26',
              background: 'white',
            },
            '&.active': {
              '&:hover': {
                color: '#3AA935 !important',
                border: '1px solid #3AA935 !important',
              },
            },
          },
        },
        {
          props: { variant: 'primary_outlined' },
          style: {
            background: 'white',
            color: '#3AA935',
            border: '1px solid #3AA935',
            '&:hover': {
              color: 'white',
              border: 0,
              padding: '7px 17px',
              background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #3AA935',
            },
            '&:active': {
              color: 'white',
              border: 0,
              padding: '7px 17px',
              background: 'linear-gradient(180deg, rgba(41, 126, 38, 0) 0%, rgba(41, 126, 38, 0.3) 100%), #3AA935',
            },
          },
        },
        {
          props: { variant: 'secondary' },
          style: {
            background: 'linear-gradient(180deg, rgba(3, 73, 158, 0) 0%, rgba(3, 73, 158, 0.5) 100%), #0460D1',
            color: 'white',
            '&:hover': {
              background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #0460D1',
            },
            '&:active': {
              background: 'linear-gradient(180deg, rgba(3, 73, 158, 0) 0%, rgba(3, 73, 158, 0.5) 100%), #0460D1',
            },
          },
        },
        {
          props: { variant: 'secondary_outlined' },
          style: {
            background: 'white',
            color: '#03499E',
            border: '1px solid #03499E',
            '&:hover': {
              color: 'white',
              border: 0,
              padding: '7px 17px',
              background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #0460D1',
            },
            '&:active': {
              color: 'white',
              border: 0,
              padding: '7px 17px',
              background: 'linear-gradient(180deg, rgba(3, 73, 158, 0) 0%, rgba(3, 73, 158, 0.5) 100%), #0460D1',
            },
          },
        },
        {
          props: { variant: 'error' },
          style: {
            background: 'linear-gradient(180deg, rgba(209, 72, 90, 0) 0%, rgba(209, 72, 90, 0.5) 100%), #FF586E',
            color: 'white',
            '&:hover': {
              background:
                'linear-gradient(180deg, rgba(255, 88, 110, 0) 0%, #FF586E 100%), linear-gradient(180deg, rgba(255, 242, 244, 0.15) 0%, rgba(255, 242, 244, 0) 100%), #FF586E',
            },
            '&:active': {
              background: '#FF586E',
            },
          },
        },
        {
          props: { variant: 'error_outlined' },
          style: {
            background: 'white',
            color: '#D1485A',
            border: '1px solid #D1485A',
            '&:hover': {
              color: 'white',
              border: 0,
              padding: '7px 17px',
              background:
                'linear-gradient(180deg, rgba(255, 88, 110, 0) 0%, #FF586E 100%), linear-gradient(180deg, rgba(255, 242, 244, 0.15) 0%, rgba(255, 242, 244, 0) 100%), #FF586E',
            },
            '&:active': {
              color: 'white',
              border: 0,
              padding: '7px 17px',
              background: '#FF586E',
            },
          },
        },
      ],
    },
  },
  shadows: Array(25).fill('none') as Shadows,
})

export { theme }
