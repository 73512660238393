import { Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IPersonCertificate } from '../../../../interfaces/certAndEducation/certAndEducation'

const ProfileCert = (props: { data: IPersonCertificate }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const { data } = props

  return (
    <Grid container rowGap={4} className='gridcontainer--formcontainer__lastitempaddings'>
      <Grid xs={12} item container rowGap={2}>
        <Grid item xs={12}>
          <span className='myskill--font__titlefont sini'>{t('Persons.CertInfo')}</span>
        </Grid>
        <Grid xs={12} item container maxHeight={'2.25rem'}>
          <Grid item xs={6} container rowGap={0.5} maxHeight={'2.25rem'}>
            <Grid item xs={12}>
              <span className='myskill--font__multilinelabel'>{t('CertAndEducation.CertificateName')}</span>
            </Grid>
            <Grid item xs={12}>
              <span className='myskill--font__multilinetext'>{data?.name ? data?.name : t('Persons.NotDefined')}</span>
            </Grid>
          </Grid>
          <Grid item xs={6} container rowGap={0.5}>
            <Grid item xs={12}>
              <span className='myskill--font__multilinelabel'>{t('CertAndEducation.FurtherInfo')}</span>
            </Grid>
            <Grid item xs={12}>
              <span className='myskill--font__multilinetext'>{data?.comment ? data?.comment : t('Persons.NotDefined')}</span>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12} item container rowGap={0.5}>
        <Grid item xs={12}>
          <span className='myskill--font__multilinelabel'>{t('CertAndEducation.CertGranter')}</span>
        </Grid>
        <Grid item xs={12}>
          <span className='myskill--font__multilinetext'>{data?.school ? data?.school : t('Persons.NotDefined')}</span>
        </Grid>
      </Grid>
      <Grid xs={12} item container columnGap={4} wrap='nowrap'>
        <Grid item container rowGap={0.5} whiteSpace='nowrap' maxWidth={'4.375rem'}>
          <Grid item xs={12}>
            <span className='myskill--font__multilinelabel'>{t('CertAndEducation.CertificateGrantDate')}</span>
          </Grid>
          <Grid item xs={12}>
            <span className='myskill--font__multilinetext'>
              {data?.startDate ? moment(data?.startDate).locale(language).format('L').toString() : t('Persons.NotDefined')}
            </span>
          </Grid>
        </Grid>
        <Grid item container rowGap={0.5} whiteSpace='nowrap' maxWidth={'4.375rem'}>
          <Grid item xs={12}>
            <span className='myskill--font__multilinelabel'>{t('CertAndEducation.CertificateExpirationDate')}</span>
          </Grid>
          <Grid item xs={12}>
            <span className='myskill--font__multilinetext'>
              {data?.expirationDate ? moment(data?.expirationDate).locale(language).format('L').toString() : t('Persons.NotDefined')}
            </span>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default observer(ProfileCert)
