import { Avatar, Chip, Grid } from '@mui/material'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import history from '../../../history'
import { IUserLanguage } from '../../../interfaces/language/ILanguage'
import { useStore } from '../../../Provider'
import { OPLoadingCircle } from '../../OPcomponents/OPLoadingcircle'
import '../../styles/grid.scss'
import '../profile.scss'

const Overview = (): JSX.Element => {
  const { t } = useTranslation()

  const rootStore = useStore()
  const { userProfileImage, validatedUser } = rootStore.loginStore
  const { sidebarUser } = rootStore.sidebarStore
  const { userObject, getUserOverview, loadingOverview } = rootStore.personStore

  useEffect(() => {
    if (isEmpty(userObject)) getUserOverview(validatedUser)
  }, [])

  return (
    <>
      {!loadingOverview ? (
        <Grid container rowGap={2}>
          <Grid item xs={12} className='profile--pointer' onClick={() => history.push('/profile/overview')}>
            <p data-testid='title' className='profile--titlefont'>
              {sidebarUser.name}
            </p>
          </Grid>
          <Grid container gap={5} data-testid='overviewId'>
            <Grid gap={5} container className='profile--pointer' onClick={() => history.push('/profile/overview')}>
              <Grid item xs={12}>
                <Grid container justifyContent={'space-between'} className='griditem--negativemargin' wrap='nowrap'>
                  <Grid item xs={8} className='profile--grid__titlepadding'>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <p className='profile--cert__headertext'>{t('Profile.ProfileTitle')}</p>
                      </Grid>
                      <Grid item xs={12}>
                        <p className='profile--deptext titleoverflow'>
                          {userObject?.title && userObject?.title !== '' ? userObject.title : t('Profile.NoAddedTitle')}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Avatar className='profile--grid__profimage' src={`data:image/jpeg;base64,${userProfileImage}`} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} className='profile--grid__summary'>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <p className='profile--cert__headertext'>{t('Profile.ProfileSummary')}</p>
                  </Grid>
                  <Grid item xs={12}>
                    <p className='profile--deptext summaryoverflow'>
                      {userObject?.summary && userObject?.summary !== '' ? userObject.summary?.slice(0, 400) : t('Profile.NoAddedSummary')}
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className='profile--grid__languages'>
              <Grid item>
                <div className='profile--cert__headertext lang'>{t('Language.Header')}</div>
              </Grid>
              <Grid container spacing={1} paddingLeft={'0.5rem'}>
                {userObject.languages?.slice(0, 10).map((lang: IUserLanguage) => (
                  <Grid item key={lang.languageId}>
                    <Chip
                      onClick={() => history.push('/profile/overview', lang.languageName)}
                      data-testid={lang.languageName}
                      size='small'
                      variant='outlined'
                      key={lang.languageId}
                      label={lang.languageName}
                      className='profile--langchip__chipstyling'
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <OPLoadingCircle />
      )}
    </>
  )
}
export default observer(Overview)
