import { isEmpty, sortBy } from 'lodash'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import agent from '../../api/agent'
import { sortByType } from '../../common/sortHelper'
import { IProjectListPerson } from '../../interfaces/persons/IPerson'
import {
  IBranch,
  IEmployer,
  ILinkedSkill,
  INewEmployer,
  INewProject,
  IOtherEmployerDetails,
  IOtherEmployerDetailsForm,
  IOwnedProjectDetails,
  IProjectPersonDetails,
  IProjects,
  IRole,
} from '../../interfaces/projects/IProjects'
import { ISortDirection } from '../../interfaces/Sort'

export default class ProjectStore {
  sortColumn = 'name'
  sortDirection: ISortDirection = 'ascending'

  /**
   * * Loader observables
   */

  loadingInitial = true
  loadingSearch = false
  loadingProjects = false
  loadingPersonProjects = false
  loadingRecentProjects = false
  loadingProjectPersonDetails = false
  loadingEmployerDetails = false

  /**
   * * Project & Role observables
   */
  newProjectName = ''
  newEmployerName = ''
  recentProjects: any[] = []
  projects: IProjects[] = []
  allRoles: IRole[] = []
  allEmployers: IEmployer[] = []
  allBranches: IBranch[] = []
  currentProject: any = {}
  currentProjectPersons: IProjectListPerson[] = []
  selectedEmployer = -1

  /**
   * * Modal and do/dont observables
   */

  showModal = false
  isSearching = false
  searchValue = ''
  isUsingManDays: string | number | undefined = undefined
  addNewProjectToSkill = false
  editingOld = false
  editingOldPrivate = false
  addingNew = false
  isDeleteable = false

  /**
   * * Add project
   */

  showAddPrivateProjectModal = false
  showAddProjectModal = false
  addPublicProject = false
  addEmployer = false
  addProjectStep = 0
  deleting = false
  savingProject = false

  /**
   * * User's project details modal and show/hide observables
   */

  showUserProjectInfoModal = false
  addUserProject = false
  showAddSkillToProjectModal = false
  showUserProjectModal = false
  showUserOwnedProjectModal = false
  userProjectDetails: IProjectPersonDetails = {} as IProjectPersonDetails
  userOwnedProjectDetails: IOwnedProjectDetails = {} as IOwnedProjectDetails
  // User's other employer details modal
  showUserOtherEmployerInfoModal = false
  showUserOtherEmployerEditModal = false
  allOtherEmployerDetails: IOtherEmployerDetails[] = []
  currentOtherEmployer: IEmployer = {} as IEmployer
  currentEmployerDetails: IOtherEmployerDetails = {} as IOtherEmployerDetails

  constructor() {
    makeObservable(this, {
      sortColumn: observable,
      sortDirection: observable,
      loadingInitial: observable,
      loadingSearch: observable,
      loadingProjects: observable,
      loadingPersonProjects: observable,
      loadingEmployerDetails: observable,
      loadingRecentProjects: observable,
      loadingProjectPersonDetails: observable,
      recentProjects: observable,
      projects: observable,
      allRoles: observable,
      allEmployers: observable,
      allBranches: observable,
      newProjectName: observable,
      newEmployerName: observable,
      currentProject: observable,
      currentProjectPersons: observable,
      selectedEmployer: observable,
      showModal: observable,
      isSearching: observable,
      searchValue: observable,
      isUsingManDays: observable,
      addNewProjectToSkill: observable,
      editingOld: observable,
      editingOldPrivate: observable,
      addingNew: observable,
      isDeleteable: observable,
      showAddPrivateProjectModal: observable,
      showAddProjectModal: observable,
      addPublicProject: observable,
      addEmployer: observable,
      addProjectStep: observable,
      deleting: observable,
      savingProject: observable,
      showUserProjectInfoModal: observable,
      addUserProject: observable,
      showAddSkillToProjectModal: observable,
      showUserProjectModal: observable,
      showUserOwnedProjectModal: observable,
      userProjectDetails: observable,
      userOwnedProjectDetails: observable,
      showUserOtherEmployerInfoModal: observable,
      showUserOtherEmployerEditModal: observable,
      allOtherEmployerDetails: observable,
      currentOtherEmployer: observable,
      currentEmployerDetails: observable,
      projectLinkedSkills: computed,
      publicDropdownProjects: computed,
      dropdownProjects: computed,
      dropdownBranches: computed,
      dropdownRoles: computed,
      dropdownEmployers: computed,
      filteredProjects: computed,
      approvedFilteredProjects: computed,
      getFilteredProjectsBytext: action,
      setEmployers: action,
      setBranches: action,
      setRoles: action,
      setShowUserProjectInfoModal: action,
      setShowUserOtherEmployerInfoModal: action,
      setUserProjectDetails: action,
      removeLinkedSkillProject: action,
      addLinkedSkillToProject: action,
      getUserProjectDetails: action,
      clearAddProject: action,
      clearUserProjectDetails: action,
      setAddNewProjectToSkill: action,
      setIsUsingManDays: action,
      setDeleting: action,
      setIsSearching: action,
      setSearchValue: action,
      setSavingProject: action,
      setAddEmployer: action,
      setEditingOld: action,
      setEditingOldPrivate: action,
      setAddingNew: action,
      setIsDeleteable: action,
      setShowUserOtherEmployerEditModal: action,
      setAddPublicProject: action,
      setAddProjectStep: action,
      showModalHandler: action,
      setShowAddSkillToProjectModal: action,
      showEditUserProjectModalHandler: action,
      showEditUserOwnedProjectModalHandler: action,
      showAddProjectModalHandler: action,
      showAddPrivateProjectModalHandler: action,
      setNewProjectName: action,
      setNewEmployerName: action,
      setCurrentProject: action,
      setCurrentEmployerDetails: action,
      setSortColumn: action,
      toggleSortDirection: action,
      getAllBranches: action,
      getAllEmployers: action,
      getAllEmployerDetails: action,
      getAllRoles: action,
      getAllProjects: action,
      getRecentProjects: action,
      deleteUserProject: action,
      postEmployer: action,
      editEmployer: action,
      postProject: action,
      editUserProject: action,
      editUserOwnedProject: action,
      postUserProject: action,
      getUserProjectTitle: action,
      getDetailedUserProjects: action,
      getOwnedProjectDetails: action,
      setSelectedEmployer: action,
    })
  }

  /**
   * * Computed
   */

  get projectLinkedSkills() {
    return this.userProjectDetails?.linkedSkills
      ?.map((skill) => ({
        key: skill.skillId,
        value: skill.skillId,
        text: skill.skillName,
      }))
      .sort((a, b) => a.text.localeCompare(b.text))
  }

  get publicDropdownProjects() {
    return this.projects
      .filter((proj) => proj.project.projectOwner === 0)
      .map((project) => ({
        key: project.project.projectId,
        value: project.project.projectId,
        text: project.project.name + (project.project.customer ? ` (${project.project.customer})` : ''),
      }))
      .sort((a, b) => a.text.localeCompare(b.text))
  }

  get dropdownProjects() {
    return this.projects
      .map((project) => ({
        key: project.project.projectId,
        value: project.project.projectId,
        text: project.project.name + (project.project.customer ? ` (${project.project.customer})` : ''),
      }))
      .sort((a, b) => a.text.localeCompare(b.text))
  }

  get dropdownBranches() {
    return this.allBranches
      .map((branch) => ({
        key: branch.branchId,
        value: branch.branchId,
        text: branch.definition,
      }))
      .sort((a, b) => a.text.localeCompare(b.text))
  }

  get dropdownRoles() {
    return this.allRoles
      .map((role) => ({
        key: role.roleId,
        value: role.roleId,
        text: role.roleName,
      }))
      .sort((a, b) => a.text.localeCompare(b.text))
  }

  get dropdownEmployers() {
    return this.allEmployers
      .map((employer) => ({
        key: employer.employerId,
        value: employer.employerId,
        text: employer.name,
      }))
      .sort((a, b) => a.text.localeCompare(b.text))
  }

  get filteredProjects() {
    let allProjects = sortBy(this.projects, (project) => {
      return sortByType(project.project, this.sortColumn)
    })
    if (this.sortDirection === 'descending') {
      allProjects = allProjects.reverse()
    }
    const params = this.searchValue
    if (isEmpty(params)) {
      return allProjects
    }
    return allProjects.filter(
      (project: IProjects) =>
        project.project.name?.toLowerCase().includes(params.toLowerCase()) ||
        project.project.description?.toLowerCase().includes(params.toLowerCase()) ||
        project.project.customer?.toLowerCase().includes(params.toLowerCase()) ||
        project.project.startDate?.toString()?.toLowerCase().includes(params.toLowerCase()) ||
        project.project.endDate?.toString().toLowerCase().includes(params.toLowerCase()),
    )
  }

  get approvedFilteredProjects() {
    const curProjects = this.filteredProjects
    return curProjects.filter((project: IProjects) => project.project.approved)
  }

  /**
   * * Actions
   */

  getFilteredProjectsBytext = (text: string) => {
    return this.filteredProjects.filter(
      (project: IProjects) =>
        project.project.name?.toLowerCase().includes(text.toLowerCase()) ||
        project.project.description?.toLowerCase().includes(text.toLowerCase()) ||
        project.project.customer?.toLowerCase().includes(text.toLowerCase()) ||
        project.project.startDate?.toString()?.toLowerCase().includes(text.toLowerCase()) ||
        project.project.endDate?.toString().toLowerCase().includes(text.toLowerCase()),
    )
  }

  setEmployers = (employers: Array<IEmployer>) => {
    this.allEmployers = employers
  }

  setBranches = (branches: Array<IBranch>) => {
    this.allBranches = branches
  }

  setRoles = (roles: any) => {
    this.allRoles = roles
  }

  setShowUserProjectInfoModal = (value: boolean) => {
    this.showUserProjectInfoModal = value
  }

  setShowUserOtherEmployerInfoModal = (value: boolean) => {
    this.showUserOtherEmployerInfoModal = value
  }

  setUserProjectDetails = (userProjectDetails: any) => {
    this.userProjectDetails = { ...userProjectDetails }
  }

  removeLinkedSkillProject = (skillId: number) => {
    if (this.userProjectDetails.linkedSkills != null) {
      this.userProjectDetails.linkedSkills = this.userProjectDetails.linkedSkills.filter((linkedSkill) => linkedSkill.skillId !== skillId)
    }
  }

  addLinkedSkillToProject = (linkedSkill: ILinkedSkill) => {
    const foundSkill = this.userProjectDetails.linkedSkills.find((skill) => skill.skillId === linkedSkill.skillId)
    if (foundSkill === undefined) {
      this.userProjectDetails.linkedSkills = [...this.userProjectDetails.linkedSkills, linkedSkill]
    }
  }

  getUserProjectDetails = () => {
    if (this.userProjectDetails.roles == null) {
      this.userProjectDetails.roles = []
    }
    return this.userProjectDetails
  }

  clearAddProject = () => {
    this.addProjectStep = 0
  }

  clearUserProjectDetails = () => {
    this.userProjectDetails = {} as IProjectPersonDetails
  }

  setAddNewProjectToSkill = (value: boolean) => {
    this.addNewProjectToSkill = value
  }

  setIsUsingManDays = (value: string | number | undefined) => {
    this.isUsingManDays = value
  }

  setDeleting = (value: boolean) => {
    this.deleting = value
  }

  setIsSearching = (isItSearching: boolean) => {
    this.isSearching = isItSearching
  }

  setSearchValue = (value: string) => {
    this.searchValue = value
  }

  setSavingProject = (value: boolean) => {
    this.savingProject = value
  }

  setAddEmployer = (value: boolean) => {
    this.addEmployer = value
  }

  setEditingOld = (value: boolean) => {
    this.editingOld = value
  }
  setEditingOldPrivate = (value: boolean) => {
    this.editingOldPrivate = value
  }

  setAddingNew = (value: boolean) => {
    this.addingNew = value
  }

  setIsDeleteable = (value: boolean) => {
    this.isDeleteable = value
  }

  setShowUserOtherEmployerEditModal = (value: boolean) => {
    this.showUserOtherEmployerEditModal = value
  }

  setAddPublicProject = () => {
    this.addPublicProject = !this.addPublicProject
  }

  setAddProjectStep = () => {
    this.addProjectStep += 1
  }

  showModalHandler = (val = !this.showModal) => {
    this.showModal = val
  }

  setShowAddSkillToProjectModal = () => {
    this.showAddSkillToProjectModal = !this.showAddSkillToProjectModal
  }

  showEditUserProjectModalHandler = (value: boolean) => {
    this.showUserProjectModal = value
  }

  showEditUserOwnedProjectModalHandler = (value: boolean) => {
    this.showUserOwnedProjectModal = value
  }

  showAddProjectModalHandler = (value = !this.showAddProjectModal) => {
    this.showAddProjectModal = value
  }
  showAddPrivateProjectModalHandler = (value = !this.showAddProjectModal) => {
    this.showAddPrivateProjectModal = value
  }

  setNewProjectName = (newName: string) => {
    this.newProjectName = newName
  }

  setNewEmployerName = (newName: string) => {
    this.newEmployerName = newName
  }

  setCurrentProject = (cProject: {} | undefined) => {
    this.currentProject = cProject
  }

  setCurrentEmployerDetails = (employerDetails: IOtherEmployerDetails) => {
    this.currentEmployerDetails = employerDetails
  }

  setSortColumn = (value: string) => {
    this.sortColumn = value
  }

  toggleSortDirection = () => {
    this.sortDirection = this.sortDirection === 'ascending' ? 'descending' : 'ascending'
  }

  getAllBranches = async () => {
    try {
      const fetchedBranches: IBranch[] = await agent.Projects.getAllBranches()
      runInAction(() => {
        this.allBranches = fetchedBranches
      })
    } catch (error) {
      return Promise.reject(error)
    }
    return Promise.resolve()
  }

  getAllEmployers = async (userId: number) => {
    try {
      const fetchedEmployers: IEmployer[] = await agent.Projects.getAllEmployers(userId)
      runInAction(() => {
        this.allEmployers = fetchedEmployers
      })
    } catch (error) {
      return Promise.reject(error)
    }
    return Promise.resolve()
  }

  getAllEmployerDetails = async (userId: number, useLoading = true) => {
    try {
      if (useLoading) {
        this.loadingEmployerDetails = true
      }
      const employerDetails: IOtherEmployerDetails[] = await agent.Projects.getAllEmployerDetails(userId)
      runInAction(() => {
        this.allOtherEmployerDetails = employerDetails
      })
    } catch (error) {
      return Promise.reject(error)
    }
    runInAction(() => {
      if (useLoading) {
        this.loadingEmployerDetails = false
      }
    })
    return Promise.resolve()
  }

  getAllRoles = async () => {
    try {
      const fetchedRoles = await agent.Projects.getAllRoles()
      runInAction(() => {
        this.allRoles = fetchedRoles
      })
    } catch (error) {
      return Promise.reject(error)
    }
    return Promise.resolve()
  }

  getAllProjects = async (userId: number, useLoading = true) => {
    if (useLoading) {
      this.loadingInitial = true
    }
    try {
      const fetchedProjects: IProjects[] = await agent.Projects.getAllProjects(userId)
      runInAction(async () => {
        this.projects = fetchedProjects
        if (useLoading) {
          this.loadingInitial = false
        }
      })
      return fetchedProjects
    } catch (error) {
      runInAction(() => {
        if (useLoading) {
          this.loadingInitial = false
        }
      })
      return Promise.reject(error)
    }
  }

  getRecentProjects = async () => {
    this.loadingRecentProjects = true
    try {
      const fetchedProjects: IProjects[] = await agent.HomePage.getRecentProjects()
      runInAction(() => {
        this.recentProjects = fetchedProjects
        this.loadingRecentProjects = false
      })
    } catch (error) {
      runInAction(() => {
        this.loadingRecentProjects = false
      })
    }
  }

  deleteUserProject = async (userId: number, projectId: number, isLink = false) => {
    try {
      await agent.Projects.deleteUserProject(userId, projectId)
      runInAction(async () => {
        if (!isLink) await this.getAllProjects(userId)
      })
    } catch (error) {
      console.log(error)
    }
  }

  postEmployer = async (employer: INewEmployer) => {
    try {
      const posted = await agent.Projects.postEmployer(employer)
      const createdEmployer: IEmployer = {
        employerId: posted,
        name: employer.name,
        jobTitle: employer.jobTitle,
        jobDescription: employer.jobDescription,
      }
      runInAction(() => {
        this.allEmployers.push(createdEmployer)
      })
      return posted
    } catch (error) {
      return Promise.reject(error)
    }
  }

  editEmployer = async (employer: IOtherEmployerDetailsForm) => {
    try {
      const message: string = await agent.Projects.editEmployerDetails(employer)
      return message
    } catch (error) {
      return Promise.reject(error)
    }
  }

  postProject = async (project: INewProject) => {
    try {
      const posted = await agent.Projects.postProject(project)
      return posted
    } catch (error) {
      return Promise.reject(error)
    }
  }

  editUserProject = async (project: any, isLink = false) => {
    try {
      await agent.Projects.editUserProject(project)
      runInAction(async () => {
        if (!isLink) await this.getAllProjects(project.userId)
      })
    } catch (error) {
      return Promise.reject(error)
    }
    return Promise.resolve()
  }

  editUserOwnedProject = async (project: any) => {
    try {
      await agent.Projects.editUserOwnedProject(project)
    } catch (error) {
      return Promise.reject(error)
    }
    return Promise.resolve()
  }

  postUserProject = async (project: any, isLink = false) => {
    try {
      await agent.Projects.postUserProject(project)
      runInAction(async () => {
        if (!isLink) await this.getAllProjects(project.userId)
      })
    } catch (error) {
      console.log(error)
    }
  }

  getUserProjectTitle = async (projectId: number) => {
    this.loadingProjectPersonDetails = true
    try {
      const projUsers = await agent.Projects.getAllProjectUsers(projectId)
      const curProjectUsers: IProjectListPerson[] = []
      for (const user of projUsers) {
        const curUser = await agent.Projects.getDetailedUserProjects(user.personId, projectId)
        curProjectUsers.push({
          id: user.personId,
          name: user.personName,
          roles: curUser.roles !== undefined ? curUser.roles : [],
          title: curUser.roles[0] !== undefined ? curUser.roles[0].roleName : '',
        })
      }
      runInAction(() => {
        this.currentProjectPersons = curProjectUsers
        this.loadingProjectPersonDetails = false
      })
      return curProjectUsers
    } catch (error) {
      Promise.reject(error)
    }
  }

  getDetailedUserProjects = async (userId: number, projectId: number) => {
    this.loadingProjectPersonDetails = true
    this.addUserProject = false
    try {
      const projectDetails: IProjectPersonDetails = await agent.Projects.getDetailedUserProjects(userId, projectId)
      runInAction(() => {
        this.userProjectDetails = projectDetails
        this.loadingProjectPersonDetails = false
      })
      // returns whether project is user or Netum-owned
    } catch (error) {
      runInAction(() => {
        this.clearUserProjectDetails()
        this.userProjectDetails.roles = []
        this.addUserProject = true
        this.loadingProjectPersonDetails = false
      })
    }
    return this.userProjectDetails
  }

  getOwnedProjectDetails = () => {
    runInAction(() => {
      this.userOwnedProjectDetails = {
        projectName: this.currentProject.name,
        projectDescription: this.currentProject.description,
        employerId: this.currentProject.employerId,
        customer: this.currentProject.customer,
        branchId: this.currentProject.branchId,
        public: this.currentProject.public,
        canUseAsReference: this.currentProject.canUseAsReference,
        startDate: this.userProjectDetails.startDate,
        endDate: this.userProjectDetails.endDate,
        quota: this.userProjectDetails.quota,
        manDays: this.userProjectDetails.manDays,
        usingManDays: this.userProjectDetails.usingManDays,
        description: this.userProjectDetails.description,
        roles: this.userProjectDetails.roles,
      } as IOwnedProjectDetails
    })
  }

  setSelectedEmployer = (employerId: number) => {
    runInAction(() => {
      this.selectedEmployer = employerId
    })
  }
}
