import { Avatar, Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getRole } from '../../common/roleHelper'
import history from '../../history'
import { IListPerson, IListPersonWithSkills } from '../../interfaces/persons/IPerson'
import { useStore } from '../../Provider'
import { scrollSaver } from '../common/scrollHelper'
import { OPLoadMore } from '../OPcomponents/OPLoadMore'

import { Data, OPNewTable } from '../OPcomponents/OPNewTable'

const PersonsTable = (): JSX.Element => {
  const { t } = useTranslation()
  const rootStore = useStore()
  const { filteredPersons } = rootStore.personStore
  const { resetPersonProfileOverview } = rootStore.profileStore
  const { stepperAmount } = rootStore.seachStore
  const [tableRows, setTableRows] = useState<Data[]>([])
  const [tableLength, setTableLength] = useState(10)

  const onRowClick = (id: number | string) => {
    scrollSaver(tableLength, rootStore)
    resetPersonProfileOverview()
    history.push('/persons/' + id)
  }

  useEffect(() => {
    const newRows: Data[] = []
    filteredPersons.forEach((person: IListPersonWithSkills) => {
      newRows.push({
        ['id']: person.personId ?? -1,
        rows: {
          [t('Persons.Name')]: {
            text: (
              <Grid key={person.name} container alignItems='center' columnSpacing={2}>
                <Grid item>
                  <Avatar src={`data:image/jpeg;base64,${person.photo}`}></Avatar>
                </Grid>
                <Grid item>{person.name}</Grid>
              </Grid>
            ),
          },
          [t('Persons.Role')]: { text: getRole(person as IListPerson) ?? '' },
          [t('Persons.Skills')]: { text: person.topSkills?.join(', ') ?? '' },
        },
      })
      setTableRows(newRows)
    })
  }, [t, filteredPersons])

  return filteredPersons.length ? (
    <>
      <OPNewTable rows={tableRows} curLength={tableLength} onRowClick={onRowClick} />
      <OPLoadMore
        startingStepper={stepperAmount}
        stepper={10}
        curLength={tableLength}
        maxLength={filteredPersons.length}
        filteredArray={filteredPersons}
        onLoadMore={(data) => setTableLength(data)}
      />
    </>
  ) : (
    <span className='profile--notfound'>{t('NotFound.NoResults')}</span>
  )
}

export default observer(PersonsTable)
