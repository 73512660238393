import { Grid } from '@mui/material'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import React, { MutableRefObject, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import history from '../../../history'
import { IClickedNeededSkill } from '../../../interfaces/skills/ISkills'
import { useStore } from '../../../Provider'
import { OPLoadingCircle } from '../../OPcomponents/OPLoadingcircle'
import { OPSnackbar } from '../../OPcomponents/OPSnackbar'

import NeededSkillsTable from './NeededSkillsTable'

const OPAllNeededSkills = () => {
  const { t } = useTranslation()
  const rootStore = useStore()
  const { validatedUser } = rootStore.loginStore
  const { getAllDepSkills, loadingAllNeededSkills, sortedNeededSkills } = rootStore.skillStore

  const profileClicked = history.location.state as IClickedNeededSkill

  const clickable: MutableRefObject<HTMLDivElement | null> = useRef(null)
  useEffect(() => {
    if (validatedUser !== 0) {
      getAllDepSkills(validatedUser)
    }
  }, [getAllDepSkills, validatedUser])

  return (
    <Grid container rowSpacing={4}>
      <Grid item xs={12}>
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <span className='profile--titlebigfont'>{t('AllDeparmentsCompetenceNeeds.Box1')}</span>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <OPSnackbar text1={t('Skills.AllNeededSkillsDesc')} />
      </Grid>
      {loadingAllNeededSkills && (
        <Grid item xs={12}>
          <OPLoadingCircle />
        </Grid>
      )}
      {sortedNeededSkills.map((depSkill, index) => (
        <Grid item xs={12} key={_.uniqueId('needed-')}>
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <span className='profile--titlegrayfont'>{depSkill.department}</span>
            </Grid>
            <Grid item xs={12}>
              {depSkill.skills.length > 0 ? (
                <NeededSkillsTable
                  rowId={depSkill.department === profileClicked?.userDepartment ? profileClicked?.rowId : undefined}
                  data={depSkill.skills}
                  clickable={clickable}
                />
              ) : (
                <p className='profile--projecttext'>{t('Skills.NoSkillsWithDepTag')}</p>
              )}
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}

export default observer(OPAllNeededSkills)
