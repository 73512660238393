import { Avatar, Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../../Provider'
import { OPLoadingCircle } from '../../OPcomponents/OPLoadingcircle'
import { Data, OPNewTable } from '../../OPcomponents/OPNewTable'

const ProfileOverview = (): JSX.Element => {
  const { t } = useTranslation()
  const rootStore = useStore()
  const { personProfileOverview, loadingOverview } = rootStore.profileStore

  const rows: Data[] = []

  personProfileOverview.languages?.forEach((language) =>
    rows.push({
      id: language.languageId,
      rows: {
        [t('Language.Language')]: { text: language.languageName, width: '22%' },
        [t('Language.SkillLevel')]: { text: language.languageLevel, width: '17%' },
        [t('Language.Description')]: { text: language.description, width: '61%' },
      },
    }),
  )

  return (
    <Grid container spacing={5} className='gridcontainer--formcontainer__addnewpaddings'>
      {loadingOverview ? (
        <Grid item xs={12}>
          <OPLoadingCircle />
        </Grid>
      ) : (
        <>
          <Grid item xs={12}>
            <Grid container justifyContent={'space-between'} className='griditem--negativemargin'>
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <p className='profile--titlecaption profpadding'>{t('Profile.ProfileTitle')}:</p>
                  </Grid>
                  <Grid item xs={12}>
                    <p className='profile--deptext profpadding'>
                      {personProfileOverview?.title !== '' ? personProfileOverview.title : t('Profile.NoAddedTitle')}
                    </p>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Avatar className='profile--grid__profimage' src={`data:image/jpeg;base64,${personProfileOverview.profilePhoto}`} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <p className='profile--titlecaption profpadding'>{t('Profile.ProfileSummary')}:</p>
              </Grid>
              <Grid item xs={12}>
                <p className='profile--deptext profpadding'>
                  {personProfileOverview?.summary !== '' ? personProfileOverview.summary : t('Profile.NoAddedSummary')}
                </p>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <p className='profile--titlecaption profpadding'>{t('Language.Header')}:</p>
              </Grid>
              <Grid item xs={12}>
                {personProfileOverview.languages && personProfileOverview?.languages?.length > 0 ? (
                  <OPNewTable rows={rows} curLength={rows.length} />
                ) : (
                  <p className='profile--deptext profpadding'>{t('Profile.NoAddedLanguages')}</p>
                )}
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  )
}
export default observer(ProfileOverview)
