import { observer } from 'mobx-react-lite'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getRole } from '../../../common/roleHelper'
import history from '../../../history'
import { IListPerson, IListPersonWithSkills } from '../../../interfaces/persons/IPerson'
import { useStore } from '../../../Provider'
import { OPAccordionItem } from '../../Accordion/OPAccordionItem'
import { scrollSaver } from '../../common/scrollHelper'
import { OPLoadMore } from '../../OPcomponents/OPLoadMore'
import { Data, OPNewTable } from '../../OPcomponents/OPNewTable'

interface PersonSearchAccordionProps {
  search: string
}

const PersonSearchAccordion = (props: PersonSearchAccordionProps) => {
  const { search } = props
  const { t } = useTranslation()
  const rootStore = useStore()
  const { setFromPersonSearch, fromPersonSearch, stepperAmount } = rootStore.seachStore
  const { resetPersonProfileOverview } = rootStore.profileStore
  const { filteredPersons, getFilteredPersonsByText, loadingInitial } = rootStore.personStore
  const [tableLength, setTableLength] = useState(10)

  const [tableRows, setTableRows] = useState<Data[]>([])

  const onRowClick = (id: number | string) => {
    scrollSaver(tableRows.length, rootStore)
    resetPersonProfileOverview()

    history.push('/persons/' + id, 'search')
    setFromPersonSearch(true)
  }

  const filteredSearchPersons = useMemo(() => {
    if (loadingInitial) return []
    return filteredPersons ? getFilteredPersonsByText(search) : []
  }, [filteredPersons, getFilteredPersonsByText, loadingInitial, search])

  useEffect(() => {
    const newRows: Data[] = []
    filteredSearchPersons.forEach((person: IListPersonWithSkills) => {
      newRows.push({
        ['id']: person.personId ?? -1,
        rows: {
          [t('Persons.Name')]: { text: person.name ?? '' },
          [t('Persons.Role')]: { text: getRole(person as IListPerson) ?? '' },
        },
      })
      setTableRows(newRows)
    })
  }, [t, filteredSearchPersons])

  return (
    <OPAccordionItem
      isOpenOnInit={fromPersonSearch}
      title={`${t('Persons.Header')} (${loadingInitial ? '\u2026' : filteredSearchPersons.length})`}
      content={
        <>
          {filteredSearchPersons.length > 0 ? (
            <>
              <OPNewTable rows={tableRows} curLength={tableLength} onRowClick={onRowClick} />
              <OPLoadMore
                startingStepper={stepperAmount}
                stepper={10}
                curLength={tableLength}
                maxLength={filteredSearchPersons.length}
                filteredArray={filteredSearchPersons}
                onLoadMore={(data) => setTableLength(data)}
              />
            </>
          ) : (
            <span className='profile--notfound'>{t('NotFound.NoResults')}</span>
          )}
        </>
      }
    />
  )
}
export default observer(PersonSearchAccordion)
