import { Grid, IconButton } from '@mui/material'
import { Form, Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getErrMsg, toastNeg, toastPos } from '../../../common/toastHelper'
import { ILinkedProject, INewSkill, ISkillForm } from '../../../interfaces/skills/ISkills'
import { useStore } from '../../../Provider'
import DeleteConfirmation from '../../OPcomponents/DeleteConfirmation'
import { ExitConfirmation } from '../../OPcomponents/ExitConfirmation'
import AutoSave from '../../OPcomponents/FormikAutosave'
import { MuiButtonVariants, OPButton } from '../../OPcomponents/OPButton'
import { OPMultiline } from '../../OPcomponents/OPMultiline'
import { OPSkillbar } from '../../OPcomponents/OPSkillbar'
import { skillLevelSchema } from '../../OPcomponents/ValidationSchemas'
import '../../styles/grid.scss'
import { DeleteIconBlack } from '../../styles/Icons'
import { OPSkillsButtons } from '../OPSkillsButtons'
import '../profile.scss'

interface userSkill {
  collapseHandler?: () => void
  linkingProject?: boolean
  setLinkingProject?: (value: boolean) => void
  data?: ISkillForm
  isNew?: boolean
  setLinkedProjects?: (val: ILinkedProject[]) => void
  linkedProjects: ILinkedProject[]
}

const UserSkill = (props: userSkill) => {
  const { t } = useTranslation()
  const { collapseHandler, linkingProject, data, setLinkingProject, linkedProjects, setLinkedProjects, isNew } = props
  const rootStore = useStore()
  const { getAllUserSkills, getPersonInterests } = rootStore.personStore
  const { validatedUser } = rootStore.loginStore
  const {
    getAllSkills,
    clearEditCurrent,
    editUserSkill,
    editCurrentSkill,
    showEditSkillModalHandler,
    showAddProjectToSkillModal,
    setSaving,
    saving,
    deleteNewSkillProject,
    postNewSkillToUser,
    removeLinkedSkillProject,
    getUserDetailedSkillLinkedProjects,
    deleteUserSkill,
  } = rootStore.skillStore

  const initValues: INewSkill = {
    personId: validatedUser,
    skillId: data?.skillId ?? -1,
    skillLevel: data?.skillLevel ?? 0,
    interest: data?.interest ?? 0,
    comment: data?.comment ?? '',
  }

  const [deleteSkillConfirmation, setdeleteSkillConfirmation] = useState(false)

  const formSubmit = async (values: INewSkill) => {
    setSaving(true)
    try {
      if (isNew) {
        await postNewSkillToUser(values, validatedUser, false)
        toastPos(t('Toasts.SkillUserAdd'))
        closeHandler()
      } else {
        await editUserSkill(values, validatedUser, false)
      }
    } catch (error) {
      toastNeg(getErrMsg(error, t) || t('Toasts.SkillUserAddError'))
    } finally {
      setSaving(false)
    }
  }

  const onProjectRemoveConfirm = async (id: number) => {
    try {
      await deleteNewSkillProject(validatedUser, editCurrentSkill.skillId, id)
      removeLinkedSkillProject(id)
      toastPos(t('Toasts.SkillProjectDelete'), true)
    } catch (error) {
      toastNeg(getErrMsg(error, t) || t('Toasts.SkillProjectDeleteError'), true)
    } finally {
      const linkedProjects = (await getUserDetailedSkillLinkedProjects(validatedUser, editCurrentSkill.skillId)).skill.linkedProjects
      setLinkedProjects?.(linkedProjects)
      await getPersonInterests(validatedUser)
    }
  }

  const removeSkill = async () => {
    setSaving(true)
    try {
      await deleteUserSkill(validatedUser, editCurrentSkill.skillId, false)
      toastPos(t('Toasts.SkillUserDelete'))
    } catch (error) {
      toastNeg(getErrMsg(error, t) || t('Toasts.SkillUserDeleteError'))
    } finally {
      setSaving(false)
      closeHandler()
    }
  }

  const closeHandler = (formClose = true) => {
    if (collapseHandler) collapseHandler()
    setTimeout(async () => {
      clearEditCurrent()
      showEditSkillModalHandler(false)
      if (formClose) {
        await getAllUserSkills(validatedUser, false)
        await getAllSkills(validatedUser, false)
      }
    }, 550)
  }

  return (
    <>
      <Grid container className='gridcontainer--formcontainer__lastitempaddings'>
        <Grid item xs={12} className='griditem--formitem__titlemargin'>
          <span className='myskill--font__titlefont sini'>{t('Skills.SkillForMe')}</span>
        </Grid>
        {editCurrentSkill && (
          <Formik initialValues={initValues} onSubmit={formSubmit} validationSchema={skillLevelSchema}>
            {({ isValid, dirty, setFieldValue, values, handleChange, handleSubmit }) => (
              <Form onSubmit={handleSubmit} className='profile--form__formwidth'>
                <ExitConfirmation when={dirty} />
                <Grid item xs={12}>
                  <OPSkillbar
                    name='skillLevel'
                    hasLabel
                    label={`${t('Skills.Level')}`}
                    value={values.skillLevel ?? undefined}
                    onChange={(e, val) => setFieldValue('skillLevel', val)}
                    disabled={deleteSkillConfirmation}
                  />
                </Grid>
                <Grid item xs={12} className='griditem--formitem__inneritemmargin'>
                  <OPMultiline
                    width='28.125rem'
                    placeholder={`${t('Skills.FurtherInfo')}\u2026`}
                    label={t('Skills.FurtherInfo')}
                    hasCounter
                    maxChars={1000}
                    minRows={6}
                    value={values.comment}
                    onChange={handleChange}
                    name='comment'
                    disabled={deleteSkillConfirmation}
                  />
                </Grid>
                {!isNew && (
                  <>
                    <Grid item xs={8} className='griditem--userskill__skilltopmargin'>
                      <span className='myskill--font__titlefont subTitle'>{`${t('Skills.AddedProjects')}`}</span>
                    </Grid>
                    <Grid
                      container
                      className='gridcontainer--addskilltoproject__tablecontainer tabledata'
                      xs={12}
                      lg={6}
                      item
                      rowSpacing={2}
                    >
                      {linkedProjects.length > 0 ? (
                        linkedProjects.map((project) => (
                          <Grid
                            container
                            key={project.projectId}
                            item
                            className='gridcontainer--addskilltoproject__skillcontainer'
                            wrap='nowrap'
                            justifyContent='space-between'
                          >
                            <Grid item className='gridcontainer--addskilltoproject__hideoverflow'>
                              <span className='myskill--font__personText'>
                                {project.projectName + (project.customer ? ` (${project.customer})` : '')}
                              </span>
                            </Grid>
                            <Grid item className='griditem--deletebutton'>
                              <IconButton
                                disabled={deleteSkillConfirmation || saving}
                                className='profile--listitembtn__pointerall'
                                onClick={() => onProjectRemoveConfirm(project.projectId)}
                              >
                                <DeleteIconBlack />
                              </IconButton>
                            </Grid>
                          </Grid>
                        ))
                      ) : (
                        <Grid item xs={12}>
                          <span className='myskill--font__regtext'>{`${t('Skills.NoLinkedProjects')}`}</span>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <OPButton
                          onClick={() => setLinkingProject?.(true)}
                          label={t('Skills.AddProject')}
                          buttonVariant={MuiButtonVariants.secondary_outlined}
                          disabled={showAddProjectToSkillModal || deleteSkillConfirmation}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
                {!linkingProject && (
                  <>
                    <Grid item container xs={12} justifyContent='flex-end'>
                      <Grid item className='griditem--userskill__buttonmargin'>
                        <OPSkillsButtons
                          noPadding
                          isDelete={!isNew}
                          onDelete={() => setdeleteSkillConfirmation(true)}
                          disabled={showAddProjectToSkillModal || saving || !(isValid && dirty)}
                          bothDisabled={saving || showAddProjectToSkillModal || deleteSkillConfirmation}
                          onClose={() => {
                            closeHandler(false)
                          }}
                          isAddingSkillToSelf
                          onAutosave={!isNew}
                          onSubmit={handleSubmit}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <DeleteConfirmation
                        open={deleteSkillConfirmation}
                        whatToDelete='Skill'
                        deleteHandler={removeSkill}
                        handleClose={() => setdeleteSkillConfirmation(false)}
                      />
                    </Grid>
                  </>
                )}
                {!isNew && <AutoSave />}
              </Form>
            )}
          </Formik>
        )}
      </Grid>
    </>
  )
}

export default observer(UserSkill)
