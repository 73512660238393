import { Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { IProfileProjectInfo } from '../../../../interfaces/projects/IProjects'
import { IPersonProfileSkill } from '../../../../interfaces/skills/ISkills'
import { useStore } from '../../../../Provider'
import { OPLoadingCircle } from '../../../OPcomponents/OPLoadingcircle'
import { Data, OPNewTable } from '../../../OPcomponents/OPNewTable'
import { OPSkillbar } from '../../../OPcomponents/OPSkillbar'

import ProfileSkillForm from './ProfileSkillForm'

interface userId {
  userId: string
}

const ProfileSkills = ({ userId }: userId): JSX.Element => {
  const { t } = useTranslation()
  const rootStore = useStore()
  const {
    getPersonPrivateProjects,
    getPersonPublicProjects,
    privateWorkHistory,
    privateWorkHistoryLoading,
    publicWorkHistory,
    publicWorkHistoryLoading,
    getPersonSkills,
    loadingSkills,
    filteredSkills,
  } = rootStore.profileStore
  const { allSkills, getAllSkills } = rootStore.skillStore
  const { validatedUser } = rootStore.loginStore
  const { filteredProjects, getAllProjects } = rootStore.projectStore

  useEffect(() => {
    async function fetchData() {
      await Promise.all([
        getPersonSkills(Number(userId)),
        allSkills.length === 0 ? getAllSkills(validatedUser) : Promise.resolve(),
        filteredProjects.length === 0 ? getAllProjects(validatedUser) : Promise.resolve(),
        privateWorkHistory.length === 0 ? getPersonPrivateProjects(Number(userId)) : Promise.resolve(),
        publicWorkHistory.length === 0 ? getPersonPublicProjects(Number(userId)) : Promise.resolve(),
      ])
    }
    fetchData()
  }, [])

  const rows: Data[] = filteredSkills.map((skill) => ({
    id: skill.skillId,
    rows: {
      [t('Skills.SkillName')]: { text: skill.skillName, width: '34%' },
      [t('Skills.Level')]: { text: <OPSkillbar name={skill.skillName} value={skill.skillLevel} readonly />, width: '21%' },
      [t('Skills.Expirience')]: { text: `${skill.experienceDays} ${t('Date.ExperienceDays')} `, width: '11%' },
      [t('Skills.Projects')]: { text: skill.linkedProjectNames?.join(', '), width: '34%' },
    },
  }))

  const onRowClick = async (id: number | string) => {
    const curClicked = allSkills.find((skill) => skill.skill.skillId === id)?.skill
    const userInfo = filteredSkills.find((skill) => skill.skillId === id)
    if (curClicked) {
      const detailedProjectInfo: IProfileProjectInfo[] = await Promise.all(
        userInfo?.linkedProjectNames?.map(async (project: string) => {
          const projectId =
            publicWorkHistory?.find((p) => p.projectName === project)?.projectId ??
            privateWorkHistory?.find((p) => p.projectName === project)?.projectId
          const projectinfo = await rootStore.projectStore.getDetailedUserProjects(Number(userId), Number(projectId))
          return {
            projectId,
            projectName: projectinfo?.projectName,
            startDate: projectinfo?.startDate,
            endDate: projectinfo?.endDate,
            description: projectinfo?.description,
            workAmount: projectinfo?.expDays,
            roles: projectinfo?.roles.map((role) => role.roleName).join(', '),
          }
        }),
      )

      const skillInfo: IPersonProfileSkill = {
        skill: {
          skillName: curClicked.skillName,
          skillOverview: curClicked.skillDescription,
          skillCategory: curClicked.categoryName,
          skillTags: curClicked.skillTags,
        },
        personSkill: {
          skillLevel: userInfo.skillLevel,
          skillComment: userInfo.comment,
          linkedProjects: detailedProjectInfo,
        },
      }
      return skillInfo
    }
  }

  return (
    <Grid container spacing={5} className='gridcontainer--formcontainer__addnewpaddings'>
      {loadingSkills || privateWorkHistoryLoading || publicWorkHistoryLoading ? (
        <Grid item xs={12}>
          <OPLoadingCircle />
        </Grid>
      ) : (
        <Grid item xs={12}>
          {filteredSkills.length > 0 ? (
            <OPNewTable rows={rows} curLength={rows.length} onRowClick={onRowClick} FormBase={ProfileSkillForm} />
          ) : (
            <p className='profile--deptext profpadding'>{t('Profile.NoAddedSkills')}</p>
          )}
        </Grid>
      )}
    </Grid>
  )
}

export default observer(ProfileSkills)
