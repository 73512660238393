import { Divider, Grid, IconButton, useMediaQuery } from '@mui/material'
import { Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { getErrMsg, toastNeg, toastPos } from '../../../../common/toastHelper'
import { IDropdownSkill, ISkillInit, ISkillProject, IUserSkills } from '../../../../interfaces/skills/ISkills'
import { useStore } from '../../../../Provider'
import { theme } from '../../../../theme/CustomTheme'
import { MuiButtonVariants, OPButton } from '../../../OPcomponents/OPButton'
import { OPSkillbar } from '../../../OPcomponents/OPSkillbar'
import { OPSkillsCombobox } from '../../../OPcomponents/OPSkillsCombobox'
import { skillSchema } from '../../../OPcomponents/ValidationSchemas'
import '../../../styles/grid.scss'
import { DeleteIconBlack } from '../../../styles/Icons'
import '../../ceretedu.scss'
import '../../profile.scss'

interface AddSkillToProject {
  setProjectSkills: (values: ISkillProject[]) => void
  curProjectSkills: ISkillProject[]
  deleteProjectConfirmation?: boolean
  projectId: number
  isNewProject?: boolean
}

const AddSkillToProject = (props: AddSkillToProject): JSX.Element => {
  const { setProjectSkills, curProjectSkills, deleteProjectConfirmation, projectId, isNewProject = false } = props
  const rootStore = useStore()
  const { t } = useTranslation()
  const { deleteNewSkillProject, postNewSkillProject } = rootStore.skillStore
  const { validatedUser } = rootStore.loginStore
  const shouldWrap = useMediaQuery(theme.breakpoints.down('lg'))

  const skillInit: ISkillInit = {
    userId: validatedUser,
    skillId: -1,
    skillLevel: 0,
    text: '',
    comment: '',
  }

  const { getAllUserSkills, userSkills } = rootStore.personStore

  const dropdownSkills: IDropdownSkill[] = []

  userSkills
    .filter((skill) => !curProjectSkills.some((addedSkill) => skill.skillId === addedSkill.skillId))
    .forEach((skill: IUserSkills) =>
      dropdownSkills.push({
        key: skill.skillId,
        text: skill.skillName,
        value: skill.skillId.toString(),
        skillLevel: skill.skillLevel,
        comment: skill.comment,
      }),
    )

  const addSkill = async (values: ISkillInit) => {
    try {
      const skillToAdd: ISkillProject = {
        userId: validatedUser,
        projectId,
        skillId: values.skillId,
        text: values.text,
      }
      if (!isNewProject) {
        await postNewSkillProject(skillToAdd)
        toastPos(t('Toasts.ProjectSkillAdd'), true)
      }
      setProjectSkills([...curProjectSkills, skillToAdd])
    } catch (error) {
      toastNeg(getErrMsg(error, t) || t('Toasts.ProjectSkillAddError'), true)
    }
  }

  const deleteSkill = async (skillId: number) => {
    try {
      if (!isNewProject) {
        await deleteNewSkillProject(validatedUser, skillId, projectId)
        toastPos(t('Toasts.ProjectSkillDelete'), true)
      }
      setProjectSkills(curProjectSkills.filter((skill) => skill.skillId !== skillId))
    } catch (error) {
      toastNeg(getErrMsg(error, t) || t('Toasts.ProjectSkillDeleteError'), true)
    }
  }

  useEffect(() => {
    if (userSkills.length === 0) getAllUserSkills(validatedUser)
  }, [])

  return (
    <Grid
      container
      wrap={shouldWrap ? 'wrap' : 'nowrap'}
      columnSpacing={4}
      rowSpacing={4}
      className='gridcontainer--addskilltoproject__componentcontainer'
    >
      <Formik validateOnMount initialValues={skillInit} onSubmit={addSkill} validationSchema={skillSchema}>
        {({ setFieldValue, values, isSubmitting, resetForm, isValid, validateForm, dirty, submitForm }) => (
          <>
            <Grid item xs={12} lg={6}>
              <Grid container item xs={12} rowSpacing={2}>
                <Grid item xs={12}>
                  <span className='myskill--font__titlefont sini'>{t('Projects.SkillsUsedInProject')}</span>
                </Grid>
                <Grid item xs={12}>
                  <OPSkillsCombobox
                    name='skillId'
                    disabled={deleteProjectConfirmation}
                    placeholder={`${t('Discussions.GoalSkill')}\u2026`}
                    multiple={false}
                    curState={dropdownSkills.find((skill) => skill.key === values.skillId) || null}
                    optionList={dropdownSkills}
                    label={`${t('Discussions.GoalSkill')}`}
                    onChange={async (e, val) => {
                      if (val !== null) {
                        await setFieldValue('text', val.text ?? '')
                        await setFieldValue('skillId', val.key ?? -1)
                        await setFieldValue('skillLevel', val.skillLevel ?? 0)
                        await setFieldValue('comment', val.comment ?? '')
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <OPSkillbar readonly name='skillLvl' hasLabel label={`${t('Skills.Level')}`} value={values.skillLevel ?? undefined} />
                </Grid>
                <Grid item container xs={12}>
                  <Grid item xs={12}>
                    <p className='profile--titlecaption nopadding'>{t('Skills.SkillOverview')}</p>
                  </Grid>
                  <Grid item xs={12} className='griditem--addskilltoproject__summarycontainer'>
                    <p className='ceretedu--fonttext darker'>
                      {values?.comment?.length > 0 ? values?.comment : t('Skills.NoAddedDescription')}
                    </p>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <OPButton
                    buttonVariant={MuiButtonVariants.secondary_outlined}
                    onClick={async () => {
                      await submitForm()
                      resetForm()
                      validateForm(skillInit)
                    }}
                    disabled={!isValid || deleteProjectConfirmation || isSubmitting}
                    label={t('Buttons.AddSkill')}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Formik>
      <Grid item xs={12} lg>
        <Divider orientation={shouldWrap ? 'horizontal' : 'vertical'} />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Grid container className='gridcontainer--addskilltoproject__addedskills'>
          {curProjectSkills &&
            curProjectSkills.map((skill) => (
              <Grid item key={skill.skillId} xs={12}>
                <Grid container wrap='nowrap' className='gridcontainer--addskilltoproject__skillcontainer'>
                  <Grid xs={12} item className='profile--listitembtn__style'>
                    <span className='myskill--font__personText darker'>{skill.text}</span>
                  </Grid>
                  <Grid item>
                    <IconButton
                      className='profile--listitembtn__pointerall'
                      disabled={deleteProjectConfirmation}
                      onClick={() => deleteSkill(skill.skillId)}
                    >
                      <DeleteIconBlack />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default observer(AddSkillToProject)
