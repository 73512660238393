import { configure } from 'mobx'
import CertAndEducationStore from './certAndEducationStore/certAndEducationStore'
import KekeStore from './kekestore/kekeStore'
import LoginStore from './loginStore/loginStore'
import ManagerStore from './managerStore/managerStore'
import PartnerStore from './partnerStore/partnerStore'
import PersonStore from './personStore/personStore'
import ProfileStore from './profileStore/profileStore'
import ProjectStore from './projectStore/projectStore'
import SearchStore from './searchStore/searchStore'
import SidebarStore from './sidebarstore/sidebarStore'
import SkillStore from './skillStore/skillStore'
import UtilStore from './utilStore/utilStore'

configure({ enforceActions: 'always' })

export class RootStore {
  skillStore: SkillStore = new SkillStore()
  projectStore: ProjectStore = new ProjectStore()
  personStore: PersonStore = new PersonStore()
  loginStore: LoginStore = new LoginStore()
  certAndEducationStore: CertAndEducationStore = new CertAndEducationStore()
  profileStore: ProfileStore = new ProfileStore()
  seachStore: SearchStore = new SearchStore()
  sidebarStore: SidebarStore = new SidebarStore()
  utilStore: UtilStore = new UtilStore()
  kekeStore: KekeStore = new KekeStore()
  managerStore: ManagerStore = new ManagerStore()
  partnerStore: PartnerStore = new PartnerStore()
}
