import { Grid, ListItem, ListItemText } from '@mui/material'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../Provider'
import { MuiButtonVariants, OPButton } from '../OPcomponents/OPButton'
import { OPCheckBox } from '../OPcomponents/OPCheckBox'
import { OPLoadingCircle } from '../OPcomponents/OPLoadingcircle'
import OPSearch from '../OPcomponents/OPSearch'
import { Toast } from '../OPcomponents/Toast'
import '../Profile/myskills.scss'
import '../Profile/profile.scss'
import AddProject from './AddProject'
import './project.scss'
import ProjectTable from './ProjectTable'

const Projects = (): JSX.Element => {
  const { t } = useTranslation()
  const rootStore = useStore()

  const { validatedUser } = rootStore.loginStore
  const { getAllProjects, loadingInitial } = rootStore.projectStore
  const {
    showAddProjectModal,
    showAddPrivateProjectModal,
    showModal,
    filteredProjects,
    showModalHandler,
    setIsSearching,
    setSearchValue,
    isSearching,
  } = rootStore.projectStore

  const [showUnapproved, setShowUnapproved] = useState(false)
  const unapprovedProjects = filteredProjects.filter((project) => !project.project.approved)

  useEffect(() => {
    getAllProjects(validatedUser)
  }, [getAllProjects, validatedUser])

  useEffect(() => {
    return () => {
      setSearchValue('')
      setIsSearching(false)
      if (showModal) showModalHandler(false)
    }
  }, [setIsSearching, setSearchValue, showModal, showModalHandler])

  return (
    <Grid container spacing={2}>
      <Toast />
      <Grid item xs={12}>
        <p className='profile--titlebigfont'>{t('Navbar.Projects')}</p>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          className={'gridcontainer--myskills__titlepadding' + (showModal ? ' one' : ' none')}
        >
          <Grid item>
            <OPSearch
              placeholder={t('Projects.FindProject')}
              onSearchChange={(val) => {
                setSearchValue(val)
                if (!isSearching) setIsSearching(true)
              }}
              resetValue={() => {
                if (isSearching) setIsSearching(false)
                setSearchValue('')
              }}
            />
          </Grid>
          <Grid item>
            {showModal && !showAddProjectModal && !showAddPrivateProjectModal && (
              <OPButton
                className='projects--cancelbutton'
                label={t('Buttons.Cancel')}
                onClick={() => showModalHandler(false)}
                buttonVariant={MuiButtonVariants.error_outlined}
              />
            )}
            <OPButton
              label={t('Projects.AddNewProject')}
              disabled={showModal}
              onClick={() => showModalHandler(true)}
              buttonVariant={MuiButtonVariants.primary_outlined}
            />
          </Grid>
        </Grid>
      </Grid>
      {showModal && (
        <Grid item xs={12}>
          <AddProject />
        </Grid>
      )}
      {loadingInitial ? (
        <Grid item xs={12}>
          <OPLoadingCircle />
        </Grid>
      ) : (
        <>
          <Grid item className='projects--unapprovedpadding' xs={12}>
            {!isEmpty(unapprovedProjects) && (
              <>
                <OPCheckBox
                  label={`${t('Projects.ShowUnapprovedProjects')} (${unapprovedProjects.length})`}
                  value={showUnapproved}
                  leftPadding
                  onChangeState={() => setShowUnapproved(!showUnapproved)}
                />
                {showUnapproved && (
                  <Grid container className='gridcontainer--unapprovedlist'>
                    {unapprovedProjects.map((project) => {
                      return (
                        <ListItem key={project.project.projectId} className='projects--listitempadding'>
                          <ListItemText className='projects--listitemtext'>{project.project.name}</ListItemText>
                        </ListItem>
                      )
                    })}
                  </Grid>
                )}
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            <ProjectTable />
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default observer(Projects)
