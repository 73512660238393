import { Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import '../../history'
import { useStore } from '../../Provider'
import { scrollHelper } from '../common/scrollHelper'
import { OPLoadingCircle } from '../OPcomponents/OPLoadingcircle'
import OPSearch from '../OPcomponents/OPSearch'
import '../Profile/myskills.scss'
import '../Profile/profile.scss'
import { Toast } from '../OPcomponents/Toast'
import PersonsTable from './PersonsTable'

const Persons = (): JSX.Element => {
  const { t } = useTranslation()
  const rootStore = useStore()
  const { scrollPosition } = rootStore.seachStore
  const { isSearching, loadingInitial, getAllPersonsWithSkills, setIsSearching, setSearchValue, persons, searchValue } =
    rootStore.personStore

  useEffect(() => {
    if (persons.length === 0) getAllPersonsWithSkills()
  }, [getAllPersonsWithSkills, persons])

  useEffect(() => {
    return () => {
      scrollHelper('/persons/:userId', setSearchValue, false, rootStore)
    }
  }, [rootStore, setIsSearching, setSearchValue])

  useEffect(() => {
    if (scrollPosition) {
      setTimeout(() => window.scrollTo(0, scrollPosition), 100)
    }
  }, [])

  return (
    <Grid container spacing={4}>
      <Toast />
      <Grid item xs={12} marginBottom={'-1rem'}>
        <p className='profile--titlebigfont'>{t('Navbar.Persons')}</p>
      </Grid>
      <Grid item xs={12}>
        <OPSearch
          searchValue={searchValue}
          placeholder={t('Persons.FindPerson')}
          onSearchChange={(val: string) => {
            setSearchValue(val)
            if (!isSearching) setIsSearching(true)
          }}
          resetValue={() => {
            if (isSearching) setIsSearching(false)
            setSearchValue('')
          }}
        />
      </Grid>
      <Grid item xs={12}>
        {loadingInitial ? <OPLoadingCircle /> : <PersonsTable />}
      </Grid>
    </Grid>
  )
}

export default observer(Persons)
