import { Grid } from '@mui/material'
import { useFormikContext } from 'formik'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IUserProject } from '../../../../interfaces/projects/IProjects'
import { ISkillProject } from '../../../../interfaces/skills/ISkills'
import { useStore } from '../../../../Provider'
import { OPDateField } from '../../../OPcomponents/OPDateField'
import { OPMultiline } from '../../../OPcomponents/OPMultiline'
import { OPNumberField } from '../../../OPcomponents/OPNumberField'
import { OPRadioButton } from '../../../OPcomponents/OPRadioButton'
import { OPSkillsCombobox } from '../../../OPcomponents/OPSkillsCombobox'
import ProjectSkillLink from './ProjectSkillLink'

interface IUserSkillForm {
  setProjectSkills: (skills: ISkillProject[]) => void
  projectSkills: ISkillProject[]
  deleteProjectConfirmation?: boolean
  isNewProject?: boolean
  isTable?: boolean
}

const UserSkillForm = (props: IUserSkillForm): JSX.Element => {
  const { setProjectSkills, projectSkills, deleteProjectConfirmation, isNewProject = false, isTable = false } = props
  const { t } = useTranslation()
  const rootStore = useStore()
  const { dropdownRoles, getAllRoles } = rootStore.projectStore
  const { setFieldValue, values, handleChange, errors, touched, setTouched, dirty } = useFormikContext<IUserProject>()

  if (dropdownRoles.length === 0) getAllRoles()

  const radioOptions = [
    {
      value: '1',
      label: t('Projects.ManDays'),
    },
    {
      value: '0',
      label: t('Projects.Percentage'),
    },
  ]

  return (
    <Grid
      container
      rowSpacing={4}
      columnSpacing={2}
      alignItems='center'
      className={isTable ? 'gridcontainer--formcontainer__itempaddings' : ''}
    >
      <Grid xs={12} item>
        <span className='myskill--font__titlefont sini'>{t('Projects.ProjectForMe')}</span>
      </Grid>
      <Grid xs={6} item>
        <OPSkillsCombobox
          name='roleIds'
          label={t('Projects.ProjectRoles')}
          placeholder={t('Placeholders.Choose')}
          multiple
          onDelete={(v) =>
            setFieldValue(
              'roleIds',
              values.roleIds.filter((role) => role.key !== v.option.key),
            )
          }
          disabled={deleteProjectConfirmation}
          optionList={dropdownRoles}
          onChange={(e, v) => setFieldValue('roleIds', v)}
          curState={values.roleIds}
        />
      </Grid>
      <Grid xs={3} item>
        <OPDateField
          name='startDate'
          label={`${t('Projects.EmployerStartDate')}*`}
          disabled={deleteProjectConfirmation}
          error={touched.startDate && Boolean(errors.startDate)}
          errMessage={t(errors.startDate || '')}
          value={values.startDate ?? null}
          onChange={(newValue: Date | null) => {
            setTouched({ ...touched, startDate: true })
            setFieldValue('startDate', newValue)
          }}
        />
      </Grid>
      <Grid xs={3} item>
        <OPDateField
          name='endDate'
          label={`${t('Projects.EmployerEndDate')}`}
          disabled={deleteProjectConfirmation}
          value={values.endDate ?? null}
          error={Boolean(errors.endDate)}
          errMessage={t(errors.endDate || '')}
          onChange={(newValue: Date | null) => {
            setFieldValue('endDate', newValue)
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container alignItems='center' spacing={2}>
          <Grid item xs={4}>
            {values.usingManDays ? (
              <OPNumberField
                label={t('Projects.ProjectWorkAmount')}
                name='manDays'
                disabled={deleteProjectConfirmation}
                value={values.manDays}
                error={Boolean(errors.manDays)}
                errorText={t(errors.manDays || '')}
                onChange={(e) => setFieldValue('manDays', e ?? 0)}
              />
            ) : (
              <OPNumberField
                name='quota'
                label={t('Projects.ProjectWorkAmount')}
                minValue={0}
                maxValue={100}
                disabled={deleteProjectConfirmation}
                value={values.quota}
                error={Boolean(errors.quota)}
                errorText={t(errors.quota || '')}
                onChange={(e) => setFieldValue('quota', e ?? 0)}
              />
            )}
          </Grid>
          <Grid item xs={6} className='griditem--formitem__radiomargin'>
            <OPRadioButton
              direction='row'
              name='usingManDays'
              radioOptions={radioOptions}
              disabled={deleteProjectConfirmation}
              label={''}
              onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue('usingManDays', v.target.value === '1')
              }}
              defaultVal={values.usingManDays ? '1' : '0'}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12} item>
        <OPMultiline
          disabled={deleteProjectConfirmation}
          name='description'
          label={t('Projects.WorkDescription')}
          onChange={handleChange}
          maxChars={1000}
          placeholder={t('Projects.WorkDescription')}
          minRows={6}
          value={values.description}
          hasCounter
        />
      </Grid>
      <Grid xs={12} item>
        <ProjectSkillLink
          projectId={values.projectId}
          deleteProjectConfirmation={deleteProjectConfirmation}
          setProjectSkills={setProjectSkills}
          curProjectSkills={projectSkills}
          isNewProject={isNewProject}
        />
      </Grid>
      <Grid item />
    </Grid>
  )
}
export default observer(UserSkillForm)
