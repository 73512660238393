import { TFunction } from 'i18next'
import { toast } from 'react-toastify'
import { ErrorVector, SuccessVector } from '../components/styles/Icons'

export const getErrMsg = (error: any, t: TFunction) => {
  if (typeof error !== 'string') return t('Errors.0')
  const errorMessage = error.match(/\((.*)\)/)?.pop()
  if (errorMessage != null) {
    return t(`Errors.${errorMessage}`, {
      defaultValue: null,
    })
  }
  return null
}

// toast on success, onModal=true, when toasting while modal on
export const toastPos = (msg: string, onModal = false) => {
  toast.success(msg, {
    hideProgressBar: true,
    closeButton: false,
    pauseOnFocusLoss: false,
    icon: SuccessVector,
    containerId: onModal ? 'modal' : 'main',
    className: 'toast-container success',
  })
}

// toast on error
export const toastNeg = (msg: string, onModal = false) => {
  toast.error(msg, {
    hideProgressBar: true,
    closeButton: false,
    pauseOnFocusLoss: false,
    icon: ErrorVector,
    containerId: onModal ? 'modal' : 'main',
    className: 'toast-container error',
  })
}
