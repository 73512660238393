import { t } from 'i18next'
import * as yup from 'yup'

const min = '1900-01-01'
const max = '2100-12-31'

export const newProjectSchema = yup.object({
  name: yup.string().required('Validation.ProjectName'),
  branchId: yup.number().typeError('Validation.ProjectBranch').required('Validation.ProjectBranch').min(1, 'Validation.ProjectBranch'),
  description: yup.string().max(4000).nullable(),
})

export const newNetumProjectSchema = yup.object({
  name: yup.string().required(t('Validation.ProjectName')),
  branchId: yup.number().min(0).required(t('Validation.ProjectBranch')).nullable(),
  startDate: yup.date().typeError('Validation.InvalidDate').required(t('Validation.StartDate')).min(new Date(min)).max(new Date(max)),
  endDate: yup.date().typeError('Validation.InvalidDate').nullable().min(yup.ref('startDate'), 'Validation.MaxDate'),
  description: yup.string().max(1000).nullable(),
  worth: yup.number().min(0),
  totalWorkAmount: yup.number().min(0),
})
export const newPersonalProjectSchema = yup.object({
  startDate: yup
    .date()
    .typeError('Validation.InvalidDate')
    .required('Validation.StartDate')
    .min(new Date(min), 'Validation.InvalidDate')
    .max(new Date(max), 'Validation.InvalidDate'),
  endDate: yup.date().typeError('Validation.InvalidDate').nullable().min(yup.ref('startDate'), 'Validation.MinDateStart'),
  description: yup.string().max(1000).nullable(),
  manDays: yup.number().min(0, 'NumberField.NegativeError').nullable(),
  quota: yup.number().min(0, 'NumberField.NegativeError').max(100, 'NumberField.ErrorMax').nullable(),
})

export const newEmployerSchema = yup.object({
  name: yup.string().required('Validation.EmployerName').max(100),
  jobTitle: yup.string().required('Validation.EmployerJobTitle').max(150),
  employerId: yup.number().required('Validation.Employer'),
  startDate: yup
    .date()
    .required(t('Validation.EmployerStartDate'))
    .typeError('Validation.InvalidDate')
    .required('Validation.StartDate')
    .min(new Date(min), 'Validation.InvalidDate')
    .max(new Date(max), 'Validation.InvalidDate'),
  endDate: yup.date().typeError('Validation.InvalidDate').nullable().min(yup.ref('startDate'), 'Validation.MinDateStart'),
})

export const skillSchema = yup.object({
  skillId: yup.number().required(t('Validation.Skill')).min(0),
  skillLevel: yup.number().required(t('Validation.SkillLevel')).min(1),
})

export const newSkillSchema = yup.object({
  name: yup.string().required(t('Validation.SkillName')).max(100),
  description: yup.string().required(t('Validation.SkillDescription')).max(1000),
  addSkillToUser: yup.boolean(),
  skillLevel: yup.number().when('addSkillToUser', {
    is: true,
    then: yup.number().required(t('Validation.SkillLevel')).min(1),
    otherwise: yup.number(),
  }),
})

export const editSkillSchema = yup.object({
  skillName: yup.string().trim().required(t('Validation.SkillName')).max(100),
  skillDescription: yup.string().trim().required(t('Validation.SkillDescription')).max(1000),
})

export const overviewSchema = yup.object({
  title: yup.string().required('Validation.EmployerJobTitle'),
  summary: yup.string().max(4000),
})

export const skillLevelSchema = yup.object({
  skillLevel: yup.number().required(t('Validation.SkillLevel')).min(1),
})

export const certSchema = yup.object({
  name: yup.string().required('Validation.CertificationName'),
  school: yup.string().required('Validation.CertificationGranterName'),
  startDate: yup
    .date()
    .typeError('Validation.InvalidDate')
    .required('Validation.StartDate')
    .min(new Date(min), 'Validation.InvalidDate')
    .max(new Date(max), 'Validation.InvalidDate'),
  expirationDate: yup.date().typeError('Validation.InvalidDate').min(yup.ref('startDate'), 'Validation.MinDateCert').nullable(),
})

export const schoolSchema = yup.object({
  degree: yup.string().required('Validation.EducationName'),
  school: yup.string().required('Validation.EducationSchool'),
  startDate: yup
    .date()
    .typeError('Validation.InvalidDate')
    .required('Validation.StartDate')
    .min(new Date(min), 'Validation.InvalidDate')
    .max(new Date(max), 'Validation.InvalidDate'),
  endDate: yup.date().typeError('Validation.InvalidDate').min(yup.ref('startDate'), 'Validation.MinDateStart').nullable(),
})

export const langSchema = yup.object({
  languageId: yup.number().required(),
  languageName: yup.string().required('Validation.Language'),
  languageLevel: yup.string().required('Validation.LanguageLevel'),
  description: yup.string().max(4000).nullable(),
})

export const privateWorkHistorySchema = yup.object({
  employer: yup.object().concat(newEmployerSchema),
  project: yup.object().concat(newProjectSchema),
  userProject: yup.object().concat(newPersonalProjectSchema),
})

export const newProjectAndEmployerSchema = newProjectSchema.concat(newPersonalProjectSchema).concat(newEmployerSchema)
