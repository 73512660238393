import { matchPath } from 'react-router'
import { RootStore } from '../../stores/storesContext'

export const scrollHelper = (path: string, setSearchValue: (val: string) => void, shouldReset: boolean, store: RootStore) => {
  const { setScrollPosition, setStepperAmount } = store.seachStore
  const isWantedRoute = matchPath(window.location.pathname, {
    path,
    exact: true,
    strict: false,
  })
  if (shouldReset) {
    setScrollPosition(undefined)
    setStepperAmount(undefined)
  }
  if (!isWantedRoute) {
    setSearchValue('')
  }
}

export const scrollSaver = (stepperAmount: number, store: RootStore) => {
  const { setScrollPosition, setStepperAmount } = store.seachStore
  const scrollPosition = window.scrollY
  setScrollPosition(scrollPosition)
  setStepperAmount(stepperAmount)
}
