import CircularProgress from '@mui/material/CircularProgress'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import agent from '../../api/agent'
import { toastNeg } from '../../common/toastHelper'
import { IComment, IDiscussion, IListDiscussion } from '../../interfaces/discussions/IDiscussion'
import { useStore } from '../../Provider'
import '../styles/developmentPage.scss'
import { OPAccordionContent } from '../Accordion/OPAccordionContent'
import { OPAccordionItem } from '../Accordion/OPAccordionItem'
import { MuiButtonVariants, OPButton } from '../OPcomponents/OPButton'
import { OPDivider } from '../OPcomponents/OPDivider'
import { OPTextField } from '../OPcomponents/OPTextField'

interface MiniDiscussionProps {
  discussion: IListDiscussion
}

export const MiniDiscussion = observer((props: MiniDiscussionProps): JSX.Element => {
  const { discussion } = props
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const rootStore = useStore()
  const { validatedUser } = rootStore.loginStore
  const {
    isEditFeedbackGoal,
    isConfirmDeleteGoalVisible,
    isConfirmReadySectionVisible,
    isNewDevGoalVisible,
    isNewFBGoalVisible,
    isNewDiscussionFBGoalVisible,
    isNewDiscussionDevGoalVisible,
    isNewMiniDiscussionVisible,
    isNewDevDiscussionVisible,
    postDiscussionComment,
    setIsAddCommentVisible,
    isAddCommentVisible,
    setSelectedMiniDiscussionId,
    selectedMiniDiscussionId,
    isConfirmSendDiscussionVisible,
    isEditColleagueFeedback,
    selectDiscussion,
    selectedDiscussion,
  } = rootStore.kekeStore

  const [commentsLoading, setCommentsLoading] = useState<boolean>(true)
  const [comments, setComments] = useState<IComment[]>([])
  const [newCommentValue, setNewCommentValue] = useState('')
  const [discussionData, setDiscussionData] = useState<IDiscussion>()
  const [loadingData, setLoadingData] = useState<boolean>(false)
  const [accordionOpen, setAccordionOpen] = useState<boolean>(false)

  const divRef = useRef<HTMLDivElement>(null)

  const getComments = async () => {
    setCommentsLoading(true)
    agent.Discussions.getDiscussionComments(discussion.developmentDiscussionId).then((response) => {
      setComments(response)
      setCommentsLoading(false)
    })
  }

  const getDiscussionData = async () => {
    setLoadingData(true)
    agent.Discussions.getDiscussion(discussion.developmentDiscussionId)
      .then((response: IDiscussion) => {
        setDiscussionData(response)
      })
      .catch((err) => {
        toastNeg('Error getting discussion data. ' + err)
      })
      .finally(() => setLoadingData(false))
  }

  const handleAddComment = async () => {
    await postDiscussionComment(discussion.developmentDiscussionId, { personId: validatedUser, comment: newCommentValue })
    setIsAddCommentVisible(false)
    setNewCommentValue('')
    selectDiscussion(undefined)
    getComments()
  }

  useEffect(() => {
    if (accordionOpen) {
      divRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [accordionOpen])

  return (
    <div>
      <OPAccordionItem
        lockedOpen={isAddCommentVisible && selectedDiscussion?.developmentDiscussionId === discussion.developmentDiscussionId}
        insideAccordion
        title={`Mini ${t('Discussions.Kehityskeskustelu').toLowerCase()} ${moment(discussion.date).locale(language).format('L')}`}
        created={`${t('Discussions.GoalCreated')} ${moment(discussion.created).locale(language).format('L')}`}
        onFirstOpen={() => {
          getDiscussionData()
          getComments()
        }}
        setIsOpen={setAccordionOpen}
        content={
          <div>
            {loadingData ? (
              <div className='loaderDiv'>
                <CircularProgress />
              </div>
            ) : (
              <div ref={divRef} className='scrollPadding'>
                <OPAccordionContent listContent={{ label: t('Discussions.DiscussionMiniNote'), specs: discussionData?.note ?? '' }} />
                <div className='addCommentsDiv'>
                  <div>
                    <p>{t('Discussions.GoalComments')}:</p>
                  </div>

                  <div>
                    <OPButton
                      label={t('Buttons.AddComment')}
                      disabled={
                        isEditFeedbackGoal ||
                        isConfirmDeleteGoalVisible ||
                        isAddCommentVisible ||
                        isConfirmReadySectionVisible ||
                        isNewDevGoalVisible ||
                        isNewFBGoalVisible ||
                        isNewDiscussionFBGoalVisible ||
                        isNewDiscussionDevGoalVisible ||
                        isNewMiniDiscussionVisible ||
                        isNewDevDiscussionVisible ||
                        isConfirmSendDiscussionVisible ||
                        isEditColleagueFeedback
                      }
                      buttonVariant={MuiButtonVariants.secondary_outlined}
                      onClick={() => {
                        selectDiscussion(discussion)
                        setSelectedMiniDiscussionId(discussion.developmentDiscussionId)
                        setIsAddCommentVisible(true)
                      }}
                    />
                  </div>
                </div>
                {isAddCommentVisible && selectedMiniDiscussionId === discussion.developmentDiscussionId && (
                  <div className='newCommentDiv'>
                    <OPTextField
                      maxLength={2000}
                      value={newCommentValue}
                      fullWidth
                      label={t('Discussions.NewComment')}
                      onChange={(e) => setNewCommentValue(e.target.value)}
                    />
                    <div className='newCommentButtons'>
                      <div className='hideCommentButton'>
                        <OPButton
                          buttonVariant={MuiButtonVariants.error_outlined}
                          label={t('Buttons.Cancel')}
                          onClick={() => {
                            setNewCommentValue('')
                            selectDiscussion(undefined)
                            setSelectedMiniDiscussionId(undefined)
                            setIsAddCommentVisible(false)
                          }}
                        />
                      </div>
                      <div>
                        <OPButton
                          disabled={newCommentValue === ''}
                          buttonVariant={MuiButtonVariants.primary}
                          onClick={handleAddComment}
                          label={t('Buttons.AddComment')}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className='commentSection'>
                  {commentsLoading ? (
                    <div className='loaderDiv'>
                      <CircularProgress />
                    </div>
                  ) : comments.length === 0 ? (
                    <div>{t('Discussions.NoComments')}</div>
                  ) : (
                    comments.map((c) => {
                      return (
                        <div className='singleComment' key={c.commentId}>
                          {c.personName} {moment(c.date).locale(language).format('L')} {t('Discussions.CommentMidString')} {c.message}
                        </div>
                      )
                    })
                  )}
                </div>
              </div>
            )}
            <OPDivider />
          </div>
        }
      />
    </div>
  )
})
