import { Grid } from '@mui/material'
import { Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getErrMsg, toastNeg, toastPos } from '../../../common/toastHelper'
import { INewPublication, IPersonPublications } from '../../../interfaces/certAndEducation/certAndEducation'
import { useStore } from '../../../Provider'
import TextEditor from '../../MySkills/PersonalCertificates/Editor'
import { ExitConfirmation } from '../../OPcomponents/ExitConfirmation'
import { OPMultiline } from '../../OPcomponents/OPMultiline'
import '../../styles/grid.scss'
import { OPSkillsButtons } from '../OPSkillsButtons'

const NewPublication = (): JSX.Element => {
  const { t } = useTranslation()
  const rootStore = useStore()

  const { validatedUser } = rootStore.loginStore
  const { postNewPublications, isNewPublication, getPersonPublications, userPublications } = rootStore.certAndEducationStore

  const [userPublication, setUserPublication] = useState(userPublications)

  const initialValues: IPersonPublications = {
    personId: validatedUser,
    publicationId: -1,
    publicationTitle: '',
    publicationDescription: '',
  }

  const savePublication = async (values: IPersonPublications) => {
    try {
      const publication: INewPublication = {
        title: values.publicationTitle,
        description: userPublication,
      }
      await postNewPublications(validatedUser, publication)
      toastPos(t('Toasts.CreateSuccess'))
    } catch (err) {
      toastNeg(getErrMsg(err, t) || t('Toasts.CreateError'))
    } finally {
      getPersonPublications(validatedUser)
      isNewPublication(false)
    }
  }

  return (
    <Formik initialValues={initialValues} onSubmit={savePublication}>
      {({ values, handleChange, submitForm, isSubmitting, dirty, isValid }) => (
        <Grid container className='griditem--certedu__publicationpadding'>
          <ExitConfirmation when={dirty} />
          <Grid item xs={12} className='griditem--formitem__zindex griditem--addskilltoproject__titlecontainer'>
            <Grid item xs={12}>
              <OPMultiline
                width='13.75rem'
                name='publicationTitle'
                value={values.publicationTitle}
                label={t('CertAndEducation.PublicationTitle')}
                onChange={handleChange}
                maxChars={100}
              />
            </Grid>
            <Grid className='griditem--formitem__formtoppadding'>
              <TextEditor
                charLimit={4000}
                placeholderString={t('CertAndEducation.PublicationsEditorPlaceholder')}
                setValue={setUserPublication}
                value={values.publicationDescription ? values.publicationDescription : ''}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} className='griditem--addbutton__removebutton'>
            <OPSkillsButtons
              noPadding
              disabled={!(isValid && dirty) || isSubmitting}
              bothDisabled={isSubmitting}
              onClose={() => isNewPublication(false)}
              onSubmit={submitForm}
            />
          </Grid>
        </Grid>
      )}
    </Formik>
  )
}

export default observer(NewPublication)
